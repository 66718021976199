import { FormInstance } from "antd/es/form";
import { Guid } from "guid-typescript";
import moment from "moment";
import React from "react";
import Swal from "sweetalert2";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IBandrolBilgileri_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IBandrolBilgileri_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GoBack: any;
  GoBack_dummy: any;
  MyContext: any[];
  MyContext_dummy: any[];
  BandrolBilgileriById: any[];
  BandrolBilgileriById_dummy: any[];
  DokumanlarByKartIdMATBAAYAZISI: any[];
  DokumanlarByKartIdMATBAAYAZISI_dummy: any[];
  EserBilgileri_TeklifEdenKisiEmail: any[];
  EserBilgileri_TeklifEdenKisiEmail_dummy: any[];
  EserBilgileri_TeklifTalepEdenEmail: any[];
  EserBilgileri_TeklifTalepEdenEmail_dummy: any[];
  TeklifEdenSirketAd: any[];
  TeklifEdenSirketAd_dummy: any[];
  TeklifAlanSirketAd: any[];
  TeklifAlanSirketAd_dummy: any[];
  GenelParametreler: any[];
  GenelParametreler_dummy: any[];
  EserDetay_BandrolStokSUM: any[];
  EserDetay_BandrolStokSUM_dummy: any[];
  ChangeEnabledOf: any;
  ChangeEnabledOf_dummy: any;
  ChangeVisibilityOf: string;
  ChangeVisibilityOf_dummy: string;
  SaveRecord: number;
  SaveRecord_dummy: number;
  Notify: boolean;
  Notify_dummy: boolean;
  SendMailSettings: any[];
  SendMailSettings_dummy: any[];
  SendMail: boolean;
  SendMail_dummy: boolean;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  UploadFile: any;
  UploadFile_dummy: any;
  DokumanInsert: number;
  DokumanInsert_dummy: number;
  DokumanDeleteById: number;
  DokumanDeleteById_dummy: number;
  DokumanById: any[];
  DokumanById_dummy: any[];
  PrintPdf: string;
  PrintPdf_dummy: string;
  EImzaliBelgeKontrol: number;
  EImzaliBelgeKontrol_dummy: number;
  BandrolTeslimKontrol: number;
  BandrolTeslimKontrol_dummy: number;
  getEserBilgileriIdByTeklifTalepKartID: any[];
  getEserBilgileriIdByTeklifTalepKartID_dummy: any[];
  EserDetay_BandrolStokSave: number;
  EserDetay_BandrolStokSave_dummy: number;
  isComp934131Visible: "visible" | "hidden";
  isComp703152Visible: "visible" | "hidden";
  isComp371115Visible: "visible" | "hidden";
  isComp661547Visible: "visible" | "hidden";
  isComp260479Visible: "visible" | "hidden";
  isComp681796Visible: "visible" | "hidden";
  isComp912001Visible: "visible" | "hidden";
  isComp674618Visible: "visible" | "hidden";
  isComp151329Visible: "visible" | "hidden";
  isComp92399Visible: "visible" | "hidden";
  isComp119718Visible: "visible" | "hidden";
  isComp511623Visible: "visible" | "hidden";
  isComp980045Visible: "visible" | "hidden";
  isComp44547Visible: "visible" | "hidden";
  isComp341166Enabled: "enabled" | "disabled";
  isComp800452Enabled: "enabled" | "disabled";
  isComp706885Enabled: "enabled" | "disabled";
  isComp339647Enabled: "enabled" | "disabled";
  isComp864541Enabled: "enabled" | "disabled";
}

export class BandrolBilgileri_ScreenBase extends React.PureComponent<IBandrolBilgileri_ScreenProps, any> {
  bandrolbilgileri_711309_value_kuikaTableRef: React.RefObject<any>;
  ml = [
    {
      Id: "41cd36a4-5b07-cfca-4f7f-f48019ff4ff3",
      Name: "tr_TR",
      ShortName: "Türkçe",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "0069cee9-16ca-4a5a-aab9-dd58af96ca96", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "39b7f405-86ab-4eee-8bad-1faacd8f535c",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 588801, PropertyName: "value", Value: "Bandrol Bilgileri" },
        { Id: 331891, PropertyName: "value", Value: "Bandrol Talep Numarası" },
        { Id: 142527, PropertyName: "value", Value: "Bandrol Stokda Var!" },
        { Id: 608252, PropertyName: "value", Value: "Matbaadaki Tahmini Stok Adet :" },
        { Id: 93498, PropertyName: "label", Value: "Matbaa'ya Onaya Gönder" },
        { Id: 661547, PropertyName: "value", Value: "Ek Bandrol Başvurusu" },
        { Id: 260479, PropertyName: "label", Value: "YAYFED Başvuru Yapıldı" },
        { Id: 102776, PropertyName: "value", Value: "Yeni veya Ek Bandrol Başvurusu İçin" },
        { Id: 526033, PropertyName: "label", Value: "Matbaa kodu  E-imzalı Belgesini İste" },
        { Id: 738819, PropertyName: "value", Value: "Kendi Stoğumdan Teslim Edilen Bandrol Miktarı (Adet)" },
        { Id: 314227, PropertyName: "value", Value: "Matbaa E-imzalı Belge Bilgisi" },
        { Id: 181639, PropertyName: "value", Value: "Bandrol Matbaa Yazısını İndir" },
        { Id: 387082, PropertyName: "value", Value: "Matbaa e-imzalı belgesi (PDF Upload)" },
        { Id: 711309, PropertyName: "nodatafoundmessage", Value: "Matbaa Yazısı Bulunamadı!" },
        { Id: 400610, PropertyName: "title", Value: "Title" },
        { Id: 143182, PropertyName: "value", Value: "[datafield:dosyaad]" },
        { Id: 965626, PropertyName: "label", Value: "Kaydet & Gönder" },
        { Id: 324824, PropertyName: "label", Value: "YAYFED Yeni Başvuru Yapıldı" },
        { Id: 945884, PropertyName: "value", Value: "Matbaa ya Bandrollerin Gönderimi" },
        { Id: 230866, PropertyName: "value", Value: "Yayıncı Kargo firma" },
        { Id: 328784, PropertyName: "value", Value: "Yayıncı Kargo Takip No" },
        { Id: 866366, PropertyName: "value", Value: "Teslim Edilen Bandrol Miktarı (Adet)" },
        { Id: 773582, PropertyName: "label", Value: "Matbaa'ya Elden Teslim Edildi." },
        { Id: 287359, PropertyName: "label", Value: "Matbaa'ya Gönderildi" },
        { Id: 301677, PropertyName: "value", Value: "Matbaa Kontrol / Teslim Alımı" },
        { Id: 786758, PropertyName: "value", Value: "Teslim Alınan Bandrol Miktarı (Adet)" },
        { Id: 185002, PropertyName: "label", Value: "Bandrol Teslim Alındı- Kontrol & Onayla" },
        { Id: 642664, PropertyName: "value", Value: "Matbaa Kontrol / Teslim Alımı" },
        { Id: 951131, PropertyName: "value", Value: "Teslim Alınan Bandrol Miktarı (Adet)" },
        { Id: 375370, PropertyName: "label", Value: "Bandrol Kontrol İçin Geri Gönder" },
        { Id: 536574, PropertyName: "label", Value: "Bandrol Kontrol & Onayla" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.bandrolbilgileri_711309_value_kuikaTableRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GoBack: "",
      MyContext: [],
      BandrolBilgileriById: [],
      DokumanlarByKartIdMATBAAYAZISI: [],
      EserBilgileri_TeklifEdenKisiEmail: [],
      EserBilgileri_TeklifTalepEdenEmail: [],
      TeklifEdenSirketAd: [],
      TeklifAlanSirketAd: [],
      GenelParametreler: [],
      EserDetay_BandrolStokSUM: [],
      ChangeEnabledOf: "",
      ChangeVisibilityOf: "",
      SaveRecord: false,
      Notify: false,
      SendMailSettings: [],
      SendMail: false,
      NAVIGATE: "",
      UploadFile: "",
      DokumanInsert: 0,
      DokumanDeleteById: 0,
      DokumanById: [],
      PrintPdf: "",
      EImzaliBelgeKontrol: 0,
      BandrolTeslimKontrol: 0,
      getEserBilgileriIdByTeklifTalepKartID: [],
      EserDetay_BandrolStokSave: 0,
      isComp934131Visible: "hidden",
      isComp703152Visible: "hidden",
      isComp371115Visible: "hidden",
      isComp661547Visible: "hidden",
      isComp260479Visible: "hidden",
      isComp681796Visible: "hidden",
      isComp912001Visible: "hidden",
      isComp674618Visible: "hidden",
      isComp151329Visible: "hidden",
      isComp92399Visible: "hidden",
      isComp119718Visible: "hidden",
      isComp511623Visible: "hidden",
      isComp980045Visible: "hidden",
      isComp44547Visible: "hidden",
      isComp341166Enabled: "enabled",
      isComp800452Enabled: "enabled",
      isComp706885Enabled: "enabled",
      isComp339647Enabled: "enabled",
      isComp864541Enabled: "enabled"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("bandrolbilgileri", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.BandrolBilgileriPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("bandrolbilgileri", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("bandrolbilgileri", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.BandrolBilgileriPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      bandrolbilgileri_341166_value: this.state.BandrolBilgileriById?.at?.(0)?.bandrolTalepNo ?? undefined,
      bandrolbilgileri_864541_value: this.state.BandrolBilgileriById?.at?.(0)?.bandrolAdetTeslimEdilen ?? undefined,
      bandrolbilgileri_800452_value: this.state.BandrolBilgileriById?.at?.(0)?.kargoFirma ?? undefined,
      bandrolbilgileri_706885_value: this.state.BandrolBilgileriById?.at?.(0)?.kargoNo ?? undefined,
      bandrolbilgileri_339647_value: this.state.BandrolBilgileriById?.at?.(0)?.bandrolAdetTeslimEdilen ?? undefined,
      bandrolbilgileri_729052_value: this.state.BandrolBilgileriById?.at?.(0)?.bandrolAdet ?? undefined,
      bandrolbilgileri_640454_value: this.state.BandrolBilgileriById?.at?.(0)?.bandrolAdet ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  BandrolBilgileriPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      ),
      KartId_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      ),
      prmtekliftalepkartId_3: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      ),
      prmtekliftalepkartId_4: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      ),
      prmTeklifTalepKartID_5: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      ),
      prmTeklifTalepKartID_6: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      ),
      EserBilgileriID_8: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "BandrolBilgileri/BandrolBilgileriPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    stateVars.BandrolBilgileriById = result?.data.bandrolBilgileriById;
    formVars.bandrolbilgileri_341166_value = ReactSystemFunctions.toString(
      this,
      stateVars.BandrolBilgileriById?.length > 0
        ? stateVars.BandrolBilgileriById[0].bandrolTalepNo
        : this.state.BandrolBilgileriById?.length > 0
        ? this.state.BandrolBilgileriById[0].bandrolTalepNo
        : null
    );
    formVars.bandrolbilgileri_864541_value = ReactSystemFunctions.value(
      this,
      stateVars.BandrolBilgileriById?.length > 0
        ? stateVars.BandrolBilgileriById[0].bandrolAdetTeslimEdilen
        : this.state.BandrolBilgileriById?.length > 0
        ? this.state.BandrolBilgileriById[0].bandrolAdetTeslimEdilen
        : null
    );
    formVars.bandrolbilgileri_800452_value = ReactSystemFunctions.toString(
      this,
      stateVars.BandrolBilgileriById?.length > 0
        ? stateVars.BandrolBilgileriById[0].kargoFirma
        : this.state.BandrolBilgileriById?.length > 0
        ? this.state.BandrolBilgileriById[0].kargoFirma
        : null
    );
    formVars.bandrolbilgileri_706885_value = ReactSystemFunctions.toString(
      this,
      stateVars.BandrolBilgileriById?.length > 0
        ? stateVars.BandrolBilgileriById[0].kargoNo
        : this.state.BandrolBilgileriById?.length > 0
        ? this.state.BandrolBilgileriById[0].kargoNo
        : null
    );
    formVars.bandrolbilgileri_339647_value = ReactSystemFunctions.value(
      this,
      stateVars.BandrolBilgileriById?.length > 0
        ? stateVars.BandrolBilgileriById[0].bandrolAdetTeslimEdilen
        : this.state.BandrolBilgileriById?.length > 0
        ? this.state.BandrolBilgileriById[0].bandrolAdetTeslimEdilen
        : null
    );
    formVars.bandrolbilgileri_729052_value = ReactSystemFunctions.value(
      this,
      stateVars.BandrolBilgileriById?.length > 0
        ? stateVars.BandrolBilgileriById[0].bandrolAdet
        : this.state.BandrolBilgileriById?.length > 0
        ? this.state.BandrolBilgileriById[0].bandrolAdet
        : null
    );
    formVars.bandrolbilgileri_640454_value = ReactSystemFunctions.value(
      this,
      stateVars.BandrolBilgileriById?.length > 0
        ? stateVars.BandrolBilgileriById[0].bandrolAdet
        : this.state.BandrolBilgileriById?.length > 0
        ? this.state.BandrolBilgileriById[0].bandrolAdet
        : null
    );
    stateVars.isComp703152Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.BandrolBilgileriById?.length > 0
            ? stateVars.BandrolBilgileriById[0].isButtonsYAYFED_Visibility
            : this.state.BandrolBilgileriById?.length > 0
            ? this.state.BandrolBilgileriById[0].isButtonsYAYFED_Visibility
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp681796Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.BandrolBilgileriById?.length > 0
            ? stateVars.BandrolBilgileriById[0].isrwBelgeMatbaa_Visibility
            : this.state.BandrolBilgileriById?.length > 0
            ? this.state.BandrolBilgileriById[0].isrwBelgeMatbaa_Visibility
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp912001Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.BandrolBilgileriById?.length > 0
            ? stateVars.BandrolBilgileriById[0].isButtonsBelgeMatbaa_Visibility
            : this.state.BandrolBilgileriById?.length > 0
            ? this.state.BandrolBilgileriById[0].isButtonsBelgeMatbaa_Visibility
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp674618Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.BandrolBilgileriById?.length > 0
            ? stateVars.BandrolBilgileriById[0].isButtonsBelgeYayinci_Visibility
            : this.state.BandrolBilgileriById?.length > 0
            ? this.state.BandrolBilgileriById[0].isButtonsBelgeYayinci_Visibility
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp151329Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.BandrolBilgileriById?.length > 0
            ? stateVars.BandrolBilgileriById[0].isrwTeslimKabulOnay_Visibility
            : this.state.BandrolBilgileriById?.length > 0
            ? this.state.BandrolBilgileriById[0].isrwTeslimKabulOnay_Visibility
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp92399Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.BandrolBilgileriById?.length > 0
            ? stateVars.BandrolBilgileriById[0].isButtonsTeslim_Visibility
            : this.state.BandrolBilgileriById?.length > 0
            ? this.state.BandrolBilgileriById[0].isButtonsTeslim_Visibility
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp119718Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.BandrolBilgileriById?.length > 0
            ? stateVars.BandrolBilgileriById[0].isButtonsKabulOnay_Visibility
            : this.state.BandrolBilgileriById?.length > 0
            ? this.state.BandrolBilgileriById[0].isButtonsKabulOnay_Visibility
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp511623Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.BandrolBilgileriById?.length > 0
            ? stateVars.BandrolBilgileriById[0].isButtonsKabulOnay_Visibility
            : this.state.BandrolBilgileriById?.length > 0
            ? this.state.BandrolBilgileriById[0].isButtonsKabulOnay_Visibility
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp980045Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.BandrolBilgileriById?.length > 0
            ? stateVars.BandrolBilgileriById[0].isButtonsKabulOnay_Visibility
            : this.state.BandrolBilgileriById?.length > 0
            ? this.state.BandrolBilgileriById[0].isButtonsKabulOnay_Visibility
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp44547Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.BandrolBilgileriById?.length > 0
            ? stateVars.BandrolBilgileriById[0].isButtonsOnay_Visibility
            : this.state.BandrolBilgileriById?.length > 0
            ? this.state.BandrolBilgileriById[0].isButtonsOnay_Visibility
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.DokumanlarByKartIdMATBAAYAZISI = result?.data.dokumanlarByKartIdMATBAAYAZISI;

    stateVars.EserBilgileri_TeklifEdenKisiEmail = result?.data.eserBilgileri_TeklifEdenKisiEmail;
    stateVars.EserBilgileri_TeklifTalepEdenEmail = result?.data.eserBilgileri_TeklifTalepEdenEmail;
    stateVars.TeklifEdenSirketAd = result?.data.teklifEdenSirketAd;
    stateVars.TeklifAlanSirketAd = result?.data.teklifAlanSirketAd;
    stateVars.GenelParametreler = result?.data.genelParametreler;
    stateVars.EserDetay_BandrolStokSUM = result?.data.eserDetay_BandrolStokSUM;
    formVars.bandrolbilgileri_985773_value = ReactSystemFunctions.toString(
      this,
      stateVars.EserDetay_BandrolStokSUM?.length > 0
        ? stateVars.EserDetay_BandrolStokSUM[0].bakiyeAdet
        : this.state.EserDetay_BandrolStokSUM?.length > 0
        ? this.state.EserDetay_BandrolStokSUM[0].bakiyeAdet
        : null
    );
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.BandrolBilgileriPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  BandrolBilgileriPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.BandrolBilgileriById?.length > 0
            ? stateVars.BandrolBilgileriById[0].isButtonsKabulOnay_Visibility
            : this.state.BandrolBilgileriById?.length > 0
            ? this.state.BandrolBilgileriById[0].isButtonsKabulOnay_Visibility
            : null
        ),
        "1"
      )
    ) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp341166Enabled", "disabled");

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BandrolBilgileriPageInit2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BandrolBilgileriPageInit2_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BandrolBilgileriPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.BandrolBilgileriById?.length > 0
            ? stateVars.BandrolBilgileriById[0].isButtonsKabulOnay_Visibility
            : this.state.BandrolBilgileriById?.length > 0
            ? this.state.BandrolBilgileriById[0].isButtonsKabulOnay_Visibility
            : null
        ),
        "1"
      )
    ) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp800452Enabled", "disabled");

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BandrolBilgileriPageInit3_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BandrolBilgileriPageInit3_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BandrolBilgileriPageInit3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.BandrolBilgileriById?.length > 0
            ? stateVars.BandrolBilgileriById[0].isButtonsKabulOnay_Visibility
            : this.state.BandrolBilgileriById?.length > 0
            ? this.state.BandrolBilgileriById[0].isButtonsKabulOnay_Visibility
            : null
        ),
        "1"
      )
    ) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp706885Enabled", "disabled");

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BandrolBilgileriPageInit4_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BandrolBilgileriPageInit4_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BandrolBilgileriPageInit4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.BandrolBilgileriById?.length > 0
            ? stateVars.BandrolBilgileriById[0].isButtonsKabulOnay_Visibility
            : this.state.BandrolBilgileriById?.length > 0
            ? this.state.BandrolBilgileriById[0].isButtonsKabulOnay_Visibility
            : null
        ),
        "1"
      )
    ) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp339647Enabled", "disabled");

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BandrolBilgileriPageInit5_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BandrolBilgileriPageInit5_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BandrolBilgileriPageInit5_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.BandrolBilgileriById?.length > 0
            ? stateVars.BandrolBilgileriById[0].isButtonsKabulOnay_Visibility
            : this.state.BandrolBilgileriById?.length > 0
            ? this.state.BandrolBilgileriById[0].isButtonsKabulOnay_Visibility
            : null
        ),
        "1"
      )
    ) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp864541Enabled", "disabled");

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BandrolBilgileriPageInit6_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BandrolBilgileriPageInit6_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BandrolBilgileriPageInit6_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.BandrolBilgileriById?.length > 0
            ? stateVars.BandrolBilgileriById[0].isButtonsYAYFED_Visibility
            : this.state.BandrolBilgileriById?.length > 0
            ? this.state.BandrolBilgileriById[0].isButtonsYAYFED_Visibility
            : null
        ),
        "1"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp934131Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BandrolBilgileriPageInit7_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BandrolBilgileriPageInit7_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BandrolBilgileriPageInit7_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.BandrolBilgileriById?.length > 0
            ? stateVars.BandrolBilgileriById[0].isButtonsOnay_Visibility
            : this.state.BandrolBilgileriById?.length > 0
            ? this.state.BandrolBilgileriById[0].isButtonsOnay_Visibility
            : null
        ),
        "1"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp934131Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BandrolBilgileriPageInit8_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BandrolBilgileriPageInit8_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BandrolBilgileriPageInit8_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.bandrolbilgileri_341166_value = ReactSystemFunctions.toString(
      this,
      stateVars.BandrolBilgileriById?.length > 0
        ? stateVars.BandrolBilgileriById[0].bandrolTalepNo
        : this.state.BandrolBilgileriById?.length > 0
        ? this.state.BandrolBilgileriById[0].bandrolTalepNo
        : null
    );

    stateVars.isComp703152Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.BandrolBilgileriById?.length > 0
            ? stateVars.BandrolBilgileriById[0].isButtonsYAYFED_Visibility
            : this.state.BandrolBilgileriById?.length > 0
            ? this.state.BandrolBilgileriById[0].isButtonsYAYFED_Visibility
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.bandrolbilgileri_985773_value = ReactSystemFunctions.toString(
      this,
      stateVars.EserDetay_BandrolStokSUM?.length > 0
        ? stateVars.EserDetay_BandrolStokSUM[0].bakiyeAdet
        : this.state.EserDetay_BandrolStokSUM?.length > 0
        ? this.state.EserDetay_BandrolStokSUM[0].bakiyeAdet
        : null
    );

    formVars.bandrolbilgileri_864541_value = ReactSystemFunctions.value(
      this,
      stateVars.BandrolBilgileriById?.length > 0
        ? stateVars.BandrolBilgileriById[0].bandrolAdetTeslimEdilen
        : this.state.BandrolBilgileriById?.length > 0
        ? this.state.BandrolBilgileriById[0].bandrolAdetTeslimEdilen
        : null
    );

    stateVars.isComp681796Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.BandrolBilgileriById?.length > 0
            ? stateVars.BandrolBilgileriById[0].isrwBelgeMatbaa_Visibility
            : this.state.BandrolBilgileriById?.length > 0
            ? this.state.BandrolBilgileriById[0].isrwBelgeMatbaa_Visibility
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";

    stateVars.dataSource_711309 = this.state.DokumanlarByKartIdMATBAAYAZISI;
    stateVars.isComp912001Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.BandrolBilgileriById?.length > 0
            ? stateVars.BandrolBilgileriById[0].isButtonsBelgeMatbaa_Visibility
            : this.state.BandrolBilgileriById?.length > 0
            ? this.state.BandrolBilgileriById[0].isButtonsBelgeMatbaa_Visibility
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp674618Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.BandrolBilgileriById?.length > 0
            ? stateVars.BandrolBilgileriById[0].isButtonsBelgeYayinci_Visibility
            : this.state.BandrolBilgileriById?.length > 0
            ? this.state.BandrolBilgileriById[0].isButtonsBelgeYayinci_Visibility
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp151329Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.BandrolBilgileriById?.length > 0
            ? stateVars.BandrolBilgileriById[0].isrwTeslimKabulOnay_Visibility
            : this.state.BandrolBilgileriById?.length > 0
            ? this.state.BandrolBilgileriById[0].isrwTeslimKabulOnay_Visibility
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.bandrolbilgileri_800452_value = ReactSystemFunctions.toString(
      this,
      stateVars.BandrolBilgileriById?.length > 0
        ? stateVars.BandrolBilgileriById[0].kargoFirma
        : this.state.BandrolBilgileriById?.length > 0
        ? this.state.BandrolBilgileriById[0].kargoFirma
        : null
    );

    formVars.bandrolbilgileri_706885_value = ReactSystemFunctions.toString(
      this,
      stateVars.BandrolBilgileriById?.length > 0
        ? stateVars.BandrolBilgileriById[0].kargoNo
        : this.state.BandrolBilgileriById?.length > 0
        ? this.state.BandrolBilgileriById[0].kargoNo
        : null
    );

    formVars.bandrolbilgileri_339647_value = ReactSystemFunctions.value(
      this,
      stateVars.BandrolBilgileriById?.length > 0
        ? stateVars.BandrolBilgileriById[0].bandrolAdetTeslimEdilen
        : this.state.BandrolBilgileriById?.length > 0
        ? this.state.BandrolBilgileriById[0].bandrolAdetTeslimEdilen
        : null
    );

    stateVars.isComp92399Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.BandrolBilgileriById?.length > 0
            ? stateVars.BandrolBilgileriById[0].isButtonsTeslim_Visibility
            : this.state.BandrolBilgileriById?.length > 0
            ? this.state.BandrolBilgileriById[0].isButtonsTeslim_Visibility
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp119718Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.BandrolBilgileriById?.length > 0
            ? stateVars.BandrolBilgileriById[0].isButtonsKabulOnay_Visibility
            : this.state.BandrolBilgileriById?.length > 0
            ? this.state.BandrolBilgileriById[0].isButtonsKabulOnay_Visibility
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp511623Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.BandrolBilgileriById?.length > 0
            ? stateVars.BandrolBilgileriById[0].isButtonsKabulOnay_Visibility
            : this.state.BandrolBilgileriById?.length > 0
            ? this.state.BandrolBilgileriById[0].isButtonsKabulOnay_Visibility
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.bandrolbilgileri_729052_value = ReactSystemFunctions.value(
      this,
      stateVars.BandrolBilgileriById?.length > 0
        ? stateVars.BandrolBilgileriById[0].bandrolAdet
        : this.state.BandrolBilgileriById?.length > 0
        ? this.state.BandrolBilgileriById[0].bandrolAdet
        : null
    );

    stateVars.isComp980045Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.BandrolBilgileriById?.length > 0
            ? stateVars.BandrolBilgileriById[0].isButtonsKabulOnay_Visibility
            : this.state.BandrolBilgileriById?.length > 0
            ? this.state.BandrolBilgileriById[0].isButtonsKabulOnay_Visibility
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp44547Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.BandrolBilgileriById?.length > 0
            ? stateVars.BandrolBilgileriById[0].isButtonsOnay_Visibility
            : this.state.BandrolBilgileriById?.length > 0
            ? this.state.BandrolBilgileriById[0].isButtonsOnay_Visibility
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.bandrolbilgileri_640454_value = ReactSystemFunctions.value(
      this,
      stateVars.BandrolBilgileriById?.length > 0
        ? stateVars.BandrolBilgileriById[0].bandrolAdet
        : this.state.BandrolBilgileriById?.length > 0
        ? this.state.BandrolBilgileriById[0].bandrolAdet
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  BandrolBilgileriComponent_508315_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  BandrolBilgileriComponent_93498_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bandrolbilgileri_864541_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "bandrolbilgileri_864541_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      ),
      TeklifTalepKartID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      ),
      BandrolDurumID_0: ReactSystemFunctions.convertToTypeByName("00000000-0000-0000-0000-000000000001", "Guid"),
      BandrolTalepNo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bandrolbilgileri_341166_value", "value", "", "", "")
        ),
        "string"
      ),
      BandrolAdetTeslimEdilen_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bandrolbilgileri_864541_value", "value", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "BandrolBilgileri/BandrolBilgileriComponent_93498_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_1;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.BandrolBilgileriComponent_93498_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  BandrolBilgileriComponent_93498_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "ce08d7df_414f_c726_f352_7764b3248cf5_notify",
        this.defaultML,
        "Onaya Gönderildi!"
      ),
      "success",
      "bottom-right",
      2,
      null,
      null,
      null
    );

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.BandrolBilgileriComponent_93498_onClick2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  BandrolBilgileriComponent_93498_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      actionkey_0: ReactSystemFunctions.convertToTypeByName("bandrolstok", "string"),
      uyeliktipi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyelikTipi
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyelikTipi
            : null
        ),
        "string"
      ),
      numara_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.siparisNo ?? this.props.screenInputs.siparisno,
        "string"
      ),
      templateId_1: ReactSystemFunctions.convertToTypeByName("ec083187-e222-46c8-9606-bb0fe77903c9", "string"),
      receivers_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.EserBilgileri_TeklifEdenKisiEmail?.length > 0
            ? stateVars.EserBilgileri_TeklifEdenKisiEmail[0].userName
            : this.state.EserBilgileri_TeklifEdenKisiEmail?.length > 0
            ? this.state.EserBilgileri_TeklifEdenKisiEmail[0].userName
            : null
        ),
        "string"
      ),
      sender_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      cc_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      bcc_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentFileName_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentBase64_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      PublinkDestekTelNo_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.GenelParametreler?.length > 0
            ? stateVars.GenelParametreler[0].destekTelNo
            : this.state.GenelParametreler?.length > 0
            ? this.state.GenelParametreler[0].destekTelNo
            : null
        ),
        "string"
      ),
      UyeIsmi_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.EserBilgileri_TeklifEdenKisiEmail?.length > 0
            ? stateVars.EserBilgileri_TeklifEdenKisiEmail[0].firstname
            : this.state.EserBilgileri_TeklifEdenKisiEmail?.length > 0
            ? this.state.EserBilgileri_TeklifEdenKisiEmail[0].firstname
            : null
        ),
        "string"
      ),
      UyeSoyad_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.EserBilgileri_TeklifEdenKisiEmail?.length > 0
            ? stateVars.EserBilgileri_TeklifEdenKisiEmail[0].lastName
            : this.state.EserBilgileri_TeklifEdenKisiEmail?.length > 0
            ? this.state.EserBilgileri_TeklifEdenKisiEmail[0].lastName
            : null
        ),
        "string"
      ),
      UyelikTipiGonderen_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyelikTipi
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyelikTipi
            : null
        ),
        "string"
      ),
      language_1: ReactSystemFunctions.convertToTypeByName("", "string")
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "BandrolBilgileri/BandrolBilgileriComponent_93498_onClick2_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SendMailSettings = result?.data.sendMailSettings;
    stateVars.SendMail = result?.data.sendMail;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.BandrolBilgileriComponent_93498_onClick3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  BandrolBilgileriComponent_93498_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  BandrolBilgileriComponent_260479_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      ),
      TeklifTalepKartID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      ),
      BandrolDurumID_0: ReactSystemFunctions.convertToTypeByName("00000000-0000-0000-0000-000000000002", "Guid"),
      BandrolTalepNo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bandrolbilgileri_341166_value", "value", "", "", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "BandrolBilgileri/BandrolBilgileriComponent_260479_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_2;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.BandrolBilgileriComponent_260479_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  BandrolBilgileriComponent_260479_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "073f2de1_be3e_1a50_5f6b_1636651cab77_notify",
        this.defaultML,
        "Durum Güncellendi!"
      ),
      "success",
      "bottom-right",
      2,
      null,
      null,
      null
    );

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.BandrolBilgileriComponent_260479_onClick2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  BandrolBilgileriComponent_260479_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      actionkey_0: ReactSystemFunctions.convertToTypeByName("yayfedbekleniyor", "string"),
      uyeliktipi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyelikTipi
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyelikTipi
            : null
        ),
        "string"
      ),
      numara_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.siparisNo ?? this.props.screenInputs.siparisno,
        "string"
      ),
      templateId_1: ReactSystemFunctions.convertToTypeByName("8b73fd98-96b0-4887-9db1-d051621b0829", "string"),
      receivers_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.EserBilgileri_TeklifEdenKisiEmail?.length > 0
            ? stateVars.EserBilgileri_TeklifEdenKisiEmail[0].userName
            : this.state.EserBilgileri_TeklifEdenKisiEmail?.length > 0
            ? this.state.EserBilgileri_TeklifEdenKisiEmail[0].userName
            : null
        ),
        "string"
      ),
      sender_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      cc_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      bcc_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentFileName_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentBase64_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      PublinkDestekTelNo_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.GenelParametreler?.length > 0
            ? stateVars.GenelParametreler[0].destekTelNo
            : this.state.GenelParametreler?.length > 0
            ? this.state.GenelParametreler[0].destekTelNo
            : null
        ),
        "string"
      ),
      UyeIsmi_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.TeklifEdenSirketAd?.length > 0
            ? stateVars.TeklifEdenSirketAd[0].sirketAd
            : this.state.TeklifEdenSirketAd?.length > 0
            ? this.state.TeklifEdenSirketAd[0].sirketAd
            : null
        ),
        "string"
      ),
      UyeSoyad_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      UyeKategorisi_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyelikTipi
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyelikTipi
            : null
        ),
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "BandrolBilgileri/BandrolBilgileriComponent_260479_onClick2_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SendMailSettings = result?.data.sendMailSettings;
    stateVars.SendMail = result?.data.sendMail;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.BandrolBilgileriComponent_260479_onClick3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  BandrolBilgileriComponent_260479_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  BandrolBilgileriComponent_526033_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      ),
      TeklifTalepKartID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      ),
      BandrolDurumID_0: ReactSystemFunctions.convertToTypeByName("00000000-0000-0000-0000-000000000003", "Guid"),
      BandrolTalepNo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bandrolbilgileri_341166_value", "value", "", "", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "BandrolBilgileri/BandrolBilgileriComponent_526033_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_3;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.BandrolBilgileriComponent_526033_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  BandrolBilgileriComponent_526033_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "71c2b18c_a5e6_4831_6f67_5cd7b916bca6_notify",
        this.defaultML,
        "İstek Gönderildi!"
      ),
      "success",
      "bottom-right",
      2,
      null,
      null,
      null
    );

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.BandrolBilgileriComponent_526033_onClick2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  BandrolBilgileriComponent_526033_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      actionkey_0: ReactSystemFunctions.convertToTypeByName("yenibandrol", "string"),
      uyeliktipi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyelikTipi
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyelikTipi
            : null
        ),
        "string"
      ),
      numara_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.siparisNo ?? this.props.screenInputs.siparisno,
        "string"
      ),
      templateId_1: ReactSystemFunctions.convertToTypeByName("e49520d6-0059-43e1-9396-05c6164d473b", "string"),
      receivers_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.EserBilgileri_TeklifEdenKisiEmail?.length > 0
            ? stateVars.EserBilgileri_TeklifEdenKisiEmail[0].userName
            : this.state.EserBilgileri_TeklifEdenKisiEmail?.length > 0
            ? this.state.EserBilgileri_TeklifEdenKisiEmail[0].userName
            : null
        ),
        "string"
      ),
      sender_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      cc_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      bcc_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentFileName_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentBase64_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      UyeIsmi_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.EserBilgileri_TeklifEdenKisiEmail?.length > 0
            ? stateVars.EserBilgileri_TeklifEdenKisiEmail[0].firstname
            : this.state.EserBilgileri_TeklifEdenKisiEmail?.length > 0
            ? this.state.EserBilgileri_TeklifEdenKisiEmail[0].firstname
            : null
        ),
        "string"
      ),
      UyeSoyad_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.EserBilgileri_TeklifEdenKisiEmail?.length > 0
            ? stateVars.EserBilgileri_TeklifEdenKisiEmail[0].lastName
            : this.state.EserBilgileri_TeklifEdenKisiEmail?.length > 0
            ? this.state.EserBilgileri_TeklifEdenKisiEmail[0].lastName
            : null
        ),
        "string"
      ),
      PublinkDestekTelNo_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.GenelParametreler?.length > 0
            ? stateVars.GenelParametreler[0].destekTelNo
            : this.state.GenelParametreler?.length > 0
            ? this.state.GenelParametreler[0].destekTelNo
            : null
        ),
        "string"
      ),
      UyeKategorisi_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyelikTipi
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyelikTipi
            : null
        ),
        "string"
      ),
      language_1: ReactSystemFunctions.convertToTypeByName("", "string")
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "BandrolBilgileri/BandrolBilgileriComponent_526033_onClick2_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SendMailSettings = result?.data.sendMailSettings;
    stateVars.SendMail = result?.data.sendMail;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.BandrolBilgileriComponent_526033_onClick3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  BandrolBilgileriComponent_526033_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  BandrolBilgileriComponent_181639_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "BandrolYazisi",
      "prmId",
      this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "BandrolBilgileri",
      "BandrolYazisi",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "60471",
      null,
      "right",
      null,
      "50%",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  BandrolBilgileriComponent_44488_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "BandrolYazisi",
      "prmId",
      this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "BandrolBilgileri",
      "BandrolYazisi",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "596651",
      null,
      "right",
      null,
      "50%",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  BandrolBilgileriComponent_794966_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.UploadFile = ((await ReactSystemFunctions.uploadFileWithInfo()) as any).data;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.BandrolBilgileriComponent_794966_onClick1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  BandrolBilgileriComponent_794966_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(Guid.create().toString(), "Guid"),
      Dosya_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.data),
        "string"
      ),
      DosyaAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.name),
        "string"
      ),
      DosyaEtiket_0: ReactSystemFunctions.convertToTypeByName("MATBAAYAZISI", "string"),
      KartId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      ),
      EkleyenKisiID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kUserId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kUserId
            : null
        ),
        "Guid"
      ),
      EklenmeTarihi_0: ReactSystemFunctions.convertToTypeByName(moment().format("YYYY-MM-DDTHH:mm:ss"), "Date"),
      KartId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "BandrolBilgileri/BandrolBilgileriComponent_794966_onClick1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.DokumanInsert = result?.data.dokumanInsert;
    stateVars.DokumanlarByKartIdMATBAAYAZISI = result?.data.dokumanlarByKartIdMATBAAYAZISI;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.BandrolBilgileriComponent_794966_onClick2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  BandrolBilgileriComponent_794966_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_711309 = this.state.DokumanlarByKartIdMATBAAYAZISI;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  BandrolBilgileriComponent_919708_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "bandrolbilgileri_711309_value", "id"),
        "Guid"
      ),
      KartId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    stateVars.Confirm = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "325dcdf3_74d2_efc7_6388_3e9527d9e2af_confirmation",
        this.defaultML,
        "Seçilen Kayıt Silinecek; İşleme Devam Edilsin mi?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel",
      customClass: {
        title: "swal2-font-poppins"
      }
    });

    if (stateVars.Confirm.isConfirmed) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "BandrolBilgileri/BandrolBilgileriComponent_919708_onClick",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.DokumanDeleteById = result?.data.dokumanDeleteById;
      stateVars.DokumanlarByKartIdMATBAAYAZISI = result?.data.dokumanlarByKartIdMATBAAYAZISI;

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BandrolBilgileriComponent_919708_onClick1_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  BandrolBilgileriComponent_919708_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_711309 = this.state.DokumanlarByKartIdMATBAAYAZISI;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  BandrolBilgileriComponent_872838_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "bandrolbilgileri_711309_value", "id"),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "BandrolBilgileri/BandrolBilgileriComponent_872838_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.DokumanById = result?.data.dokumanById;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.BandrolBilgileriComponent_872838_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  BandrolBilgileriComponent_872838_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.PrintPdf = await ReactSystemFunctions.printPdf(
      ReactSystemFunctions.toString(
        this,
        stateVars.DokumanById?.length > 0
          ? stateVars.DokumanById[0].dosya
          : this.state.DokumanById?.length > 0
          ? this.state.DokumanById[0].dosya
          : null
      )
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  BandrolBilgileriComponent_965626_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      KartId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      ),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      ),
      TeklifTalepKartID_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      ),
      BandrolDurumID_1: ReactSystemFunctions.convertToTypeByName("00000000-0000-0000-0000-000000000004", "Guid")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "BandrolBilgileri/BandrolBilgileriComponent_965626_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.EImzaliBelgeKontrol = result?.data.eImzaliBelgeKontrol;
    stateVars.SaveRecord = result?.data.saveRecord_4;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.BandrolBilgileriComponent_965626_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  BandrolBilgileriComponent_965626_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "9648a98b_ce38_394b_656a_a71c5e102675_notify",
        this.defaultML,
        "Durum Güncellendi!"
      ),
      "success",
      "bottom-right",
      2,
      "",
      "",
      0
    );

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.BandrolBilgileriComponent_965626_onClick2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  BandrolBilgileriComponent_965626_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      actionkey_0: ReactSystemFunctions.convertToTypeByName("eimzabelgegonder", "string"),
      uyeliktipi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyelikTipi
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyelikTipi
            : null
        ),
        "string"
      ),
      numara_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.siparisNo ?? this.props.screenInputs.siparisno,
        "string"
      ),
      templateId_1: ReactSystemFunctions.convertToTypeByName("e007b46e-8baa-42ba-825c-00f620377175", "string"),
      receivers_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.EserBilgileri_TeklifTalepEdenEmail?.length > 0
            ? stateVars.EserBilgileri_TeklifTalepEdenEmail[0].userName
            : this.state.EserBilgileri_TeklifTalepEdenEmail?.length > 0
            ? this.state.EserBilgileri_TeklifTalepEdenEmail[0].userName
            : null
        ),
        "string"
      ),
      sender_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      cc_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      bcc_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentFileName_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentBase64_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      PublinkDestekTelNo_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.GenelParametreler?.length > 0
            ? stateVars.GenelParametreler[0].destekTelNo
            : this.state.GenelParametreler?.length > 0
            ? this.state.GenelParametreler[0].destekTelNo
            : null
        ),
        "string"
      ),
      UyeIsmi_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.EserBilgileri_TeklifTalepEdenEmail?.length > 0
            ? stateVars.EserBilgileri_TeklifTalepEdenEmail[0].firstName
            : this.state.EserBilgileri_TeklifTalepEdenEmail?.length > 0
            ? this.state.EserBilgileri_TeklifTalepEdenEmail[0].firstName
            : null
        ),
        "string"
      ),
      UyeSoyad_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.EserBilgileri_TeklifTalepEdenEmail?.length > 0
            ? stateVars.EserBilgileri_TeklifTalepEdenEmail[0].lastName
            : this.state.EserBilgileri_TeklifTalepEdenEmail?.length > 0
            ? this.state.EserBilgileri_TeklifTalepEdenEmail[0].lastName
            : null
        ),
        "string"
      ),
      UyelikTipiGonderen_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyelikTipi
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyelikTipi
            : null
        ),
        "string"
      ),
      language_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      FirmaAd_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyeAd
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyeAd
            : null
        ),
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "BandrolBilgileri/BandrolBilgileriComponent_965626_onClick2_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SendMailSettings = result?.data.sendMailSettings;
    stateVars.SendMail = result?.data.sendMail;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.BandrolBilgileriComponent_965626_onClick3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  BandrolBilgileriComponent_965626_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  BandrolBilgileriComponent_324824_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      ),
      TeklifTalepKartID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      ),
      BandrolDurumID_0: ReactSystemFunctions.convertToTypeByName("00000000-0000-0000-0000-000000000005", "Guid")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "BandrolBilgileri/BandrolBilgileriComponent_324824_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_5;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.BandrolBilgileriComponent_324824_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  BandrolBilgileriComponent_324824_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "de49e7fd_8efe_9df6_a378_10c9283c9714_notify",
        this.defaultML,
        "Durum Güncellendi!"
      ),
      "success",
      "bottom-right",
      2,
      null,
      null,
      null
    );

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.BandrolBilgileriComponent_324824_onClick2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  BandrolBilgileriComponent_324824_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      actionkey_0: ReactSystemFunctions.convertToTypeByName("yayfedbekleniyor", "string"),
      uyeliktipi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyelikTipi
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyelikTipi
            : null
        ),
        "string"
      ),
      numara_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.siparisNo ?? this.props.screenInputs.siparisno,
        "string"
      ),
      templateId_1: ReactSystemFunctions.convertToTypeByName("8b73fd98-96b0-4887-9db1-d051621b0829", "string"),
      receivers_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.EserBilgileri_TeklifEdenKisiEmail?.length > 0
            ? stateVars.EserBilgileri_TeklifEdenKisiEmail[0].userName
            : this.state.EserBilgileri_TeklifEdenKisiEmail?.length > 0
            ? this.state.EserBilgileri_TeklifEdenKisiEmail[0].userName
            : null
        ),
        "string"
      ),
      sender_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      cc_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      bcc_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentFileName_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentBase64_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      PublinkDestekTelNo_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.GenelParametreler?.length > 0
            ? stateVars.GenelParametreler[0].destekTelNo
            : this.state.GenelParametreler?.length > 0
            ? this.state.GenelParametreler[0].destekTelNo
            : null
        ),
        "string"
      ),
      UyeIsmi_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.EserBilgileri_TeklifEdenKisiEmail?.length > 0
            ? stateVars.EserBilgileri_TeklifEdenKisiEmail[0].firstname
            : this.state.EserBilgileri_TeklifEdenKisiEmail?.length > 0
            ? this.state.EserBilgileri_TeklifEdenKisiEmail[0].firstname
            : null
        ),
        "string"
      ),
      UyeSoyad_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.EserBilgileri_TeklifEdenKisiEmail?.length > 0
            ? stateVars.EserBilgileri_TeklifEdenKisiEmail[0].lastName
            : this.state.EserBilgileri_TeklifEdenKisiEmail?.length > 0
            ? this.state.EserBilgileri_TeklifEdenKisiEmail[0].lastName
            : null
        ),
        "string"
      ),
      UyeKategorisi_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyelikTipi
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyelikTipi
            : null
        ),
        "string"
      ),
      language_1: ReactSystemFunctions.convertToTypeByName("", "string")
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "BandrolBilgileri/BandrolBilgileriComponent_324824_onClick2_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SendMailSettings = result?.data.sendMailSettings;
    stateVars.SendMail = result?.data.sendMail;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.BandrolBilgileriComponent_324824_onClick3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  BandrolBilgileriComponent_324824_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  BandrolBilgileriComponent_773582_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bandrolbilgileri_339647_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "bandrolbilgileri_339647_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      ),
      TeklifTalepKartID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      ),
      BandrolDurumID_0: ReactSystemFunctions.convertToTypeByName("00000000-0000-0000-0000-000000000007", "Guid"),
      BandrolAdetTeslimEdilen_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bandrolbilgileri_339647_value", "value", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "BandrolBilgileri/BandrolBilgileriComponent_773582_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_6;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.BandrolBilgileriComponent_773582_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  BandrolBilgileriComponent_773582_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "9d2f06a4_94b7_5881_31f5_7298dcf8dfce_notify",
        this.defaultML,
        "Durum Güncellendi!"
      ),
      "success",
      "bottom-right",
      2,
      null,
      null,
      null
    );

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.BandrolBilgileriComponent_773582_onClick2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  BandrolBilgileriComponent_773582_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      actionkey_0: ReactSystemFunctions.convertToTypeByName("bandroleldenteslim", "string"),
      uyeliktipi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyelikTipi
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyelikTipi
            : null
        ),
        "string"
      ),
      numara_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.siparisNo ?? this.props.screenInputs.siparisno,
        "string"
      ),
      templateId_1: ReactSystemFunctions.convertToTypeByName("5ca7cbb0-264d-4dd5-84fb-91f47751804d", "string"),
      receivers_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.EserBilgileri_TeklifEdenKisiEmail?.length > 0
            ? stateVars.EserBilgileri_TeklifEdenKisiEmail[0].userName
            : this.state.EserBilgileri_TeklifEdenKisiEmail?.length > 0
            ? this.state.EserBilgileri_TeklifEdenKisiEmail[0].userName
            : null
        ),
        "string"
      ),
      sender_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      cc_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      bcc_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentFileName_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentBase64_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      PublinkDestekTelNo_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.GenelParametreler?.length > 0
            ? stateVars.GenelParametreler[0].destekTelNo
            : this.state.GenelParametreler?.length > 0
            ? this.state.GenelParametreler[0].destekTelNo
            : null
        ),
        "string"
      ),
      UyeIsmi_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.EserBilgileri_TeklifEdenKisiEmail?.length > 0
            ? stateVars.EserBilgileri_TeklifEdenKisiEmail[0].firstname
            : this.state.EserBilgileri_TeklifEdenKisiEmail?.length > 0
            ? this.state.EserBilgileri_TeklifEdenKisiEmail[0].firstname
            : null
        ),
        "string"
      ),
      UyeSoyad_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.EserBilgileri_TeklifEdenKisiEmail?.length > 0
            ? stateVars.EserBilgileri_TeklifEdenKisiEmail[0].lastName
            : this.state.EserBilgileri_TeklifEdenKisiEmail?.length > 0
            ? this.state.EserBilgileri_TeklifEdenKisiEmail[0].lastName
            : null
        ),
        "string"
      ),
      UyelikTipiGonderen_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyelikTipi
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyelikTipi
            : null
        ),
        "string"
      ),
      language_1: ReactSystemFunctions.convertToTypeByName("", "string")
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "BandrolBilgileri/BandrolBilgileriComponent_773582_onClick2_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SendMailSettings = result?.data.sendMailSettings;
    stateVars.SendMail = result?.data.sendMail;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.BandrolBilgileriComponent_773582_onClick3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  BandrolBilgileriComponent_773582_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  BandrolBilgileriComponent_287359_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bandrolbilgileri_800452_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "bandrolbilgileri_800452_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bandrolbilgileri_706885_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "bandrolbilgileri_706885_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bandrolbilgileri_339647_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "bandrolbilgileri_339647_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      ),
      TeklifTalepKartID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      ),
      BandrolDurumID_0: ReactSystemFunctions.convertToTypeByName("00000000-0000-0000-0000-000000000006", "Guid"),
      KargoFirma_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bandrolbilgileri_800452_value", "value", "", "", "")
        ),
        "string"
      ),
      KargoNo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bandrolbilgileri_706885_value", "value", "", "", "")
        ),
        "string"
      ),
      BandrolAdetTeslimEdilen_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bandrolbilgileri_339647_value", "value", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "BandrolBilgileri/BandrolBilgileriComponent_287359_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_7;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.BandrolBilgileriComponent_287359_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  BandrolBilgileriComponent_287359_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "5ad280b5_9553_41d9_b9ae_95e5bd3042e5_notify",
        this.defaultML,
        "Durum Güncellendi!"
      ),
      "success",
      "bottom-right",
      2,
      null,
      null,
      null
    );

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.BandrolBilgileriComponent_287359_onClick2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  BandrolBilgileriComponent_287359_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      actionkey_0: ReactSystemFunctions.convertToTypeByName("bandrolkargoteslim", "string"),
      uyeliktipi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyelikTipi
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyelikTipi
            : null
        ),
        "string"
      ),
      numara_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.siparisNo ?? this.props.screenInputs.siparisno,
        "string"
      ),
      templateId_1: ReactSystemFunctions.convertToTypeByName("207cb028-0a7b-4a4f-82c0-8d147bec8acb", "string"),
      receivers_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.EserBilgileri_TeklifEdenKisiEmail?.length > 0
            ? stateVars.EserBilgileri_TeklifEdenKisiEmail[0].userName
            : this.state.EserBilgileri_TeklifEdenKisiEmail?.length > 0
            ? this.state.EserBilgileri_TeklifEdenKisiEmail[0].userName
            : null
        ),
        "string"
      ),
      sender_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      cc_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      bcc_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentFileName_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentBase64_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      PublinkDestekTelNo_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.GenelParametreler?.length > 0
            ? stateVars.GenelParametreler[0].destekTelNo
            : this.state.GenelParametreler?.length > 0
            ? this.state.GenelParametreler[0].destekTelNo
            : null
        ),
        "string"
      ),
      UyeIsmi_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.EserBilgileri_TeklifEdenKisiEmail?.length > 0
            ? stateVars.EserBilgileri_TeklifEdenKisiEmail[0].firstname
            : this.state.EserBilgileri_TeklifEdenKisiEmail?.length > 0
            ? this.state.EserBilgileri_TeklifEdenKisiEmail[0].firstname
            : null
        ),
        "string"
      ),
      UyeSoyad_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.EserBilgileri_TeklifEdenKisiEmail?.length > 0
            ? stateVars.EserBilgileri_TeklifEdenKisiEmail[0].lastName
            : this.state.EserBilgileri_TeklifEdenKisiEmail?.length > 0
            ? this.state.EserBilgileri_TeklifEdenKisiEmail[0].lastName
            : null
        ),
        "string"
      ),
      UyelikTipiGonderen_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyelikTipi
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyelikTipi
            : null
        ),
        "string"
      ),
      language_1: ReactSystemFunctions.convertToTypeByName("", "string")
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "BandrolBilgileri/BandrolBilgileriComponent_287359_onClick2_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SendMailSettings = result?.data.sendMailSettings;
    stateVars.SendMail = result?.data.sendMail;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.BandrolBilgileriComponent_287359_onClick3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  BandrolBilgileriComponent_287359_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  BandrolBilgileriComponent_185002_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bandrolbilgileri_339647_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "bandrolbilgileri_339647_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bandrolbilgileri_729052_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "bandrolbilgileri_729052_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      TeslimEdilen_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bandrolbilgileri_339647_value", "value", "", "", "")
        ),
        "number"
      ),
      TeslimAlinan_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bandrolbilgileri_729052_value", "value", "", "", "")
        ),
        "number"
      ),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      ),
      TeklifTalepKartID_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      ),
      BandrolDurumID_1: ReactSystemFunctions.convertToTypeByName("00000000-0000-0000-0000-000000000000", "Guid"),
      BandrolAdet_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bandrolbilgileri_729052_value", "value", "", "", "")
        ),
        "number"
      ),
      TeklifTalepKartId_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      ),
      Adet_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bandrolbilgileri_729052_value", "value", "", "", "")
        ),
        "number"
      ),
      GCAciklama_3: ReactSystemFunctions.convertToTypeByName("Bandrol Teslim Alındı", "string"),
      GCTarih_3: ReactSystemFunctions.convertToTypeByName(moment().format("YYYY-MM-DDTHH:mm:ss"), "Date"),
      StokGC_3: ReactSystemFunctions.convertToTypeByName("G", "string"),
      UyeID_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyeId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyeId
            : null
        ),
        "Guid"
      ),
      Id_3: ReactSystemFunctions.convertToTypeByName(Guid.create().toString(), "Guid")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "BandrolBilgileri/BandrolBilgileriComponent_185002_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.BandrolTeslimKontrol = result?.data.bandrolTeslimKontrol;
    stateVars.SaveRecord = result?.data.saveRecord_8;
    stateVars.getEserBilgileriIdByTeklifTalepKartID = result?.data.getEserBilgileriIdByTeklifTalepKartID;
    stateVars.EserDetay_BandrolStokSave = result?.data.eserDetay_BandrolStokSave;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.BandrolBilgileriComponent_185002_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  BandrolBilgileriComponent_185002_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "1e0d61fe_169e_96d3_64d6_f4e0b54b3c40_notify",
        this.defaultML,
        "Bandrol Teslim Alındı!"
      ),
      "success",
      "bottom-right",
      2,
      "",
      "",
      0
    );

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.BandrolBilgileriComponent_185002_onClick2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  BandrolBilgileriComponent_185002_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      actionkey_0: ReactSystemFunctions.convertToTypeByName("bandrolteslimonay", "string"),
      uyeliktipi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyelikTipi
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyelikTipi
            : null
        ),
        "string"
      ),
      numara_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.siparisNo ?? this.props.screenInputs.siparisno,
        "string"
      ),
      templateId_1: ReactSystemFunctions.convertToTypeByName("bf6c0af2-a4da-4015-a4e6-e2e0f03fb720", "string"),
      receivers_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.EserBilgileri_TeklifTalepEdenEmail?.length > 0
            ? stateVars.EserBilgileri_TeklifTalepEdenEmail[stateVars.EserBilgileri_TeklifTalepEdenEmail.length - 1]
                .userName
            : this.state.EserBilgileri_TeklifTalepEdenEmail?.length > 0
            ? this.state.EserBilgileri_TeklifTalepEdenEmail[this.state.EserBilgileri_TeklifTalepEdenEmail.length - 1]
                .userName
            : null
        ),
        "string"
      ),
      sender_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      cc_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      bcc_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentFileName_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentBase64_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      PublinkDestekTelNo_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.GenelParametreler?.length > 0
            ? stateVars.GenelParametreler[0].destekTelNo
            : this.state.GenelParametreler?.length > 0
            ? this.state.GenelParametreler[0].destekTelNo
            : null
        ),
        "string"
      ),
      UyeIsmi_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.EserBilgileri_TeklifTalepEdenEmail?.length > 0
            ? stateVars.EserBilgileri_TeklifTalepEdenEmail[0].firstName
            : this.state.EserBilgileri_TeklifTalepEdenEmail?.length > 0
            ? this.state.EserBilgileri_TeklifTalepEdenEmail[0].firstName
            : null
        ),
        "string"
      ),
      UyeSoyad_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.EserBilgileri_TeklifTalepEdenEmail?.length > 0
            ? stateVars.EserBilgileri_TeklifTalepEdenEmail[0].lastName
            : this.state.EserBilgileri_TeklifTalepEdenEmail?.length > 0
            ? this.state.EserBilgileri_TeklifTalepEdenEmail[0].lastName
            : null
        ),
        "string"
      ),
      UyeKategorisi_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyelikTipi
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyelikTipi
            : null
        ),
        "string"
      ),
      language_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      FirmaAd_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyeAd
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyeAd
            : null
        ),
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "BandrolBilgileri/BandrolBilgileriComponent_185002_onClick2_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SendMailSettings = result?.data.sendMailSettings;
    stateVars.SendMail = result?.data.sendMail;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.BandrolBilgileriComponent_185002_onClick3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  BandrolBilgileriComponent_185002_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  BandrolBilgileriComponent_375370_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      ),
      BandrolDurumID_0: ReactSystemFunctions.convertToTypeByName(null, "Guid")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "BandrolBilgileri/BandrolBilgileriComponent_375370_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_9;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.BandrolBilgileriComponent_375370_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  BandrolBilgileriComponent_375370_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "4e41f651_bbc6_2ccf_dc58_2b5fbdcab9e2_notify",
        this.defaultML,
        "Geri Gönderildi!"
      ),
      "success",
      "bottom-right",
      2,
      null,
      null,
      null
    );
    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  BandrolBilgileriComponent_536574_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bandrolbilgileri_864541_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "bandrolbilgileri_864541_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bandrolbilgileri_640454_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "bandrolbilgileri_640454_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      TeslimEdilen_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bandrolbilgileri_864541_value", "value", "", "", "")
        ),
        "number"
      ),
      TeslimAlinan_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bandrolbilgileri_640454_value", "value", "", "", "")
        ),
        "number"
      ),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      ),
      TeklifTalepKartID_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      ),
      BandrolDurumID_1: ReactSystemFunctions.convertToTypeByName("00000000-0000-0000-0000-000000000000", "Guid"),
      BandrolAdet_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bandrolbilgileri_640454_value", "value", "", "", "")
        ),
        "number"
      ),
      TeklifTalepKartId_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      ),
      Adet_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bandrolbilgileri_640454_value", "value", "", "", "")
        ),
        "number"
      ),
      GCAciklama_3: ReactSystemFunctions.convertToTypeByName("Bandrol Teslim Alındı", "string"),
      GCTarih_3: ReactSystemFunctions.convertToTypeByName(moment().format("YYYY-MM-DDTHH:mm:ss"), "Date"),
      StokGC_3: ReactSystemFunctions.convertToTypeByName("G", "string"),
      UyeID_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyeId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyeId
            : null
        ),
        "Guid"
      ),
      Id_3: ReactSystemFunctions.convertToTypeByName(Guid.create().toString(), "Guid")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "BandrolBilgileri/BandrolBilgileriComponent_536574_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.BandrolTeslimKontrol = result?.data.bandrolTeslimKontrol;
    stateVars.SaveRecord = result?.data.saveRecord_10;
    stateVars.getEserBilgileriIdByTeklifTalepKartID = result?.data.getEserBilgileriIdByTeklifTalepKartID;
    stateVars.EserDetay_BandrolStokSave = result?.data.eserDetay_BandrolStokSave;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.BandrolBilgileriComponent_536574_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  BandrolBilgileriComponent_536574_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "3833270a_4f1c_2810_298f_c2ffa7dff9ec_notify",
        this.defaultML,
        "Durum Güncellendi!"
      ),
      "success",
      "bottom-right",
      2,
      "",
      "",
      0
    );

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.BandrolBilgileriComponent_536574_onClick2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  BandrolBilgileriComponent_536574_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      actionkey_0: ReactSystemFunctions.convertToTypeByName("bandrolonay", "string"),
      uyeliktipi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyelikTipi
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyelikTipi
            : null
        ),
        "string"
      ),
      numara_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.siparisNo ?? this.props.screenInputs.siparisno,
        "string"
      ),
      templateId_1: ReactSystemFunctions.convertToTypeByName("b33cbb18-7027-4389-805d-372ae4558023", "string"),
      receivers_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.EserBilgileri_TeklifTalepEdenEmail?.length > 0
            ? stateVars.EserBilgileri_TeklifTalepEdenEmail[0].userName
            : this.state.EserBilgileri_TeklifTalepEdenEmail?.length > 0
            ? this.state.EserBilgileri_TeklifTalepEdenEmail[0].userName
            : null
        ),
        "string"
      ),
      sender_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      cc_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      bcc_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentFileName_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentBase64_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      PublinkDestekTelNo_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.GenelParametreler?.length > 0
            ? stateVars.GenelParametreler[0].destekTelNo
            : this.state.GenelParametreler?.length > 0
            ? this.state.GenelParametreler[0].destekTelNo
            : null
        ),
        "string"
      ),
      UyeIsmi_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.EserBilgileri_TeklifTalepEdenEmail?.length > 0
            ? stateVars.EserBilgileri_TeklifTalepEdenEmail[0].firstName
            : this.state.EserBilgileri_TeklifTalepEdenEmail?.length > 0
            ? this.state.EserBilgileri_TeklifTalepEdenEmail[0].firstName
            : null
        ),
        "string"
      ),
      UyeSoyad_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.EserBilgileri_TeklifTalepEdenEmail?.length > 0
            ? stateVars.EserBilgileri_TeklifTalepEdenEmail[0].lastName
            : this.state.EserBilgileri_TeklifTalepEdenEmail?.length > 0
            ? this.state.EserBilgileri_TeklifTalepEdenEmail[0].lastName
            : null
        ),
        "string"
      ),
      UyelikTipiGonderen_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyelikTipi
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyelikTipi
            : null
        ),
        "string"
      ),
      language_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      FirmaAd_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyeAd
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyeAd
            : null
        ),
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "BandrolBilgileri/BandrolBilgileriComponent_536574_onClick2_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SendMailSettings = result?.data.sendMailSettings;
    stateVars.SendMail = result?.data.sendMail;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.BandrolBilgileriComponent_536574_onClick3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  BandrolBilgileriComponent_536574_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [60471, 596651] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.BandrolBilgileriPageInit();
    }
  }
}
