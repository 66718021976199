import { FormInstance } from "antd/es/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IDashBoardYazar_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IDashBoardYazar_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  Logout: boolean;
  Logout_dummy: boolean;
  goToSignInActivity: any;
  goToSignInActivity_dummy: any;
  DB_GetDateRangesNames: any[];
  DB_GetDateRangesNames_dummy: any[];
  KullaniciLocalStorageByUsername: any[];
  KullaniciLocalStorageByUsername_dummy: any[];
  DB_GetDateRanges: any[];
  DB_GetDateRanges_dummy: any[];
  SetValueOf: any;
  SetValueOf_dummy: any;
  MyContext: any[];
  MyContext_dummy: any[];
  SetLanguage: string;
  SetLanguage_dummy: string;
  DBMatbaa_TeklifTaslakCount: any[];
  DBMatbaa_TeklifTaslakCount_dummy: any[];
  DBMatbaa_TeklifOnayCount: any[];
  DBMatbaa_TeklifOnayCount_dummy: any[];
  DBMatbaa_TeklifOnaylananCount: any[];
  DBMatbaa_TeklifOnaylananCount_dummy: any[];
  DBMatbaa_TeklifOnaylanmayanCount: any[];
  DBMatbaa_TeklifOnaylanmayanCount_dummy: any[];
  DBMatbaa_SiparisTaslakCount: any[];
  DBMatbaa_SiparisTaslakCount_dummy: any[];
  DBMatbaa_SiparisOnayCount: any[];
  DBMatbaa_SiparisOnayCount_dummy: any[];
  DBMatbaa_SiparisOnaylananCount: any[];
  DBMatbaa_SiparisOnaylananCount_dummy: any[];
  DBMatbaa_SiparisOnaylanmayanCount: any[];
  DBMatbaa_SiparisOnaylanmayanCount_dummy: any[];
  DBMatbaa_UretimBaslayanCount: any[];
  DBMatbaa_UretimBaslayanCount_dummy: any[];
  DBMatbaa_UretimBitenCount: any[];
  DBMatbaa_UretimBitenCount_dummy: any[];
  KullaniciLocalStorageSave: number;
  KullaniciLocalStorageSave_dummy: number;
  TriggerEvent: string;
  TriggerEvent_dummy: string;
  isComp271676Visible: "visible" | "hidden";
  isComp23040Visible: "visible" | "hidden";
  isComp819129Visible: "visible" | "hidden";
}

export class DashBoardYazar_ScreenBase extends React.PureComponent<IDashBoardYazar_ScreenProps, any> {
  dashboardyazar_467599_value_kuikaDateRef: React.RefObject<any>;
  dashboardyazar_266248_value_kuikaDateRef: React.RefObject<any>;
  dashboardyazar_19319_value_kuikaSelectBoxRef: React.RefObject<any>;
  dashboardyazar_767386_value_kuikaTableRef: React.RefObject<any>;
  dashboardyazar_512534_value_kuikaPaginationRef: React.RefObject<any>;
  ml = [
    {
      Id: "41cd36a4-5b07-cfca-4f7f-f48019ff4ff3",
      Name: "tr_TR",
      ShortName: "Türkçe",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "0069cee9-16ca-4a5a-aab9-dd58af96ca96", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "87444f56-1760-4f37-9285-0614519b6440",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 881224, PropertyName: "label", Value: "Profil" },
        { Id: 310750, PropertyName: "label", Value: "Çıkış Yap" },
        { Id: 719506, PropertyName: "value", Value: "Başlangıç Tarihi" },
        { Id: 467599, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 629184, PropertyName: "value", Value: "Bitiş Tarihi" },
        { Id: 266248, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 582893, PropertyName: "value", Value: "Dönem" },
        { Id: 19319, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 411224, PropertyName: "value", Value: "Yazar Dashboard" },
        { Id: 280273, PropertyName: "value", Value: "Teklif" },
        { Id: 232396, PropertyName: "value", Value: "Taslak Teklif Sayısı" },
        { Id: 462261, PropertyName: "value", Value: "Onay Bekleyen Teklif Sayısı" },
        { Id: 678447, PropertyName: "value", Value: "Onaylanan Teklif Sayısı" },
        { Id: 799607, PropertyName: "value", Value: "Reddedilen Teklif Sayısı" },
        { Id: 988226, PropertyName: "value", Value: "Sipariş" },
        { Id: 134893, PropertyName: "value", Value: "Taslak Sipariş Sayısı" },
        { Id: 790410, PropertyName: "value", Value: "Onay Bekleyen Sipariş Sayısı" },
        { Id: 758983, PropertyName: "value", Value: "Onaylanan Sipariş Sayısı" },
        { Id: 750787, PropertyName: "value", Value: "Reddedilen Sipariş Sayısı" },
        { Id: 860703, PropertyName: "value", Value: "Üretim" },
        { Id: 796910, PropertyName: "value", Value: "Üretim İşlemleri Başlatılan" },
        { Id: 292298, PropertyName: "value", Value: "Üretim İşlemleri Biten" },
        { Id: 590128, PropertyName: "value", Value: "........................................." },
        { Id: 743535, PropertyName: "value", Value: "---" },
        { Id: 449815, PropertyName: "value", Value: "........................................." },
        { Id: 665321, PropertyName: "value", Value: "---" },
        { Id: 891654, PropertyName: "value", Value: "........................................." },
        { Id: 623036, PropertyName: "value", Value: "---" },
        { Id: 71802, PropertyName: "value", Value: "........................................." },
        { Id: 877467, PropertyName: "value", Value: "---" },
        { Id: 508416, PropertyName: "value", Value: "Formlar" },
        { Id: 896159, PropertyName: "label", Value: "Yazar Bilgi Formu" },
        { Id: 206153, PropertyName: "value", Value: "Üye Listesi" },
        { Id: 628761, PropertyName: "value", Value: "Tümü" },
        { Id: 767386, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 427279, PropertyName: "title", Value: "Üyelik Tipi" },
        { Id: 770805, PropertyName: "value", Value: "Yayıncı" },
        { Id: 30995, PropertyName: "title", Value: "Ad Soyad" },
        { Id: 740743, PropertyName: "value", Value: "Ali Mavişehir" },
        { Id: 592024, PropertyName: "title", Value: "E-posta" },
        { Id: 954695, PropertyName: "value", Value: "ali.mavisehir@mail.com" },
        { Id: 958550, PropertyName: "title", Value: "Telefon numarası" },
        { Id: 126558, PropertyName: "value", Value: "+90555 666 77 88" },
        { Id: 389173, PropertyName: "title", Value: "Şirket Adı" },
        { Id: 805996, PropertyName: "value", Value: "Şirket Adı 1" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.dashboardyazar_467599_value_kuikaDateRef = React.createRef();
    this.dashboardyazar_266248_value_kuikaDateRef = React.createRef();
    this.dashboardyazar_19319_value_kuikaSelectBoxRef = React.createRef();
    this.dashboardyazar_767386_value_kuikaTableRef = React.createRef();
    this.dashboardyazar_512534_value_kuikaPaginationRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      NAVIGATE: "",
      Logout: false,
      goToSignInActivity: "",
      DB_GetDateRangesNames: [],
      KullaniciLocalStorageByUsername: [],
      DB_GetDateRanges: [],
      SetValueOf: "",
      MyContext: [],
      SetLanguage: "",
      DBMatbaa_TeklifTaslakCount: [],
      DBMatbaa_TeklifOnayCount: [],
      DBMatbaa_TeklifOnaylananCount: [],
      DBMatbaa_TeklifOnaylanmayanCount: [],
      DBMatbaa_SiparisTaslakCount: [],
      DBMatbaa_SiparisOnayCount: [],
      DBMatbaa_SiparisOnaylananCount: [],
      DBMatbaa_SiparisOnaylanmayanCount: [],
      DBMatbaa_UretimBaslayanCount: [],
      DBMatbaa_UretimBitenCount: [],
      KullaniciLocalStorageSave: 0,
      TriggerEvent: "",
      isComp271676Visible: "hidden",
      isComp23040Visible: "hidden",
      isComp819129Visible: "hidden"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("dashboardyazar", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.DashBoardYazarPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("dashboardyazar", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("dashboardyazar", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.DashBoardYazarPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      dashboardyazar_81624_value: this.state.MyContext?.at?.(0)?.profilFoto ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  DashBoardYazarPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      userName_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "DashBoardYazar/DashBoardYazarPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.DB_GetDateRangesNames = result?.data.dB_GetDateRangesNames;

    formVars.dashboardyazar_19319_options = stateVars.DB_GetDateRangesNames;
    stateVars.KullaniciLocalStorageByUsername = result?.data.kullaniciLocalStorageByUsername;
    stateVars.DB_GetDateRanges = result?.data.dB_GetDateRanges;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.DashBoardYazarPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  DashBoardYazarPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "dashboardyazar_467599_value",
      ReactSystemFunctions.value(
        this,
        stateVars.DB_GetDateRanges?.length > 0
          ? stateVars.DB_GetDateRanges[0].baslangic
          : this.state.DB_GetDateRanges?.length > 0
          ? this.state.DB_GetDateRanges[0].baslangic
          : null
      ),
      null
    );
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "dashboardyazar_266248_value",
      ReactSystemFunctions.value(
        this,
        stateVars.DB_GetDateRanges?.length > 0
          ? stateVars.DB_GetDateRanges[0].bitis
          : this.state.DB_GetDateRanges?.length > 0
          ? this.state.DB_GetDateRanges[0].bitis
          : null
      ),
      null
    );

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.DashBoardYazarPageInit2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  DashBoardYazarPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "DashBoardYazar/DashBoardYazarPageInit2_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    formVars.dashboardyazar_84249_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0
        ? stateVars.MyContext[0].uyeAd
        : this.state.MyContext?.length > 0
        ? this.state.MyContext[0].uyeAd
        : null
    );
    formVars.dashboardyazar_81624_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0
        ? stateVars.MyContext[0].profilFoto
        : this.state.MyContext?.length > 0
        ? this.state.MyContext[0].profilFoto
        : null
    );
    formVars.dashboardyazar_980974_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0
        ? stateVars.MyContext[0].fullName
        : this.state.MyContext?.length > 0
        ? this.state.MyContext[0].fullName
        : null
    );
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.DashBoardYazarPageInit3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  DashBoardYazarPageInit3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.SetLanguage = await ReactSystemFunctions.setLanguage(this, "tr_TR");

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.DashBoardYazarPageInit4_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  DashBoardYazarPageInit4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      UyeId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyeId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyeId
            : null
        ),
        "Guid"
      ),
      BasT_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyazar_467599_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyazar_266248_value", "value", "", "", "")
        ),
        "Date"
      ),
      UyeId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyeId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyeId
            : null
        ),
        "Guid"
      ),
      BasT_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyazar_467599_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyazar_266248_value", "value", "", "", "")
        ),
        "Date"
      ),
      UyeId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyeId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyeId
            : null
        ),
        "Guid"
      ),
      BasT_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyazar_467599_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyazar_266248_value", "value", "", "", "")
        ),
        "Date"
      ),
      UyeId_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyeId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyeId
            : null
        ),
        "Guid"
      ),
      BasT_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyazar_467599_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyazar_266248_value", "value", "", "", "")
        ),
        "Date"
      ),
      UyeId_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyeId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyeId
            : null
        ),
        "Guid"
      ),
      BasT_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyazar_467599_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyazar_266248_value", "value", "", "", "")
        ),
        "Date"
      ),
      UyeId_5: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyeId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyeId
            : null
        ),
        "Guid"
      ),
      BasT_5: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyazar_467599_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_5: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyazar_266248_value", "value", "", "", "")
        ),
        "Date"
      ),
      UyeId_6: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyeId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyeId
            : null
        ),
        "Guid"
      ),
      BasT_6: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyazar_467599_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_6: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyazar_266248_value", "value", "", "", "")
        ),
        "Date"
      ),
      UyeId_7: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyeId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyeId
            : null
        ),
        "Guid"
      ),
      BasT_7: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyazar_467599_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_7: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyazar_266248_value", "value", "", "", "")
        ),
        "Date"
      ),
      UyeId_8: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyeId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyeId
            : null
        ),
        "Guid"
      ),
      BasT_8: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyazar_467599_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_8: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyazar_266248_value", "value", "", "", "")
        ),
        "Date"
      ),
      UyeId_9: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyeId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyeId
            : null
        ),
        "Guid"
      ),
      BasT_9: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyazar_467599_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_9: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyazar_266248_value", "value", "", "", "")
        ),
        "Date"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "DashBoardYazar/DashBoardYazarPageInit4_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.DBMatbaa_TeklifTaslakCount = result?.data.dbMatbaa_TeklifTaslakCount;
    formVars.dashboardyazar_574514_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_TeklifTaslakCount?.length > 0
        ? stateVars.DBMatbaa_TeklifTaslakCount[0].recordCount
        : this.state.DBMatbaa_TeklifTaslakCount?.length > 0
        ? this.state.DBMatbaa_TeklifTaslakCount[0].recordCount
        : null
    );
    stateVars.DBMatbaa_TeklifOnayCount = result?.data.dbMatbaa_TeklifOnayCount;
    formVars.dashboardyazar_449235_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_TeklifOnayCount?.length > 0
        ? stateVars.DBMatbaa_TeklifOnayCount[0].recordCount
        : this.state.DBMatbaa_TeklifOnayCount?.length > 0
        ? this.state.DBMatbaa_TeklifOnayCount[0].recordCount
        : null
    );
    stateVars.DBMatbaa_TeklifOnaylananCount = result?.data.dbMatbaa_TeklifOnaylananCount;
    formVars.dashboardyazar_769574_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_TeklifOnaylananCount?.length > 0
        ? stateVars.DBMatbaa_TeklifOnaylananCount[0].recordCount
        : this.state.DBMatbaa_TeklifOnaylananCount?.length > 0
        ? this.state.DBMatbaa_TeklifOnaylananCount[0].recordCount
        : null
    );
    stateVars.DBMatbaa_TeklifOnaylanmayanCount = result?.data.dbMatbaa_TeklifOnaylanmayanCount;
    formVars.dashboardyazar_781946_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_TeklifOnaylanmayanCount?.length > 0
        ? stateVars.DBMatbaa_TeklifOnaylanmayanCount[0].recordCount
        : this.state.DBMatbaa_TeklifOnaylanmayanCount?.length > 0
        ? this.state.DBMatbaa_TeklifOnaylanmayanCount[0].recordCount
        : null
    );
    stateVars.DBMatbaa_SiparisTaslakCount = result?.data.dbMatbaa_SiparisTaslakCount;
    formVars.dashboardyazar_239415_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_SiparisTaslakCount?.length > 0
        ? stateVars.DBMatbaa_SiparisTaslakCount[0].recordCount
        : this.state.DBMatbaa_SiparisTaslakCount?.length > 0
        ? this.state.DBMatbaa_SiparisTaslakCount[0].recordCount
        : null
    );
    stateVars.DBMatbaa_SiparisOnayCount = result?.data.dbMatbaa_SiparisOnayCount;
    formVars.dashboardyazar_679156_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_SiparisOnayCount?.length > 0
        ? stateVars.DBMatbaa_SiparisOnayCount[0].recordCount
        : this.state.DBMatbaa_SiparisOnayCount?.length > 0
        ? this.state.DBMatbaa_SiparisOnayCount[0].recordCount
        : null
    );
    stateVars.DBMatbaa_SiparisOnaylananCount = result?.data.dbMatbaa_SiparisOnaylananCount;
    formVars.dashboardyazar_267459_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_SiparisOnaylananCount?.length > 0
        ? stateVars.DBMatbaa_SiparisOnaylananCount[0].recordCount
        : this.state.DBMatbaa_SiparisOnaylananCount?.length > 0
        ? this.state.DBMatbaa_SiparisOnaylananCount[0].recordCount
        : null
    );
    stateVars.DBMatbaa_SiparisOnaylanmayanCount = result?.data.dbMatbaa_SiparisOnaylanmayanCount;
    formVars.dashboardyazar_825698_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_SiparisOnaylanmayanCount?.length > 0
        ? stateVars.DBMatbaa_SiparisOnaylanmayanCount[0].recordCount
        : this.state.DBMatbaa_SiparisOnaylanmayanCount?.length > 0
        ? this.state.DBMatbaa_SiparisOnaylanmayanCount[0].recordCount
        : null
    );
    stateVars.DBMatbaa_UretimBaslayanCount = result?.data.dbMatbaa_UretimBaslayanCount;
    formVars.dashboardyazar_784595_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_UretimBaslayanCount?.length > 0
        ? stateVars.DBMatbaa_UretimBaslayanCount[0].recordCount
        : this.state.DBMatbaa_UretimBaslayanCount?.length > 0
        ? this.state.DBMatbaa_UretimBaslayanCount[0].recordCount
        : null
    );
    stateVars.DBMatbaa_UretimBitenCount = result?.data.dbMatbaa_UretimBitenCount;
    formVars.dashboardyazar_941125_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_UretimBitenCount?.length > 0
        ? stateVars.DBMatbaa_UretimBitenCount[0].recordCount
        : this.state.DBMatbaa_UretimBitenCount?.length > 0
        ? this.state.DBMatbaa_UretimBitenCount[0].recordCount
        : null
    );
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.DashBoardYazarPageInit5_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  DashBoardYazarPageInit5_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.dashboardyazar_84249_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0
        ? stateVars.MyContext[0].uyeAd
        : this.state.MyContext?.length > 0
        ? this.state.MyContext[0].uyeAd
        : null
    );

    formVars.dashboardyazar_81624_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0
        ? stateVars.MyContext[0].profilFoto
        : this.state.MyContext?.length > 0
        ? this.state.MyContext[0].profilFoto
        : null
    );

    formVars.dashboardyazar_980974_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0
        ? stateVars.MyContext[0].fullName
        : this.state.MyContext?.length > 0
        ? this.state.MyContext[0].fullName
        : null
    );

    stateVars.dataSource_19319 = this.state.DB_GetDateRangesNames;
    stateVars.dataSource_19319 = this.state.DB_GetDateRangesNames;
    formVars.dashboardyazar_574514_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_TeklifTaslakCount?.length > 0
        ? stateVars.DBMatbaa_TeklifTaslakCount[0].recordCount
        : this.state.DBMatbaa_TeklifTaslakCount?.length > 0
        ? this.state.DBMatbaa_TeklifTaslakCount[0].recordCount
        : null
    );

    formVars.dashboardyazar_449235_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_TeklifOnayCount?.length > 0
        ? stateVars.DBMatbaa_TeklifOnayCount[0].recordCount
        : this.state.DBMatbaa_TeklifOnayCount?.length > 0
        ? this.state.DBMatbaa_TeklifOnayCount[0].recordCount
        : null
    );

    formVars.dashboardyazar_769574_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_TeklifOnaylananCount?.length > 0
        ? stateVars.DBMatbaa_TeklifOnaylananCount[0].recordCount
        : this.state.DBMatbaa_TeklifOnaylananCount?.length > 0
        ? this.state.DBMatbaa_TeklifOnaylananCount[0].recordCount
        : null
    );

    formVars.dashboardyazar_781946_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_TeklifOnaylanmayanCount?.length > 0
        ? stateVars.DBMatbaa_TeklifOnaylanmayanCount[0].recordCount
        : this.state.DBMatbaa_TeklifOnaylanmayanCount?.length > 0
        ? this.state.DBMatbaa_TeklifOnaylanmayanCount[0].recordCount
        : null
    );

    formVars.dashboardyazar_239415_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_SiparisTaslakCount?.length > 0
        ? stateVars.DBMatbaa_SiparisTaslakCount[0].recordCount
        : this.state.DBMatbaa_SiparisTaslakCount?.length > 0
        ? this.state.DBMatbaa_SiparisTaslakCount[0].recordCount
        : null
    );

    formVars.dashboardyazar_679156_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_SiparisOnayCount?.length > 0
        ? stateVars.DBMatbaa_SiparisOnayCount[0].recordCount
        : this.state.DBMatbaa_SiparisOnayCount?.length > 0
        ? this.state.DBMatbaa_SiparisOnayCount[0].recordCount
        : null
    );

    formVars.dashboardyazar_267459_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_SiparisOnaylananCount?.length > 0
        ? stateVars.DBMatbaa_SiparisOnaylananCount[0].recordCount
        : this.state.DBMatbaa_SiparisOnaylananCount?.length > 0
        ? this.state.DBMatbaa_SiparisOnaylananCount[0].recordCount
        : null
    );

    formVars.dashboardyazar_825698_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_SiparisOnaylanmayanCount?.length > 0
        ? stateVars.DBMatbaa_SiparisOnaylanmayanCount[0].recordCount
        : this.state.DBMatbaa_SiparisOnaylanmayanCount?.length > 0
        ? this.state.DBMatbaa_SiparisOnaylanmayanCount[0].recordCount
        : null
    );

    formVars.dashboardyazar_784595_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_UretimBaslayanCount?.length > 0
        ? stateVars.DBMatbaa_UretimBaslayanCount[0].recordCount
        : this.state.DBMatbaa_UretimBaslayanCount?.length > 0
        ? this.state.DBMatbaa_UretimBaslayanCount[0].recordCount
        : null
    );

    formVars.dashboardyazar_941125_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_UretimBitenCount?.length > 0
        ? stateVars.DBMatbaa_UretimBitenCount[0].recordCount
        : this.state.DBMatbaa_UretimBitenCount?.length > 0
        ? this.state.DBMatbaa_UretimBitenCount[0].recordCount
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  DashBoardYazarComponent_284229_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "DashBoardYazar",
      "LeftMenu",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "997406",
      null,
      "left",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashBoardYazarComponent_881224_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "DashBoardYazar",
      "Profil",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "705049",
      null,
      "right",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashBoardYazarComponent_310750_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.Logout = await ReactSystemFunctions.logout();
    stateVars.tmpBoolResult = await ReactSystemFunctions.goToSignInActivity(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashBoardYazarComponent_467599_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UyeId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyeId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyeId
            : null
        ),
        "Guid"
      ),
      BasT_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyazar_467599_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyazar_266248_value", "value", "", "", "")
        ),
        "Date"
      ),
      UyeId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyeId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyeId
            : null
        ),
        "Guid"
      ),
      BasT_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyazar_467599_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyazar_266248_value", "value", "", "", "")
        ),
        "Date"
      ),
      UyeId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyeId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyeId
            : null
        ),
        "Guid"
      ),
      BasT_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyazar_467599_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyazar_266248_value", "value", "", "", "")
        ),
        "Date"
      ),
      UyeId_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyeId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyeId
            : null
        ),
        "Guid"
      ),
      BasT_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyazar_467599_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyazar_266248_value", "value", "", "", "")
        ),
        "Date"
      ),
      UyeId_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyeId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyeId
            : null
        ),
        "Guid"
      ),
      BasT_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyazar_467599_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyazar_266248_value", "value", "", "", "")
        ),
        "Date"
      ),
      UyeId_5: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyeId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyeId
            : null
        ),
        "Guid"
      ),
      BasT_5: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyazar_467599_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_5: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyazar_266248_value", "value", "", "", "")
        ),
        "Date"
      ),
      UyeId_6: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyeId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyeId
            : null
        ),
        "Guid"
      ),
      BasT_6: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyazar_467599_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_6: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyazar_266248_value", "value", "", "", "")
        ),
        "Date"
      ),
      UyeId_7: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyeId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyeId
            : null
        ),
        "Guid"
      ),
      BasT_7: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyazar_467599_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_7: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyazar_266248_value", "value", "", "", "")
        ),
        "Date"
      ),
      UyeId_8: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyeId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyeId
            : null
        ),
        "Guid"
      ),
      BasT_8: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyazar_467599_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_8: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyazar_266248_value", "value", "", "", "")
        ),
        "Date"
      ),
      UyeId_9: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyeId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyeId
            : null
        ),
        "Guid"
      ),
      BasT_9: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyazar_467599_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_9: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyazar_266248_value", "value", "", "", "")
        ),
        "Date"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "DashBoardYazar/DashBoardYazarComponent_467599_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.DBMatbaa_TeklifTaslakCount = result?.data.dbMatbaa_TeklifTaslakCount;
    formVars.dashboardyazar_574514_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_TeklifTaslakCount?.length > 0
        ? stateVars.DBMatbaa_TeklifTaslakCount[0].recordCount
        : this.state.DBMatbaa_TeklifTaslakCount?.length > 0
        ? this.state.DBMatbaa_TeklifTaslakCount[0].recordCount
        : null
    );
    stateVars.DBMatbaa_TeklifOnayCount = result?.data.dbMatbaa_TeklifOnayCount;
    formVars.dashboardyazar_449235_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_TeklifOnayCount?.length > 0
        ? stateVars.DBMatbaa_TeklifOnayCount[0].recordCount
        : this.state.DBMatbaa_TeklifOnayCount?.length > 0
        ? this.state.DBMatbaa_TeklifOnayCount[0].recordCount
        : null
    );
    stateVars.DBMatbaa_TeklifOnaylananCount = result?.data.dbMatbaa_TeklifOnaylananCount;
    formVars.dashboardyazar_769574_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_TeklifOnaylananCount?.length > 0
        ? stateVars.DBMatbaa_TeklifOnaylananCount[0].recordCount
        : this.state.DBMatbaa_TeklifOnaylananCount?.length > 0
        ? this.state.DBMatbaa_TeklifOnaylananCount[0].recordCount
        : null
    );
    stateVars.DBMatbaa_TeklifOnaylanmayanCount = result?.data.dbMatbaa_TeklifOnaylanmayanCount;
    formVars.dashboardyazar_781946_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_TeklifOnaylanmayanCount?.length > 0
        ? stateVars.DBMatbaa_TeklifOnaylanmayanCount[0].recordCount
        : this.state.DBMatbaa_TeklifOnaylanmayanCount?.length > 0
        ? this.state.DBMatbaa_TeklifOnaylanmayanCount[0].recordCount
        : null
    );
    stateVars.DBMatbaa_SiparisTaslakCount = result?.data.dbMatbaa_SiparisTaslakCount;
    formVars.dashboardyazar_239415_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_SiparisTaslakCount?.length > 0
        ? stateVars.DBMatbaa_SiparisTaslakCount[0].recordCount
        : this.state.DBMatbaa_SiparisTaslakCount?.length > 0
        ? this.state.DBMatbaa_SiparisTaslakCount[0].recordCount
        : null
    );
    stateVars.DBMatbaa_SiparisOnayCount = result?.data.dbMatbaa_SiparisOnayCount;
    formVars.dashboardyazar_679156_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_SiparisOnayCount?.length > 0
        ? stateVars.DBMatbaa_SiparisOnayCount[0].recordCount
        : this.state.DBMatbaa_SiparisOnayCount?.length > 0
        ? this.state.DBMatbaa_SiparisOnayCount[0].recordCount
        : null
    );
    stateVars.DBMatbaa_SiparisOnaylananCount = result?.data.dbMatbaa_SiparisOnaylananCount;
    formVars.dashboardyazar_267459_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_SiparisOnaylananCount?.length > 0
        ? stateVars.DBMatbaa_SiparisOnaylananCount[0].recordCount
        : this.state.DBMatbaa_SiparisOnaylananCount?.length > 0
        ? this.state.DBMatbaa_SiparisOnaylananCount[0].recordCount
        : null
    );
    stateVars.DBMatbaa_SiparisOnaylanmayanCount = result?.data.dbMatbaa_SiparisOnaylanmayanCount;
    formVars.dashboardyazar_825698_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_SiparisOnaylanmayanCount?.length > 0
        ? stateVars.DBMatbaa_SiparisOnaylanmayanCount[0].recordCount
        : this.state.DBMatbaa_SiparisOnaylanmayanCount?.length > 0
        ? this.state.DBMatbaa_SiparisOnaylanmayanCount[0].recordCount
        : null
    );
    stateVars.DBMatbaa_UretimBaslayanCount = result?.data.dbMatbaa_UretimBaslayanCount;
    formVars.dashboardyazar_784595_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_UretimBaslayanCount?.length > 0
        ? stateVars.DBMatbaa_UretimBaslayanCount[0].recordCount
        : this.state.DBMatbaa_UretimBaslayanCount?.length > 0
        ? this.state.DBMatbaa_UretimBaslayanCount[0].recordCount
        : null
    );
    stateVars.DBMatbaa_UretimBitenCount = result?.data.dbMatbaa_UretimBitenCount;
    formVars.dashboardyazar_941125_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_UretimBitenCount?.length > 0
        ? stateVars.DBMatbaa_UretimBitenCount[0].recordCount
        : this.state.DBMatbaa_UretimBitenCount?.length > 0
        ? this.state.DBMatbaa_UretimBitenCount[0].recordCount
        : null
    );
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.DashBoardYazarComponent_467599_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  DashBoardYazarComponent_467599_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.dashboardyazar_574514_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_TeklifTaslakCount?.length > 0
        ? stateVars.DBMatbaa_TeklifTaslakCount[0].recordCount
        : this.state.DBMatbaa_TeklifTaslakCount?.length > 0
        ? this.state.DBMatbaa_TeklifTaslakCount[0].recordCount
        : null
    );

    formVars.dashboardyazar_449235_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_TeklifOnayCount?.length > 0
        ? stateVars.DBMatbaa_TeklifOnayCount[0].recordCount
        : this.state.DBMatbaa_TeklifOnayCount?.length > 0
        ? this.state.DBMatbaa_TeklifOnayCount[0].recordCount
        : null
    );

    formVars.dashboardyazar_769574_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_TeklifOnaylananCount?.length > 0
        ? stateVars.DBMatbaa_TeklifOnaylananCount[0].recordCount
        : this.state.DBMatbaa_TeklifOnaylananCount?.length > 0
        ? this.state.DBMatbaa_TeklifOnaylananCount[0].recordCount
        : null
    );

    formVars.dashboardyazar_781946_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_TeklifOnaylanmayanCount?.length > 0
        ? stateVars.DBMatbaa_TeklifOnaylanmayanCount[0].recordCount
        : this.state.DBMatbaa_TeklifOnaylanmayanCount?.length > 0
        ? this.state.DBMatbaa_TeklifOnaylanmayanCount[0].recordCount
        : null
    );

    formVars.dashboardyazar_239415_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_SiparisTaslakCount?.length > 0
        ? stateVars.DBMatbaa_SiparisTaslakCount[0].recordCount
        : this.state.DBMatbaa_SiparisTaslakCount?.length > 0
        ? this.state.DBMatbaa_SiparisTaslakCount[0].recordCount
        : null
    );

    formVars.dashboardyazar_679156_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_SiparisOnayCount?.length > 0
        ? stateVars.DBMatbaa_SiparisOnayCount[0].recordCount
        : this.state.DBMatbaa_SiparisOnayCount?.length > 0
        ? this.state.DBMatbaa_SiparisOnayCount[0].recordCount
        : null
    );

    formVars.dashboardyazar_267459_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_SiparisOnaylananCount?.length > 0
        ? stateVars.DBMatbaa_SiparisOnaylananCount[0].recordCount
        : this.state.DBMatbaa_SiparisOnaylananCount?.length > 0
        ? this.state.DBMatbaa_SiparisOnaylananCount[0].recordCount
        : null
    );

    formVars.dashboardyazar_825698_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_SiparisOnaylanmayanCount?.length > 0
        ? stateVars.DBMatbaa_SiparisOnaylanmayanCount[0].recordCount
        : this.state.DBMatbaa_SiparisOnaylanmayanCount?.length > 0
        ? this.state.DBMatbaa_SiparisOnaylanmayanCount[0].recordCount
        : null
    );

    formVars.dashboardyazar_784595_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_UretimBaslayanCount?.length > 0
        ? stateVars.DBMatbaa_UretimBaslayanCount[0].recordCount
        : this.state.DBMatbaa_UretimBaslayanCount?.length > 0
        ? this.state.DBMatbaa_UretimBaslayanCount[0].recordCount
        : null
    );

    formVars.dashboardyazar_941125_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_UretimBitenCount?.length > 0
        ? stateVars.DBMatbaa_UretimBitenCount[0].recordCount
        : this.state.DBMatbaa_UretimBitenCount?.length > 0
        ? this.state.DBMatbaa_UretimBitenCount[0].recordCount
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashBoardYazarComponent_266248_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UyeId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyeId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyeId
            : null
        ),
        "Guid"
      ),
      BasT_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyazar_467599_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyazar_266248_value", "value", "", "", "")
        ),
        "Date"
      ),
      UyeId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyeId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyeId
            : null
        ),
        "Guid"
      ),
      BasT_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyazar_467599_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyazar_266248_value", "value", "", "", "")
        ),
        "Date"
      ),
      UyeId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyeId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyeId
            : null
        ),
        "Guid"
      ),
      BasT_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyazar_467599_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyazar_266248_value", "value", "", "", "")
        ),
        "Date"
      ),
      UyeId_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyeId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyeId
            : null
        ),
        "Guid"
      ),
      BasT_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyazar_467599_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyazar_266248_value", "value", "", "", "")
        ),
        "Date"
      ),
      UyeId_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyeId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyeId
            : null
        ),
        "Guid"
      ),
      BasT_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyazar_467599_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyazar_266248_value", "value", "", "", "")
        ),
        "Date"
      ),
      UyeId_5: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyeId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyeId
            : null
        ),
        "Guid"
      ),
      BasT_5: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyazar_467599_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_5: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyazar_266248_value", "value", "", "", "")
        ),
        "Date"
      ),
      UyeId_6: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyeId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyeId
            : null
        ),
        "Guid"
      ),
      BasT_6: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyazar_467599_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_6: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyazar_266248_value", "value", "", "", "")
        ),
        "Date"
      ),
      UyeId_7: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyeId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyeId
            : null
        ),
        "Guid"
      ),
      BasT_7: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyazar_467599_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_7: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyazar_266248_value", "value", "", "", "")
        ),
        "Date"
      ),
      UyeId_8: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyeId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyeId
            : null
        ),
        "Guid"
      ),
      BasT_8: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyazar_467599_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_8: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyazar_266248_value", "value", "", "", "")
        ),
        "Date"
      ),
      UyeId_9: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyeId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyeId
            : null
        ),
        "Guid"
      ),
      BasT_9: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyazar_467599_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_9: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyazar_266248_value", "value", "", "", "")
        ),
        "Date"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "DashBoardYazar/DashBoardYazarComponent_266248_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.DBMatbaa_TeklifTaslakCount = result?.data.dbMatbaa_TeklifTaslakCount;
    formVars.dashboardyazar_574514_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_TeklifTaslakCount?.length > 0
        ? stateVars.DBMatbaa_TeklifTaslakCount[0].recordCount
        : this.state.DBMatbaa_TeklifTaslakCount?.length > 0
        ? this.state.DBMatbaa_TeklifTaslakCount[0].recordCount
        : null
    );
    stateVars.DBMatbaa_TeklifOnayCount = result?.data.dbMatbaa_TeklifOnayCount;
    formVars.dashboardyazar_449235_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_TeklifOnayCount?.length > 0
        ? stateVars.DBMatbaa_TeklifOnayCount[0].recordCount
        : this.state.DBMatbaa_TeklifOnayCount?.length > 0
        ? this.state.DBMatbaa_TeklifOnayCount[0].recordCount
        : null
    );
    stateVars.DBMatbaa_TeklifOnaylananCount = result?.data.dbMatbaa_TeklifOnaylananCount;
    formVars.dashboardyazar_769574_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_TeklifOnaylananCount?.length > 0
        ? stateVars.DBMatbaa_TeklifOnaylananCount[0].recordCount
        : this.state.DBMatbaa_TeklifOnaylananCount?.length > 0
        ? this.state.DBMatbaa_TeklifOnaylananCount[0].recordCount
        : null
    );
    stateVars.DBMatbaa_TeklifOnaylanmayanCount = result?.data.dbMatbaa_TeklifOnaylanmayanCount;
    formVars.dashboardyazar_781946_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_TeklifOnaylanmayanCount?.length > 0
        ? stateVars.DBMatbaa_TeklifOnaylanmayanCount[0].recordCount
        : this.state.DBMatbaa_TeklifOnaylanmayanCount?.length > 0
        ? this.state.DBMatbaa_TeklifOnaylanmayanCount[0].recordCount
        : null
    );
    stateVars.DBMatbaa_SiparisTaslakCount = result?.data.dbMatbaa_SiparisTaslakCount;
    formVars.dashboardyazar_239415_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_SiparisTaslakCount?.length > 0
        ? stateVars.DBMatbaa_SiparisTaslakCount[0].recordCount
        : this.state.DBMatbaa_SiparisTaslakCount?.length > 0
        ? this.state.DBMatbaa_SiparisTaslakCount[0].recordCount
        : null
    );
    stateVars.DBMatbaa_SiparisOnayCount = result?.data.dbMatbaa_SiparisOnayCount;
    formVars.dashboardyazar_679156_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_SiparisOnayCount?.length > 0
        ? stateVars.DBMatbaa_SiparisOnayCount[0].recordCount
        : this.state.DBMatbaa_SiparisOnayCount?.length > 0
        ? this.state.DBMatbaa_SiparisOnayCount[0].recordCount
        : null
    );
    stateVars.DBMatbaa_SiparisOnaylananCount = result?.data.dbMatbaa_SiparisOnaylananCount;
    formVars.dashboardyazar_267459_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_SiparisOnaylananCount?.length > 0
        ? stateVars.DBMatbaa_SiparisOnaylananCount[0].recordCount
        : this.state.DBMatbaa_SiparisOnaylananCount?.length > 0
        ? this.state.DBMatbaa_SiparisOnaylananCount[0].recordCount
        : null
    );
    stateVars.DBMatbaa_SiparisOnaylanmayanCount = result?.data.dbMatbaa_SiparisOnaylanmayanCount;
    formVars.dashboardyazar_825698_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_SiparisOnaylanmayanCount?.length > 0
        ? stateVars.DBMatbaa_SiparisOnaylanmayanCount[0].recordCount
        : this.state.DBMatbaa_SiparisOnaylanmayanCount?.length > 0
        ? this.state.DBMatbaa_SiparisOnaylanmayanCount[0].recordCount
        : null
    );
    stateVars.DBMatbaa_UretimBaslayanCount = result?.data.dbMatbaa_UretimBaslayanCount;
    formVars.dashboardyazar_784595_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_UretimBaslayanCount?.length > 0
        ? stateVars.DBMatbaa_UretimBaslayanCount[0].recordCount
        : this.state.DBMatbaa_UretimBaslayanCount?.length > 0
        ? this.state.DBMatbaa_UretimBaslayanCount[0].recordCount
        : null
    );
    stateVars.DBMatbaa_UretimBitenCount = result?.data.dbMatbaa_UretimBitenCount;
    formVars.dashboardyazar_941125_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_UretimBitenCount?.length > 0
        ? stateVars.DBMatbaa_UretimBitenCount[0].recordCount
        : this.state.DBMatbaa_UretimBitenCount?.length > 0
        ? this.state.DBMatbaa_UretimBitenCount[0].recordCount
        : null
    );
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.DashBoardYazarComponent_266248_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  DashBoardYazarComponent_266248_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.dashboardyazar_574514_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_TeklifTaslakCount?.length > 0
        ? stateVars.DBMatbaa_TeklifTaslakCount[0].recordCount
        : this.state.DBMatbaa_TeklifTaslakCount?.length > 0
        ? this.state.DBMatbaa_TeklifTaslakCount[0].recordCount
        : null
    );

    formVars.dashboardyazar_449235_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_TeklifOnayCount?.length > 0
        ? stateVars.DBMatbaa_TeklifOnayCount[0].recordCount
        : this.state.DBMatbaa_TeklifOnayCount?.length > 0
        ? this.state.DBMatbaa_TeklifOnayCount[0].recordCount
        : null
    );

    formVars.dashboardyazar_769574_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_TeklifOnaylananCount?.length > 0
        ? stateVars.DBMatbaa_TeklifOnaylananCount[0].recordCount
        : this.state.DBMatbaa_TeklifOnaylananCount?.length > 0
        ? this.state.DBMatbaa_TeklifOnaylananCount[0].recordCount
        : null
    );

    formVars.dashboardyazar_781946_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_TeklifOnaylanmayanCount?.length > 0
        ? stateVars.DBMatbaa_TeklifOnaylanmayanCount[0].recordCount
        : this.state.DBMatbaa_TeklifOnaylanmayanCount?.length > 0
        ? this.state.DBMatbaa_TeklifOnaylanmayanCount[0].recordCount
        : null
    );

    formVars.dashboardyazar_239415_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_SiparisTaslakCount?.length > 0
        ? stateVars.DBMatbaa_SiparisTaslakCount[0].recordCount
        : this.state.DBMatbaa_SiparisTaslakCount?.length > 0
        ? this.state.DBMatbaa_SiparisTaslakCount[0].recordCount
        : null
    );

    formVars.dashboardyazar_679156_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_SiparisOnayCount?.length > 0
        ? stateVars.DBMatbaa_SiparisOnayCount[0].recordCount
        : this.state.DBMatbaa_SiparisOnayCount?.length > 0
        ? this.state.DBMatbaa_SiparisOnayCount[0].recordCount
        : null
    );

    formVars.dashboardyazar_267459_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_SiparisOnaylananCount?.length > 0
        ? stateVars.DBMatbaa_SiparisOnaylananCount[0].recordCount
        : this.state.DBMatbaa_SiparisOnaylananCount?.length > 0
        ? this.state.DBMatbaa_SiparisOnaylananCount[0].recordCount
        : null
    );

    formVars.dashboardyazar_825698_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_SiparisOnaylanmayanCount?.length > 0
        ? stateVars.DBMatbaa_SiparisOnaylanmayanCount[0].recordCount
        : this.state.DBMatbaa_SiparisOnaylanmayanCount?.length > 0
        ? this.state.DBMatbaa_SiparisOnaylanmayanCount[0].recordCount
        : null
    );

    formVars.dashboardyazar_784595_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_UretimBaslayanCount?.length > 0
        ? stateVars.DBMatbaa_UretimBaslayanCount[0].recordCount
        : this.state.DBMatbaa_UretimBaslayanCount?.length > 0
        ? this.state.DBMatbaa_UretimBaslayanCount[0].recordCount
        : null
    );

    formVars.dashboardyazar_941125_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_UretimBitenCount?.length > 0
        ? stateVars.DBMatbaa_UretimBitenCount[0].recordCount
        : this.state.DBMatbaa_UretimBitenCount?.length > 0
        ? this.state.DBMatbaa_UretimBitenCount[0].recordCount
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashBoardYazarComponent_19319_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      SonTarihAraligi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "dashboardyazar_19319_value",
            "value",
            "DB_GetDateRangesNames",
            "rangeName",
            ""
          )
        ),
        "string"
      ),
      userName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      RangeName_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "dashboardyazar_19319_value",
            "value",
            "DB_GetDateRangesNames",
            "rangeName",
            "rangeName"
          )
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "DashBoardYazar/DashBoardYazarComponent_19319_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.KullaniciLocalStorageSave = result?.data.kullaniciLocalStorageSave;
    stateVars.DB_GetDateRanges = result?.data.dB_GetDateRanges;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.DashBoardYazarComponent_19319_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  DashBoardYazarComponent_19319_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    await this.DashBoardYazarPageInit();

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [997406, 705049] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.DashBoardYazarPageInit();
    }
  }
}
