import { FormInstance } from "antd/es/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IEseriOnaylananTeklifList_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IEseriOnaylananTeklifList_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  Logout: boolean;
  Logout_dummy: boolean;
  goToSignInActivity: any;
  goToSignInActivity_dummy: any;
  MyContext: any[];
  MyContext_dummy: any[];
  TeklifEserOnaylananList: any[];
  TeklifEserOnaylananList_dummy: any[];
  setPTotalCount01: any[];
  setPTotalCount01_dummy: any[];
  RoleSadeceGoruntule: any[];
  RoleSadeceGoruntule_dummy: any[];
  ChangeVisibilityOf: string;
  ChangeVisibilityOf_dummy: string;
  TeklifEserOnaylananListToExcel: any[];
  TeklifEserOnaylananListToExcel_dummy: any[];
  ExportExcel: any;
  ExportExcel_dummy: any;
  isComp251583Visible: "visible" | "hidden";
  isComp636745Visible: "visible" | "hidden";
  isComp48382Visible: "visible" | "hidden";
  isComp285705Visible: "visible" | "hidden";
  isComp5970Visible: "visible" | "hidden";
  isComp989604Visible: "visible" | "hidden";
  isComp546420Visible: "visible" | "hidden";
}

export class EseriOnaylananTeklifList_ScreenBase extends React.PureComponent<
  IEseriOnaylananTeklifList_ScreenProps,
  any
> {
  eserionaylanantekliflist_211503_value_kuikaSelectBoxRef: React.RefObject<any>;
  eserionaylanantekliflist_477972_value_kuikaSelectBoxRef: React.RefObject<any>;
  eserionaylanantekliflist_348605_value_kuikaTableRef: React.RefObject<any>;
  eserionaylanantekliflist_880048_value_kuikaPaginationRef: React.RefObject<any>;
  ml = [
    {
      Id: "41cd36a4-5b07-cfca-4f7f-f48019ff4ff3",
      Name: "tr_TR",
      ShortName: "Türkçe",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "0069cee9-16ca-4a5a-aab9-dd58af96ca96", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "8100aa19-5585-4cbe-9c72-6bd72d578467",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 364094, PropertyName: "value", Value: "Teklif Listesi (Eser Bilgileri Onaylı)" },
        { Id: 412428, PropertyName: "label", Value: "Profil" },
        { Id: 668195, PropertyName: "label", Value: "Çıkış Yap" },
        { Id: 152909, PropertyName: "value", Value: "Sipariş oluşturulanları da göster" },
        { Id: 211503, PropertyName: "placeholder", Value: "Sıralama" },
        { Id: 477972, PropertyName: "placeholder", Value: "A-z" },
        { Id: 685841, PropertyName: "placeholder", Value: "Ara..." },
        { Id: 348605, PropertyName: "nodatafoundmessage", Value: "Teklif Kaydı Bulunamadı." },
        { Id: 418694, PropertyName: "title", Value: "Talep No" },
        { Id: 325340, PropertyName: "value", Value: "[datafield:talep_no]" },
        { Id: 439820, PropertyName: "title", Value: "Teklif No" },
        { Id: 619741, PropertyName: "value", Value: "[datafield:teklif_no]" },
        { Id: 642939, PropertyName: "title", Value: "Sipariş No" },
        { Id: 636745, PropertyName: "value", Value: "[datafield:siparis_no]" },
        { Id: 256601, PropertyName: "title", Value: "Kitap Adı" },
        { Id: 744431, PropertyName: "value", Value: "[datafield:kitapad]" },
        { Id: 52036, PropertyName: "title", Value: "Baskı Adet" },
        { Id: 257784, PropertyName: "value", Value: "[datafield:baskiadet]" },
        { Id: 121670, PropertyName: "title", Value: "Firma" },
        { Id: 208725, PropertyName: "value", Value: "[datafield:firma]" },
        { Id: 467153, PropertyName: "title", Value: "Teklif Tarihi" },
        { Id: 658945, PropertyName: "value", Value: "[datafield:tekliftarihi]" },
        { Id: 558595, PropertyName: "title", Value: "Geçerlilik Tarihi" },
        { Id: 586928, PropertyName: "value", Value: "[datafield:teklifgecerliliktarihi]" },
        { Id: 421555, PropertyName: "title", Value: "Teklif Fiyatı" },
        { Id: 863036, PropertyName: "value", Value: "[datafield:tekliffiyat]" },
        { Id: 243022, PropertyName: "title", Value: "Eser Bilgisi" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";
  EseriOnaylananTeklifListRecuringAction_30_Interval: any;

  constructor(props) {
    super(props);
    this.eserionaylanantekliflist_211503_value_kuikaSelectBoxRef = React.createRef();
    this.eserionaylanantekliflist_477972_value_kuikaSelectBoxRef = React.createRef();
    this.eserionaylanantekliflist_348605_value_kuikaTableRef = React.createRef();
    this.eserionaylanantekliflist_880048_value_kuikaPaginationRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      NAVIGATE: "",
      Logout: false,
      goToSignInActivity: "",
      MyContext: [],
      TeklifEserOnaylananList: [],
      setPTotalCount01: [],
      RoleSadeceGoruntule: [],
      ChangeVisibilityOf: "",
      TeklifEserOnaylananListToExcel: [],
      ExportExcel: "",
      dataSource_211503: [
        { key: "TeklifNo", text: "Teklif No" },
        { key: "TeklifTarihi", text: "Teklif Tarihi" },
        { key: "SiparisNo", text: "Sipariş No" }
      ],
      dataSource_477972: [
        { key: "ASC", text: "A-Z" },
        { key: "DESC", text: "Z-A" }
      ],
      isComp251583Visible: "visible",
      isComp636745Visible: "hidden",
      isComp48382Visible: "hidden",
      isComp285705Visible: "hidden",
      isComp5970Visible: "hidden",
      isComp989604Visible: "hidden",
      isComp546420Visible: "hidden"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("eserionaylanantekliflist", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.EseriOnaylananTeklifListPageInit();
    }

    if (false) {
      if (false) {
        await this.EseriOnaylananTeklifListRecuringAction_30_();
      }
      this.EseriOnaylananTeklifListRecuringAction_30_Interval = setInterval(async () => {
        await this.EseriOnaylananTeklifListRecuringAction_30_();
      }, 30000);
    }

    KuikaAppManager.calculateAndSetBodyHeight("eserionaylanantekliflist", "");
  }

  componentWillUnmount() {
    if (this.EseriOnaylananTeklifListRecuringAction_30_Interval) {
      clearInterval(this.EseriOnaylananTeklifListRecuringAction_30_Interval);
    }
  }

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("eserionaylanantekliflist", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.EseriOnaylananTeklifListPageInit();
    }

    if (prevState.isPageInitLoaded !== this.state.isPageInitLoaded && this.state.isPageInitLoaded === true) {
      if (false && true) {
        await this.EseriOnaylananTeklifListRecuringAction_30_();
      }
      this.EseriOnaylananTeklifListRecuringAction_30_Interval = setInterval(async () => {
        await this.EseriOnaylananTeklifListRecuringAction_30_();
      }, 30000);
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      eserionaylanantekliflist_801926_value: this.state.MyContext?.at?.(0)?.profilFoto ?? undefined,
      eserionaylanantekliflist_11022_value: false
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  EseriOnaylananTeklifListPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      MuhatapmiFirmami_1: ReactSystemFunctions.convertToTypeByName("Muhatap", "string"),
      SiparisOlusturulanGoster_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "eserionaylanantekliflist_11022_value", "value", "", "", "")
        ),
        "boolean"
      ),
      ara_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "eserionaylanantekliflist_685841_value", "value", "", "", "")
        ),
        "string"
      ),
      OrderByColumn_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "eserionaylanantekliflist_211503_value", "value", "", "key", "")
        ),
        "string"
      ),
      AscDesc_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "eserionaylanantekliflist_477972_value", "value", "", "key", "")
        ),
        "string"
      ),
      currentpage_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "eserionaylanantekliflist_880048_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "eserionaylanantekliflist_880048_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EseriOnaylananTeklifList/EseriOnaylananTeklifListPageInit",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    formVars.eserionaylanantekliflist_801926_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0
        ? stateVars.MyContext[0].profilFoto
        : this.state.MyContext?.length > 0
        ? this.state.MyContext[0].profilFoto
        : null
    );
    formVars.eserionaylanantekliflist_172357_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0
        ? stateVars.MyContext[0].fullName
        : this.state.MyContext?.length > 0
        ? this.state.MyContext[0].fullName
        : null
    );
    formVars.eserionaylanantekliflist_590045_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0
        ? stateVars.MyContext[0].uyeAd
        : this.state.MyContext?.length > 0
        ? this.state.MyContext[0].uyeAd
        : null
    );
    stateVars.TeklifEserOnaylananList = result?.data.teklifEserOnaylananList;

    stateVars.setPTotalCount01 = result?.data.setPTotalCount01;
    stateVars.RoleSadeceGoruntule = result?.data.roleSadeceGoruntule;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EseriOnaylananTeklifListPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EseriOnaylananTeklifListPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg043
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg043
            : null
        ),
        "1"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp251583Visible",
        "hidden"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EseriOnaylananTeklifListPageInit2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EseriOnaylananTeklifListPageInit2_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  EseriOnaylananTeklifListPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg043
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg043
            : null
        ),
        "1"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp251583Visible",
        "hidden"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EseriOnaylananTeklifListPageInit3_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EseriOnaylananTeklifListPageInit3_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  EseriOnaylananTeklifListPageInit3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.eserionaylanantekliflist_801926_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0
        ? stateVars.MyContext[0].profilFoto
        : this.state.MyContext?.length > 0
        ? this.state.MyContext[0].profilFoto
        : null
    );

    formVars.eserionaylanantekliflist_172357_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0
        ? stateVars.MyContext[0].fullName
        : this.state.MyContext?.length > 0
        ? this.state.MyContext[0].fullName
        : null
    );

    formVars.eserionaylanantekliflist_590045_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0
        ? stateVars.MyContext[0].uyeAd
        : this.state.MyContext?.length > 0
        ? this.state.MyContext[0].uyeAd
        : null
    );

    stateVars.dataSource_348605 = this.state.TeklifEserOnaylananList;
    stateVars.isComp636745Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(this, "eserionaylanantekliflist_348605_value", "siparis_No"),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp48382Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.value(this, "eserionaylanantekliflist_348605_value", "siparis_No"),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp285705Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "eserionaylanantekliflist_348605_value", "isOnayBeklemedeIconsVisibility"),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp5970Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "eserionaylanantekliflist_348605_value", "isReddedildiIconsVisibility"),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp989604Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "eserionaylanantekliflist_348605_value", "isOnaylandiIconsVisibility"),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp546420Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "eserionaylanantekliflist_348605_value", "isInceleIconsVisibility"),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.eserionaylanantekliflist_880048_total = ReactSystemFunctions.value(
      this,
      stateVars.setPTotalCount01?.length > 0
        ? stateVars.setPTotalCount01[0].totalCount
        : this.state.setPTotalCount01?.length > 0
        ? this.state.setPTotalCount01[0].totalCount
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  EseriOnaylananTeklifListComponent_54788_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "EseriOnaylananTeklifList",
      "LeftMenu",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "242761",
      null,
      "left",
      null,
      "480px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EseriOnaylananTeklifListComponent_412428_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "EseriOnaylananTeklifList",
      "Profil",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "52075",
      null,
      "right",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EseriOnaylananTeklifListComponent_668195_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.Logout = await ReactSystemFunctions.logout();
    stateVars.tmpBoolResult = await ReactSystemFunctions.goToSignInActivity(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EseriOnaylananTeklifListComponent_11022_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      MuhatapmiFirmami_0: ReactSystemFunctions.convertToTypeByName("Muhatap", "string"),
      UyeId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyeId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyeId
            : null
        ),
        "Guid"
      ),
      SiparisOlusturulanGoster_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "eserionaylanantekliflist_11022_value", "value", "", "", "")
        ),
        "boolean"
      ),
      ara_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "eserionaylanantekliflist_685841_value", "value", "", "", "")
        ),
        "string"
      ),
      OrderByColumn_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "eserionaylanantekliflist_211503_value", "value", "", "key", "")
        ),
        "string"
      ),
      AscDesc_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "eserionaylanantekliflist_477972_value", "value", "", "key", "")
        ),
        "string"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "eserionaylanantekliflist_880048_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "eserionaylanantekliflist_880048_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EseriOnaylananTeklifList/EseriOnaylananTeklifListComponent_11022_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.TeklifEserOnaylananList = result?.data.teklifEserOnaylananList;

    stateVars.setPTotalCount01 = result?.data.setPTotalCount01;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EseriOnaylananTeklifListComponent_11022_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EseriOnaylananTeklifListComponent_11022_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_348605 = this.state.TeklifEserOnaylananList;
    formVars.eserionaylanantekliflist_880048_total = ReactSystemFunctions.value(
      this,
      stateVars.setPTotalCount01?.length > 0
        ? stateVars.setPTotalCount01[0].totalCount
        : this.state.setPTotalCount01?.length > 0
        ? this.state.setPTotalCount01[0].totalCount
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EseriOnaylananTeklifListComponent_211503_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      MuhatapmiFirmami_0: ReactSystemFunctions.convertToTypeByName("Muhatap", "string"),
      UyeId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyeId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyeId
            : null
        ),
        "Guid"
      ),
      SiparisOlusturulanGoster_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "eserionaylanantekliflist_11022_value", "value", "", "", "")
        ),
        "boolean"
      ),
      ara_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "eserionaylanantekliflist_685841_value", "value", "", "", "")
        ),
        "string"
      ),
      OrderByColumn_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "eserionaylanantekliflist_211503_value", "value", "", "key", "")
        ),
        "string"
      ),
      AscDesc_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "eserionaylanantekliflist_477972_value", "value", "", "key", "")
        ),
        "string"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "eserionaylanantekliflist_880048_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "eserionaylanantekliflist_880048_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EseriOnaylananTeklifList/EseriOnaylananTeklifListComponent_211503_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.TeklifEserOnaylananList = result?.data.teklifEserOnaylananList;

    stateVars.setPTotalCount01 = result?.data.setPTotalCount01;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EseriOnaylananTeklifListComponent_211503_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EseriOnaylananTeklifListComponent_211503_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_348605 = this.state.TeklifEserOnaylananList;
    formVars.eserionaylanantekliflist_880048_total = ReactSystemFunctions.value(
      this,
      stateVars.setPTotalCount01?.length > 0
        ? stateVars.setPTotalCount01[0].totalCount
        : this.state.setPTotalCount01?.length > 0
        ? this.state.setPTotalCount01[0].totalCount
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EseriOnaylananTeklifListComponent_477972_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      MuhatapmiFirmami_0: ReactSystemFunctions.convertToTypeByName("Muhatap", "string"),
      UyeId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyeId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyeId
            : null
        ),
        "Guid"
      ),
      SiparisOlusturulanGoster_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "eserionaylanantekliflist_11022_value", "value", "", "", "")
        ),
        "boolean"
      ),
      ara_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "eserionaylanantekliflist_685841_value", "value", "", "", "")
        ),
        "string"
      ),
      OrderByColumn_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "eserionaylanantekliflist_211503_value", "value", "", "key", "")
        ),
        "string"
      ),
      AscDesc_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "eserionaylanantekliflist_477972_value", "value", "", "key", "")
        ),
        "string"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "eserionaylanantekliflist_880048_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "eserionaylanantekliflist_880048_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EseriOnaylananTeklifList/EseriOnaylananTeklifListComponent_477972_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.TeklifEserOnaylananList = result?.data.teklifEserOnaylananList;

    stateVars.setPTotalCount01 = result?.data.setPTotalCount01;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EseriOnaylananTeklifListComponent_477972_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EseriOnaylananTeklifListComponent_477972_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_348605 = this.state.TeklifEserOnaylananList;
    formVars.eserionaylanantekliflist_880048_total = ReactSystemFunctions.value(
      this,
      stateVars.setPTotalCount01?.length > 0
        ? stateVars.setPTotalCount01[0].totalCount
        : this.state.setPTotalCount01?.length > 0
        ? this.state.setPTotalCount01[0].totalCount
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EseriOnaylananTeklifListComponent_413122_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      MuhatapmiFirmami_0: ReactSystemFunctions.convertToTypeByName("Muhatap", "string"),
      UyeId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyeId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyeId
            : null
        ),
        "Guid"
      ),
      SiparisOlusturulanGoster_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "eserionaylanantekliflist_11022_value", "value", "", "", "")
        ),
        "boolean"
      ),
      ara_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "eserionaylanantekliflist_685841_value", "value", "", "", "")
        ),
        "string"
      ),
      OrderByColumn_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "eserionaylanantekliflist_211503_value", "value", "", "key", "")
        ),
        "string"
      ),
      AscDesc_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "eserionaylanantekliflist_477972_value", "value", "", "key", "")
        ),
        "string"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "eserionaylanantekliflist_880048_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "eserionaylanantekliflist_880048_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EseriOnaylananTeklifList/EseriOnaylananTeklifListComponent_413122_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.TeklifEserOnaylananList = result?.data.teklifEserOnaylananList;

    stateVars.setPTotalCount01 = result?.data.setPTotalCount01;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EseriOnaylananTeklifListComponent_413122_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EseriOnaylananTeklifListComponent_413122_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_348605 = this.state.TeklifEserOnaylananList;
    formVars.eserionaylanantekliflist_880048_total = ReactSystemFunctions.value(
      this,
      stateVars.setPTotalCount01?.length > 0
        ? stateVars.setPTotalCount01[0].totalCount
        : this.state.setPTotalCount01?.length > 0
        ? this.state.setPTotalCount01[0].totalCount
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EseriOnaylananTeklifListComponent_762556_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      MuhatapmiFirmami_0: ReactSystemFunctions.convertToTypeByName("Muhatap", "string"),
      UyeId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyeId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyeId
            : null
        ),
        "Guid"
      ),
      SiparisOlusturulanGoster_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "eserionaylanantekliflist_11022_value", "value", "", "", "")
        ),
        "boolean"
      ),
      ara_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "eserionaylanantekliflist_685841_value", "value", "", "", "")
        ),
        "string"
      ),
      OrderByColumn_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "eserionaylanantekliflist_211503_value", "value", "", "key", "")
        ),
        "string"
      ),
      AscDesc_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "eserionaylanantekliflist_477972_value", "value", "", "key", "")
        ),
        "string"
      ),
      header_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      footer_1: ReactSystemFunctions.convertToTypeByName("", "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EseriOnaylananTeklifList/EseriOnaylananTeklifListComponent_762556_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.TeklifEserOnaylananListToExcel = result?.data.teklifEserOnaylananListToExcel;
    stateVars.ExportExcel = result?.data.exportExcel;
    ReactSystemFunctions.downloadFile(result?.data?.exportExcel);
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  EseriOnaylananTeklifListComponent_253641_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "VerilenTeklifKarsilastirma",
      "TeklifTalepKartID",
      ReactSystemFunctions.value(this, "eserionaylanantekliflist_348605_value", "teklifTalepKartId")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "EseriOnaylananTeklifList",
      "VerilenTeklifKarsilastirma",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "864693",
      null,
      "right",
      null,
      "90%",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EseriOnaylananTeklifListComponent_625771_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "TeklifVermeKart",
      "prmTeklifTalepID",
      ReactSystemFunctions.value(this, "eserionaylanantekliflist_348605_value", "teklifTalepKartId")
    );
    KuikaAppManager.addToPageInputVariables("TeklifVermeKart", "NewEditDisplay", "D");
    KuikaAppManager.addToPageInputVariables(
      "TeklifVermeKart",
      "prmUID",
      ReactSystemFunctions.value(this, "eserionaylanantekliflist_348605_value", "uyeId")
    );
    KuikaAppManager.addToPageInputVariables("TeklifVermeKart", "prmMuhatapMiFirmaMi", "Muhatap");

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "EseriOnaylananTeklifList",
      "TeklifVermeKart",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "348381",
      null,
      "right",
      null,
      "50%",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EseriOnaylananTeklifListComponent_942659_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "EserBilgileri",
      "prmTeklifTalepKartID",
      ReactSystemFunctions.value(this, "eserionaylanantekliflist_348605_value", "teklifTalepKartId")
    );
    KuikaAppManager.addToPageInputVariables("EserBilgileri", "NewEditDisplay", "D");
    KuikaAppManager.addToPageInputVariables("EserBilgileri", "prmKitapAd", null);
    KuikaAppManager.addToPageInputVariables(
      "EserBilgileri",
      "teklifNo",
      ReactSystemFunctions.value(this, "eserionaylanantekliflist_348605_value", "teklif_No")
    );
    KuikaAppManager.addToPageInputVariables(
      "EserBilgileri",
      "uyeAd",
      ReactSystemFunctions.value(this, "eserionaylanantekliflist_348605_value", "firma")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "EseriOnaylananTeklifList",
      "EserBilgileri",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "180819",
      null,
      "right",
      null,
      "50%",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EseriOnaylananTeklifListComponent_880048_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      MuhatapmiFirmami_0: ReactSystemFunctions.convertToTypeByName("Muhatap", "string"),
      UyeId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyeId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyeId
            : null
        ),
        "Guid"
      ),
      SiparisOlusturulanGoster_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "eserionaylanantekliflist_11022_value", "value", "", "", "")
        ),
        "boolean"
      ),
      ara_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "eserionaylanantekliflist_685841_value", "value", "", "", "")
        ),
        "string"
      ),
      OrderByColumn_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "eserionaylanantekliflist_211503_value", "value", "", "key", "")
        ),
        "string"
      ),
      AscDesc_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "eserionaylanantekliflist_477972_value", "value", "", "key", "")
        ),
        "string"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "eserionaylanantekliflist_880048_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "eserionaylanantekliflist_880048_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EseriOnaylananTeklifList/EseriOnaylananTeklifListComponent_880048_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.TeklifEserOnaylananList = result?.data.teklifEserOnaylananList;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EseriOnaylananTeklifListComponent_880048_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EseriOnaylananTeklifListComponent_880048_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_348605 = this.state.TeklifEserOnaylananList;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EseriOnaylananTeklifListRecuringAction_30_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      MuhatapmiFirmami_1: ReactSystemFunctions.convertToTypeByName("Muhatap", "string"),
      SiparisOlusturulanGoster_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "eserionaylanantekliflist_11022_value", "value", "", "", "")
        ),
        "boolean"
      ),
      ara_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "eserionaylanantekliflist_685841_value", "value", "", "", "")
        ),
        "string"
      ),
      OrderByColumn_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "eserionaylanantekliflist_211503_value", "value", "", "key", "")
        ),
        "string"
      ),
      AscDesc_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "eserionaylanantekliflist_477972_value", "value", "", "key", "")
        ),
        "string"
      ),
      currentpage_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "eserionaylanantekliflist_880048_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "eserionaylanantekliflist_880048_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    if (false) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EseriOnaylananTeklifList/EseriOnaylananTeklifListRecuringAction_30_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    formVars.eserionaylanantekliflist_801926_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0
        ? stateVars.MyContext[0].profilFoto
        : this.state.MyContext?.length > 0
        ? this.state.MyContext[0].profilFoto
        : null
    );
    formVars.eserionaylanantekliflist_172357_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0
        ? stateVars.MyContext[0].fullName
        : this.state.MyContext?.length > 0
        ? this.state.MyContext[0].fullName
        : null
    );
    formVars.eserionaylanantekliflist_590045_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0
        ? stateVars.MyContext[0].uyeAd
        : this.state.MyContext?.length > 0
        ? this.state.MyContext[0].uyeAd
        : null
    );
    stateVars.TeklifEserOnaylananList = result?.data.teklifEserOnaylananList;

    stateVars.setPTotalCount01 = result?.data.setPTotalCount01;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EseriOnaylananTeklifListRecuringAction_30_1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EseriOnaylananTeklifListRecuringAction_30_1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.eserionaylanantekliflist_801926_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0
        ? stateVars.MyContext[0].profilFoto
        : this.state.MyContext?.length > 0
        ? this.state.MyContext[0].profilFoto
        : null
    );

    formVars.eserionaylanantekliflist_172357_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0
        ? stateVars.MyContext[0].fullName
        : this.state.MyContext?.length > 0
        ? this.state.MyContext[0].fullName
        : null
    );

    formVars.eserionaylanantekliflist_590045_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0
        ? stateVars.MyContext[0].uyeAd
        : this.state.MyContext?.length > 0
        ? this.state.MyContext[0].uyeAd
        : null
    );

    stateVars.dataSource_348605 = this.state.TeklifEserOnaylananList;
    formVars.eserionaylanantekliflist_880048_total = ReactSystemFunctions.value(
      this,
      stateVars.setPTotalCount01?.length > 0
        ? stateVars.setPTotalCount01[0].totalCount
        : this.state.setPTotalCount01?.length > 0
        ? this.state.setPTotalCount01[0].totalCount
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [242761, 52075, 864693, 348381, 180819] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.EseriOnaylananTeklifListPageInit();
    }
  }
}
