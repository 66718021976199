import { FormInstance } from "antd/es/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IDogrulamaKodu_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IDogrulamaKodu_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  ReadFromSessionStorage: string;
  ReadFromSessionStorage_dummy: string;
  getFormatliGSM: any[];
  getFormatliGSM_dummy: any[];
  SetValueOf: any;
  SetValueOf_dummy: any;
  ChangeVisibilityOf: string;
  ChangeVisibilityOf_dummy: string;
  deleteUserNameINKUser: number;
  deleteUserNameINKUser_dummy: number;
  GoBack: any;
  GoBack_dummy: any;
  IsVerificationCodeValid: boolean;
  IsVerificationCodeValid_dummy: boolean;
  goToNextScreenAfterVerifyCode: any;
  goToNextScreenAfterVerifyCode_dummy: any;
  isComp56882Visible: "visible" | "hidden";
  isComp792604Visible: "visible" | "hidden";
  isComp86319Visible: "visible" | "hidden";
  isComp971102Visible: "visible" | "hidden";
  isComp639668Visible: "visible" | "hidden";
  isComp317628Visible: "visible" | "hidden";
  isComp367517Visible: "visible" | "hidden";
}

export class DogrulamaKodu_ScreenBase extends React.PureComponent<IDogrulamaKodu_ScreenProps, any> {
  ml = [
    {
      Id: "41cd36a4-5b07-cfca-4f7f-f48019ff4ff3",
      Name: "tr_TR",
      ShortName: "Türkçe",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "0069cee9-16ca-4a5a-aab9-dd58af96ca96", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "cdf94181-3a9f-4776-91e4-a331b51c490c",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 86319, PropertyName: "value", Value: "SMS Doğrulama Ekranı" },
        { Id: 56882, PropertyName: "value", Value: "Doğrulama Ekranı" },
        { Id: 639668, PropertyName: "placeholder", Value: "0***********" },
        { Id: 971102, PropertyName: "value", Value: "numaralı telefonunuza gönderdiğimiz doğrulama kodunu girin." },
        { Id: 792604, PropertyName: "value", Value: "Mail adresinize  gönderdiğimiz doğrulama kodunu girin." },
        { Id: 167495, PropertyName: "value", Value: "Doğrulama Kodu" },
        { Id: 13639, PropertyName: "placeholder", Value: "123456" },
        { Id: 619188, PropertyName: "value", Value: "Doğrulama Kodu Gelmediyse..." },
        { Id: 9622, PropertyName: "label", Value: "Bilgi Kontrolü İçin Geri Dön" },
        { Id: 887191, PropertyName: "label", Value: "Onayla" },
        { Id: 317628, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 367517, PropertyName: "placeholder", Value: "Type here..." }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      ReadFromSessionStorage: "",
      getFormatliGSM: [],
      SetValueOf: "",
      ChangeVisibilityOf: "",
      deleteUserNameINKUser: 0,
      GoBack: "",
      IsVerificationCodeValid: false,
      goToNextScreenAfterVerifyCode: "",
      isComp56882Visible: "hidden",
      isComp792604Visible: "hidden",
      isComp86319Visible: "hidden",
      isComp971102Visible: "hidden",
      isComp639668Visible: "hidden",
      isComp317628Visible: "hidden",
      isComp367517Visible: "hidden"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("dogrulamakodu", "");
      return;
    }

    if (true) {
      await this.DogrulamaKoduPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("dogrulamakodu", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("dogrulamakodu", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.DogrulamaKoduPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  DogrulamaKoduPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ReadFromSessionStorage = await ReactSystemFunctions.readFromSessionStorage("username");

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.DogrulamaKoduPageInit1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  DogrulamaKoduPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.ReadFromSessionStorage),
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "DogrulamaKodu/DogrulamaKoduPageInit1_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getFormatliGSM = result?.data.getFormatliGSM;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.DogrulamaKoduPageInit2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  DogrulamaKoduPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "dogrulamakodu_639668_value",
      ReactSystemFunctions.toString(
        this,
        stateVars.getFormatliGSM?.length > 0
          ? stateVars.getFormatliGSM[0].gsm
          : this.state.getFormatliGSM?.length > 0
          ? this.state.getFormatliGSM[0].gsm
          : null
      ),
      null
    );
    stateVars.ReadFromSessionStorage = await ReactSystemFunctions.readFromSessionStorage("ForgotmuSignupmi");

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.DogrulamaKoduPageInit3_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  DogrulamaKoduPageInit3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, this.state.ReadFromSessionStorage), "forgot")
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp56882Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.DogrulamaKoduPageInit4_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.DogrulamaKoduPageInit4_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  DogrulamaKoduPageInit4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, this.state.ReadFromSessionStorage), "forgot")
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp792604Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.DogrulamaKoduPageInit5_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.DogrulamaKoduPageInit5_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  DogrulamaKoduPageInit5_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, this.state.ReadFromSessionStorage), "signup")
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp86319Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.DogrulamaKoduPageInit6_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.DogrulamaKoduPageInit6_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  DogrulamaKoduPageInit6_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, this.state.ReadFromSessionStorage), "signup")
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp971102Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.DogrulamaKoduPageInit7_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.DogrulamaKoduPageInit7_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  DogrulamaKoduPageInit7_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, this.state.ReadFromSessionStorage), "signup")
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp639668Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  DogrulamaKoduComponent_9622_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ReadFromSessionStorage = await ReactSystemFunctions.readFromSessionStorage("username");

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.DogrulamaKoduComponent_9622_onClick1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  DogrulamaKoduComponent_9622_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.ReadFromSessionStorage),
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "DogrulamaKodu/DogrulamaKoduComponent_9622_onClick1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.deleteUserNameINKUser = result?.data.deleteUserNameINKUser;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.DogrulamaKoduComponent_9622_onClick2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  DogrulamaKoduComponent_9622_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DogrulamaKoduComponent_887191_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.IsVerificationCodeValid = await ReactSystemFunctions.isVerificationCodeValid(
      this,
      ReactSystemFunctions.value(
        this,
        ReactSystemFunctions.value(this, "dogrulamakodu_13639_value", "value", "", "", "")
      )
    );
    stateVars.tmpBoolResult = await ReactSystemFunctions.goToNextScreenAfterVerifyCode(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
