import { FormInstance } from "antd/es/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IBandrolYazisi_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IBandrolYazisi_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  MyContext: any[];
  MyContext_dummy: any[];
  BandrolYazisi: any[];
  BandrolYazisi_dummy: any[];
  ComponentToPdf2: string;
  ComponentToPdf2_dummy: string;
}

export class BandrolYazisi_ScreenBase extends React.PureComponent<IBandrolYazisi_ScreenProps, any> {
  ml = [
    {
      Id: "41cd36a4-5b07-cfca-4f7f-f48019ff4ff3",
      Name: "tr_TR",
      ShortName: "Türkçe",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "0069cee9-16ca-4a5a-aab9-dd58af96ca96", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "88d9e23f-8bc9-4741-bbb5-18a3e9e0950d",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 961950, PropertyName: "value", Value: "MATBAA YAZISI " },
        { Id: 392800, PropertyName: "value", Value: "YAYIMCI MESLEK BİRLİKLERİ FEDERASYONU’NA" },
        { Id: 134182, PropertyName: "value", Value: "Tarih :" },
        { Id: 804799, PropertyName: "value", Value: "Matbaa Adı :" },
        { Id: 116486, PropertyName: "value", Value: "Toplam Bandrol Adedi :" },
        { Id: 665695, PropertyName: "value", Value: "Kaşe :" },
        { Id: 59607, PropertyName: "value", Value: "İmza :" },
        { Id: 472662, PropertyName: "value", Value: "No :" },
        { Id: 966768, PropertyName: "value", Value: "Eser Adı :" },
        { Id: 208116, PropertyName: "value", Value: "Baskı Sayısı  :" },
        { Id: 983086, PropertyName: "value", Value: "Adedi :" },
        { Id: 165427, PropertyName: "label", Value: "PDF İndir" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      MyContext: [],
      BandrolYazisi: [],
      ComponentToPdf2: ""
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("bandrolyazisi", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.BandrolYazisiPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("bandrolyazisi", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("bandrolyazisi", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.BandrolYazisiPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  BandrolYazisiPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmId ?? this.props.screenInputs.prmid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "BandrolYazisi/BandrolYazisiPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    stateVars.BandrolYazisi = result?.data.bandrolYazisi;
    formVars.bandrolyazisi_966115_value = ReactSystemFunctions.toString(
      this,
      stateVars.BandrolYazisi?.length > 0
        ? stateVars.BandrolYazisi[0].metin
        : this.state.BandrolYazisi?.length > 0
        ? this.state.BandrolYazisi[0].metin
        : null
    );
    formVars.bandrolyazisi_284713_value = ReactSystemFunctions.toString(
      this,
      stateVars.BandrolYazisi?.length > 0
        ? stateVars.BandrolYazisi[0].tarih
        : this.state.BandrolYazisi?.length > 0
        ? this.state.BandrolYazisi[0].tarih
        : null
    );
    formVars.bandrolyazisi_799891_value = ReactSystemFunctions.toString(
      this,
      stateVars.BandrolYazisi?.length > 0
        ? stateVars.BandrolYazisi[0].matbaaAd
        : this.state.BandrolYazisi?.length > 0
        ? this.state.BandrolYazisi[0].matbaaAd
        : null
    );
    formVars.bandrolyazisi_334566_value = ReactSystemFunctions.toString(
      this,
      stateVars.BandrolYazisi?.length > 0
        ? stateVars.BandrolYazisi[0].bandrolAdet
        : this.state.BandrolYazisi?.length > 0
        ? this.state.BandrolYazisi[0].bandrolAdet
        : null
    );
    formVars.bandrolyazisi_632793_value = ReactSystemFunctions.toString(
      this,
      stateVars.BandrolYazisi?.length > 0
        ? stateVars.BandrolYazisi[0].sNo
        : this.state.BandrolYazisi?.length > 0
        ? this.state.BandrolYazisi[0].sNo
        : null
    );
    formVars.bandrolyazisi_757180_value = ReactSystemFunctions.toString(
      this,
      stateVars.BandrolYazisi?.length > 0
        ? stateVars.BandrolYazisi[0].eseraAd
        : this.state.BandrolYazisi?.length > 0
        ? this.state.BandrolYazisi[0].eseraAd
        : null
    );
    formVars.bandrolyazisi_854319_value = ReactSystemFunctions.toString(
      this,
      stateVars.BandrolYazisi?.length > 0
        ? stateVars.BandrolYazisi[0].baskiSayısı
        : this.state.BandrolYazisi?.length > 0
        ? this.state.BandrolYazisi[0].baskiSayısı
        : null
    );
    formVars.bandrolyazisi_773502_value = ReactSystemFunctions.toString(
      this,
      stateVars.BandrolYazisi?.length > 0
        ? stateVars.BandrolYazisi[0].adet
        : this.state.BandrolYazisi?.length > 0
        ? this.state.BandrolYazisi[0].adet
        : null
    );
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.BandrolYazisiPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  BandrolYazisiPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.bandrolyazisi_966115_value = ReactSystemFunctions.toString(
      this,
      stateVars.BandrolYazisi?.length > 0
        ? stateVars.BandrolYazisi[0].metin
        : this.state.BandrolYazisi?.length > 0
        ? this.state.BandrolYazisi[0].metin
        : null
    );

    formVars.bandrolyazisi_284713_value = ReactSystemFunctions.toString(
      this,
      stateVars.BandrolYazisi?.length > 0
        ? stateVars.BandrolYazisi[0].tarih
        : this.state.BandrolYazisi?.length > 0
        ? this.state.BandrolYazisi[0].tarih
        : null
    );

    formVars.bandrolyazisi_799891_value = ReactSystemFunctions.toString(
      this,
      stateVars.BandrolYazisi?.length > 0
        ? stateVars.BandrolYazisi[0].matbaaAd
        : this.state.BandrolYazisi?.length > 0
        ? this.state.BandrolYazisi[0].matbaaAd
        : null
    );

    formVars.bandrolyazisi_334566_value = ReactSystemFunctions.toString(
      this,
      stateVars.BandrolYazisi?.length > 0
        ? stateVars.BandrolYazisi[0].bandrolAdet
        : this.state.BandrolYazisi?.length > 0
        ? this.state.BandrolYazisi[0].bandrolAdet
        : null
    );

    formVars.bandrolyazisi_632793_value = ReactSystemFunctions.toString(
      this,
      stateVars.BandrolYazisi?.length > 0
        ? stateVars.BandrolYazisi[0].sNo
        : this.state.BandrolYazisi?.length > 0
        ? this.state.BandrolYazisi[0].sNo
        : null
    );

    formVars.bandrolyazisi_757180_value = ReactSystemFunctions.toString(
      this,
      stateVars.BandrolYazisi?.length > 0
        ? stateVars.BandrolYazisi[0].eseraAd
        : this.state.BandrolYazisi?.length > 0
        ? this.state.BandrolYazisi[0].eseraAd
        : null
    );

    formVars.bandrolyazisi_854319_value = ReactSystemFunctions.toString(
      this,
      stateVars.BandrolYazisi?.length > 0
        ? stateVars.BandrolYazisi[0].baskiSayısı
        : this.state.BandrolYazisi?.length > 0
        ? this.state.BandrolYazisi[0].baskiSayısı
        : null
    );

    formVars.bandrolyazisi_773502_value = ReactSystemFunctions.toString(
      this,
      stateVars.BandrolYazisi?.length > 0
        ? stateVars.BandrolYazisi[0].adet
        : this.state.BandrolYazisi?.length > 0
        ? this.state.BandrolYazisi[0].adet
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  BandrolYazisiComponent_165427_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ComponentToPdf2 = await ReactSystemFunctions.componentToPDFSave(
      "928235",
      "BandrolYazısı.pdf",
      "NORMAL",
      ""
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
