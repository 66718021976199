import { FormInstance } from "antd/es/form";
import { Guid } from "guid-typescript";
import React from "react";
import Swal from "sweetalert2";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IUretimDetayKart_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IUretimDetayKart_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  MaxICKAPAKBelgeOnayTarihi: any[];
  MaxICKAPAKBelgeOnayTarihi_dummy: any[];
  UretimKartEnabled: any[];
  UretimKartEnabled_dummy: any[];
  UretimKartById: any[];
  UretimKartById_dummy: any[];
  EserBilgileri_TeklifTalepEdenEmail: any[];
  EserBilgileri_TeklifTalepEdenEmail_dummy: any[];
  TeklifAlanSirketAd: any[];
  TeklifAlanSirketAd_dummy: any[];
  TeklifEdenSirketAd: any[];
  TeklifEdenSirketAd_dummy: any[];
  MyContext: any[];
  MyContext_dummy: any[];
  GenelParametreler: any[];
  GenelParametreler_dummy: any[];
  BandrolYapistirmaListById: any[];
  BandrolYapistirmaListById_dummy: any[];
  BaskiAdetInfo: any[];
  BaskiAdetInfo_dummy: any[];
  UretimTamamlandiEnabledKontrol: any[];
  UretimTamamlandiEnabledKontrol_dummy: any[];
  UretimKartSave: number;
  UretimKartSave_dummy: number;
  UretimTarihKontrol: number;
  UretimTarihKontrol_dummy: number;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  BandrolStokDeleteByBandrolYapistirmaID: number;
  BandrolStokDeleteByBandrolYapistirmaID_dummy: number;
  BandrolYapistirmaDelete: number;
  BandrolYapistirmaDelete_dummy: number;
  ChangeEnabledOf: any;
  ChangeEnabledOf_dummy: any;
  GoBack: any;
  GoBack_dummy: any;
  EserDetay_BandrolStokSUM_UretimKontrol: number;
  EserDetay_BandrolStokSUM_UretimKontrol_dummy: number;
  getEserBilgileriIdByTeklifTalepKartID: any[];
  getEserBilgileriIdByTeklifTalepKartID_dummy: any[];
  EserDetay_BandrolStokSave: number;
  EserDetay_BandrolStokSave_dummy: number;
  SendMailSettings: any[];
  SendMailSettings_dummy: any[];
  SendMail: boolean;
  SendMail_dummy: boolean;
  isComp470056Visible: "visible" | "hidden";
  isComp198737Visible: "visible" | "hidden";
  isComp858704Visible: "visible" | "hidden";
  isComp653002Visible: "visible" | "hidden";
  isComp627448Visible: "visible" | "hidden";
  isComp298937Visible: "visible" | "hidden";
  isComp553286Visible: "visible" | "hidden";
  isComp41555Visible: "visible" | "hidden";
  isComp638119Visible: "visible" | "hidden";
  isComp349002Enabled: "enabled" | "disabled";
  isComp275305Enabled: "enabled" | "disabled";
  isComp946837Enabled: "enabled" | "disabled";
  isComp763256Enabled: "enabled" | "disabled";
  isComp919345Enabled: "enabled" | "disabled";
  isComp656727Enabled: "enabled" | "disabled";
  isComp57599Enabled: "enabled" | "disabled";
}

export class UretimDetayKart_ScreenBase extends React.PureComponent<IUretimDetayKart_ScreenProps, any> {
  uretimdetaykart_275305_value_kuikaDateRef: React.RefObject<any>;
  uretimdetaykart_946837_value_kuikaDateRef: React.RefObject<any>;
  uretimdetaykart_763256_value_kuikaDateRef: React.RefObject<any>;
  uretimdetaykart_919345_value_kuikaDateRef: React.RefObject<any>;
  uretimdetaykart_656727_value_kuikaTableRef: React.RefObject<any>;
  uretimdetaykart_932622_value_kuikaDateRef: React.RefObject<any>;
  uretimdetaykart_135515_value_kuikaDateRef: React.RefObject<any>;
  ml = [
    {
      Id: "41cd36a4-5b07-cfca-4f7f-f48019ff4ff3",
      Name: "tr_TR",
      ShortName: "Türkçe",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "0069cee9-16ca-4a5a-aab9-dd58af96ca96", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "ba8489f9-c050-47e9-980c-58b37da2390e",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 268500, PropertyName: "value", Value: "Üretim Bilgileri" },
        { Id: 27617, PropertyName: "value", Value: "İç Görsel Baskı Tarihi" },
        { Id: 275305, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 411767, PropertyName: "value", Value: "Kapak Görsel Basım Tarihi" },
        { Id: 946837, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 55577, PropertyName: "value", Value: "Kapak Takma/Dikiş Tarihi" },
        { Id: 763256, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 40300, PropertyName: "value", Value: "Son Bıçak Tarihi" },
        { Id: 919345, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 596501, PropertyName: "label", Value: "Bandrol Yapıştırma ve Depo Teslim" },
        { Id: 596851, PropertyName: "value", Value: "Baskı Adet" },
        { Id: 853552, PropertyName: "value", Value: "Bandrol Yapıştırılan Adet" },
        { Id: 656727, PropertyName: "nodatafoundmessage", Value: "Veri Bulunamadı!" },
        { Id: 229002, PropertyName: "title", Value: "Yapıştırma Tarihi" },
        { Id: 173350, PropertyName: "value", Value: "[datafield:fbandroltarihi]" },
        { Id: 894282, PropertyName: "title", Value: "Yapıştırma Ad." },
        { Id: 140002, PropertyName: "value", Value: "[datafield:bandroladetyapistirma]" },
        { Id: 845362, PropertyName: "title", Value: "Fire Ad." },
        { Id: 992163, PropertyName: "value", Value: "[datafield:bandroladetfire]" },
        { Id: 915776, PropertyName: "title", Value: "Depo T.Tarihi" },
        { Id: 620576, PropertyName: "value", Value: "[datafield:fdepoteslimtarihi]" },
        { Id: 79918, PropertyName: "value", Value: "Bandrol Yapıştırıldı" },
        { Id: 312554, PropertyName: "value", Value: "Yapıştırılma Tarihi" },
        { Id: 932622, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 116551, PropertyName: "value", Value: "Bandrol Yapıştırma Adet" },
        { Id: 792868, PropertyName: "value", Value: "Bandrol Fire Adet" },
        { Id: 20536, PropertyName: "value", Value: "Depo Teslim Edildi" },
        { Id: 528109, PropertyName: "value", Value: "Teslim Tarihi" },
        { Id: 135515, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 412996, PropertyName: "value", Value: "Üretim Tamamlandı" },
        { Id: 890290, PropertyName: "label", Value: "Vazgeç" },
        { Id: 378775, PropertyName: "label", Value: "Kaydet" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.uretimdetaykart_275305_value_kuikaDateRef = React.createRef();
    this.uretimdetaykart_946837_value_kuikaDateRef = React.createRef();
    this.uretimdetaykart_763256_value_kuikaDateRef = React.createRef();
    this.uretimdetaykart_919345_value_kuikaDateRef = React.createRef();
    this.uretimdetaykart_656727_value_kuikaTableRef = React.createRef();
    this.uretimdetaykart_932622_value_kuikaDateRef = React.createRef();
    this.uretimdetaykart_135515_value_kuikaDateRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      MaxICKAPAKBelgeOnayTarihi: [],
      UretimKartEnabled: [],
      UretimKartById: [],
      EserBilgileri_TeklifTalepEdenEmail: [],
      TeklifAlanSirketAd: [],
      TeklifEdenSirketAd: [],
      MyContext: [],
      GenelParametreler: [],
      BandrolYapistirmaListById: [],
      BaskiAdetInfo: [],
      UretimTamamlandiEnabledKontrol: [],
      UretimKartSave: 0,
      UretimTarihKontrol: 0,
      NAVIGATE: "",
      BandrolStokDeleteByBandrolYapistirmaID: 0,
      BandrolYapistirmaDelete: 0,
      ChangeEnabledOf: "",
      GoBack: "",
      EserDetay_BandrolStokSUM_UretimKontrol: 0,
      getEserBilgileriIdByTeklifTalepKartID: [],
      EserDetay_BandrolStokSave: 0,
      SendMailSettings: [],
      SendMail: false,
      isComp470056Visible: "hidden",
      isComp198737Visible: "hidden",
      isComp858704Visible: "hidden",
      isComp653002Visible: "hidden",
      isComp627448Visible: "hidden",
      isComp298937Visible: "hidden",
      isComp553286Visible: "hidden",
      isComp41555Visible: "hidden",
      isComp638119Visible: "hidden",
      isComp349002Enabled: "disabled",
      isComp275305Enabled: "disabled",
      isComp946837Enabled: "disabled",
      isComp763256Enabled: "disabled",
      isComp919345Enabled: "disabled",
      isComp656727Enabled: "disabled",
      isComp57599Enabled: "disabled"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("uretimdetaykart", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.UretimDetayKartPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("uretimdetaykart", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("uretimdetaykart", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.UretimDetayKartPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      uretimdetaykart_275305_value: this.state.UretimKartById?.at?.(0)?.icGorselBaskiTarihi ?? undefined,
      uretimdetaykart_946837_value: this.state.UretimKartById?.at?.(0)?.kapakGorselBaskiTarihi ?? undefined,
      uretimdetaykart_763256_value: this.state.UretimKartById?.at?.(0)?.ciltlemeTarihi ?? undefined,
      uretimdetaykart_919345_value: this.state.UretimKartById?.at?.(0)?.sonBicakTarihi ?? undefined,
      uretimdetaykart_349002_value: this.state.UretimKartById?.at?.(0)?.bandrolIslemiYapildi ?? undefined,
      uretimdetaykart_932622_value: this.state.UretimKartById?.at?.(0)?.bandrolTarihi ?? undefined,
      uretimdetaykart_204674_value: this.state.UretimKartById?.at?.(0)?.bandrolAdetYapistirma ?? undefined,
      uretimdetaykart_712085_value: this.state.UretimKartById?.at?.(0)?.bandrolAdetFire ?? undefined,
      uretimdetaykart_785885_value: this.state.UretimKartById?.at?.(0)?.depoTeslimiYapildi ?? undefined,
      uretimdetaykart_135515_value: this.state.UretimKartById?.at?.(0)?.depoTeslimTarihi ?? undefined,
      uretimdetaykart_57599_value: this.state.UretimKartById?.at?.(0)?.uretimTamanlandi ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  UretimDetayKartPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      ),
      TeklifTalepId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      ),
      NewEditDisplay_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.NewEditDisplay ?? this.props.screenInputs.neweditdisplay,
        "string"
      ),
      TeklifTalepKartID_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      ),
      prmtekliftalepkartId_3: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      ),
      prmTeklifTalepKartID_4: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      ),
      prmTeklifTalepKartID_5: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      ),
      UserName_6: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      TeklifTalepKartID_8: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      ),
      TeklifTalepKartID_9: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      ),
      TeklifTalepKartId_10: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "UretimDetayKart/UretimDetayKartPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MaxICKAPAKBelgeOnayTarihi = result?.data.maxICKAPAKBelgeOnayTarihi;
    formVars.uretimdetaykart_275305_value = ReactSystemFunctions.value(
      this,
      stateVars.UretimKartById?.length > 0
        ? stateVars.UretimKartById[0].icGorselBaskiTarihi
        : this.state.UretimKartById?.length > 0
        ? this.state.UretimKartById[0].icGorselBaskiTarihi
        : null
    );
    formVars.uretimdetaykart_275305_disablebeforedate = ReactSystemFunctions.toDate(
      this,
      stateVars.MaxICKAPAKBelgeOnayTarihi?.length > 0
        ? stateVars.MaxICKAPAKBelgeOnayTarihi[0].maxICKAPAKBelgeOnayTarihi
        : this.state.MaxICKAPAKBelgeOnayTarihi?.length > 0
        ? this.state.MaxICKAPAKBelgeOnayTarihi[0].maxICKAPAKBelgeOnayTarihi
        : null
    );
    formVars.uretimdetaykart_946837_value = ReactSystemFunctions.value(
      this,
      stateVars.UretimKartById?.length > 0
        ? stateVars.UretimKartById[0].kapakGorselBaskiTarihi
        : this.state.UretimKartById?.length > 0
        ? this.state.UretimKartById[0].kapakGorselBaskiTarihi
        : null
    );
    formVars.uretimdetaykart_946837_disablebeforedate = ReactSystemFunctions.toDate(
      this,
      stateVars.MaxICKAPAKBelgeOnayTarihi?.length > 0
        ? stateVars.MaxICKAPAKBelgeOnayTarihi[0].maxICKAPAKBelgeOnayTarihi
        : this.state.MaxICKAPAKBelgeOnayTarihi?.length > 0
        ? this.state.MaxICKAPAKBelgeOnayTarihi[0].maxICKAPAKBelgeOnayTarihi
        : null
    );
    stateVars.UretimKartEnabled = result?.data.uretimKartEnabled;
    stateVars.isComp275305Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UretimKartEnabled?.length > 0
            ? stateVars.UretimKartEnabled[0].isEnabledIcTarih
            : this.state.UretimKartEnabled?.length > 0
            ? this.state.UretimKartEnabled[0].isEnabledIcTarih
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp946837Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UretimKartEnabled?.length > 0
            ? stateVars.UretimKartEnabled[0].isEnabledKapakTarih
            : this.state.UretimKartEnabled?.length > 0
            ? this.state.UretimKartEnabled[0].isEnabledKapakTarih
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.UretimKartById = result?.data.uretimKartById;
    formVars.uretimdetaykart_275305_value = ReactSystemFunctions.value(
      this,
      stateVars.UretimKartById?.length > 0
        ? stateVars.UretimKartById[0].icGorselBaskiTarihi
        : this.state.UretimKartById?.length > 0
        ? this.state.UretimKartById[0].icGorselBaskiTarihi
        : null
    );
    formVars.uretimdetaykart_275305_disablebeforedate = ReactSystemFunctions.toDate(
      this,
      stateVars.MaxICKAPAKBelgeOnayTarihi?.length > 0
        ? stateVars.MaxICKAPAKBelgeOnayTarihi[0].maxICKAPAKBelgeOnayTarihi
        : this.state.MaxICKAPAKBelgeOnayTarihi?.length > 0
        ? this.state.MaxICKAPAKBelgeOnayTarihi[0].maxICKAPAKBelgeOnayTarihi
        : null
    );
    formVars.uretimdetaykart_946837_value = ReactSystemFunctions.value(
      this,
      stateVars.UretimKartById?.length > 0
        ? stateVars.UretimKartById[0].kapakGorselBaskiTarihi
        : this.state.UretimKartById?.length > 0
        ? this.state.UretimKartById[0].kapakGorselBaskiTarihi
        : null
    );
    formVars.uretimdetaykart_946837_disablebeforedate = ReactSystemFunctions.toDate(
      this,
      stateVars.MaxICKAPAKBelgeOnayTarihi?.length > 0
        ? stateVars.MaxICKAPAKBelgeOnayTarihi[0].maxICKAPAKBelgeOnayTarihi
        : this.state.MaxICKAPAKBelgeOnayTarihi?.length > 0
        ? this.state.MaxICKAPAKBelgeOnayTarihi[0].maxICKAPAKBelgeOnayTarihi
        : null
    );
    formVars.uretimdetaykart_763256_value = ReactSystemFunctions.value(
      this,
      stateVars.UretimKartById?.length > 0
        ? stateVars.UretimKartById[0].ciltlemeTarihi
        : this.state.UretimKartById?.length > 0
        ? this.state.UretimKartById[0].ciltlemeTarihi
        : null
    );
    formVars.uretimdetaykart_763256_disablebeforedate = ReactSystemFunctions.value(
      this,
      ReactSystemFunctions.value(this, "uretimdetaykart_946837_value", "value", "", "", "")
    );
    formVars.uretimdetaykart_919345_value = ReactSystemFunctions.value(
      this,
      stateVars.UretimKartById?.length > 0
        ? stateVars.UretimKartById[0].sonBicakTarihi
        : this.state.UretimKartById?.length > 0
        ? this.state.UretimKartById[0].sonBicakTarihi
        : null
    );
    formVars.uretimdetaykart_919345_disablebeforedate = ReactSystemFunctions.value(
      this,
      ReactSystemFunctions.value(this, "uretimdetaykart_763256_value", "value", "", "", "")
    );
    formVars.uretimdetaykart_349002_value = ReactSystemFunctions.value(
      this,
      stateVars.UretimKartById?.length > 0
        ? stateVars.UretimKartById[0].bandrolIslemiYapildi
        : this.state.UretimKartById?.length > 0
        ? this.state.UretimKartById[0].bandrolIslemiYapildi
        : null
    );
    formVars.uretimdetaykart_932622_value = ReactSystemFunctions.value(
      this,
      stateVars.UretimKartById?.length > 0
        ? stateVars.UretimKartById[0].bandrolTarihi
        : this.state.UretimKartById?.length > 0
        ? this.state.UretimKartById[0].bandrolTarihi
        : null
    );
    formVars.uretimdetaykart_932622_disablebeforedate = ReactSystemFunctions.value(
      this,
      ReactSystemFunctions.value(this, "uretimdetaykart_919345_value", "value", "", "", "")
    );
    formVars.uretimdetaykart_204674_value = ReactSystemFunctions.value(
      this,
      stateVars.UretimKartById?.length > 0
        ? stateVars.UretimKartById[0].bandrolAdetYapistirma
        : this.state.UretimKartById?.length > 0
        ? this.state.UretimKartById[0].bandrolAdetYapistirma
        : null
    );
    formVars.uretimdetaykart_712085_value = ReactSystemFunctions.value(
      this,
      stateVars.UretimKartById?.length > 0
        ? stateVars.UretimKartById[0].bandrolAdetFire
        : this.state.UretimKartById?.length > 0
        ? this.state.UretimKartById[0].bandrolAdetFire
        : null
    );
    formVars.uretimdetaykart_785885_value = ReactSystemFunctions.value(
      this,
      stateVars.UretimKartById?.length > 0
        ? stateVars.UretimKartById[0].depoTeslimiYapildi
        : this.state.UretimKartById?.length > 0
        ? this.state.UretimKartById[0].depoTeslimiYapildi
        : null
    );
    formVars.uretimdetaykart_135515_value = ReactSystemFunctions.value(
      this,
      stateVars.UretimKartById?.length > 0
        ? stateVars.UretimKartById[0].depoTeslimTarihi
        : this.state.UretimKartById?.length > 0
        ? this.state.UretimKartById[0].depoTeslimTarihi
        : null
    );
    formVars.uretimdetaykart_135515_disablebeforedate = ReactSystemFunctions.value(
      this,
      ReactSystemFunctions.value(this, "uretimdetaykart_932622_value", "value", "", "", "")
    );
    formVars.uretimdetaykart_57599_value = ReactSystemFunctions.value(
      this,
      stateVars.UretimKartById?.length > 0
        ? stateVars.UretimKartById[0].uretimTamanlandi
        : this.state.UretimKartById?.length > 0
        ? this.state.UretimKartById[0].uretimTamanlandi
        : null
    );
    stateVars.EserBilgileri_TeklifTalepEdenEmail = result?.data.eserBilgileri_TeklifTalepEdenEmail;
    stateVars.TeklifAlanSirketAd = result?.data.teklifAlanSirketAd;
    stateVars.TeklifEdenSirketAd = result?.data.teklifEdenSirketAd;
    stateVars.MyContext = result?.data.myContext;
    stateVars.GenelParametreler = result?.data.genelParametreler;
    stateVars.BandrolYapistirmaListById = result?.data.bandrolYapistirmaListById;

    stateVars.BaskiAdetInfo = result?.data.baskiAdetInfo;
    formVars.uretimdetaykart_854256_value = ReactSystemFunctions.toString(
      this,
      stateVars.BaskiAdetInfo?.length > 0
        ? stateVars.BaskiAdetInfo[0].baskiAdet
        : this.state.BaskiAdetInfo?.length > 0
        ? this.state.BaskiAdetInfo[0].baskiAdet
        : null
    );
    formVars.uretimdetaykart_832914_value = ReactSystemFunctions.toString(
      this,
      stateVars.BaskiAdetInfo?.length > 0
        ? stateVars.BaskiAdetInfo[0].yapistirmaAdet
        : this.state.BaskiAdetInfo?.length > 0
        ? this.state.BaskiAdetInfo[0].yapistirmaAdet
        : null
    );
    stateVars.UretimTamamlandiEnabledKontrol = result?.data.uretimTamamlandiEnabledKontrol;
    stateVars.isComp57599Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UretimTamamlandiEnabledKontrol?.length > 0
            ? stateVars.UretimTamamlandiEnabledKontrol[0].isEnabled
            : this.state.UretimTamamlandiEnabledKontrol?.length > 0
            ? this.state.UretimTamamlandiEnabledKontrol[0].isEnabled
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.UretimDetayKartPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  UretimDetayKartPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.uretimdetaykart_275305_value = ReactSystemFunctions.value(
      this,
      stateVars.UretimKartById?.length > 0
        ? stateVars.UretimKartById[0].icGorselBaskiTarihi
        : this.state.UretimKartById?.length > 0
        ? this.state.UretimKartById[0].icGorselBaskiTarihi
        : null
    );

    formVars.uretimdetaykart_275305_disableBeforeDate = ReactSystemFunctions.toDate(
      this,
      stateVars.MaxICKAPAKBelgeOnayTarihi?.length > 0
        ? stateVars.MaxICKAPAKBelgeOnayTarihi[0].maxICKAPAKBelgeOnayTarihi
        : this.state.MaxICKAPAKBelgeOnayTarihi?.length > 0
        ? this.state.MaxICKAPAKBelgeOnayTarihi[0].maxICKAPAKBelgeOnayTarihi
        : null
    );

    stateVars.isComp275305Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UretimKartEnabled?.length > 0
            ? stateVars.UretimKartEnabled[0].isEnabledIcTarih
            : this.state.UretimKartEnabled?.length > 0
            ? this.state.UretimKartEnabled[0].isEnabledIcTarih
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    formVars.uretimdetaykart_946837_value = ReactSystemFunctions.value(
      this,
      stateVars.UretimKartById?.length > 0
        ? stateVars.UretimKartById[0].kapakGorselBaskiTarihi
        : this.state.UretimKartById?.length > 0
        ? this.state.UretimKartById[0].kapakGorselBaskiTarihi
        : null
    );

    formVars.uretimdetaykart_946837_disableBeforeDate = ReactSystemFunctions.toDate(
      this,
      stateVars.MaxICKAPAKBelgeOnayTarihi?.length > 0
        ? stateVars.MaxICKAPAKBelgeOnayTarihi[0].maxICKAPAKBelgeOnayTarihi
        : this.state.MaxICKAPAKBelgeOnayTarihi?.length > 0
        ? this.state.MaxICKAPAKBelgeOnayTarihi[0].maxICKAPAKBelgeOnayTarihi
        : null
    );

    stateVars.isComp946837Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UretimKartEnabled?.length > 0
            ? stateVars.UretimKartEnabled[0].isEnabledKapakTarih
            : this.state.UretimKartEnabled?.length > 0
            ? this.state.UretimKartEnabled[0].isEnabledKapakTarih
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    formVars.uretimdetaykart_763256_value = ReactSystemFunctions.value(
      this,
      stateVars.UretimKartById?.length > 0
        ? stateVars.UretimKartById[0].ciltlemeTarihi
        : this.state.UretimKartById?.length > 0
        ? this.state.UretimKartById[0].ciltlemeTarihi
        : null
    );

    stateVars.isComp763256Enabled =
      ReactSystemFunctions.isNotEqualTo(
        this.props.screenInputs.NewEditDisplay ?? this.props.screenInputs.neweditdisplay,
        "D"
      ) === true
        ? "enabled"
        : "disabled";
    ReactSystemFunctions.setValueOf(
      this,
      "uretimdetaykart_763256_value",
      ReactSystemFunctions.value(this, "uretimdetaykart_946837_value", "value"),
      "disablebeforedate"
    );

    formVars.uretimdetaykart_919345_value = ReactSystemFunctions.value(
      this,
      stateVars.UretimKartById?.length > 0
        ? stateVars.UretimKartById[0].sonBicakTarihi
        : this.state.UretimKartById?.length > 0
        ? this.state.UretimKartById[0].sonBicakTarihi
        : null
    );

    stateVars.isComp919345Enabled =
      ReactSystemFunctions.isNotEqualTo(
        this.props.screenInputs.NewEditDisplay ?? this.props.screenInputs.neweditdisplay,
        "D"
      ) === true
        ? "enabled"
        : "disabled";
    ReactSystemFunctions.setValueOf(
      this,
      "uretimdetaykart_919345_value",
      ReactSystemFunctions.value(this, "uretimdetaykart_763256_value", "value"),
      "disablebeforedate"
    );

    stateVars.isComp470056Visible =
      ReactSystemFunctions.isNotEqualTo(
        this.props.screenInputs.NewEditDisplay ?? this.props.screenInputs.neweditdisplay,
        "D"
      ) === true
        ? "visible"
        : "hidden";
    formVars.uretimdetaykart_854256_value = ReactSystemFunctions.toString(
      this,
      stateVars.BaskiAdetInfo?.length > 0
        ? stateVars.BaskiAdetInfo[0].baskiAdet
        : this.state.BaskiAdetInfo?.length > 0
        ? this.state.BaskiAdetInfo[0].baskiAdet
        : null
    );

    formVars.uretimdetaykart_832914_value = ReactSystemFunctions.toString(
      this,
      stateVars.BaskiAdetInfo?.length > 0
        ? stateVars.BaskiAdetInfo[0].yapistirmaAdet
        : this.state.BaskiAdetInfo?.length > 0
        ? this.state.BaskiAdetInfo[0].yapistirmaAdet
        : null
    );

    stateVars.dataSource_656727 = this.state.BandrolYapistirmaListById;
    stateVars.isComp656727Enabled =
      ReactSystemFunctions.isNotEqualTo(
        this.props.screenInputs.NewEditDisplay ?? this.props.screenInputs.neweditdisplay,
        "D"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp198737Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "uretimdetaykart_656727_value", "isDeleteEditIconsVisibility"),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp858704Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "uretimdetaykart_656727_value", "isDeleteEditIconsVisibility"),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.uretimdetaykart_349002_value = ReactSystemFunctions.value(
      this,
      stateVars.UretimKartById?.length > 0
        ? stateVars.UretimKartById[0].bandrolIslemiYapildi
        : this.state.UretimKartById?.length > 0
        ? this.state.UretimKartById[0].bandrolIslemiYapildi
        : null
    );

    stateVars.isComp349002Enabled =
      ReactSystemFunctions.isNotEqualTo(
        this.props.screenInputs.NewEditDisplay ?? this.props.screenInputs.neweditdisplay,
        "D"
      ) === true
        ? "enabled"
        : "disabled";
    formVars.uretimdetaykart_932622_value = ReactSystemFunctions.value(
      this,
      stateVars.UretimKartById?.length > 0
        ? stateVars.UretimKartById[0].bandrolTarihi
        : this.state.UretimKartById?.length > 0
        ? this.state.UretimKartById[0].bandrolTarihi
        : null
    );

    ReactSystemFunctions.setValueOf(
      this,
      "uretimdetaykart_932622_value",
      ReactSystemFunctions.value(this, "uretimdetaykart_919345_value", "value"),
      "disablebeforedate"
    );

    formVars.uretimdetaykart_204674_value = ReactSystemFunctions.value(
      this,
      stateVars.UretimKartById?.length > 0
        ? stateVars.UretimKartById[0].bandrolAdetYapistirma
        : this.state.UretimKartById?.length > 0
        ? this.state.UretimKartById[0].bandrolAdetYapistirma
        : null
    );

    formVars.uretimdetaykart_712085_value = ReactSystemFunctions.value(
      this,
      stateVars.UretimKartById?.length > 0
        ? stateVars.UretimKartById[0].bandrolAdetFire
        : this.state.UretimKartById?.length > 0
        ? this.state.UretimKartById[0].bandrolAdetFire
        : null
    );

    formVars.uretimdetaykart_785885_value = ReactSystemFunctions.value(
      this,
      stateVars.UretimKartById?.length > 0
        ? stateVars.UretimKartById[0].depoTeslimiYapildi
        : this.state.UretimKartById?.length > 0
        ? this.state.UretimKartById[0].depoTeslimiYapildi
        : null
    );

    formVars.uretimdetaykart_135515_value = ReactSystemFunctions.value(
      this,
      stateVars.UretimKartById?.length > 0
        ? stateVars.UretimKartById[0].depoTeslimTarihi
        : this.state.UretimKartById?.length > 0
        ? this.state.UretimKartById[0].depoTeslimTarihi
        : null
    );

    ReactSystemFunctions.setValueOf(
      this,
      "uretimdetaykart_135515_value",
      ReactSystemFunctions.value(this, "uretimdetaykart_932622_value", "value"),
      "disablebeforedate"
    );

    formVars.uretimdetaykart_57599_value = ReactSystemFunctions.value(
      this,
      stateVars.UretimKartById?.length > 0
        ? stateVars.UretimKartById[0].uretimTamanlandi
        : this.state.UretimKartById?.length > 0
        ? this.state.UretimKartById[0].uretimTamanlandi
        : null
    );

    stateVars.isComp57599Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UretimTamamlandiEnabledKontrol?.length > 0
            ? stateVars.UretimTamamlandiEnabledKontrol[0].isEnabled
            : this.state.UretimTamamlandiEnabledKontrol?.length > 0
            ? this.state.UretimTamamlandiEnabledKontrol[0].isEnabled
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp638119Visible =
      ReactSystemFunctions.isNotEqualTo(
        this.props.screenInputs.NewEditDisplay ?? this.props.screenInputs.neweditdisplay,
        "D"
      ) === true
        ? "visible"
        : "hidden";

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  UretimDetayKartComponent_596501_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      CiltlemeTarihi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uretimdetaykart_763256_value", "value", "", "", "")
        ),
        "Date"
      ),
      IcGorselBaskiTarihi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uretimdetaykart_275305_value", "value", "", "", "")
        ),
        "Date"
      ),
      KapakGorselBaskiTarihi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uretimdetaykart_946837_value", "value", "", "", "")
        ),
        "Date"
      ),
      SonBicakTarihi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uretimdetaykart_919345_value", "value", "", "", "")
        ),
        "Date"
      ),
      UretimTamanlandi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uretimdetaykart_57599_value", "value", "", "", "")
        ),
        "boolean"
      ),
      TeklifTalepKartID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(Guid.create().toString(), "Guid"),
      IcGorselBaskiTarihi_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "uretimdetaykart_275305_value", "value", "", "", "")
        ),
        "string"
      ),
      KapakGorselBaskiTarihi_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "uretimdetaykart_946837_value", "value", "", "", "")
        ),
        "string"
      ),
      KapakTakmaTarihi_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "uretimdetaykart_763256_value", "value", "", "", "")
        ),
        "string"
      ),
      SonBicakTarihi_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "uretimdetaykart_919345_value", "value", "", "", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "UretimDetayKart/UretimDetayKartComponent_596501_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.UretimKartSave = result?.data.uretimKartSave;
    stateVars.UretimTarihKontrol = result?.data.uretimTarihKontrol;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.UretimDetayKartComponent_596501_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  UretimDetayKartComponent_596501_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "PopupBandrolYapistirma",
      "prmTeklifTalepKartID",
      this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid
    );
    KuikaAppManager.addToPageInputVariables(
      "PopupBandrolYapistirma",
      "dteBicak",
      ReactSystemFunctions.value(
        this,
        ReactSystemFunctions.value(this, "uretimdetaykart_919345_value", "value", "", "", "")
      )
    );
    KuikaAppManager.addToPageInputVariables("PopupBandrolYapistirma", "prmId", Guid.create().toString());
    KuikaAppManager.addToPageInputVariables("PopupBandrolYapistirma", "NewEditDisplay", "N");

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      this.UretimDetayKartComponent_596501_onClick2_,
      "UretimDetayKart",
      "PopupBandrolYapistirma",
      "",
      "9924ef5f-4b84-4200-b624-8d76348c7686",
      "315083",
      null,
      null,
      "true",
      "480px",
      "60vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };
  UretimDetayKartComponent_596501_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      TeklifTalepKartID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "UretimDetayKart/UretimDetayKartComponent_596501_onClick2_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.BandrolYapistirmaListById = result?.data.bandrolYapistirmaListById;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.UretimDetayKartComponent_596501_onClick3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  UretimDetayKartComponent_596501_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_656727 = this.state.BandrolYapistirmaListById;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  UretimDetayKartComponent_198737_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      BandrolYapistirmaID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "uretimdetaykart_656727_value", "id"),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "UretimDetayKart/UretimDetayKartComponent_198737_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.BandrolStokDeleteByBandrolYapistirmaID = result?.data.bandrolStokDeleteByBandrolYapistirmaID;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.UretimDetayKartComponent_198737_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  UretimDetayKartComponent_198737_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "uretimdetaykart_656727_value", "id"),
        "Guid"
      ),
      TeklifTalepKartID_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      ),
      TeklifTalepKartID_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      ),
      TeklifTalepKartId_3: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      )
    };

    stateVars.Confirm = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "92e03324_6ab4_5207_ea3d_53aca6d4a1f4_confirmation",
        this.defaultML,
        "Seçilen Kayıt Silinecek; İşleme Devam Edilsin mi?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel",
      customClass: {
        title: "swal2-font-poppins"
      }
    });

    if (stateVars.Confirm.isConfirmed) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "UretimDetayKart/UretimDetayKartComponent_198737_onClick1_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.BandrolYapistirmaDelete = result?.data.bandrolYapistirmaDelete;
      stateVars.BandrolYapistirmaListById = result?.data.bandrolYapistirmaListById;

      stateVars.BaskiAdetInfo = result?.data.baskiAdetInfo;
      formVars.uretimdetaykart_854256_value = ReactSystemFunctions.toString(
        this,
        stateVars.BaskiAdetInfo?.length > 0
          ? stateVars.BaskiAdetInfo[0].baskiAdet
          : this.state.BaskiAdetInfo?.length > 0
          ? this.state.BaskiAdetInfo[0].baskiAdet
          : null
      );
      formVars.uretimdetaykart_832914_value = ReactSystemFunctions.toString(
        this,
        stateVars.BaskiAdetInfo?.length > 0
          ? stateVars.BaskiAdetInfo[0].yapistirmaAdet
          : this.state.BaskiAdetInfo?.length > 0
          ? this.state.BaskiAdetInfo[0].yapistirmaAdet
          : null
      );
      stateVars.UretimTamamlandiEnabledKontrol = result?.data.uretimTamamlandiEnabledKontrol;
      stateVars.isComp57599Enabled =
        ReactSystemFunctions.isEqualTo(
          ReactSystemFunctions.value(
            this,
            stateVars.UretimTamamlandiEnabledKontrol?.length > 0
              ? stateVars.UretimTamamlandiEnabledKontrol[0].isEnabled
              : this.state.UretimTamamlandiEnabledKontrol?.length > 0
              ? this.state.UretimTamamlandiEnabledKontrol[0].isEnabled
              : null
          ),
          "1"
        ) === true
          ? "enabled"
          : "disabled";
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.UretimDetayKartComponent_198737_onClick2_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  UretimDetayKartComponent_198737_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.uretimdetaykart_854256_value = ReactSystemFunctions.toString(
      this,
      stateVars.BaskiAdetInfo?.length > 0
        ? stateVars.BaskiAdetInfo[0].baskiAdet
        : this.state.BaskiAdetInfo?.length > 0
        ? this.state.BaskiAdetInfo[0].baskiAdet
        : null
    );

    formVars.uretimdetaykart_832914_value = ReactSystemFunctions.toString(
      this,
      stateVars.BaskiAdetInfo?.length > 0
        ? stateVars.BaskiAdetInfo[0].yapistirmaAdet
        : this.state.BaskiAdetInfo?.length > 0
        ? this.state.BaskiAdetInfo[0].yapistirmaAdet
        : null
    );

    stateVars.dataSource_656727 = this.state.BandrolYapistirmaListById;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  UretimDetayKartComponent_858704_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "PopupBandrolYapistirma",
      "prmTeklifTalepKartID",
      this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid
    );
    KuikaAppManager.addToPageInputVariables(
      "PopupBandrolYapistirma",
      "dteBicak",
      ReactSystemFunctions.value(
        this,
        ReactSystemFunctions.value(this, "uretimdetaykart_919345_value", "value", "", "", "")
      )
    );
    KuikaAppManager.addToPageInputVariables(
      "PopupBandrolYapistirma",
      "prmId",
      ReactSystemFunctions.value(this, "uretimdetaykart_656727_value", "id")
    );
    KuikaAppManager.addToPageInputVariables("PopupBandrolYapistirma", "NewEditDisplay", "E");

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      this.UretimDetayKartComponent_858704_onClick1_,
      "UretimDetayKart",
      "PopupBandrolYapistirma",
      "",
      "9924ef5f-4b84-4200-b624-8d76348c7686",
      "490873",
      null,
      null,
      "true",
      "480px",
      "60vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };
  UretimDetayKartComponent_858704_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      TeklifTalepKartID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      ),
      TeklifTalepKartID_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "UretimDetayKart/UretimDetayKartComponent_858704_onClick1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.BandrolYapistirmaListById = result?.data.bandrolYapistirmaListById;

    stateVars.BaskiAdetInfo = result?.data.baskiAdetInfo;
    formVars.uretimdetaykart_854256_value = ReactSystemFunctions.toString(
      this,
      stateVars.BaskiAdetInfo?.length > 0
        ? stateVars.BaskiAdetInfo[0].baskiAdet
        : this.state.BaskiAdetInfo?.length > 0
        ? this.state.BaskiAdetInfo[0].baskiAdet
        : null
    );
    formVars.uretimdetaykart_832914_value = ReactSystemFunctions.toString(
      this,
      stateVars.BaskiAdetInfo?.length > 0
        ? stateVars.BaskiAdetInfo[0].yapistirmaAdet
        : this.state.BaskiAdetInfo?.length > 0
        ? this.state.BaskiAdetInfo[0].yapistirmaAdet
        : null
    );
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.UretimDetayKartComponent_858704_onClick2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  UretimDetayKartComponent_858704_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.uretimdetaykart_854256_value = ReactSystemFunctions.toString(
      this,
      stateVars.BaskiAdetInfo?.length > 0
        ? stateVars.BaskiAdetInfo[0].baskiAdet
        : this.state.BaskiAdetInfo?.length > 0
        ? this.state.BaskiAdetInfo[0].baskiAdet
        : null
    );

    formVars.uretimdetaykart_832914_value = ReactSystemFunctions.toString(
      this,
      stateVars.BaskiAdetInfo?.length > 0
        ? stateVars.BaskiAdetInfo[0].yapistirmaAdet
        : this.state.BaskiAdetInfo?.length > 0
        ? this.state.BaskiAdetInfo[0].yapistirmaAdet
        : null
    );

    stateVars.dataSource_656727 = this.state.BandrolYapistirmaListById;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  UretimDetayKartComponent_932622_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    ReactSystemFunctions.setValueOf(
      this,
      "uretimdetaykart_135515_value",
      ReactSystemFunctions.value(this, "uretimdetaykart_932622_value", "value"),
      "disablebeforedate"
    );

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.UretimDetayKartComponent_932622_onChange1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  UretimDetayKartComponent_932622_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uretimdetaykart_349002_value", "value", "", "", "")
        ),
        true
      )
    ) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp349002Enabled", "enabled");

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.UretimDetayKartComponent_932622_onChange2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.UretimDetayKartComponent_932622_onChange2_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  UretimDetayKartComponent_932622_onChange2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uretimdetaykart_349002_value", "value", "", "", "")
        ),
        false
      )
    ) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp349002Enabled", "disabled");

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  UretimDetayKartComponent_890290_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  UretimDetayKartComponent_378775_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uretimdetaykart_204674_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "uretimdetaykart_204674_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uretimdetaykart_349002_value", "value", "", "", "")
        ),
        true
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uretimdetaykart_712085_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "uretimdetaykart_712085_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uretimdetaykart_349002_value", "value", "", "", "")
        ),
        true
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      EserBilgileriID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      ),
      UyeID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyeId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyeId
            : null
        ),
        "Guid"
      ),
      RolName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].rolName
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].rolName
            : null
        ),
        "string"
      ),
      Yapistirma_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uretimdetaykart_204674_value", "value", "", "", "")
        ),
        "number"
      ),
      Fire_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uretimdetaykart_712085_value", "value", "", "", "")
        ),
        "number"
      ),
      CiltlemeTarihi_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uretimdetaykart_763256_value", "value", "", "", "")
        ),
        "Date"
      ),
      IcGorselBaskiTarihi_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uretimdetaykart_275305_value", "value", "", "", "")
        ),
        "Date"
      ),
      KapakGorselBaskiTarihi_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uretimdetaykart_946837_value", "value", "", "", "")
        ),
        "Date"
      ),
      SonBicakTarihi_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uretimdetaykart_919345_value", "value", "", "", "")
        ),
        "Date"
      ),
      UretimTamanlandi_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uretimdetaykart_57599_value", "value", "", "", "")
        ),
        "boolean"
      ),
      TeklifTalepKartID_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      ),
      Id_1: ReactSystemFunctions.convertToTypeByName(Guid.create().toString(), "Guid"),
      TeklifTalepKartId_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "UretimDetayKart/UretimDetayKartComponent_378775_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.EserDetay_BandrolStokSUM_UretimKontrol = result?.data.eserDetay_BandrolStokSUM_UretimKontrol;
    stateVars.UretimKartSave = result?.data.uretimKartSave;
    stateVars.getEserBilgileriIdByTeklifTalepKartID = result?.data.getEserBilgileriIdByTeklifTalepKartID;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.UretimDetayKartComponent_378775_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  UretimDetayKartComponent_378775_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uretimdetaykart_204674_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "uretimdetaykart_204674_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uretimdetaykart_349002_value", "value", "", "", "")
        ),
        true
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uretimdetaykart_932622_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "uretimdetaykart_932622_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uretimdetaykart_349002_value", "value", "", "", "")
        ),
        true
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Adet_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uretimdetaykart_204674_value", "value", "", "", "")
        ),
        "number"
      ),
      EserBilgileriID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.getEserBilgileriIdByTeklifTalepKartID?.length > 0
            ? stateVars.getEserBilgileriIdByTeklifTalepKartID[0].eserBilgileriId
            : this.state.getEserBilgileriIdByTeklifTalepKartID?.length > 0
            ? this.state.getEserBilgileriIdByTeklifTalepKartID[0].eserBilgileriId
            : null
        ),
        "Guid"
      ),
      GCAciklama_0: ReactSystemFunctions.convertToTypeByName("Üretim Bandrol Yapıştırma", "string"),
      GCTarih_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uretimdetaykart_932622_value", "value", "", "", "")
        ),
        "Date"
      ),
      StokGC_0: ReactSystemFunctions.convertToTypeByName("C", "string"),
      UyeID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyeId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyeId
            : null
        ),
        "Guid"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(Guid.create().toString(), "Guid")
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uretimdetaykart_349002_value", "value", "", "", "")
        ),
        true
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "UretimDetayKart/UretimDetayKartComponent_378775_onClick1_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.EserDetay_BandrolStokSave = result?.data.eserDetay_BandrolStokSave;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.UretimDetayKartComponent_378775_onClick2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.UretimDetayKartComponent_378775_onClick2_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  UretimDetayKartComponent_378775_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uretimdetaykart_712085_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "uretimdetaykart_712085_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uretimdetaykart_349002_value", "value", "", "", "")
        ),
        true
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uretimdetaykart_932622_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "uretimdetaykart_932622_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uretimdetaykart_349002_value", "value", "", "", "")
        ),
        true
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Adet_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uretimdetaykart_712085_value", "value", "", "", "")
        ),
        "number"
      ),
      EserBilgileriID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.getEserBilgileriIdByTeklifTalepKartID?.length > 0
            ? stateVars.getEserBilgileriIdByTeklifTalepKartID[0].eserBilgileriId
            : this.state.getEserBilgileriIdByTeklifTalepKartID?.length > 0
            ? this.state.getEserBilgileriIdByTeklifTalepKartID[0].eserBilgileriId
            : null
        ),
        "Guid"
      ),
      GCAciklama_0: ReactSystemFunctions.convertToTypeByName("Üretim Bandrol Fire", "string"),
      GCTarih_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uretimdetaykart_932622_value", "value", "", "", "")
        ),
        "Date"
      ),
      StokGC_0: ReactSystemFunctions.convertToTypeByName("C", "string"),
      UyeID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kUserId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kUserId
            : null
        ),
        "Guid"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(Guid.create().toString(), "Guid")
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uretimdetaykart_349002_value", "value", "", "", "")
        ),
        true
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "UretimDetayKart/UretimDetayKartComponent_378775_onClick2_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.EserDetay_BandrolStokSave = result?.data.eserDetay_BandrolStokSave;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.UretimDetayKartComponent_378775_onClick3_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.UretimDetayKartComponent_378775_onClick3_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  UretimDetayKartComponent_378775_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      actionkey_0: ReactSystemFunctions.convertToTypeByName("depo", "string"),
      uyeliktipi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyelikTipi
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyelikTipi
            : null
        ),
        "string"
      ),
      numara_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.siparisNo ?? this.props.screenInputs.siparisno,
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "UretimDetayKart/UretimDetayKartComponent_378775_onClick3_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SendMailSettings = result?.data.sendMailSettings;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.UretimDetayKartComponent_378775_onClick4_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  UretimDetayKartComponent_378775_onClick4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      templateId_0: ReactSystemFunctions.convertToTypeByName("bd88a6fe-1c11-453c-8337-8705b3ab3d3a", "string"),
      receivers_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.EserBilgileri_TeklifTalepEdenEmail?.length > 0
            ? stateVars.EserBilgileri_TeklifTalepEdenEmail[0].userName
            : this.state.EserBilgileri_TeklifTalepEdenEmail?.length > 0
            ? this.state.EserBilgileri_TeklifTalepEdenEmail[0].userName
            : null
        ),
        "string"
      ),
      subject_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.SendMailSettings?.length > 0
            ? stateVars.SendMailSettings[0].mailSubject
            : this.state.SendMailSettings?.length > 0
            ? this.state.SendMailSettings[0].mailSubject
            : null
        ),
        "string"
      ),
      sender_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      cc_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      bcc_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentFileName_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentBase64_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      url_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.SendMailSettings?.length > 0
            ? stateVars.SendMailSettings[0].urLink
            : this.state.SendMailSettings?.length > 0
            ? this.state.SendMailSettings[0].urLink
            : null
        ),
        "string"
      ),
      PublinkDestekTelNo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.GenelParametreler?.length > 0
            ? stateVars.GenelParametreler[0].destekTelNo
            : this.state.GenelParametreler?.length > 0
            ? this.state.GenelParametreler[0].destekTelNo
            : null
        ),
        "string"
      ),
      UyeIsmi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.EserBilgileri_TeklifTalepEdenEmail?.length > 0
            ? stateVars.EserBilgileri_TeklifTalepEdenEmail[0].firstName
            : this.state.EserBilgileri_TeklifTalepEdenEmail?.length > 0
            ? this.state.EserBilgileri_TeklifTalepEdenEmail[0].firstName
            : null
        ),
        "string"
      ),
      UyeSoyad_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.EserBilgileri_TeklifTalepEdenEmail?.length > 0
            ? stateVars.EserBilgileri_TeklifTalepEdenEmail[0].lastName
            : this.state.EserBilgileri_TeklifTalepEdenEmail?.length > 0
            ? this.state.EserBilgileri_TeklifTalepEdenEmail[0].lastName
            : null
        ),
        "string"
      ),
      language_0: ReactSystemFunctions.convertToTypeByName("", "string")
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uretimdetaykart_785885_value", "value", "", "", "")
        ),
        true
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "UretimDetayKart/UretimDetayKartComponent_378775_onClick4_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.SendMail = result?.data.sendMail;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.UretimDetayKartComponent_378775_onClick5_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.UretimDetayKartComponent_378775_onClick5_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  UretimDetayKartComponent_378775_onClick5_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      TeklifTalepKartId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "UretimDetayKart/UretimDetayKartComponent_378775_onClick5_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.UretimTamamlandiEnabledKontrol = result?.data.uretimTamamlandiEnabledKontrol;
    stateVars.isComp57599Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UretimTamamlandiEnabledKontrol?.length > 0
            ? stateVars.UretimTamamlandiEnabledKontrol[0].isEnabled
            : this.state.UretimTamamlandiEnabledKontrol?.length > 0
            ? this.state.UretimTamamlandiEnabledKontrol[0].isEnabled
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.UretimDetayKartComponent_378775_onClick6_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  UretimDetayKartComponent_378775_onClick6_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  UretimDetayKartComponent_946837_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    ReactSystemFunctions.setValueOf(
      this,
      "uretimdetaykart_763256_value",
      ReactSystemFunctions.value(this, "uretimdetaykart_946837_value", "value"),
      "disablebeforedate"
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  UretimDetayKartComponent_763256_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    ReactSystemFunctions.setValueOf(
      this,
      "uretimdetaykart_919345_value",
      ReactSystemFunctions.value(this, "uretimdetaykart_763256_value", "value"),
      "disablebeforedate"
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  UretimDetayKartComponent_919345_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    ReactSystemFunctions.setValueOf(
      this,
      "uretimdetaykart_932622_value",
      ReactSystemFunctions.value(this, "uretimdetaykart_919345_value", "value"),
      "disablebeforedate"
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [315083, 490873] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.UretimDetayKartPageInit();
    }
    if (diId == 315083) {
      isErrorOccurred = await this.UretimDetayKartComponent_596501_onClick2_();
      if (isErrorOccurred) return true;
    } else if (diId == 490873) {
      isErrorOccurred = await this.UretimDetayKartComponent_858704_onClick1_();
      if (isErrorOccurred) return true;
    }
  }
}
