import { FormInstance } from "antd/es/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IHizliTeklifKarsilastirma_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IHizliTeklifKarsilastirma_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GoBack: any;
  GoBack_dummy: any;
  MyContext: any[];
  MyContext_dummy: any[];
  GenelParametreler: any[];
  GenelParametreler_dummy: any[];
  HizliTeklifKarsilastirmaList: any[];
  HizliTeklifKarsilastirmaList_dummy: any[];
  HizliTeklifTempToTeklifTalepKartTeklifIste: any[];
  HizliTeklifTempToTeklifTalepKartTeklifIste_dummy: any[];
  getTalepNoUserByTeklifTalepKartId: any[];
  getTalepNoUserByTeklifTalepKartId_dummy: any[];
  UyelikTipiLowercase: any[];
  UyelikTipiLowercase_dummy: any[];
  SendMailSettings: any[];
  SendMailSettings_dummy: any[];
  SendMail: boolean;
  SendMail_dummy: boolean;
  Notify: boolean;
  Notify_dummy: boolean;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  HizliTeklifTempToTeklifTalepKart: any[];
  HizliTeklifTempToTeklifTalepKart_dummy: any[];
}

export class HizliTeklifKarsilastirma_ScreenBase extends React.PureComponent<
  IHizliTeklifKarsilastirma_ScreenProps,
  any
> {
  hizliteklifkarsilastirma_922676_value_kuikaGalleryViewRef: React.RefObject<any>;
  ml = [
    {
      Id: "41cd36a4-5b07-cfca-4f7f-f48019ff4ff3",
      Name: "tr_TR",
      ShortName: "Türkçe",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "0069cee9-16ca-4a5a-aab9-dd58af96ca96", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "4bebc098-0b09-4344-86c1-981ba442ca75",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 364938, PropertyName: "value", Value: "Hızlı Teklif Karşılaştırma Listesi" },
        { Id: 922676, PropertyName: "nodatafoundmessage", Value: "Teklif Bulunamadı!" },
        { Id: 880694, PropertyName: "value", Value: "Firma" },
        { Id: 242082, PropertyName: "title", Value: "[datafield:sirketad]" },
        { Id: 351552, PropertyName: "value", Value: "[datafield:sirketad]" },
        { Id: 88383, PropertyName: "value", Value: "Sayfa" },
        { Id: 57253, PropertyName: "value", Value: "Birim Fiyatı" },
        { Id: 692138, PropertyName: "value", Value: "[datafield:sayfabfiyat]" },
        { Id: 295062, PropertyName: "value", Value: "Kapak" },
        { Id: 59276, PropertyName: "value", Value: "Birim Fiyatı" },
        { Id: 949571, PropertyName: "value", Value: "[datafield:kapakbfiyat]" },
        { Id: 189441, PropertyName: "value", Value: "Cilt" },
        { Id: 324046, PropertyName: "value", Value: "Birim Fiyatı" },
        { Id: 67546, PropertyName: "value", Value: "[datafield:ciltbfiyat]" },
        { Id: 899165, PropertyName: "value", Value: "Teklif Fiyatı" },
        { Id: 495891, PropertyName: "value", Value: "[datafield:tekliffiyat]" },
        { Id: 800509, PropertyName: "value", Value: "Birim Fiyatı" },
        { Id: 443212, PropertyName: "value", Value: "[datafield:birimfiyat]" },
        { Id: 645792, PropertyName: "value", Value: '"Fiyatlara KDV dahil değildir."' },
        { Id: 689512, PropertyName: "value", Value: "diğer Koşullar" },
        { Id: 674119, PropertyName: "value", Value: "[datafield:digerkosullar]" },
        { Id: 653900, PropertyName: "value", Value: "Notlar" },
        { Id: 882463, PropertyName: "title", Value: "[datafield:aciklama]" },
        { Id: 178531, PropertyName: "value", Value: "[datafield:aciklama]" },
        { Id: 509249, PropertyName: "label", Value: "Yeni Teklif İste" },
        { Id: 400438, PropertyName: "label", Value: "Talep Oluştur" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.hizliteklifkarsilastirma_922676_value_kuikaGalleryViewRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GoBack: "",
      MyContext: [],
      GenelParametreler: [],
      HizliTeklifKarsilastirmaList: [],
      HizliTeklifTempToTeklifTalepKartTeklifIste: [],
      getTalepNoUserByTeklifTalepKartId: [],
      UyelikTipiLowercase: [],
      SendMailSettings: [],
      SendMail: false,
      Notify: false,
      NAVIGATE: "",
      HizliTeklifTempToTeklifTalepKart: []
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("hizliteklifkarsilastirma", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.HizliTeklifKarsilastirmaPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("hizliteklifkarsilastirma", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("hizliteklifkarsilastirma", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.HizliTeklifKarsilastirmaPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  HizliTeklifKarsilastirmaPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      HizliTeklifTempID_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.HizliTeklifTTempID ?? this.props.screenInputs.hizliteklifttempid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "HizliTeklifKarsilastirma/HizliTeklifKarsilastirmaPageInit",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    stateVars.GenelParametreler = result?.data.genelParametreler;
    stateVars.HizliTeklifKarsilastirmaList = result?.data.hizliTeklifKarsilastirmaList;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.HizliTeklifKarsilastirmaPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  HizliTeklifKarsilastirmaPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_922676 = this.state.HizliTeklifKarsilastirmaList;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  HizliTeklifKarsilastirmaComponent_521698_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  HizliTeklifKarsilastirmaComponent_509249_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      HizliTeklifId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "hizliteklifkarsilastirma_922676_value", "hizliTeklifKartId"),
        "Guid"
      ),
      HizliTeklifTempId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.HizliTeklifTTempID ?? this.props.screenInputs.hizliteklifttempid,
        "Guid"
      ),
      TeklifTalepEdenKisiID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kUserId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kUserId
            : null
        ),
        "Guid"
      ),
      TeklifTalepEdenUyeID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyeId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyeId
            : null
        ),
        "Guid"
      ),
      UyelikTipi_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyelikTipi
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyelikTipi
            : null
        ),
        "string"
      ),
      actionkey_3: ReactSystemFunctions.convertToTypeByName("hizliteklifmatbaayagonder", "string"),
      uyeliktipi_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyelikTipi
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyelikTipi
            : null
        ),
        "string"
      ),
      templateId_4: ReactSystemFunctions.convertToTypeByName("99dbd169-b239-4818-967f-cae8ea13d300", "string"),
      sender_4: ReactSystemFunctions.convertToTypeByName("", "string"),
      cc_4: ReactSystemFunctions.convertToTypeByName("", "string"),
      bcc_4: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentFileName_4: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentBase64_4: ReactSystemFunctions.convertToTypeByName("", "string"),
      UyeIsmi_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyeAd
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyeAd
            : null
        ),
        "string"
      ),
      UyeSoyad_4: ReactSystemFunctions.convertToTypeByName("", "string"),
      PublinkDestekTelNo_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.GenelParametreler?.length > 0
            ? stateVars.GenelParametreler[0].destekTelNo
            : this.state.GenelParametreler?.length > 0
            ? this.state.GenelParametreler[0].destekTelNo
            : null
        ),
        "string"
      ),
      language_4: ReactSystemFunctions.convertToTypeByName("", "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "HizliTeklifKarsilastirma/HizliTeklifKarsilastirmaComponent_509249_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.HizliTeklifTempToTeklifTalepKartTeklifIste = result?.data.hizliTeklifTempToTeklifTalepKartTeklifIste;
    stateVars.getTalepNoUserByTeklifTalepKartId = result?.data.getTalepNoUserByTeklifTalepKartId;
    stateVars.UyelikTipiLowercase = result?.data.uyelikTipiLowercase;
    stateVars.SendMailSettings = result?.data.sendMailSettings;
    stateVars.SendMail = result?.data.sendMail;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.HizliTeklifKarsilastirmaComponent_509249_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  HizliTeklifKarsilastirmaComponent_509249_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "2aaf750e_95fa_f37a_1141_57532e50b7dc_notify",
        this.defaultML,
        "Teklif Talebiniz Oluşturuldu!"
      ),
      "success",
      "bottom-right",
      1,
      null,
      null,
      null
    );
    stateVars.GoBack = await ReactSystemFunctions.goBack(this);
    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("TeklifTalepList", "tabIndex", 2);

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "HizliTeklifKarsilastirma",
      "TeklifTalepList",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  HizliTeklifKarsilastirmaComponent_400438_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      HizliTeklifId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "hizliteklifkarsilastirma_922676_value", "hizliTeklifKartId"),
        "Guid"
      ),
      HizliTeklifTempId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.HizliTeklifTTempID ?? this.props.screenInputs.hizliteklifttempid,
        "Guid"
      ),
      TeklifTalepEdenKisiID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kUserId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kUserId
            : null
        ),
        "Guid"
      ),
      TeklifTalepEdenUyeID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyeId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyeId
            : null
        ),
        "Guid"
      ),
      UyelikTipi_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyelikTipi
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyelikTipi
            : null
        ),
        "string"
      ),
      actionkey_3: ReactSystemFunctions.convertToTypeByName("hizliteklifmatbaayagonder", "string"),
      uyeliktipi_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyelikTipi
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyelikTipi
            : null
        ),
        "string"
      ),
      templateId_4: ReactSystemFunctions.convertToTypeByName("99dbd169-b239-4818-967f-cae8ea13d300", "string"),
      sender_4: ReactSystemFunctions.convertToTypeByName("", "string"),
      cc_4: ReactSystemFunctions.convertToTypeByName("", "string"),
      bcc_4: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentFileName_4: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentBase64_4: ReactSystemFunctions.convertToTypeByName("", "string"),
      UyeIsmi_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyeAd
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyeAd
            : null
        ),
        "string"
      ),
      UyeSoyad_4: ReactSystemFunctions.convertToTypeByName("", "string"),
      PublinkDestekTelNo_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.GenelParametreler?.length > 0
            ? stateVars.GenelParametreler[0].destekTelNo
            : this.state.GenelParametreler?.length > 0
            ? this.state.GenelParametreler[0].destekTelNo
            : null
        ),
        "string"
      ),
      language_4: ReactSystemFunctions.convertToTypeByName("", "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "HizliTeklifKarsilastirma/HizliTeklifKarsilastirmaComponent_400438_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.HizliTeklifTempToTeklifTalepKart = result?.data.hizliTeklifTempToTeklifTalepKart;
    stateVars.getTalepNoUserByTeklifTalepKartId = result?.data.getTalepNoUserByTeklifTalepKartId;
    stateVars.UyelikTipiLowercase = result?.data.uyelikTipiLowercase;
    stateVars.SendMailSettings = result?.data.sendMailSettings;
    stateVars.SendMail = result?.data.sendMail;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.HizliTeklifKarsilastirmaComponent_400438_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  HizliTeklifKarsilastirmaComponent_400438_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "f4ddb2af_abda_6ac7_0cba_432f602fd966_notify",
        this.defaultML,
        "Teklif Talebiniz Oluşturuldu!"
      ),
      "success",
      "bottom-right",
      1,
      null,
      null,
      null
    );
    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "HizliTeklifKarsilastirma",
      "KabulEdilenTeklifList",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
