import { FormInstance } from "antd/es/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IMOnayBekleyenSiparisList_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IMOnayBekleyenSiparisList_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  Logout: boolean;
  Logout_dummy: boolean;
  goToSignInActivity: any;
  goToSignInActivity_dummy: any;
  MyContext: any[];
  MyContext_dummy: any[];
  RoleSadeceGoruntule: any[];
  RoleSadeceGoruntule_dummy: any[];
  TaslakMOnayBekleyenSiparisList: any[];
  TaslakMOnayBekleyenSiparisList_dummy: any[];
  setPTotalCount01: any[];
  setPTotalCount01_dummy: any[];
  isComp243022Visible: "visible" | "hidden";
  isComp221036Visible: "visible" | "hidden";
}

export class MOnayBekleyenSiparisList_ScreenBase extends React.PureComponent<
  IMOnayBekleyenSiparisList_ScreenProps,
  any
> {
  monaybekleyensiparislist_348605_value_kuikaTableRef: React.RefObject<any>;
  monaybekleyensiparislist_880048_value_kuikaPaginationRef: React.RefObject<any>;
  ml = [
    {
      Id: "41cd36a4-5b07-cfca-4f7f-f48019ff4ff3",
      Name: "tr_TR",
      ShortName: "Türkçe",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "0069cee9-16ca-4a5a-aab9-dd58af96ca96", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "ee706977-4eda-49b5-9971-08820bc0bdd9",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 364094, PropertyName: "value", Value: "Onay Bekleyen Sipariş Listesi" },
        { Id: 412428, PropertyName: "label", Value: "Profil" },
        { Id: 668195, PropertyName: "label", Value: "Çıkış Yap" },
        { Id: 348605, PropertyName: "nodatafoundmessage", Value: "Onay Bekleyen Sipariş Kaydı Bulunamadı." },
        { Id: 27045, PropertyName: "title", Value: "Siparis No" },
        { Id: 736543, PropertyName: "value", Value: "[datafield:siparis_no]" },
        { Id: 970982, PropertyName: "title", Value: "Teklif No" },
        { Id: 26026, PropertyName: "value", Value: "[datafield:teklifnos]" },
        { Id: 833287, PropertyName: "title", Value: "Firma" },
        { Id: 534463, PropertyName: "value", Value: "[datafield:firma]" },
        { Id: 916867, PropertyName: "title", Value: "Sipariş Durumu" },
        { Id: 989618, PropertyName: "value", Value: "[datafield:siparisdurum]" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";
  MOnayBekleyenSiparisListRecuringAction_29_Interval: any;

  constructor(props) {
    super(props);
    this.monaybekleyensiparislist_348605_value_kuikaTableRef = React.createRef();
    this.monaybekleyensiparislist_880048_value_kuikaPaginationRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      NAVIGATE: "",
      Logout: false,
      goToSignInActivity: "",
      MyContext: [],
      RoleSadeceGoruntule: [],
      TaslakMOnayBekleyenSiparisList: [],
      setPTotalCount01: [],
      isComp243022Visible: "hidden",
      isComp221036Visible: "hidden"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("monaybekleyensiparislist", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.MOnayBekleyenSiparisListPageInit();
    }

    if (false) {
      if (false) {
        await this.MOnayBekleyenSiparisListRecuringAction_29_();
      }
      this.MOnayBekleyenSiparisListRecuringAction_29_Interval = setInterval(async () => {
        await this.MOnayBekleyenSiparisListRecuringAction_29_();
      }, 29000);
    }

    KuikaAppManager.calculateAndSetBodyHeight("monaybekleyensiparislist", "");
  }

  componentWillUnmount() {
    if (this.MOnayBekleyenSiparisListRecuringAction_29_Interval) {
      clearInterval(this.MOnayBekleyenSiparisListRecuringAction_29_Interval);
    }
  }

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("monaybekleyensiparislist", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.MOnayBekleyenSiparisListPageInit();
    }

    if (prevState.isPageInitLoaded !== this.state.isPageInitLoaded && this.state.isPageInitLoaded === true) {
      if (false && true) {
        await this.MOnayBekleyenSiparisListRecuringAction_29_();
      }
      this.MOnayBekleyenSiparisListRecuringAction_29_Interval = setInterval(async () => {
        await this.MOnayBekleyenSiparisListRecuringAction_29_();
      }, 29000);
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      monaybekleyensiparislist_801926_value: this.state.MyContext?.at?.(0)?.profilFoto ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  MOnayBekleyenSiparisListPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "MOnayBekleyenSiparisList/MOnayBekleyenSiparisListPageInit",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    formVars.monaybekleyensiparislist_801926_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0
        ? stateVars.MyContext[0].profilFoto
        : this.state.MyContext?.length > 0
        ? this.state.MyContext[0].profilFoto
        : null
    );
    formVars.monaybekleyensiparislist_172357_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0
        ? stateVars.MyContext[0].fullName
        : this.state.MyContext?.length > 0
        ? this.state.MyContext[0].fullName
        : null
    );
    formVars.monaybekleyensiparislist_590045_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0
        ? stateVars.MyContext[0].uyeAd
        : this.state.MyContext?.length > 0
        ? this.state.MyContext[0].uyeAd
        : null
    );
    stateVars.RoleSadeceGoruntule = result?.data.roleSadeceGoruntule;
    stateVars.isComp243022Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg045
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg045
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.TaslakMOnayBekleyenSiparisList = result?.data.taslakMOnayBekleyenSiparisList;

    stateVars.setPTotalCount01 = result?.data.setPTotalCount01;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MOnayBekleyenSiparisListPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MOnayBekleyenSiparisListPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.monaybekleyensiparislist_801926_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0
        ? stateVars.MyContext[0].profilFoto
        : this.state.MyContext?.length > 0
        ? this.state.MyContext[0].profilFoto
        : null
    );

    formVars.monaybekleyensiparislist_172357_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0
        ? stateVars.MyContext[0].fullName
        : this.state.MyContext?.length > 0
        ? this.state.MyContext[0].fullName
        : null
    );

    formVars.monaybekleyensiparislist_590045_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0
        ? stateVars.MyContext[0].uyeAd
        : this.state.MyContext?.length > 0
        ? this.state.MyContext[0].uyeAd
        : null
    );

    stateVars.dataSource_348605 = this.state.TaslakMOnayBekleyenSiparisList;
    stateVars.isComp243022Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg045
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg045
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp221036Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "monaybekleyensiparislist_348605_value", "isOnayBeklemedeIconsVisibility"),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.monaybekleyensiparislist_880048_total = ReactSystemFunctions.value(
      this,
      stateVars.setPTotalCount01?.length > 0
        ? stateVars.setPTotalCount01[0].totalCount
        : this.state.setPTotalCount01?.length > 0
        ? this.state.setPTotalCount01[0].totalCount
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  MOnayBekleyenSiparisListComponent_54788_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "MOnayBekleyenSiparisList",
      "LeftMenu",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "242761",
      null,
      "left",
      null,
      "480px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MOnayBekleyenSiparisListComponent_412428_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "MOnayBekleyenSiparisList",
      "Profil",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "417926",
      null,
      "right",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MOnayBekleyenSiparisListComponent_668195_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.Logout = await ReactSystemFunctions.logout();
    stateVars.tmpBoolResult = await ReactSystemFunctions.goToSignInActivity(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MOnayBekleyenSiparisListComponent_221036_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("TSiparisKart", "TaslakmiKartmi", "Kart");
    KuikaAppManager.addToPageInputVariables(
      "TSiparisKart",
      "SiparisKartId",
      ReactSystemFunctions.value(this, "monaybekleyensiparislist_348605_value", "id")
    );
    KuikaAppManager.addToPageInputVariables("TSiparisKart", "NewEditDisplay", "D");
    KuikaAppManager.addToPageInputVariables(
      "TSiparisKart",
      "FirmaAd",
      ReactSystemFunctions.value(this, "monaybekleyensiparislist_348605_value", "firma")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "MOnayBekleyenSiparisList",
      "TSiparisKart",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "537471",
      null,
      "right",
      null,
      "80%",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MOnayBekleyenSiparisListComponent_880048_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      TalepUyeID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyeId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyeId
            : null
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "MOnayBekleyenSiparisList/MOnayBekleyenSiparisListComponent_880048_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.TaslakMOnayBekleyenSiparisList = result?.data.taslakMOnayBekleyenSiparisList;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MOnayBekleyenSiparisListComponent_880048_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MOnayBekleyenSiparisListComponent_880048_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_348605 = this.state.TaslakMOnayBekleyenSiparisList;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MOnayBekleyenSiparisListRecuringAction_29_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    if (false) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "MOnayBekleyenSiparisList/MOnayBekleyenSiparisListRecuringAction_29_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    formVars.monaybekleyensiparislist_801926_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0
        ? stateVars.MyContext[0].profilFoto
        : this.state.MyContext?.length > 0
        ? this.state.MyContext[0].profilFoto
        : null
    );
    formVars.monaybekleyensiparislist_172357_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0
        ? stateVars.MyContext[0].fullName
        : this.state.MyContext?.length > 0
        ? this.state.MyContext[0].fullName
        : null
    );
    formVars.monaybekleyensiparislist_590045_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0
        ? stateVars.MyContext[0].uyeAd
        : this.state.MyContext?.length > 0
        ? this.state.MyContext[0].uyeAd
        : null
    );
    stateVars.TaslakMOnayBekleyenSiparisList = result?.data.taslakMOnayBekleyenSiparisList;

    stateVars.setPTotalCount01 = result?.data.setPTotalCount01;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MOnayBekleyenSiparisListRecuringAction_29_1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MOnayBekleyenSiparisListRecuringAction_29_1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.monaybekleyensiparislist_801926_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0
        ? stateVars.MyContext[0].profilFoto
        : this.state.MyContext?.length > 0
        ? this.state.MyContext[0].profilFoto
        : null
    );

    formVars.monaybekleyensiparislist_172357_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0
        ? stateVars.MyContext[0].fullName
        : this.state.MyContext?.length > 0
        ? this.state.MyContext[0].fullName
        : null
    );

    formVars.monaybekleyensiparislist_590045_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0
        ? stateVars.MyContext[0].uyeAd
        : this.state.MyContext?.length > 0
        ? this.state.MyContext[0].uyeAd
        : null
    );

    stateVars.dataSource_348605 = this.state.TaslakMOnayBekleyenSiparisList;
    formVars.monaybekleyensiparislist_880048_total = ReactSystemFunctions.value(
      this,
      stateVars.setPTotalCount01?.length > 0
        ? stateVars.setPTotalCount01[0].totalCount
        : this.state.setPTotalCount01?.length > 0
        ? this.state.setPTotalCount01[0].totalCount
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [242761, 417926, 537471] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.MOnayBekleyenSiparisListPageInit();
    }
  }
}
