import { FormInstance } from "antd/es/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IDashBoardPowerUser_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IDashBoardPowerUser_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  Logout: boolean;
  Logout_dummy: boolean;
  goToSignInActivity: any;
  goToSignInActivity_dummy: any;
  MyContext: any[];
  MyContext_dummy: any[];
  SetLanguage: string;
  SetLanguage_dummy: string;
  isComp819129Visible: "visible" | "hidden";
}

export class DashBoardPowerUser_ScreenBase extends React.PureComponent<IDashBoardPowerUser_ScreenProps, any> {
  dashboardpoweruser_767386_value_kuikaTableRef: React.RefObject<any>;
  dashboardpoweruser_512534_value_kuikaPaginationRef: React.RefObject<any>;
  ml = [
    {
      Id: "41cd36a4-5b07-cfca-4f7f-f48019ff4ff3",
      Name: "tr_TR",
      ShortName: "Türkçe",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "0069cee9-16ca-4a5a-aab9-dd58af96ca96", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "6217849a-234c-4fd4-8c25-d271a1e2e254",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 119224, PropertyName: "value", Value: "Power User Dashboard" },
        { Id: 881224, PropertyName: "label", Value: "Profil" },
        { Id: 310750, PropertyName: "label", Value: "Çıkış Yap" },
        { Id: 590128, PropertyName: "value", Value: "........................................." },
        { Id: 743535, PropertyName: "value", Value: "---" },
        { Id: 449815, PropertyName: "value", Value: "........................................." },
        { Id: 665321, PropertyName: "value", Value: "---" },
        { Id: 891654, PropertyName: "value", Value: "........................................." },
        { Id: 623036, PropertyName: "value", Value: "---" },
        { Id: 71802, PropertyName: "value", Value: "........................................." },
        { Id: 877467, PropertyName: "value", Value: "---" },
        { Id: 508416, PropertyName: "value", Value: "Formlar" },
        { Id: 172312, PropertyName: "label", Value: "Yayıncı Bilgileri" },
        { Id: 510731, PropertyName: "label", Value: "Matbaa Bilgileri" },
        { Id: 78295, PropertyName: "label", Value: "Müşteri Bilgi" },
        { Id: 896159, PropertyName: "label", Value: "Yazar Bilgi Formu" },
        { Id: 991514, PropertyName: "label", Value: "Yayıncı Tedarikçi Bilgi" },
        { Id: 206153, PropertyName: "value", Value: "Üye Listesi" },
        { Id: 628761, PropertyName: "value", Value: "Tümü" },
        { Id: 767386, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 427279, PropertyName: "title", Value: "Üyelik Tipi" },
        { Id: 770805, PropertyName: "value", Value: "Yayıncı" },
        { Id: 30995, PropertyName: "title", Value: "Ad Soyad" },
        { Id: 740743, PropertyName: "value", Value: "Ali Mavişehir" },
        { Id: 592024, PropertyName: "title", Value: "E-posta" },
        { Id: 954695, PropertyName: "value", Value: "ali.mavisehir@mail.com" },
        { Id: 958550, PropertyName: "title", Value: "Telefon numarası" },
        { Id: 126558, PropertyName: "value", Value: "+90555 666 77 88" },
        { Id: 389173, PropertyName: "title", Value: "Şirket Adı" },
        { Id: 805996, PropertyName: "value", Value: "Şirket Adı 1" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.dashboardpoweruser_767386_value_kuikaTableRef = React.createRef();
    this.dashboardpoweruser_512534_value_kuikaPaginationRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      NAVIGATE: "",
      Logout: false,
      goToSignInActivity: "",
      MyContext: [],
      SetLanguage: "",
      isComp819129Visible: "hidden"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("dashboardpoweruser", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.DashBoardPowerUserPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("dashboardpoweruser", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("dashboardpoweruser", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.DashBoardPowerUserPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      dashboardpoweruser_81624_value: this.state.MyContext?.at?.(0)?.profilFoto ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  DashBoardPowerUserPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "DashBoardPowerUser/DashBoardPowerUserPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    formVars.dashboardpoweruser_734865_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0
        ? stateVars.MyContext[0].uyeAd
        : this.state.MyContext?.length > 0
        ? this.state.MyContext[0].uyeAd
        : null
    );
    formVars.dashboardpoweruser_81624_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0
        ? stateVars.MyContext[0].profilFoto
        : this.state.MyContext?.length > 0
        ? this.state.MyContext[0].profilFoto
        : null
    );
    formVars.dashboardpoweruser_980974_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0
        ? stateVars.MyContext[0].fullName
        : this.state.MyContext?.length > 0
        ? this.state.MyContext[0].fullName
        : null
    );
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.DashBoardPowerUserPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  DashBoardPowerUserPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.SetLanguage = await ReactSystemFunctions.setLanguage(this, "tr_TR");
    formVars.dashboardpoweruser_734865_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0
        ? stateVars.MyContext[0].uyeAd
        : this.state.MyContext?.length > 0
        ? this.state.MyContext[0].uyeAd
        : null
    );

    formVars.dashboardpoweruser_81624_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0
        ? stateVars.MyContext[0].profilFoto
        : this.state.MyContext?.length > 0
        ? this.state.MyContext[0].profilFoto
        : null
    );

    formVars.dashboardpoweruser_980974_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0
        ? stateVars.MyContext[0].fullName
        : this.state.MyContext?.length > 0
        ? this.state.MyContext[0].fullName
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  DashBoardPowerUserComponent_284229_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "DashBoardPowerUser",
      "LeftMenu",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "997406",
      null,
      "left",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashBoardPowerUserComponent_881224_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "DashBoardPowerUser",
      "Profil",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "167901",
      null,
      "right",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashBoardPowerUserComponent_310750_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.Logout = await ReactSystemFunctions.logout();
    stateVars.tmpBoolResult = await ReactSystemFunctions.goToSignInActivity(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [997406, 167901] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.DashBoardPowerUserPageInit();
    }
  }
}
