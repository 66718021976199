import { FormInstance } from "antd/es/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IResetPassword_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IResetPassword_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  ChangeEnabledOf: any;
  ChangeEnabledOf_dummy: any;
  IfThenElse: boolean;
  IfThenElse_dummy: boolean;
  fnPasswordControl: any;
  fnPasswordControl_dummy: any;
  PasswordReset: string;
  PasswordReset_dummy: string;
  goToSignInActivity: any;
  goToSignInActivity_dummy: any;
  isComp826965Enabled: "enabled" | "disabled";
}

export class ResetPassword_ScreenBase extends React.PureComponent<IResetPassword_ScreenProps, any> {
  ml = [
    {
      Id: "41cd36a4-5b07-cfca-4f7f-f48019ff4ff3",
      Name: "tr_TR",
      ShortName: "Türkçe",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "0069cee9-16ca-4a5a-aab9-dd58af96ca96", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "f90611bb-a639-4a6c-ab24-eb5021529d68",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 209555, PropertyName: "value", Value: "Şifrenizi sıfırlayın" },
        { Id: 515838, PropertyName: "value", Value: "Lütfen yeni şifrenizi yazın" },
        { Id: 887423, PropertyName: "value", Value: "Yeni Şifre" },
        { Id: 601479, PropertyName: "value", Value: "Yeni Şifre (Tekrar)" },
        {
          Id: 614796,
          PropertyName: "value",
          Value:
            "Şifre uzunluğu en az 8 karakter olmalıdır, En az 1 büyük, 1 küçük harf ve 1 özel karakter içermelidir!"
        },
        { Id: 826965, PropertyName: "label", Value: "Kaydet" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      ChangeEnabledOf: "",
      IfThenElse: false,
      fnPasswordControl: "",
      PasswordReset: "",
      goToSignInActivity: "",
      isComp826965Enabled: "enabled"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("resetpassword", "");
      return;
    }

    if (true) {
      await this.ResetPasswordPageInit();
    }

    window.addEventListener("keydown", this.onKeyDown);

    KuikaAppManager.calculateAndSetBodyHeight("resetpassword", "");
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.onKeyDown);
  }

  onKeyDown = (e: any) => {
    if (e.keyCode === undefined) return;
    const keyCode = e.keyCode;
    if (keyCode === 13) {
      var element = document.getElementById("826965");
      if (element && element.click) {
        element.click();
      }
    }
  };

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("resetpassword", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.ResetPasswordPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  ResetPasswordPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp826965Enabled", "disabled");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  ResetPasswordComponent_646272_onChange_if0 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp826965Enabled", "enabled");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };

  ResetPasswordComponent_646272_onChange_else0 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp826965Enabled", "disabled");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };

  ResetPasswordComponent_646272_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "resetpassword_646272_value", "value", "", "", "")
        ),
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "resetpassword_491266_value", "value", "", "", "")
        )
      )
    ) {
      isErrorOccurred = await this.ResetPasswordComponent_646272_onChange_if0();
      if (isErrorOccurred) return true;
    } else {
      isErrorOccurred = await this.ResetPasswordComponent_646272_onChange_else0();
      if (isErrorOccurred) return true;
    }
    KuikaAppManager.showSpinner(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ResetPasswordComponent_491266_onChange_if0 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp826965Enabled", "enabled");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };

  ResetPasswordComponent_491266_onChange_else0 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp826965Enabled", "disabled");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };

  ResetPasswordComponent_491266_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "resetpassword_491266_value", "value", "", "", "")
        ),
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "resetpassword_646272_value", "value", "", "", "")
        )
      )
    ) {
      isErrorOccurred = await this.ResetPasswordComponent_491266_onChange_if0();
      if (isErrorOccurred) return true;
    } else {
      isErrorOccurred = await this.ResetPasswordComponent_491266_onChange_else0();
      if (isErrorOccurred) return true;
    }
    KuikaAppManager.showSpinner(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ResetPasswordComponent_826965_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "resetpassword_646272_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "resetpassword_646272_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "resetpassword_646272_value", "value", "", "", "")
        ),
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "resetpassword_491266_value", "value", "", "", "")
        )
      ),
      message: "‎Şifreyi kontrol ediniz!",
      formName: "resetpassword_646272_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      pass_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "resetpassword_646272_value", "value", "", "", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "ResetPassword/ResetPasswordComponent_826965_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.ResetPasswordComponent_826965_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ResetPasswordComponent_826965_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "resetpassword_491266_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "resetpassword_491266_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "resetpassword_491266_value", "value", "", "", "")
        ),
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "resetpassword_646272_value", "value", "", "", "")
        )
      ),
      message: "Şifreyi kontrol ediniz!",
      formName: "resetpassword_491266_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.PasswordReset = await ReactSystemFunctions.resetPassword(
      this,
      ReactSystemFunctions.value(
        this,
        ReactSystemFunctions.value(this, "resetpassword_491266_value", "value", "", "", "")
      ),
      false,
      null
    );
    stateVars.tmpBoolResult = await ReactSystemFunctions.goToSignInActivity(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
