import { Form, Spin, Typography } from "antd/es";
import { connect } from "react-redux";
import {
  Button,
  Checkbox,
  HorizontalStack,
  Icon,
  KCol,
  KDatePicker,
  KRow,
  Label,
  TextInput,
  VerticalStack
} from "../../components/web-components";
import { KSelectBox } from "../../kuika";
import {
  hideImportMappedExcelModal,
  hideIyzicoDrawer,
  hidePhotoTake,
  hideStripeDrawer,
  showImportMappedExcelModal,
  showIyzicoDrawer,
  showPhotoTake,
  showStripeDrawer
} from "../../redux/modals/actions";
import { AppState } from "../../redux/root-reducer";
import { withContext } from "../../shared/hoc/with-context";
import { withForm } from "../../shared/hoc/with-form";
import { withGoogleLogin } from "../../shared/hoc/with-google-login";
import { withHistory } from "../../shared/hoc/with-history";
import { withScreenInput } from "../../shared/hoc/with-screen-input";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";
import { SiparisTeslimDetayKart_ScreenBase } from "./siparisteslimdetaykart-base";

declare let window: any;
const { Title, Paragraph, Text, Link } = Typography;
const menuMap = [];

class SiparisTeslimDetayKart_Screen extends SiparisTeslimDetayKart_ScreenBase {
  render() {
    if (this.state.isPageVisible === false) {
      return <></>;
    }
    return (
      <>
        <Spin spinning={this.state.spinnerCount > 0}>
          <Form
            form={this.props.form}
            name="basic"
            initialValues={{
              siparisteslimdetaykart_267791_value: this.state.SiparisTeslimKartById?.at?.(0)?.teslimTuru ?? undefined,
              siparisteslimdetaykart_681452_value: this.state.SiparisTeslimKartById?.at?.(0)?.kargoTarihi ?? undefined,
              siparisteslimdetaykart_588853_value: this.state.SiparisTeslimKartById?.at?.(0)?.irsaliyeNo ?? undefined,
              siparisteslimdetaykart_380156_value: this.state.SiparisTeslimKartById?.at?.(0)?.kargoFirma ?? undefined,
              siparisteslimdetaykart_599253_value: this.state.SiparisTeslimKartById?.at?.(0)?.kargoNo ?? undefined,
              siparisteslimdetaykart_903181_value: this.state.SiparisTeslimKartById?.at?.(0)?.isGonderildi ?? undefined,
              siparisteslimdetaykart_349002_value:
                this.state.SiparisTeslimKartById?.at?.(0)?.isTeslimEdildi ?? undefined,
              siparisteslimdetaykart_932622_value: this.state.SiparisTeslimKartById?.at?.(0)?.teslimTarihi ?? undefined
            }}
            onValuesChange={(changedValues, allValues) => {
              this.clearFieldValidation(changedValues);
            }}
          >
            <div
              id="siparisteslimdetaykart_body"
              style={
                {
                  borderStyle: "solid",
                  borderTopWidth: 0,
                  borderRightWidth: 0,
                  borderBottomWidth: 0,
                  borderLeftWidth: 0,
                  backgroundColor: "rgba(244, 244, 244, 1)",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "contain",
                  backgroundPosition: "left",
                  display: "block"
                } as any
              }
            >
              <KRow
                id="631351"
                align="top"
                justify="start"
                horizontalGutter={0}
                verticalGutter={0}
                style={
                  {
                    borderStyle: "solid",
                    borderTopWidth: 0,
                    borderRightWidth: 0,
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    paddingTop: 8,
                    paddingRight: 8,
                    paddingBottom: 8,
                    paddingLeft: 8,
                    alignItems: "flex-start",
                    textAlign: "-webkit-left",
                    fontFamily: "Poppins",
                    fontWeight: 500,
                    fontSize: "14px",
                    color: "rgba(22, 48, 32, 1)"
                  } as any
                }
              >
                <KCol
                  id="956209"
                  xs={20}
                  sm={20}
                  md={20}
                  lg={20}
                  xl={20}
                  xxl={20}
                  order={0}
                  pull={0}
                  push={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 8,
                      paddingRight: 8,
                      paddingBottom: 8,
                      paddingLeft: 8,
                      alignItems: "flex-start",
                      textAlign: "-webkit-left",
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(22, 48, 32, 1)"
                    } as any
                  }
                >
                  <Label
                    id="268500"
                    value={ReactSystemFunctions.translate(this.ml, 268500, "value", this.defaultML)}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 0,
                        paddingRight: 0,
                        paddingBottom: 0,
                        paddingLeft: 0,
                        alignItems: "center",
                        textAlign: "-webkit-left",
                        display: "inline",
                        fontFamily: "Poppins",
                        fontWeight: 400,
                        fontSize: "18px",
                        color: "rgba(2, 145, 201, 1)"
                      } as any
                    }
                  ></Label>
                </KCol>

                <KCol
                  id="967993"
                  xs={4}
                  sm={4}
                  md={4}
                  lg={4}
                  xl={4}
                  xxl={4}
                  order={0}
                  pull={0}
                  push={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 8,
                      paddingRight: 8,
                      paddingBottom: 8,
                      paddingLeft: 8,
                      alignItems: "center",
                      textAlign: "-webkit-right",
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(22, 48, 32, 1)"
                    } as any
                  }
                >
                  <Icon
                    id="300623"
                    onClick={(e?: any) => {
                      if (e && e.stopPropagation) e.stopPropagation();
                      this.SiparisTeslimDetayKartComponent_300623_onClick();
                    }}
                    showCursorPointer
                    iconName="close"
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 0,
                        paddingRight: 0,
                        paddingBottom: 0,
                        paddingLeft: 0,
                        alignItems: "center",
                        textAlign: "-webkit-left",
                        display: "inline",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "20px",
                        color: "rgba(2, 145, 201, 1)",
                        letterSpacing: "1px"
                      } as any
                    }
                  ></Icon>
                </KCol>
              </KRow>

              <KRow
                id="933599"
                align="top"
                justify="start"
                horizontalGutter={0}
                verticalGutter={0}
                style={
                  {
                    borderStyle: "solid",
                    borderTopWidth: 0,
                    borderRightWidth: 0,
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    paddingTop: 8,
                    paddingRight: 8,
                    paddingBottom: 8,
                    paddingLeft: 8,
                    alignItems: "flex-start",
                    textAlign: "-webkit-left",
                    fontFamily: "Poppins",
                    fontWeight: 500,
                    fontSize: "14px",
                    color: "rgba(22, 48, 32, 1)"
                  } as any
                }
              >
                <KCol
                  id="911201"
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                  xxl={24}
                  order={0}
                  pull={0}
                  push={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 8,
                      paddingRight: 8,
                      paddingBottom: 8,
                      paddingLeft: 8,
                      alignItems: "flex-start",
                      textAlign: "-webkit-left",
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(22, 48, 32, 1)"
                    } as any
                  }
                >
                  <KRow
                    id="898459"
                    align="top"
                    justify="start"
                    horizontalGutter={0}
                    verticalGutter={0}
                    style={
                      {
                        borderTopLeftRadius: 12,
                        borderTopRightRadius: 12,
                        borderBottomRightRadius: 12,
                        borderBottomLeftRadius: 12,
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        backgroundColor: "rgba(255, 255, 255, 1)",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "contain",
                        backgroundPosition: "left",
                        paddingTop: 8,
                        paddingRight: 8,
                        paddingBottom: 8,
                        paddingLeft: 8,
                        alignItems: "flex-start",
                        textAlign: "-webkit-left",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "14px",
                        color: "rgba(22, 48, 32, 1)"
                      } as any
                    }
                  >
                    <KCol
                      id="345365"
                      xs={24}
                      sm={24}
                      md={24}
                      lg={24}
                      xl={24}
                      xxl={24}
                      order={0}
                      pull={0}
                      push={0}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 0,
                          paddingRight: 0,
                          paddingBottom: 0,
                          paddingLeft: 0,
                          alignItems: "flex-start",
                          textAlign: "-webkit-left",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "rgba(22, 48, 32, 1)"
                        } as any
                      }
                    >
                      <KRow
                        id="619526"
                        align="top"
                        justify="start"
                        horizontalGutter={0}
                        verticalGutter={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 0,
                            paddingRight: 0,
                            paddingBottom: 0,
                            paddingLeft: 0,
                            alignItems: "flex-start",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(22, 48, 32, 1)"
                          } as any
                        }
                      >
                        <KCol
                          id="754035"
                          xs={24}
                          sm={24}
                          md={24}
                          lg={24}
                          xl={24}
                          xxl={24}
                          order={0}
                          pull={0}
                          push={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              alignItems: "flex-start",
                              textAlign: "-webkit-left",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(22, 48, 32, 1)"
                            } as any
                          }
                        >
                          <Label
                            id="411767"
                            value={ReactSystemFunctions.translate(this.ml, 411767, "value", this.defaultML)}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                display: "inline",
                                fontFamily: "Poppins",
                                fontWeight: 400,
                                fontSize: "12px",
                                color: "rgba(186, 187, 187, 1)",
                                lineHeight: "20px"
                              } as any
                            }
                          ></Label>

                          <Form.Item className="kFormItem" name="siparisteslimdetaykart_267791_value">
                            <KSelectBox
                              id="267791"
                              editability={this.state.isComp267791Enabled}
                              kuikaRef={this.siparisteslimdetaykart_267791_value_kuikaSelectBoxRef}
                              options={this.state.TeslimTuruAll}
                              placeholder={ReactSystemFunctions.translate(
                                this.ml,
                                267791,
                                "placeholder",
                                this.defaultML
                              )}
                              allowClear={false}
                              autoClearSearchValue={true}
                              showSearch={false}
                              widthType="fill"
                              containsNullItem={false}
                              sortBy="none"
                              datavaluefield="id"
                              datatextfield="teslimTuru"
                              style={
                                {
                                  borderTopLeftRadius: 4,
                                  borderTopRightRadius: 4,
                                  borderBottomRightRadius: 4,
                                  borderBottomLeftRadius: 4,
                                  borderTopWidth: 1,
                                  borderRightWidth: 1,
                                  borderBottomWidth: 1,
                                  borderLeftWidth: 1,
                                  borderColor: "rgba(218, 218, 218, 1)",
                                  borderStyle: "solid",
                                  backgroundColor: "rgba(255, 255, 255, 1)",
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(22, 48, 32, 1)"
                                } as any
                              }
                            ></KSelectBox>
                          </Form.Item>
                        </KCol>
                      </KRow>

                      <KRow
                        id="354884"
                        align="top"
                        justify="start"
                        horizontalGutter={0}
                        verticalGutter={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 0,
                            paddingRight: 0,
                            paddingBottom: 0,
                            paddingLeft: 0,
                            alignItems: "flex-start",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(22, 48, 32, 1)"
                          } as any
                        }
                      >
                        <KCol
                          id="706748"
                          xs={24}
                          sm={24}
                          md={24}
                          lg={24}
                          xl={24}
                          xxl={24}
                          order={0}
                          pull={0}
                          push={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              alignItems: "flex-start",
                              textAlign: "-webkit-left",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(22, 48, 32, 1)"
                            } as any
                          }
                        >
                          <Label
                            id="93894"
                            value={ReactSystemFunctions.translate(this.ml, 93894, "value", this.defaultML)}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                display: "inline",
                                fontFamily: "Poppins",
                                fontWeight: 400,
                                fontSize: "12px",
                                color: "rgba(186, 187, 187, 1)",
                                lineHeight: "20px"
                              } as any
                            }
                          ></Label>

                          <Form.Item className="kFormItem" name="siparisteslimdetaykart_681452_value">
                            <KDatePicker
                              id="681452"
                              editability={this.state.isComp681452Enabled}
                              kuikaRef={this.siparisteslimdetaykart_681452_value_kuikaDateRef}
                              placeholder={ReactSystemFunctions.translate(
                                this.ml,
                                681452,
                                "placeholder",
                                this.defaultML
                              )}
                              allowClear={true}
                              format="DD/MM/YYYY"
                              style={
                                {
                                  borderTopLeftRadius: 4,
                                  borderTopRightRadius: 4,
                                  borderBottomRightRadius: 4,
                                  borderBottomLeftRadius: 4,
                                  borderTopWidth: 1,
                                  borderRightWidth: 1,
                                  borderBottomWidth: 1,
                                  borderLeftWidth: 1,
                                  borderColor: "rgba(218, 218, 218, 1)",
                                  borderStyle: "solid",
                                  backgroundColor: "rgba(255, 255, 255, 1)",
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(22, 48, 32, 1)"
                                } as any
                              }
                            ></KDatePicker>
                          </Form.Item>
                        </KCol>
                      </KRow>

                      <KRow
                        id="842084"
                        align="top"
                        justify="start"
                        horizontalGutter={0}
                        verticalGutter={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 0,
                            paddingRight: 0,
                            paddingBottom: 0,
                            paddingLeft: 0,
                            alignItems: "flex-start",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(22, 48, 32, 1)"
                          } as any
                        }
                      >
                        <KCol
                          id="825641"
                          xs={24}
                          sm={24}
                          md={24}
                          lg={24}
                          xl={24}
                          xxl={24}
                          order={0}
                          pull={0}
                          push={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              alignItems: "flex-start",
                              textAlign: "-webkit-left",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(22, 48, 32, 1)"
                            } as any
                          }
                        >
                          <Label
                            id="27617"
                            value={ReactSystemFunctions.translate(this.ml, 27617, "value", this.defaultML)}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                display: "inline",
                                fontFamily: "Poppins",
                                fontWeight: 400,
                                fontSize: "12px",
                                color: "rgba(186, 187, 187, 1)",
                                lineHeight: "20px"
                              } as any
                            }
                          ></Label>

                          <Form.Item className="kFormItem" name="siparisteslimdetaykart_588853_value">
                            <TextInput
                              id="588853"
                              editability={this.state.isComp588853Enabled}
                              placeholder={ReactSystemFunctions.translate(
                                this.ml,
                                588853,
                                "placeholder",
                                this.defaultML
                              )}
                              allowClear={false}
                              bordered={true}
                              disabled={false}
                              type="text"
                              iconColor="rgba(0, 0, 0, 1)"
                              formatter="None"
                              isCharOnly={false}
                              autoComplete={true}
                              inputMode="all"
                              style={
                                {
                                  borderTopLeftRadius: 4,
                                  borderTopRightRadius: 4,
                                  borderBottomRightRadius: 4,
                                  borderBottomLeftRadius: 4,
                                  borderTopWidth: 1,
                                  borderRightWidth: 1,
                                  borderBottomWidth: 1,
                                  borderLeftWidth: 1,
                                  borderColor: "rgba(218, 218, 218, 1)",
                                  borderStyle: "solid",
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(22, 48, 32, 1)"
                                } as any
                              }
                            ></TextInput>
                          </Form.Item>
                        </KCol>
                      </KRow>

                      <KRow
                        id="204608"
                        align="top"
                        justify="start"
                        horizontalGutter={0}
                        verticalGutter={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 0,
                            paddingRight: 0,
                            paddingBottom: 0,
                            paddingLeft: 0,
                            alignItems: "flex-start",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(22, 48, 32, 1)"
                          } as any
                        }
                      >
                        <KCol
                          id="408445"
                          xs={24}
                          sm={24}
                          md={24}
                          lg={24}
                          xl={24}
                          xxl={24}
                          order={0}
                          pull={0}
                          push={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              alignItems: "flex-start",
                              textAlign: "-webkit-left",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(22, 48, 32, 1)"
                            } as any
                          }
                        >
                          <Label
                            id="962760"
                            value={ReactSystemFunctions.translate(this.ml, 962760, "value", this.defaultML)}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                display: "inline",
                                fontFamily: "Poppins",
                                fontWeight: 400,
                                fontSize: "12px",
                                color: "rgba(186, 187, 187, 1)",
                                lineHeight: "20px"
                              } as any
                            }
                          ></Label>

                          <Form.Item className="kFormItem" name="siparisteslimdetaykart_380156_value">
                            <TextInput
                              id="380156"
                              editability={this.state.isComp380156Enabled}
                              placeholder={ReactSystemFunctions.translate(
                                this.ml,
                                380156,
                                "placeholder",
                                this.defaultML
                              )}
                              allowClear={false}
                              bordered={true}
                              disabled={false}
                              type="text"
                              iconColor="rgba(0, 0, 0, 1)"
                              formatter="None"
                              isCharOnly={false}
                              autoComplete={true}
                              inputMode="all"
                              style={
                                {
                                  borderTopLeftRadius: 4,
                                  borderTopRightRadius: 4,
                                  borderBottomRightRadius: 4,
                                  borderBottomLeftRadius: 4,
                                  borderTopWidth: 1,
                                  borderRightWidth: 1,
                                  borderBottomWidth: 1,
                                  borderLeftWidth: 1,
                                  borderColor: "rgba(218, 218, 218, 1)",
                                  borderStyle: "solid",
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(22, 48, 32, 1)"
                                } as any
                              }
                            ></TextInput>
                          </Form.Item>
                        </KCol>
                      </KRow>

                      <KRow
                        id="761325"
                        align="top"
                        justify="start"
                        horizontalGutter={0}
                        verticalGutter={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 0,
                            paddingRight: 0,
                            paddingBottom: 0,
                            paddingLeft: 0,
                            alignItems: "flex-start",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(22, 48, 32, 1)"
                          } as any
                        }
                      >
                        <KCol
                          id="585028"
                          xs={24}
                          sm={24}
                          md={24}
                          lg={24}
                          xl={24}
                          xxl={24}
                          order={0}
                          pull={0}
                          push={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              alignItems: "flex-start",
                              textAlign: "-webkit-left",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(22, 48, 32, 1)"
                            } as any
                          }
                        >
                          <Label
                            id="314617"
                            value={ReactSystemFunctions.translate(this.ml, 314617, "value", this.defaultML)}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                display: "inline",
                                fontFamily: "Poppins",
                                fontWeight: 400,
                                fontSize: "12px",
                                color: "rgba(186, 187, 187, 1)",
                                lineHeight: "20px"
                              } as any
                            }
                          ></Label>

                          <Form.Item className="kFormItem" name="siparisteslimdetaykart_599253_value">
                            <TextInput
                              id="599253"
                              editability={this.state.isComp599253Enabled}
                              placeholder={ReactSystemFunctions.translate(
                                this.ml,
                                599253,
                                "placeholder",
                                this.defaultML
                              )}
                              allowClear={false}
                              bordered={true}
                              disabled={false}
                              type="text"
                              iconColor="rgba(0, 0, 0, 1)"
                              formatter="None"
                              isCharOnly={false}
                              autoComplete={true}
                              inputMode="all"
                              style={
                                {
                                  borderTopLeftRadius: 4,
                                  borderTopRightRadius: 4,
                                  borderBottomRightRadius: 4,
                                  borderBottomLeftRadius: 4,
                                  borderTopWidth: 1,
                                  borderRightWidth: 1,
                                  borderBottomWidth: 1,
                                  borderLeftWidth: 1,
                                  borderColor: "rgba(218, 218, 218, 1)",
                                  borderStyle: "solid",
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(22, 48, 32, 1)"
                                } as any
                              }
                            ></TextInput>
                          </Form.Item>
                        </KCol>
                      </KRow>

                      <KRow
                        id="653002"
                        align="top"
                        justify="start"
                        horizontalGutter={0}
                        verticalGutter={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 0,
                            paddingRight: 0,
                            paddingBottom: 0,
                            paddingLeft: 0,
                            alignItems: "flex-start",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(22, 48, 32, 1)"
                          } as any
                        }
                      >
                        <KCol
                          id="278560"
                          xs={24}
                          sm={24}
                          md={24}
                          lg={24}
                          xl={24}
                          xxl={24}
                          order={0}
                          pull={0}
                          push={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              alignItems: "flex-start",
                              textAlign: "-webkit-left",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(22, 48, 32, 1)"
                            } as any
                          }
                        >
                          <HorizontalStack
                            id="866361"
                            direction="horizontal"
                            size={12}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(22, 48, 32, 1)"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="siparisteslimdetaykart_903181_value">
                              <Checkbox
                                id="903181"
                                editability={this.state.isComp903181Enabled}
                                children={ReactSystemFunctions.translate(this.ml, 903181, "children", this.defaultML)}
                                disabled={false}
                                style={
                                  {
                                    borderTopWidth: 1,
                                    borderRightWidth: 2,
                                    borderBottomWidth: 2,
                                    borderLeftWidth: 1,
                                    borderColor: "rgba(250, 173, 20, 1)",
                                    borderStyle: "solid",
                                    backgroundColor: "rgba(255, 255, 255, 1)",
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 0,
                                    paddingLeft: 0,
                                    alignItems: "flex-start",
                                    textAlign: "-webkit-left",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "16px",
                                    color: "rgba(255, 255, 255, 1)",
                                    lineHeight: "22px"
                                  } as any
                                }
                              ></Checkbox>
                            </Form.Item>

                            <Label
                              id="79918"
                              value={ReactSystemFunctions.translate(this.ml, 79918, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 0,
                                  paddingLeft: 0,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 400,
                                  fontSize: "12px",
                                  color: "rgba(186, 187, 187, 1)",
                                  lineHeight: "20px"
                                } as any
                              }
                            ></Label>
                          </HorizontalStack>
                        </KCol>
                      </KRow>

                      <KRow
                        id="631358"
                        visibility={this.state.isComp631358Visible}
                        visibilityCondition={(rowData: object) =>
                          ReactSystemFunctions.isEqualTo(
                            this.state.SiparisTeslimKartById[0]?.isRowTeslimAlanVisibility?.toString(),
                            "1"
                          )
                        }
                        align="top"
                        justify="start"
                        horizontalGutter={0}
                        verticalGutter={0}
                        style={
                          {
                            borderTopWidth: 1,
                            borderColor: "rgba(250, 173, 20, 1)",
                            borderStyle: "solid",
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 0,
                            paddingRight: 0,
                            paddingBottom: 0,
                            paddingLeft: 0,
                            alignItems: "flex-start",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(22, 48, 32, 1)"
                          } as any
                        }
                      >
                        <KCol
                          id="283658"
                          xs={24}
                          sm={24}
                          md={24}
                          lg={24}
                          xl={24}
                          xxl={24}
                          order={0}
                          pull={0}
                          push={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0
                            } as any
                          }
                        >
                          <KRow
                            id="306454"
                            align="top"
                            justify="start"
                            horizontalGutter={0}
                            verticalGutter={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 8,
                                paddingRight: 0,
                                paddingBottom: 8,
                                paddingLeft: 0,
                                alignItems: "flex-start",
                                textAlign: "-webkit-left",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(22, 48, 32, 1)"
                              } as any
                            }
                          >
                            <KCol
                              id="64709"
                              xs={24}
                              sm={24}
                              md={24}
                              lg={24}
                              xl={24}
                              xxl={24}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "flex-start",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(22, 48, 32, 1)"
                                } as any
                              }
                            >
                              <HorizontalStack
                                id="391223"
                                direction="horizontal"
                                size={12}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 0,
                                    paddingLeft: 0,
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <Form.Item className="kFormItem" name="siparisteslimdetaykart_349002_value">
                                  <Checkbox
                                    id="349002"
                                    onChange={(e?: any) => {
                                      if (e && e.stopPropagation) e.stopPropagation();
                                      this.SiparisTeslimDetayKartComponent_349002_onChange();
                                    }}
                                    children={ReactSystemFunctions.translate(
                                      this.ml,
                                      349002,
                                      "children",
                                      this.defaultML
                                    )}
                                    disabled={false}
                                    style={
                                      {
                                        borderTopWidth: 1,
                                        borderRightWidth: 2,
                                        borderBottomWidth: 2,
                                        borderLeftWidth: 1,
                                        borderColor: "rgba(250, 173, 20, 1)",
                                        borderStyle: "solid",
                                        backgroundColor: "rgba(255, 255, 255, 1)",
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 0,
                                        paddingLeft: 0,
                                        alignItems: "flex-start",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "16px",
                                        color: "rgba(255, 255, 255, 1)",
                                        lineHeight: "22px"
                                      } as any
                                    }
                                  ></Checkbox>
                                </Form.Item>

                                <Label
                                  id="908924"
                                  value={ReactSystemFunctions.translate(this.ml, 908924, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 400,
                                      fontSize: "12px",
                                      color: "rgba(186, 187, 187, 1)",
                                      lineHeight: "20px"
                                    } as any
                                  }
                                ></Label>
                              </HorizontalStack>
                            </KCol>
                          </KRow>

                          <KRow
                            id="627448"
                            align="top"
                            justify="start"
                            horizontalGutter={0}
                            verticalGutter={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                alignItems: "flex-start",
                                textAlign: "-webkit-left",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(22, 48, 32, 1)"
                              } as any
                            }
                          >
                            <KCol
                              id="993838"
                              xs={24}
                              sm={24}
                              md={24}
                              lg={24}
                              xl={24}
                              xxl={24}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 0,
                                  paddingLeft: 0,
                                  alignItems: "flex-start",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(22, 48, 32, 1)"
                                } as any
                              }
                            >
                              <Label
                                id="312554"
                                value={ReactSystemFunctions.translate(this.ml, 312554, "value", this.defaultML)}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 0,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 400,
                                    fontSize: "12px",
                                    color: "rgba(186, 187, 187, 1)",
                                    lineHeight: "20px"
                                  } as any
                                }
                              ></Label>

                              <Form.Item className="kFormItem" name="siparisteslimdetaykart_932622_value">
                                <KDatePicker
                                  id="932622"
                                  editability={this.state.isComp932622Enabled}
                                  kuikaRef={this.siparisteslimdetaykart_932622_value_kuikaDateRef}
                                  placeholder={ReactSystemFunctions.translate(
                                    this.ml,
                                    932622,
                                    "placeholder",
                                    this.defaultML
                                  )}
                                  allowClear={true}
                                  format="DD/MM/YYYY"
                                  disableBeforeDate={
                                    this.state.SiparisTeslimKartById?.at?.(0)?.kargoTarihi ?? undefined
                                  }
                                  style={
                                    {
                                      borderTopLeftRadius: 4,
                                      borderTopRightRadius: 4,
                                      borderBottomRightRadius: 4,
                                      borderBottomLeftRadius: 4,
                                      borderTopWidth: 1,
                                      borderRightWidth: 1,
                                      borderBottomWidth: 1,
                                      borderLeftWidth: 1,
                                      borderColor: "rgba(218, 218, 218, 1)",
                                      borderStyle: "solid",
                                      backgroundColor: "rgba(255, 255, 255, 1)",
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      paddingTop: 8,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "block",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                ></KDatePicker>
                              </Form.Item>
                            </KCol>
                          </KRow>

                          <KRow
                            id="120668"
                            visibility={this.state.isComp120668Visible}
                            align="top"
                            justify="start"
                            horizontalGutter={0}
                            verticalGutter={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundColor: "rgba(217, 217, 217, 0.16)",
                                paddingTop: 0,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                alignItems: "flex-start",
                                textAlign: "-webkit-left",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(22, 48, 32, 1)"
                              } as any
                            }
                          >
                            <KCol
                              id="341068"
                              xs={24}
                              sm={24}
                              md={24}
                              lg={24}
                              xl={24}
                              xxl={24}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "flex-start",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(22, 48, 32, 1)"
                                } as any
                              }
                            >
                              <VerticalStack
                                id="539147"
                                direction="vertical"
                                size={1}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 0,
                                    paddingLeft: 0,
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="390802"
                                  value={ReactSystemFunctions.translate(this.ml, 390802, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 400,
                                      fontSize: "12px",
                                      color: "rgba(186, 187, 187, 1)",
                                      lineHeight: "20px"
                                    } as any
                                  }
                                ></Label>

                                <Label
                                  id="937554"
                                  value={this.state.SiparisTeslimKartById?.at?.(0)?.fullName ?? undefined}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(140, 140, 140, 1)"
                                    } as any
                                  }
                                ></Label>
                              </VerticalStack>
                            </KCol>
                          </KRow>
                        </KCol>
                      </KRow>

                      <KRow
                        id="638119"
                        align="top"
                        justify="start"
                        horizontalGutter={0}
                        verticalGutter={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 0,
                            paddingRight: 0,
                            paddingBottom: 0,
                            paddingLeft: 0,
                            alignItems: "flex-start",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(22, 48, 32, 1)"
                          } as any
                        }
                      >
                        <KCol
                          id="634646"
                          xs={24}
                          sm={24}
                          md={24}
                          lg={24}
                          xl={24}
                          xxl={24}
                          order={0}
                          pull={0}
                          push={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              alignItems: "flex-start",
                              textAlign: "-webkit-right",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(22, 48, 32, 1)"
                            } as any
                          }
                        >
                          <HorizontalStack
                            id="436052"
                            direction="horizontal"
                            size={12}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                alignItems: "center",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(22, 48, 32, 1)"
                              } as any
                            }
                          >
                            <Button
                              id="890290"
                              onClick={(e?: any) => {
                                if (e && e.stopPropagation) e.stopPropagation();
                                this.SiparisTeslimDetayKartComponent_890290_onClick();
                              }}
                              showCursorPointer
                              label={ReactSystemFunctions.translate(this.ml, 890290, "label", this.defaultML)}
                              size="middle"
                              loading={false}
                              ghost={false}
                              block={false}
                              htmlType="button"
                              iconPosition="left"
                              danger={false}
                              style={
                                {
                                  borderTopLeftRadius: 4,
                                  borderTopRightRadius: 4,
                                  borderBottomRightRadius: 4,
                                  borderBottomLeftRadius: 4,
                                  borderTopWidth: 1,
                                  borderRightWidth: 1,
                                  borderBottomWidth: 1,
                                  borderLeftWidth: 1,
                                  borderColor: "rgba(0, 167, 229, 1)",
                                  borderStyle: "solid",
                                  backgroundColor: "rgba(255, 255, 255, 1)",
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  paddingTop: 8,
                                  paddingRight: 16,
                                  paddingBottom: 8,
                                  paddingLeft: 16,
                                  alignItems: "center",
                                  textAlign: "-webkit-center",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "16px",
                                  color: "rgba(2, 145, 201, 1)",
                                  lineHeight: "22px"
                                } as any
                              }
                            ></Button>

                            <Label
                              id="222840"
                              visibility={this.state.isComp222840Visible}
                              value={ReactSystemFunctions.translate(this.ml, 222840, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 0,
                                  paddingLeft: 0,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(22, 48, 32, 1)"
                                } as any
                              }
                            ></Label>

                            <Button
                              id="378775"
                              onClick={(e?: any) => {
                                if (e && e.stopPropagation) e.stopPropagation();
                                this.SiparisTeslimDetayKartComponent_378775_onClick();
                              }}
                              showCursorPointer
                              visibility={this.state.isComp378775Visible}
                              visibilityCondition={(rowData: object) =>
                                ReactSystemFunctions.isEqualTo(
                                  this.state.MyContext[0]?.muhatapMiFirmaMi?.toString(),
                                  "Firma"
                                )
                              }
                              label={ReactSystemFunctions.translate(this.ml, 378775, "label", this.defaultML)}
                              size="middle"
                              loading={false}
                              ghost={false}
                              block={false}
                              htmlType="button"
                              iconPosition="left"
                              danger={false}
                              style={
                                {
                                  borderTopLeftRadius: 4,
                                  borderTopRightRadius: 4,
                                  borderBottomRightRadius: 4,
                                  borderBottomLeftRadius: 4,
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundColor: "rgba(2, 145, 201, 1)",
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  paddingTop: 8,
                                  paddingRight: 16,
                                  paddingBottom: 8,
                                  paddingLeft: 16,
                                  alignItems: "center",
                                  textAlign: "-webkit-center",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "16px",
                                  color: "rgba(255, 255, 255, 1)",
                                  lineHeight: "22px"
                                } as any
                              }
                            ></Button>

                            <Label
                              id="707238"
                              visibility={this.state.isComp707238Visible}
                              value={ReactSystemFunctions.translate(this.ml, 707238, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 0,
                                  paddingLeft: 0,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(22, 48, 32, 1)"
                                } as any
                              }
                            ></Label>

                            <Button
                              id="941117"
                              onClick={(e?: any) => {
                                if (e && e.stopPropagation) e.stopPropagation();
                                this.SiparisTeslimDetayKartComponent_941117_onClick();
                              }}
                              showCursorPointer
                              visibility={this.state.isComp941117Visible}
                              visibilityCondition={(rowData: object) =>
                                ReactSystemFunctions.isEqualTo(
                                  this.state.MyContext[0]?.muhatapMiFirmaMi?.toString(),
                                  "Muhatap"
                                )
                              }
                              label={ReactSystemFunctions.translate(this.ml, 941117, "label", this.defaultML)}
                              size="middle"
                              loading={false}
                              ghost={false}
                              block={false}
                              htmlType="button"
                              iconPosition="left"
                              danger={false}
                              style={
                                {
                                  borderTopLeftRadius: 4,
                                  borderTopRightRadius: 4,
                                  borderBottomRightRadius: 4,
                                  borderBottomLeftRadius: 4,
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundColor: "rgba(2, 145, 201, 1)",
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  paddingTop: 8,
                                  paddingRight: 16,
                                  paddingBottom: 8,
                                  paddingLeft: 16,
                                  alignItems: "center",
                                  textAlign: "-webkit-center",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "16px",
                                  color: "rgba(255, 255, 255, 1)",
                                  lineHeight: "22px"
                                } as any
                              }
                            ></Button>
                          </HorizontalStack>
                        </KCol>
                      </KRow>
                    </KCol>
                  </KRow>
                </KCol>
              </KRow>
            </div>
          </Form>
        </Spin>
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  return {
    spinnerCount: state.spinnerReducer.spinnerCount,
    photoTakeVisible: state.photoTakeReducer.visible,
    isImportMappedExcelModalVisible: state.importMappedExcelModalReducer.visible
  };
};
const mapDispatchToProps = (dispatch) => ({
  showPhotoTake: () => dispatch(showPhotoTake()),
  hidePhotoTake: () => dispatch(hidePhotoTake()),
  showStripeDrawer: (data, callback, that, future) => dispatch(showStripeDrawer(data, callback, that, future)),
  hideStripeDrawer: (data, callback, that) => dispatch(hideStripeDrawer(data, callback, that)),
  showIyzicoDrawer: (data, callback, that) => dispatch(showIyzicoDrawer(data, callback, that)),
  hideIyzicoDrawer: (data, callback, that) => dispatch(hideIyzicoDrawer(data, callback, that)),
  showImportMappedExcelModal: (dsId, tableName, primaryKey, columnMapping) =>
    dispatch(showImportMappedExcelModal(dsId, tableName, primaryKey, columnMapping)),
  hideImportMappedExcelModal: () => dispatch(hideImportMappedExcelModal())
});
const tmp = withGoogleLogin(
  withContext(
    withScreenInput(withForm(withHistory(connect(mapStateToProps, mapDispatchToProps)(SiparisTeslimDetayKart_Screen))))
  )
);
export { tmp as SiparisTeslimDetayKart_Screen };
//export default tmp;
//export { tmp as SiparisTeslimDetayKart_Screen };
