import { createRoot } from "react-dom/client";
import { AppBootstrapper, IAppMode, IAuthType } from "./bootstrapper";
import { IRouterType } from "./routes/router";
import { KMainFunctions } from "./shared/utilty/main-functions";
import WebComponentInit from "./web-component-init";

KMainFunctions.ignoreKnownConsoleErrors();
KMainFunctions.ignoreKnownConsoleWarns();

const owner = document.getElementById("meteksan-pop");
if (owner) {
  const container = document.getElementById("meteksan-pop");

  const root = createRoot(container!);

  const authType: IAuthType = "Kuika";
  const routerType: IRouterType = "BrowserRouter";
  const appMode: IAppMode = "WebSite";

  root.render(<AppBootstrapper authType={authType} routerType={routerType} appMode={appMode} />);

  if ((module as any).hot) {
    (module as any).hot.accept();
  }
} else {
  WebComponentInit();
}

const workspace = "meteksan";
const app = "pop";
const webComponentTag = "meteksan-pop";
export { app, webComponentTag, workspace };
