import { FormInstance } from "antd/es/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IEserDetayIcBaskiRenk_List_Form_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IEserDetayIcBaskiRenk_List_Form_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GoBack: any;
  GoBack_dummy: any;
  EserDetay_IcBaskiRenkSelectById: any[];
  EserDetay_IcBaskiRenkSelectById_dummy: any[];
  EserDetay_IcBaskiRenkSave: number;
  EserDetay_IcBaskiRenkSave_dummy: number;
  isComp8291959Visible: "visible" | "hidden";
}

export class EserDetayIcBaskiRenk_List_Form_ScreenBase extends React.PureComponent<
  IEserDetayIcBaskiRenk_List_Form_ScreenProps,
  any
> {
  ml = [
    {
      Id: "41cd36a4-5b07-cfca-4f7f-f48019ff4ff3",
      Name: "tr_TR",
      ShortName: "Türkçe",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "0069cee9-16ca-4a5a-aab9-dd58af96ca96", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "317b2c26-bd17-4d91-9af1-6caa1bf66f68",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 796540, PropertyName: "value", Value: "İç Baskı Renk" },
        { Id: 1770483, PropertyName: "value", Value: "İç Baskı Renk" },
        { Id: 4495830, PropertyName: "value", Value: "Ic baski renk key" },
        { Id: 523229, PropertyName: "value", Value: "Aktif" },
        { Id: 9544, PropertyName: "label", Value: "Kaydet" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GoBack: "",
      EserDetay_IcBaskiRenkSelectById: [],
      EserDetay_IcBaskiRenkSave: 0,
      isComp8291959Visible: "hidden"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("eserdetayicbaskirenk_list_form", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.EserDetayIcBaskiRenk_List_FormPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("eserdetayicbaskirenk_list_form", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("eserdetayicbaskirenk_list_form", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.EserDetayIcBaskiRenk_List_FormPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      eserdetayicbaskirenk_list_form_5028322_value:
        this.state.EserDetay_IcBaskiRenkSelectById?.at?.(0)?.icBaskiRenk ?? undefined,
      eserdetayicbaskirenk_list_form_3121104_value:
        this.state.EserDetay_IcBaskiRenkSelectById?.at?.(0)?.icBaskiRenkKey ?? undefined,
      eserdetayicbaskirenk_list_form_875501_value:
        this.state.EserDetay_IcBaskiRenkSelectById?.at?.(0)?.aktifMi ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  EserDetayIcBaskiRenk_List_FormPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.Id ?? this.props.screenInputs.id, "Guid")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EserDetayIcBaskiRenk_List_Form/EserDetayIcBaskiRenk_List_FormPageInit",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.EserDetay_IcBaskiRenkSelectById = result?.data.eserDetay_IcBaskiRenkSelectById;
    formVars.eserdetayicbaskirenk_list_form_5028322_value = ReactSystemFunctions.toString(
      this,
      stateVars.EserDetay_IcBaskiRenkSelectById?.length > 0
        ? stateVars.EserDetay_IcBaskiRenkSelectById[0].icBaskiRenk
        : this.state.EserDetay_IcBaskiRenkSelectById?.length > 0
        ? this.state.EserDetay_IcBaskiRenkSelectById[0].icBaskiRenk
        : null
    );
    formVars.eserdetayicbaskirenk_list_form_3121104_value = ReactSystemFunctions.toString(
      this,
      stateVars.EserDetay_IcBaskiRenkSelectById?.length > 0
        ? stateVars.EserDetay_IcBaskiRenkSelectById[0].icBaskiRenkKey
        : this.state.EserDetay_IcBaskiRenkSelectById?.length > 0
        ? this.state.EserDetay_IcBaskiRenkSelectById[0].icBaskiRenkKey
        : null
    );
    formVars.eserdetayicbaskirenk_list_form_875501_value = ReactSystemFunctions.value(
      this,
      stateVars.EserDetay_IcBaskiRenkSelectById?.length > 0
        ? stateVars.EserDetay_IcBaskiRenkSelectById[0].aktifMi
        : this.state.EserDetay_IcBaskiRenkSelectById?.length > 0
        ? this.state.EserDetay_IcBaskiRenkSelectById[0].aktifMi
        : null
    );
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EserDetayIcBaskiRenk_List_FormPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EserDetayIcBaskiRenk_List_FormPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.eserdetayicbaskirenk_list_form_5028322_value = ReactSystemFunctions.toString(
      this,
      stateVars.EserDetay_IcBaskiRenkSelectById?.length > 0
        ? stateVars.EserDetay_IcBaskiRenkSelectById[0].icBaskiRenk
        : this.state.EserDetay_IcBaskiRenkSelectById?.length > 0
        ? this.state.EserDetay_IcBaskiRenkSelectById[0].icBaskiRenk
        : null
    );

    formVars.eserdetayicbaskirenk_list_form_3121104_value = ReactSystemFunctions.toString(
      this,
      stateVars.EserDetay_IcBaskiRenkSelectById?.length > 0
        ? stateVars.EserDetay_IcBaskiRenkSelectById[0].icBaskiRenkKey
        : this.state.EserDetay_IcBaskiRenkSelectById?.length > 0
        ? this.state.EserDetay_IcBaskiRenkSelectById[0].icBaskiRenkKey
        : null
    );

    formVars.eserdetayicbaskirenk_list_form_875501_value = ReactSystemFunctions.value(
      this,
      stateVars.EserDetay_IcBaskiRenkSelectById?.length > 0
        ? stateVars.EserDetay_IcBaskiRenkSelectById[0].aktifMi
        : this.state.EserDetay_IcBaskiRenkSelectById?.length > 0
        ? this.state.EserDetay_IcBaskiRenkSelectById[0].aktifMi
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  EserDetayIcBaskiRenk_List_FormComponent_968274_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EserDetayIcBaskiRenk_List_FormComponent_9544_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      AktifMi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "eserdetayicbaskirenk_list_form_875501_value", "value", "", "", "")
        ),
        "boolean"
      ),
      IcBaskiRenk_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "eserdetayicbaskirenk_list_form_5028322_value", "value", "", "", "")
        ),
        "string"
      ),
      IcBaskiRenkKey_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "eserdetayicbaskirenk_list_form_3121104_value", "value", "", "", "")
        ),
        "number"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.Id ?? this.props.screenInputs.id, "Guid")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EserDetayIcBaskiRenk_List_Form/EserDetayIcBaskiRenk_List_FormComponent_9544_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.EserDetay_IcBaskiRenkSave = result?.data.eserDetay_IcBaskiRenkSave;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EserDetayIcBaskiRenk_List_FormComponent_9544_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EserDetayIcBaskiRenk_List_FormComponent_9544_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
