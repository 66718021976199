import { FormInstance } from "antd/es/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface ITeklifVerme_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface ITeklifVerme_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  Logout: boolean;
  Logout_dummy: boolean;
  goToSignInActivity: any;
  goToSignInActivity_dummy: any;
}

export class TeklifVerme_ScreenBase extends React.PureComponent<ITeklifVerme_ScreenProps, any> {
  teklifverme_120403_value_kuikaDateRef: React.RefObject<any>;
  teklifverme_334367_value_kuikaDateRef: React.RefObject<any>;
  teklifverme_359002_value_kuikaTableRef: React.RefObject<any>;
  teklifverme_339179_value_kuikaPaginationRef: React.RefObject<any>;
  teklifverme_392511_value_kuikaTableRef: React.RefObject<any>;
  teklifverme_141532_value_kuikaPaginationRef: React.RefObject<any>;
  teklifverme_867802_value_kuikaTableRef: React.RefObject<any>;
  teklifverme_94486_value_kuikaPaginationRef: React.RefObject<any>;
  teklifverme_443260_value_kuikaTableRef: React.RefObject<any>;
  teklifverme_496395_value_kuikaPaginationRef: React.RefObject<any>;
  ml = [
    {
      Id: "41cd36a4-5b07-cfca-4f7f-f48019ff4ff3",
      Name: "tr_TR",
      ShortName: "Türkçe",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "0069cee9-16ca-4a5a-aab9-dd58af96ca96", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "7197f8f7-78bc-4791-b996-f8eef5b55a93",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 172357, PropertyName: "value", Value: "Ali Mavişehir" },
        { Id: 412428, PropertyName: "label", Value: "Profil" },
        { Id: 668195, PropertyName: "label", Value: "Çıkış Yap" },
        { Id: 948392, PropertyName: "tab", Value: "Teklif Verme" },
        { Id: 248310, PropertyName: "value", Value: "Teklif Muhatabı" },
        { Id: 527689, PropertyName: "value", Value: "Teklif Tarihi" },
        { Id: 951956, PropertyName: "value", Value: "Para Birimi" },
        { Id: 716627, PropertyName: "value", Value: "Teklif Fiyatı" },
        { Id: 102531, PropertyName: "value", Value: "Teklif Geçerlilik Süresi" },
        { Id: 142952, PropertyName: "value", Value: "İndirimler" },
        { Id: 453457, PropertyName: "value", Value: "Baskı Cinsi (Ebat, Kapak, Cilt, Teslim Türü vb. bilgiler)" },
        { Id: 866736, PropertyName: "value", Value: "Notlar" },
        { Id: 127588, PropertyName: "value", Value: "Ekler" },
        { Id: 887191, PropertyName: "label", Value: "Taslak Olarak Kaydet" },
        { Id: 78626, PropertyName: "label", Value: "Onaya Gönder" },
        { Id: 259559, PropertyName: "tab", Value: "Taslaklar" },
        { Id: 359002, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 763328, PropertyName: "title", Value: "Tarih" },
        { Id: 730632, PropertyName: "value", Value: "3.06.2023" },
        { Id: 808314, PropertyName: "title", Value: "Firma" },
        { Id: 366933, PropertyName: "value", Value: "ABC Matbaa" },
        { Id: 1842, PropertyName: "title", Value: "Teklif Muhattabı" },
        { Id: 749682, PropertyName: "value", Value: "Ahmet Yılmaz" },
        { Id: 251016, PropertyName: "title", Value: "Ebat" },
        { Id: 138659, PropertyName: "value", Value: "21x20" },
        { Id: 290848, PropertyName: "title", Value: "Miktar" },
        { Id: 199258, PropertyName: "value", Value: "2000 Adet" },
        { Id: 676939, PropertyName: "title", Value: "Teslim Türü" },
        { Id: 403082, PropertyName: "value", Value: "Kargo" },
        { Id: 440682, PropertyName: "title", Value: "Notlar" },
        { Id: 706641, PropertyName: "value", Value: "Detaylı notlar" },
        { Id: 568491, PropertyName: "tab", Value: "Verdiğim Teklifler" },
        { Id: 392511, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 107055, PropertyName: "title", Value: "Talep Tarihi" },
        { Id: 924962, PropertyName: "value", Value: "3.06.2023" },
        { Id: 786359, PropertyName: "title", Value: "Teklif No" },
        { Id: 426698, PropertyName: "value", Value: "9002002" },
        { Id: 348356, PropertyName: "title", Value: "Durum" },
        { Id: 682238, PropertyName: "value", Value: "Müşteri Onayladı" },
        { Id: 151355, PropertyName: "title", Value: "Teklif Tarihi" },
        { Id: 63727, PropertyName: "value", Value: "3.06.2023" },
        { Id: 526151, PropertyName: "title", Value: "Firma" },
        { Id: 736308, PropertyName: "value", Value: "ABC Matbaa" },
        { Id: 383769, PropertyName: "title", Value: "Teklif Muhattabı" },
        { Id: 95347, PropertyName: "value", Value: "Ahmet Yılmaz" },
        { Id: 671955, PropertyName: "title", Value: "Ebat" },
        { Id: 479274, PropertyName: "value", Value: "21x25" },
        { Id: 136582, PropertyName: "title", Value: "Miktar" },
        { Id: 941084, PropertyName: "value", Value: "2100 Adet" },
        { Id: 201559, PropertyName: "title", Value: "Teklif Fiyatı" },
        { Id: 530319, PropertyName: "value", Value: "3,000 TRY" },
        { Id: 127590, PropertyName: "title", Value: "Geçerlilik Sonu" },
        { Id: 498283, PropertyName: "value", Value: "30.06.2023" },
        { Id: 719982, PropertyName: "title", Value: "Teslim Türü" },
        { Id: 636424, PropertyName: "value", Value: "Kargo" },
        { Id: 992575, PropertyName: "title", Value: "Firma Onay" },
        { Id: 549190, PropertyName: "value", Value: "Onaylandı" },
        { Id: 907427, PropertyName: "tab", Value: "Onaylar" },
        { Id: 74309, PropertyName: "value", Value: "Onayımda Bekleyenler" },
        { Id: 867802, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 836657, PropertyName: "title", Value: "Talep Tarihi" },
        { Id: 6209, PropertyName: "value", Value: "3.06.2023" },
        { Id: 810813, PropertyName: "title", Value: "Teklif No" },
        { Id: 881521, PropertyName: "value", Value: "3.06.2023" },
        { Id: 416783, PropertyName: "title", Value: "Teklif Tarihi" },
        { Id: 91762, PropertyName: "value", Value: "3.06.2023" },
        { Id: 830935, PropertyName: "title", Value: "Firma" },
        { Id: 295495, PropertyName: "value", Value: "ABC Matbaa" },
        { Id: 614318, PropertyName: "title", Value: "Teklif Muhattabı" },
        { Id: 353810, PropertyName: "value", Value: "Ahmet Yılmaz" },
        { Id: 848148, PropertyName: "title", Value: "Ebat" },
        { Id: 552503, PropertyName: "value", Value: "21x20" },
        { Id: 686281, PropertyName: "title", Value: "Miktar" },
        { Id: 320205, PropertyName: "value", Value: "2000 Adet" },
        { Id: 659938, PropertyName: "title", Value: "Teklif Fiyatı" },
        { Id: 693096, PropertyName: "value", Value: "1,500 USD" },
        { Id: 324848, PropertyName: "title", Value: "Geçerlilik Sonu" },
        { Id: 492950, PropertyName: "value", Value: "30.06.2023" },
        { Id: 459624, PropertyName: "title", Value: "Teslim Türü" },
        { Id: 560434, PropertyName: "value", Value: "Kargo" },
        { Id: 3246, PropertyName: "title", Value: "Onay" },
        { Id: 670230, PropertyName: "label", Value: "Onayla" },
        { Id: 880707, PropertyName: "value", Value: "Bekleyen Teklif Talepleri" },
        { Id: 443260, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 256070, PropertyName: "title", Value: "Talep Tarihi" },
        { Id: 767727, PropertyName: "value", Value: "3.06.2023" },
        { Id: 439621, PropertyName: "title", Value: "Firma" },
        { Id: 939910, PropertyName: "value", Value: "ABC Matbaa" },
        { Id: 211907, PropertyName: "title", Value: "Teklif Muhattabı" },
        { Id: 102518, PropertyName: "value", Value: "Ahmet Yılmaz" },
        { Id: 689966, PropertyName: "title", Value: "Ebat" },
        { Id: 530428, PropertyName: "value", Value: "21x20" },
        { Id: 352944, PropertyName: "title", Value: "Miktar" },
        { Id: 944148, PropertyName: "value", Value: "2000 Adet" },
        { Id: 455135, PropertyName: "title", Value: "Teslim Türü" },
        { Id: 325115, PropertyName: "value", Value: "Kargo" },
        { Id: 336983, PropertyName: "title", Value: "Notlar" },
        { Id: 185778, PropertyName: "value", Value: "Detaylı Notlar" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.teklifverme_120403_value_kuikaDateRef = React.createRef();
    this.teklifverme_334367_value_kuikaDateRef = React.createRef();
    this.teklifverme_359002_value_kuikaTableRef = React.createRef();
    this.teklifverme_339179_value_kuikaPaginationRef = React.createRef();
    this.teklifverme_392511_value_kuikaTableRef = React.createRef();
    this.teklifverme_141532_value_kuikaPaginationRef = React.createRef();
    this.teklifverme_867802_value_kuikaTableRef = React.createRef();
    this.teklifverme_94486_value_kuikaPaginationRef = React.createRef();
    this.teklifverme_443260_value_kuikaTableRef = React.createRef();
    this.teklifverme_496395_value_kuikaPaginationRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      NAVIGATE: "",
      Logout: false,
      goToSignInActivity: ""
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("teklifverme", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
    }

    KuikaAppManager.calculateAndSetBodyHeight("teklifverme", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("teklifverme", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      teklifverme_209917_value: undefined,
      teklifverme_416280_value: undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  checkTabErrors = () => {
    document.querySelectorAll(".rc-tabs-tab").forEach((tab) => {
      (tab as HTMLElement).style.color = "";
    });

    const tabPanes = document.querySelectorAll(".rc-tabs-tabpane");
    const tabs = document.querySelectorAll(".rc-tabs-tab");

    tabPanes.forEach((tabPane, index) => {
      const uniquePaneId = `rc-tabs-tabpane-${index}`;
      if (!tabPane.hasAttribute("id")) {
        tabPane.setAttribute("id", uniquePaneId);
      }

      const uniqueTabId = `rc-tabs-tab-${index}`;
      if (tabs[index]) {
        if (!tabs[index].hasAttribute("id")) {
          tabs[index].setAttribute("id", uniqueTabId);
        }
        tabs[index].setAttribute("aria-controls", uniquePaneId);
      }
    });

    const errorElements = document.querySelectorAll(".rc-tabs .ant-form-item-explain-error");

    const tabsWithErrors = new Set<string>();

    errorElements.forEach((errorElement) => {
      const tabPane = errorElement.closest(".rc-tabs-tabpane");
      if (tabPane && tabPane.id) {
        tabsWithErrors.add(tabPane.id);
      }
    });

    tabsWithErrors.forEach((tabPaneId) => {
      const tab = document.querySelector(`.rc-tabs-tab[aria-controls="${tabPaneId}"]`);
      if (tab) {
        (tab as HTMLElement).style.color = "red";
      }
    });
  };

  TeklifVermeComponent_54788_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "TeklifVerme",
      "LeftMenu",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "242761",
      null,
      "left",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TeklifVermeComponent_412428_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "TeklifVerme",
      "Profil",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TeklifVermeComponent_668195_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.Logout = await ReactSystemFunctions.logout();
    stateVars.tmpBoolResult = await ReactSystemFunctions.goToSignInActivity(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [242761] as number[];
  }
}
