import { FormInstance } from "antd/es/form";
import { Guid } from "guid-typescript";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface ISiparisTeslimDetayKart_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface ISiparisTeslimDetayKart_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  MyContext: any[];
  MyContext_dummy: any[];
  TeslimTuruAll: any[];
  TeslimTuruAll_dummy: any[];
  SiparisTeslimKartById: any[];
  SiparisTeslimKartById_dummy: any[];
  EserBilgileri_TeklifEdenKisiEmail: any[];
  EserBilgileri_TeklifEdenKisiEmail_dummy: any[];
  TeklifEdenSirketAd: any[];
  TeklifEdenSirketAd_dummy: any[];
  EserBilgileri_TeklifTalepEdenEmail: any[];
  EserBilgileri_TeklifTalepEdenEmail_dummy: any[];
  TeklifAlanSirketAd: any[];
  TeklifAlanSirketAd_dummy: any[];
  GenelParametreler: any[];
  GenelParametreler_dummy: any[];
  ChangeEnabledOf: any;
  ChangeEnabledOf_dummy: any;
  GoBack: any;
  GoBack_dummy: any;
  SiparisTeslimKartSave: number;
  SiparisTeslimKartSave_dummy: number;
  SiparisTeslimAlanUpdate: number;
  SiparisTeslimAlanUpdate_dummy: number;
  SendMailSettings: any[];
  SendMailSettings_dummy: any[];
  SendMail: boolean;
  SendMail_dummy: boolean;
  isComp631358Visible: "visible" | "hidden";
  isComp120668Visible: "visible" | "hidden";
  isComp222840Visible: "visible" | "hidden";
  isComp378775Visible: "visible" | "hidden";
  isComp707238Visible: "visible" | "hidden";
  isComp941117Visible: "visible" | "hidden";
  isComp588853Enabled: "enabled" | "disabled";
  isComp267791Enabled: "enabled" | "disabled";
  isComp380156Enabled: "enabled" | "disabled";
  isComp681452Enabled: "enabled" | "disabled";
  isComp599253Enabled: "enabled" | "disabled";
  isComp903181Enabled: "enabled" | "disabled";
  isComp932622Enabled: "enabled" | "disabled";
}

export class SiparisTeslimDetayKart_ScreenBase extends React.PureComponent<ISiparisTeslimDetayKart_ScreenProps, any> {
  siparisteslimdetaykart_267791_value_kuikaSelectBoxRef: React.RefObject<any>;
  siparisteslimdetaykart_681452_value_kuikaDateRef: React.RefObject<any>;
  siparisteslimdetaykart_932622_value_kuikaDateRef: React.RefObject<any>;
  ml = [
    {
      Id: "41cd36a4-5b07-cfca-4f7f-f48019ff4ff3",
      Name: "tr_TR",
      ShortName: "Türkçe",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "0069cee9-16ca-4a5a-aab9-dd58af96ca96", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "c0d06355-c2e6-4a2b-b886-6315d36f8b7f",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 268500, PropertyName: "value", Value: "Sipariş Teslim Bilgileri" },
        { Id: 411767, PropertyName: "value", Value: "Teslimat Şekli" },
        { Id: 267791, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 93894, PropertyName: "value", Value: "Teslim / Kargolama Tarihi" },
        { Id: 681452, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 27617, PropertyName: "value", Value: "İrsaliye No" },
        { Id: 588853, PropertyName: "placeholder", Value: "Giriniz..." },
        { Id: 962760, PropertyName: "value", Value: "Kargo Firma" },
        { Id: 380156, PropertyName: "placeholder", Value: "Giriniz..." },
        { Id: 314617, PropertyName: "value", Value: "Kargo No" },
        { Id: 599253, PropertyName: "placeholder", Value: "Giriniz..." },
        { Id: 79918, PropertyName: "value", Value: "Gönderildi/Teslim Edildi" },
        { Id: 908924, PropertyName: "value", Value: "Teslim Alındı" },
        { Id: 312554, PropertyName: "value", Value: "Teslim Alma Tarihi" },
        { Id: 932622, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 390802, PropertyName: "value", Value: "Teslim Alan" },
        { Id: 890290, PropertyName: "label", Value: "Vazgeç" },
        { Id: 222840, PropertyName: "value", Value: "matbaa" },
        { Id: 378775, PropertyName: "label", Value: "Kaydet" },
        { Id: 707238, PropertyName: "value", Value: "yayıncı" },
        { Id: 941117, PropertyName: "label", Value: "Kaydet" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.siparisteslimdetaykart_267791_value_kuikaSelectBoxRef = React.createRef();
    this.siparisteslimdetaykart_681452_value_kuikaDateRef = React.createRef();
    this.siparisteslimdetaykart_932622_value_kuikaDateRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      MyContext: [],
      TeslimTuruAll: [],
      SiparisTeslimKartById: [],
      EserBilgileri_TeklifEdenKisiEmail: [],
      TeklifEdenSirketAd: [],
      EserBilgileri_TeklifTalepEdenEmail: [],
      TeklifAlanSirketAd: [],
      GenelParametreler: [],
      ChangeEnabledOf: "",
      GoBack: "",
      SiparisTeslimKartSave: 0,
      SiparisTeslimAlanUpdate: 0,
      SendMailSettings: [],
      SendMail: false,
      isComp631358Visible: "hidden",
      isComp120668Visible: "hidden",
      isComp222840Visible: "hidden",
      isComp378775Visible: "hidden",
      isComp707238Visible: "hidden",
      isComp941117Visible: "hidden",
      isComp588853Enabled: "enabled",
      isComp267791Enabled: "enabled",
      isComp380156Enabled: "enabled",
      isComp681452Enabled: "enabled",
      isComp599253Enabled: "enabled",
      isComp903181Enabled: "enabled",
      isComp932622Enabled: "disabled"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("siparisteslimdetaykart", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.SiparisTeslimDetayKartPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("siparisteslimdetaykart", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("siparisteslimdetaykart", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.SiparisTeslimDetayKartPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      siparisteslimdetaykart_267791_value: this.state.SiparisTeslimKartById?.at?.(0)?.teslimTuru ?? undefined,
      siparisteslimdetaykart_681452_value: this.state.SiparisTeslimKartById?.at?.(0)?.kargoTarihi ?? undefined,
      siparisteslimdetaykart_588853_value: this.state.SiparisTeslimKartById?.at?.(0)?.irsaliyeNo ?? undefined,
      siparisteslimdetaykart_380156_value: this.state.SiparisTeslimKartById?.at?.(0)?.kargoFirma ?? undefined,
      siparisteslimdetaykart_599253_value: this.state.SiparisTeslimKartById?.at?.(0)?.kargoNo ?? undefined,
      siparisteslimdetaykart_903181_value: this.state.SiparisTeslimKartById?.at?.(0)?.isGonderildi ?? undefined,
      siparisteslimdetaykart_349002_value: this.state.SiparisTeslimKartById?.at?.(0)?.isTeslimEdildi ?? undefined,
      siparisteslimdetaykart_932622_value: this.state.SiparisTeslimKartById?.at?.(0)?.teslimTarihi ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  SiparisTeslimDetayKartPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      TeklifTalepKartID_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      ),
      BandrolYapistirmaID_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmBandrolYapistirmaID ?? this.props.screenInputs.prmbandrolyapistirmaid,
        "Guid"
      ),
      prmtekliftalepkartId_3: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      ),
      prmTeklifTalepKartID_4: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      ),
      prmtekliftalepkartId_5: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      ),
      prmTeklifTalepKartID_6: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      ),
      prmtekliftalepkartId_8: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      ),
      prmtekliftalepkartId_9: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "SiparisTeslimDetayKart/SiparisTeslimDetayKartPageInit",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    stateVars.isComp378775Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].muhatapMiFirmaMi
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].muhatapMiFirmaMi
            : null
        ),
        "Firma"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp941117Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].muhatapMiFirmaMi
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].muhatapMiFirmaMi
            : null
        ),
        "Muhatap"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.TeslimTuruAll = result?.data.teslimTuruAll;

    formVars.siparisteslimdetaykart_267791_value =
      stateVars.SiparisTeslimKartById?.length > 0
        ? stateVars.SiparisTeslimKartById[0].teslimTuru
        : this.state.SiparisTeslimKartById?.length > 0
        ? this.state.SiparisTeslimKartById[0].teslimTuru
        : null;
    formVars.siparisteslimdetaykart_267791_options = stateVars.TeslimTuruAll;
    stateVars.SiparisTeslimKartById = result?.data.siparisTeslimKartById;
    formVars.siparisteslimdetaykart_267791_value =
      stateVars.SiparisTeslimKartById?.length > 0
        ? stateVars.SiparisTeslimKartById[0].teslimTuru
        : this.state.SiparisTeslimKartById?.length > 0
        ? this.state.SiparisTeslimKartById[0].teslimTuru
        : null;
    formVars.siparisteslimdetaykart_267791_options = stateVars.TeslimTuruAll;
    formVars.siparisteslimdetaykart_681452_value = ReactSystemFunctions.value(
      this,
      stateVars.SiparisTeslimKartById?.length > 0
        ? stateVars.SiparisTeslimKartById[0].kargoTarihi
        : this.state.SiparisTeslimKartById?.length > 0
        ? this.state.SiparisTeslimKartById[0].kargoTarihi
        : null
    );
    formVars.siparisteslimdetaykart_588853_value = ReactSystemFunctions.toString(
      this,
      stateVars.SiparisTeslimKartById?.length > 0
        ? stateVars.SiparisTeslimKartById[0].irsaliyeNo
        : this.state.SiparisTeslimKartById?.length > 0
        ? this.state.SiparisTeslimKartById[0].irsaliyeNo
        : null
    );
    formVars.siparisteslimdetaykart_380156_value = ReactSystemFunctions.toString(
      this,
      stateVars.SiparisTeslimKartById?.length > 0
        ? stateVars.SiparisTeslimKartById[0].kargoFirma
        : this.state.SiparisTeslimKartById?.length > 0
        ? this.state.SiparisTeslimKartById[0].kargoFirma
        : null
    );
    formVars.siparisteslimdetaykart_599253_value = ReactSystemFunctions.toString(
      this,
      stateVars.SiparisTeslimKartById?.length > 0
        ? stateVars.SiparisTeslimKartById[0].kargoNo
        : this.state.SiparisTeslimKartById?.length > 0
        ? this.state.SiparisTeslimKartById[0].kargoNo
        : null
    );
    formVars.siparisteslimdetaykart_903181_value = ReactSystemFunctions.value(
      this,
      stateVars.SiparisTeslimKartById?.length > 0
        ? stateVars.SiparisTeslimKartById[0].isGonderildi
        : this.state.SiparisTeslimKartById?.length > 0
        ? this.state.SiparisTeslimKartById[0].isGonderildi
        : null
    );
    formVars.siparisteslimdetaykart_349002_value = ReactSystemFunctions.value(
      this,
      stateVars.SiparisTeslimKartById?.length > 0
        ? stateVars.SiparisTeslimKartById[0].isTeslimEdildi
        : this.state.SiparisTeslimKartById?.length > 0
        ? this.state.SiparisTeslimKartById[0].isTeslimEdildi
        : null
    );
    formVars.siparisteslimdetaykart_932622_value = ReactSystemFunctions.value(
      this,
      stateVars.SiparisTeslimKartById?.length > 0
        ? stateVars.SiparisTeslimKartById[0].teslimTarihi
        : this.state.SiparisTeslimKartById?.length > 0
        ? this.state.SiparisTeslimKartById[0].teslimTarihi
        : null
    );
    formVars.siparisteslimdetaykart_932622_disablebeforedate = ReactSystemFunctions.value(
      this,
      stateVars.SiparisTeslimKartById?.length > 0
        ? stateVars.SiparisTeslimKartById[0].kargoTarihi
        : this.state.SiparisTeslimKartById?.length > 0
        ? this.state.SiparisTeslimKartById[0].kargoTarihi
        : null
    );
    formVars.siparisteslimdetaykart_937554_value = ReactSystemFunctions.toString(
      this,
      stateVars.SiparisTeslimKartById?.length > 0
        ? stateVars.SiparisTeslimKartById[0].fullName
        : this.state.SiparisTeslimKartById?.length > 0
        ? this.state.SiparisTeslimKartById[0].fullName
        : null
    );
    stateVars.isComp631358Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.SiparisTeslimKartById?.length > 0
            ? stateVars.SiparisTeslimKartById[0].isRowTeslimAlanVisibility
            : this.state.SiparisTeslimKartById?.length > 0
            ? this.state.SiparisTeslimKartById[0].isRowTeslimAlanVisibility
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.EserBilgileri_TeklifEdenKisiEmail = result?.data.eserBilgileri_TeklifEdenKisiEmail;
    stateVars.TeklifEdenSirketAd = result?.data.teklifEdenSirketAd;
    stateVars.EserBilgileri_TeklifTalepEdenEmail = result?.data.eserBilgileri_TeklifTalepEdenEmail;
    stateVars.TeklifAlanSirketAd = result?.data.teklifAlanSirketAd;
    stateVars.GenelParametreler = result?.data.genelParametreler;
    stateVars.EserBilgileri_TeklifEdenKisiEmail = result?.data.eserBilgileri_TeklifEdenKisiEmail;
    stateVars.EserBilgileri_TeklifTalepEdenEmail = result?.data.eserBilgileri_TeklifTalepEdenEmail;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.SiparisTeslimDetayKartPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SiparisTeslimDetayKartPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.SiparisTeslimKartById?.length > 0
            ? stateVars.SiparisTeslimKartById[0].isGonderildi
            : this.state.SiparisTeslimKartById?.length > 0
            ? this.state.SiparisTeslimKartById[0].isGonderildi
            : null
        ),
        true
      )
    ) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp588853Enabled", "disabled");

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SiparisTeslimDetayKartPageInit2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SiparisTeslimDetayKartPageInit2_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  SiparisTeslimDetayKartPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.SiparisTeslimKartById?.length > 0
            ? stateVars.SiparisTeslimKartById[0].isGonderildi
            : this.state.SiparisTeslimKartById?.length > 0
            ? this.state.SiparisTeslimKartById[0].isGonderildi
            : null
        ),
        true
      )
    ) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp267791Enabled", "disabled");

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SiparisTeslimDetayKartPageInit3_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SiparisTeslimDetayKartPageInit3_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  SiparisTeslimDetayKartPageInit3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.SiparisTeslimKartById?.length > 0
            ? stateVars.SiparisTeslimKartById[0].isRowTeslimAlanVisibility
            : this.state.SiparisTeslimKartById?.length > 0
            ? this.state.SiparisTeslimKartById[0].isRowTeslimAlanVisibility
            : null
        ),
        "1"
      )
    ) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp588853Enabled", "disabled");

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SiparisTeslimDetayKartPageInit4_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SiparisTeslimDetayKartPageInit4_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  SiparisTeslimDetayKartPageInit4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.SiparisTeslimKartById?.length > 0
            ? stateVars.SiparisTeslimKartById[0].isRowTeslimAlanVisibility
            : this.state.SiparisTeslimKartById?.length > 0
            ? this.state.SiparisTeslimKartById[0].isRowTeslimAlanVisibility
            : null
        ),
        "1"
      )
    ) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp267791Enabled", "disabled");

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SiparisTeslimDetayKartPageInit5_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SiparisTeslimDetayKartPageInit5_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  SiparisTeslimDetayKartPageInit5_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.SiparisTeslimKartById?.length > 0
            ? stateVars.SiparisTeslimKartById[0].isRowTeslimAlanVisibility
            : this.state.SiparisTeslimKartById?.length > 0
            ? this.state.SiparisTeslimKartById[0].isRowTeslimAlanVisibility
            : null
        ),
        "1"
      )
    ) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp380156Enabled", "disabled");

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SiparisTeslimDetayKartPageInit6_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SiparisTeslimDetayKartPageInit6_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  SiparisTeslimDetayKartPageInit6_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.SiparisTeslimKartById?.length > 0
            ? stateVars.SiparisTeslimKartById[0].isRowTeslimAlanVisibility
            : this.state.SiparisTeslimKartById?.length > 0
            ? this.state.SiparisTeslimKartById[0].isRowTeslimAlanVisibility
            : null
        ),
        "1"
      )
    ) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp681452Enabled", "disabled");

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SiparisTeslimDetayKartPageInit7_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SiparisTeslimDetayKartPageInit7_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  SiparisTeslimDetayKartPageInit7_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.SiparisTeslimKartById?.length > 0
            ? stateVars.SiparisTeslimKartById[0].isRowTeslimAlanVisibility
            : this.state.SiparisTeslimKartById?.length > 0
            ? this.state.SiparisTeslimKartById[0].isRowTeslimAlanVisibility
            : null
        ),
        "1"
      )
    ) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp599253Enabled", "disabled");

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SiparisTeslimDetayKartPageInit8_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SiparisTeslimDetayKartPageInit8_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  SiparisTeslimDetayKartPageInit8_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.SiparisTeslimKartById?.length > 0
            ? stateVars.SiparisTeslimKartById[0].isRowTeslimAlanVisibility
            : this.state.SiparisTeslimKartById?.length > 0
            ? this.state.SiparisTeslimKartById[0].isRowTeslimAlanVisibility
            : null
        ),
        "1"
      )
    ) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp903181Enabled", "disabled");

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SiparisTeslimDetayKartPageInit9_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SiparisTeslimDetayKartPageInit9_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  SiparisTeslimDetayKartPageInit9_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparisteslimdetaykart_349002_value", "value", "", "", "")
        ),
        false
      )
    ) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp932622Enabled", "disabled");

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SiparisTeslimDetayKartPageInit10_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SiparisTeslimDetayKartPageInit10_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  SiparisTeslimDetayKartPageInit10_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.siparisteslimdetaykart_267791_value = ReactSystemFunctions.toString(
      this,
      stateVars.SiparisTeslimKartById?.length > 0
        ? stateVars.SiparisTeslimKartById[0].teslimTuru
        : this.state.SiparisTeslimKartById?.length > 0
        ? this.state.SiparisTeslimKartById[0].teslimTuru
        : null
    );

    stateVars.dataSource_267791 = this.state.TeslimTuruAll;
    stateVars.dataSource_267791 = this.state.TeslimTuruAll;
    formVars.siparisteslimdetaykart_681452_value = ReactSystemFunctions.value(
      this,
      stateVars.SiparisTeslimKartById?.length > 0
        ? stateVars.SiparisTeslimKartById[0].kargoTarihi
        : this.state.SiparisTeslimKartById?.length > 0
        ? this.state.SiparisTeslimKartById[0].kargoTarihi
        : null
    );

    formVars.siparisteslimdetaykart_588853_value = ReactSystemFunctions.toString(
      this,
      stateVars.SiparisTeslimKartById?.length > 0
        ? stateVars.SiparisTeslimKartById[0].irsaliyeNo
        : this.state.SiparisTeslimKartById?.length > 0
        ? this.state.SiparisTeslimKartById[0].irsaliyeNo
        : null
    );

    formVars.siparisteslimdetaykart_380156_value = ReactSystemFunctions.toString(
      this,
      stateVars.SiparisTeslimKartById?.length > 0
        ? stateVars.SiparisTeslimKartById[0].kargoFirma
        : this.state.SiparisTeslimKartById?.length > 0
        ? this.state.SiparisTeslimKartById[0].kargoFirma
        : null
    );

    formVars.siparisteslimdetaykart_599253_value = ReactSystemFunctions.toString(
      this,
      stateVars.SiparisTeslimKartById?.length > 0
        ? stateVars.SiparisTeslimKartById[0].kargoNo
        : this.state.SiparisTeslimKartById?.length > 0
        ? this.state.SiparisTeslimKartById[0].kargoNo
        : null
    );

    formVars.siparisteslimdetaykart_903181_value = ReactSystemFunctions.value(
      this,
      stateVars.SiparisTeslimKartById?.length > 0
        ? stateVars.SiparisTeslimKartById[0].isGonderildi
        : this.state.SiparisTeslimKartById?.length > 0
        ? this.state.SiparisTeslimKartById[0].isGonderildi
        : null
    );

    stateVars.isComp631358Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.SiparisTeslimKartById?.length > 0
            ? stateVars.SiparisTeslimKartById[0].isRowTeslimAlanVisibility
            : this.state.SiparisTeslimKartById?.length > 0
            ? this.state.SiparisTeslimKartById[0].isRowTeslimAlanVisibility
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.siparisteslimdetaykart_349002_value = ReactSystemFunctions.value(
      this,
      stateVars.SiparisTeslimKartById?.length > 0
        ? stateVars.SiparisTeslimKartById[0].isTeslimEdildi
        : this.state.SiparisTeslimKartById?.length > 0
        ? this.state.SiparisTeslimKartById[0].isTeslimEdildi
        : null
    );

    formVars.siparisteslimdetaykart_932622_value = ReactSystemFunctions.value(
      this,
      stateVars.SiparisTeslimKartById?.length > 0
        ? stateVars.SiparisTeslimKartById[0].teslimTarihi
        : this.state.SiparisTeslimKartById?.length > 0
        ? this.state.SiparisTeslimKartById[0].teslimTarihi
        : null
    );
    formVars.siparisteslimdetaykart_932622_disableBeforeDate = ReactSystemFunctions.value(
      this,
      stateVars.SiparisTeslimKartById?.length > 0
        ? stateVars.SiparisTeslimKartById[0].kargoTarihi
        : this.state.SiparisTeslimKartById?.length > 0
        ? this.state.SiparisTeslimKartById[0].kargoTarihi
        : null
    );

    formVars.siparisteslimdetaykart_932622_value = ReactSystemFunctions.value(
      this,
      stateVars.SiparisTeslimKartById?.length > 0
        ? stateVars.SiparisTeslimKartById[0].teslimTarihi
        : this.state.SiparisTeslimKartById?.length > 0
        ? this.state.SiparisTeslimKartById[0].teslimTarihi
        : null
    );
    formVars.siparisteslimdetaykart_932622_disableBeforeDate = ReactSystemFunctions.value(
      this,
      stateVars.SiparisTeslimKartById?.length > 0
        ? stateVars.SiparisTeslimKartById[0].kargoTarihi
        : this.state.SiparisTeslimKartById?.length > 0
        ? this.state.SiparisTeslimKartById[0].kargoTarihi
        : null
    );

    formVars.siparisteslimdetaykart_937554_value = ReactSystemFunctions.toString(
      this,
      stateVars.SiparisTeslimKartById?.length > 0
        ? stateVars.SiparisTeslimKartById[0].fullName
        : this.state.SiparisTeslimKartById?.length > 0
        ? this.state.SiparisTeslimKartById[0].fullName
        : null
    );

    stateVars.isComp378775Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].muhatapMiFirmaMi
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].muhatapMiFirmaMi
            : null
        ),
        "Firma"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp941117Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].muhatapMiFirmaMi
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].muhatapMiFirmaMi
            : null
        ),
        "Muhatap"
      ) === true
        ? "visible"
        : "hidden";

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  SiparisTeslimDetayKartComponent_300623_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SiparisTeslimDetayKartComponent_349002_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparisteslimdetaykart_349002_value", "value", "", "", "")
        ),
        true
      )
    ) {
      KuikaAppManager.showSpinner(this);

      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp932622Enabled", "enabled");

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SiparisTeslimDetayKartComponent_349002_onChange1_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SiparisTeslimDetayKartComponent_349002_onChange1_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  SiparisTeslimDetayKartComponent_349002_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparisteslimdetaykart_349002_value", "value", "", "", "")
        ),
        false
      )
    ) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp932622Enabled", "disabled");

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  SiparisTeslimDetayKartComponent_890290_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SiparisTeslimDetayKartComponent_378775_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparisteslimdetaykart_681452_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "siparisteslimdetaykart_681452_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "siparisteslimdetaykart_267791_value", "value", "TeslimTuruAll", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "siparisteslimdetaykart_267791_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      IrsaliyeNo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "siparisteslimdetaykart_588853_value", "value", "", "", "")
        ),
        "string"
      ),
      isTeslimEdildi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparisteslimdetaykart_349002_value", "value", "", "", "")
        ),
        "boolean"
      ),
      KargoFirma_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "siparisteslimdetaykart_380156_value", "value", "", "", "")
        ),
        "string"
      ),
      KargoNo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "siparisteslimdetaykart_599253_value", "value", "", "", "")
        ),
        "string"
      ),
      KargoTarihi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparisteslimdetaykart_681452_value", "value", "", "", "")
        ),
        "Date"
      ),
      TeklifTalepKartID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      ),
      TeslimTuru_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparisteslimdetaykart_267791_value", "value", "TeslimTuruAll", "id", "id")
        ),
        "Guid"
      ),
      isGonderildi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparisteslimdetaykart_903181_value", "value", "", "", "")
        ),
        "boolean"
      ),
      BandrolYapistirmaID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmBandrolYapistirmaID ?? this.props.screenInputs.prmbandrolyapistirmaid,
        "Guid"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(Guid.create().toString(), "Guid")
    };

    KuikaAppManager.showSpinner(this);
    if (
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.SiparisTeslimKartById?.length > 0
            ? stateVars.SiparisTeslimKartById[0].isRowTeslimAlanVisibility
            : this.state.SiparisTeslimKartById?.length > 0
            ? this.state.SiparisTeslimKartById[0].isRowTeslimAlanVisibility
            : null
        ),
        null
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "SiparisTeslimDetayKart/SiparisTeslimDetayKartComponent_378775_onClick",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.SiparisTeslimKartSave = result?.data.siparisTeslimKartSave;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SiparisTeslimDetayKartComponent_378775_onClick1_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SiparisTeslimDetayKartComponent_378775_onClick1_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SiparisTeslimDetayKartComponent_378775_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparisteslimdetaykart_681452_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "siparisteslimdetaykart_681452_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "siparisteslimdetaykart_267791_value", "value", "TeslimTuruAll", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "siparisteslimdetaykart_267791_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      IrsaliyeNo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "siparisteslimdetaykart_588853_value", "value", "", "", "")
        ),
        "string"
      ),
      isTeslimEdildi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparisteslimdetaykart_349002_value", "value", "", "", "")
        ),
        "boolean"
      ),
      KargoFirma_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "siparisteslimdetaykart_380156_value", "value", "", "", "")
        ),
        "string"
      ),
      KargoNo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "siparisteslimdetaykart_599253_value", "value", "", "", "")
        ),
        "string"
      ),
      KargoTarihi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparisteslimdetaykart_681452_value", "value", "", "", "")
        ),
        "Date"
      ),
      TeklifTalepKartID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      ),
      TeslimTuru_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparisteslimdetaykart_267791_value", "value", "TeslimTuruAll", "id", "id")
        ),
        "Guid"
      ),
      isGonderildi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparisteslimdetaykart_903181_value", "value", "", "", "")
        ),
        "boolean"
      ),
      BandrolYapistirmaID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmBandrolYapistirmaID ?? this.props.screenInputs.prmbandrolyapistirmaid,
        "Guid"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(Guid.create().toString(), "Guid")
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.SiparisTeslimKartById?.length > 0
            ? stateVars.SiparisTeslimKartById[0].isRowTeslimAlanVisibility
            : this.state.SiparisTeslimKartById?.length > 0
            ? this.state.SiparisTeslimKartById[0].isRowTeslimAlanVisibility
            : null
        ),
        "0"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "SiparisTeslimDetayKart/SiparisTeslimDetayKartComponent_378775_onClick1_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.SiparisTeslimKartSave = result?.data.siparisTeslimKartSave;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SiparisTeslimDetayKartComponent_378775_onClick2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SiparisTeslimDetayKartComponent_378775_onClick2_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SiparisTeslimDetayKartComponent_378775_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparisteslimdetaykart_932622_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "siparisteslimdetaykart_932622_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparisteslimdetaykart_349002_value", "value", "", "", "")
        ),
        true
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      TeslimAlan_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kUserId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kUserId
            : null
        ),
        "Guid"
      ),
      TeslimTarih_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparisteslimdetaykart_932622_value", "value", "", "", "")
        ),
        "Date"
      ),
      isTeslimEdildi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparisteslimdetaykart_349002_value", "value", "", "", "")
        ),
        "boolean"
      ),
      TeklifTalepKartID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      ),
      BandrolYapistirmaID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmBandrolYapistirmaID ?? this.props.screenInputs.prmbandrolyapistirmaid,
        "Guid"
      )
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.SiparisTeslimKartById?.length > 0
            ? stateVars.SiparisTeslimKartById[0].isRowTeslimAlanVisibility
            : this.state.SiparisTeslimKartById?.length > 0
            ? this.state.SiparisTeslimKartById[0].isRowTeslimAlanVisibility
            : null
        ),
        "1"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "SiparisTeslimDetayKart/SiparisTeslimDetayKartComponent_378775_onClick2_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.SiparisTeslimAlanUpdate = result?.data.siparisTeslimAlanUpdate;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SiparisTeslimDetayKartComponent_378775_onClick3_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SiparisTeslimDetayKartComponent_378775_onClick3_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SiparisTeslimDetayKartComponent_378775_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      actionkey_0: ReactSystemFunctions.convertToTypeByName("gonderildi", "string"),
      uyeliktipi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyelikTipi
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyelikTipi
            : null
        ),
        "string"
      ),
      numara_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.siparisNo ?? this.props.screenInputs.siparisno,
        "string"
      )
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.SiparisDetayTeslimKart_Decider?.length > 0
            ? stateVars.SiparisDetayTeslimKart_Decider[0].result
            : this.state.SiparisDetayTeslimKart_Decider?.length > 0
            ? this.state.SiparisDetayTeslimKart_Decider[0].result
            : null
        ),
        false
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "SiparisTeslimDetayKart/SiparisTeslimDetayKartComponent_378775_onClick3_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.SendMailSettings = result?.data.sendMailSettings;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SiparisTeslimDetayKartComponent_378775_onClick4_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SiparisTeslimDetayKartComponent_378775_onClick4_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SiparisTeslimDetayKartComponent_378775_onClick4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      templateId_0: ReactSystemFunctions.convertToTypeByName("ec12bdcd-6e74-443e-810c-103e8a718d61", "string"),
      receivers_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.EserBilgileri_TeklifTalepEdenEmail?.length > 0
            ? stateVars.EserBilgileri_TeklifTalepEdenEmail[0].userName
            : this.state.EserBilgileri_TeklifTalepEdenEmail?.length > 0
            ? this.state.EserBilgileri_TeklifTalepEdenEmail[0].userName
            : null
        ),
        "string"
      ),
      subject_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.SendMailSettings?.length > 0
            ? stateVars.SendMailSettings[0].mailSubject
            : this.state.SendMailSettings?.length > 0
            ? this.state.SendMailSettings[0].mailSubject
            : null
        ),
        "string"
      ),
      sender_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      cc_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      bcc_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentFileName_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentBase64_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      url_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.SendMailSettings?.length > 0
            ? stateVars.SendMailSettings[0].urLink
            : this.state.SendMailSettings?.length > 0
            ? this.state.SendMailSettings[0].urLink
            : null
        ),
        "string"
      ),
      PublinkDestekTelNo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.GenelParametreler?.length > 0
            ? stateVars.GenelParametreler[0].destekTelNo
            : this.state.GenelParametreler?.length > 0
            ? this.state.GenelParametreler[0].destekTelNo
            : null
        ),
        "string"
      ),
      UyeIsmi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.EserBilgileri_TeklifTalepEdenEmail?.length > 0
            ? stateVars.EserBilgileri_TeklifTalepEdenEmail[0].firstName
            : this.state.EserBilgileri_TeklifTalepEdenEmail?.length > 0
            ? this.state.EserBilgileri_TeklifTalepEdenEmail[0].firstName
            : null
        ),
        "string"
      ),
      UyeSoyad_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.EserBilgileri_TeklifTalepEdenEmail?.length > 0
            ? stateVars.EserBilgileri_TeklifTalepEdenEmail[0].lastName
            : this.state.EserBilgileri_TeklifTalepEdenEmail?.length > 0
            ? this.state.EserBilgileri_TeklifTalepEdenEmail[0].lastName
            : null
        ),
        "string"
      ),
      language_0: ReactSystemFunctions.convertToTypeByName("", "string")
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparisteslimdetaykart_903181_value", "value", "", "", "")
        ),
        true
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "SiparisTeslimDetayKart/SiparisTeslimDetayKartComponent_378775_onClick4_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.SendMail = result?.data.sendMail;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SiparisTeslimDetayKartComponent_378775_onClick5_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SiparisTeslimDetayKartComponent_378775_onClick5_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SiparisTeslimDetayKartComponent_378775_onClick5_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SiparisTeslimDetayKartComponent_941117_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparisteslimdetaykart_681452_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "siparisteslimdetaykart_681452_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "siparisteslimdetaykart_267791_value", "value", "TeslimTuruAll", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "siparisteslimdetaykart_267791_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      IrsaliyeNo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "siparisteslimdetaykart_588853_value", "value", "", "", "")
        ),
        "string"
      ),
      isTeslimEdildi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparisteslimdetaykart_349002_value", "value", "", "", "")
        ),
        "boolean"
      ),
      KargoFirma_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "siparisteslimdetaykart_380156_value", "value", "", "", "")
        ),
        "string"
      ),
      KargoNo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "siparisteslimdetaykart_599253_value", "value", "", "", "")
        ),
        "string"
      ),
      KargoTarihi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparisteslimdetaykart_681452_value", "value", "", "", "")
        ),
        "Date"
      ),
      TeklifTalepKartID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      ),
      TeslimTuru_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparisteslimdetaykart_267791_value", "value", "TeslimTuruAll", "id", "id")
        ),
        "Guid"
      ),
      isGonderildi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparisteslimdetaykart_903181_value", "value", "", "", "")
        ),
        "boolean"
      ),
      BandrolYapistirmaID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmBandrolYapistirmaID ?? this.props.screenInputs.prmbandrolyapistirmaid,
        "Guid"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(Guid.create().toString(), "Guid")
    };

    KuikaAppManager.showSpinner(this);
    if (
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.SiparisTeslimKartById?.length > 0
            ? stateVars.SiparisTeslimKartById[0].isRowTeslimAlanVisibility
            : this.state.SiparisTeslimKartById?.length > 0
            ? this.state.SiparisTeslimKartById[0].isRowTeslimAlanVisibility
            : null
        ),
        null
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "SiparisTeslimDetayKart/SiparisTeslimDetayKartComponent_941117_onClick",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.SiparisTeslimKartSave = result?.data.siparisTeslimKartSave;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SiparisTeslimDetayKartComponent_941117_onClick1_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SiparisTeslimDetayKartComponent_941117_onClick1_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SiparisTeslimDetayKartComponent_941117_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparisteslimdetaykart_681452_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "siparisteslimdetaykart_681452_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "siparisteslimdetaykart_267791_value", "value", "TeslimTuruAll", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "siparisteslimdetaykart_267791_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      IrsaliyeNo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "siparisteslimdetaykart_588853_value", "value", "", "", "")
        ),
        "string"
      ),
      isTeslimEdildi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparisteslimdetaykart_349002_value", "value", "", "", "")
        ),
        "boolean"
      ),
      KargoFirma_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "siparisteslimdetaykart_380156_value", "value", "", "", "")
        ),
        "string"
      ),
      KargoNo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "siparisteslimdetaykart_599253_value", "value", "", "", "")
        ),
        "string"
      ),
      KargoTarihi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparisteslimdetaykart_681452_value", "value", "", "", "")
        ),
        "Date"
      ),
      TeklifTalepKartID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      ),
      TeslimTuru_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparisteslimdetaykart_267791_value", "value", "TeslimTuruAll", "id", "id")
        ),
        "Guid"
      ),
      isGonderildi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparisteslimdetaykart_903181_value", "value", "", "", "")
        ),
        "boolean"
      ),
      BandrolYapistirmaID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmBandrolYapistirmaID ?? this.props.screenInputs.prmbandrolyapistirmaid,
        "Guid"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(Guid.create().toString(), "Guid")
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.SiparisTeslimKartById?.length > 0
            ? stateVars.SiparisTeslimKartById[0].isRowTeslimAlanVisibility
            : this.state.SiparisTeslimKartById?.length > 0
            ? this.state.SiparisTeslimKartById[0].isRowTeslimAlanVisibility
            : null
        ),
        "0"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "SiparisTeslimDetayKart/SiparisTeslimDetayKartComponent_941117_onClick1_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.SiparisTeslimKartSave = result?.data.siparisTeslimKartSave;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SiparisTeslimDetayKartComponent_941117_onClick2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SiparisTeslimDetayKartComponent_941117_onClick2_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SiparisTeslimDetayKartComponent_941117_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparisteslimdetaykart_932622_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "siparisteslimdetaykart_932622_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparisteslimdetaykart_349002_value", "value", "", "", "")
        ),
        true
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      TeslimAlan_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kUserId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kUserId
            : null
        ),
        "Guid"
      ),
      TeslimTarih_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparisteslimdetaykart_932622_value", "value", "", "", "")
        ),
        "Date"
      ),
      isTeslimEdildi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparisteslimdetaykart_349002_value", "value", "", "", "")
        ),
        "boolean"
      ),
      TeklifTalepKartID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      ),
      BandrolYapistirmaID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmBandrolYapistirmaID ?? this.props.screenInputs.prmbandrolyapistirmaid,
        "Guid"
      )
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.SiparisTeslimKartById?.length > 0
            ? stateVars.SiparisTeslimKartById[0].isRowTeslimAlanVisibility
            : this.state.SiparisTeslimKartById?.length > 0
            ? this.state.SiparisTeslimKartById[0].isRowTeslimAlanVisibility
            : null
        ),
        "1"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "SiparisTeslimDetayKart/SiparisTeslimDetayKartComponent_941117_onClick2_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.SiparisTeslimAlanUpdate = result?.data.siparisTeslimAlanUpdate;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SiparisTeslimDetayKartComponent_941117_onClick3_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SiparisTeslimDetayKartComponent_941117_onClick3_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SiparisTeslimDetayKartComponent_941117_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      actionkey_0: ReactSystemFunctions.convertToTypeByName("teslimalindi", "string"),
      uyeliktipi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyelikTipi
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyelikTipi
            : null
        ),
        "string"
      ),
      numara_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.siparisNo ?? this.props.screenInputs.siparisno,
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "SiparisTeslimDetayKart/SiparisTeslimDetayKartComponent_941117_onClick3_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SendMailSettings = result?.data.sendMailSettings;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.SiparisTeslimDetayKartComponent_941117_onClick4_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SiparisTeslimDetayKartComponent_941117_onClick4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      templateId_0: ReactSystemFunctions.convertToTypeByName("6906acb8-8539-49aa-8651-3b4c6f4875b7", "string"),
      receivers_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.EserBilgileri_TeklifEdenKisiEmail?.length > 0
            ? stateVars.EserBilgileri_TeklifEdenKisiEmail[0].userName
            : this.state.EserBilgileri_TeklifEdenKisiEmail?.length > 0
            ? this.state.EserBilgileri_TeklifEdenKisiEmail[0].userName
            : null
        ),
        "string"
      ),
      subject_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.SendMailSettings?.length > 0
            ? stateVars.SendMailSettings[0].mailSubject
            : this.state.SendMailSettings?.length > 0
            ? this.state.SendMailSettings[0].mailSubject
            : null
        ),
        "string"
      ),
      sender_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      cc_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      bcc_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentFileName_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentBase64_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      url_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.SendMailSettings?.length > 0
            ? stateVars.SendMailSettings[0].urLink
            : this.state.SendMailSettings?.length > 0
            ? this.state.SendMailSettings[0].urLink
            : null
        ),
        "string"
      ),
      PublinkDestekTelNo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.GenelParametreler?.length > 0
            ? stateVars.GenelParametreler[0].destekTelNo
            : this.state.GenelParametreler?.length > 0
            ? this.state.GenelParametreler[0].destekTelNo
            : null
        ),
        "string"
      ),
      UyeIsmi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.EserBilgileri_TeklifEdenKisiEmail?.length > 0
            ? stateVars.EserBilgileri_TeklifEdenKisiEmail[0].firstname
            : this.state.EserBilgileri_TeklifEdenKisiEmail?.length > 0
            ? this.state.EserBilgileri_TeklifEdenKisiEmail[0].firstname
            : null
        ),
        "string"
      ),
      UyeSoyad_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.EserBilgileri_TeklifEdenKisiEmail?.length > 0
            ? stateVars.EserBilgileri_TeklifEdenKisiEmail[0].lastName
            : this.state.EserBilgileri_TeklifEdenKisiEmail?.length > 0
            ? this.state.EserBilgileri_TeklifEdenKisiEmail[0].lastName
            : null
        ),
        "string"
      ),
      GonderenFirmaAdi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyeAd
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyeAd
            : null
        ),
        "string"
      ),
      language_0: ReactSystemFunctions.convertToTypeByName("", "string")
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparisteslimdetaykart_349002_value", "value", "", "", "")
        ),
        true
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "SiparisTeslimDetayKart/SiparisTeslimDetayKartComponent_941117_onClick4_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.SendMail = result?.data.sendMail;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SiparisTeslimDetayKartComponent_941117_onClick5_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SiparisTeslimDetayKartComponent_941117_onClick5_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SiparisTeslimDetayKartComponent_941117_onClick5_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
