import { FormInstance } from "antd/es/form";
import React from "react";
import Swal from "sweetalert2";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IEseriOnaylananTeklifListMatbaa_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IEseriOnaylananTeklifListMatbaa_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  Logout: boolean;
  Logout_dummy: boolean;
  goToSignInActivity: any;
  goToSignInActivity_dummy: any;
  MyContext: any[];
  MyContext_dummy: any[];
  RoleSadeceGoruntule: any[];
  RoleSadeceGoruntule_dummy: any[];
  TeklifSeciliIdTempDelete: number;
  TeklifSeciliIdTempDelete_dummy: number;
  TeklifEserOnaylananList: any[];
  TeklifEserOnaylananList_dummy: any[];
  setPTotalCount01: any[];
  setPTotalCount01_dummy: any[];
  TeklifEserOnaylananListToExcel: any[];
  TeklifEserOnaylananListToExcel_dummy: any[];
  ExportExcel: any;
  ExportExcel_dummy: any;
  getTeklifSeciliIdTempCount: any[];
  getTeklifSeciliIdTempCount_dummy: any[];
  getTeklifSeciliIdTempUyeCount: any[];
  getTeklifSeciliIdTempUyeCount_dummy: any[];
  Notify: boolean;
  Notify_dummy: boolean;
  getTeklifSeciliIdTempControl: any[];
  getTeklifSeciliIdTempControl_dummy: any[];
  TeklifSeciliIdTempUpdate: number;
  TeklifSeciliIdTempUpdate_dummy: number;
  isComp702503Visible: "visible" | "hidden";
  isComp13077Visible: "visible" | "hidden";
  isComp138668Visible: "visible" | "hidden";
  isComp178302Visible: "visible" | "hidden";
  isComp243022Visible: "visible" | "hidden";
  isComp285705Visible: "visible" | "hidden";
  isComp787023Visible: "visible" | "hidden";
  isComp29106Visible: "visible" | "hidden";
  isComp5970Visible: "visible" | "hidden";
  isComp989604Visible: "visible" | "hidden";
  isComp246514Visible: "visible" | "hidden";
  isComp708380Visible: "visible" | "hidden";
  isComp633574Visible: "visible" | "hidden";
  isComp942659Visible: "visible" | "hidden";
}

export class EseriOnaylananTeklifListMatbaa_ScreenBase extends React.PureComponent<
  IEseriOnaylananTeklifListMatbaa_ScreenProps,
  any
> {
  eserionaylanantekliflistmatbaa_438082_value_kuikaSelectBoxRef: React.RefObject<any>;
  eserionaylanantekliflistmatbaa_663436_value_kuikaSelectBoxRef: React.RefObject<any>;
  eserionaylanantekliflistmatbaa_348605_value_kuikaTableRef: React.RefObject<any>;
  eserionaylanantekliflistmatbaa_880048_value_kuikaPaginationRef: React.RefObject<any>;
  ml = [
    {
      Id: "41cd36a4-5b07-cfca-4f7f-f48019ff4ff3",
      Name: "tr_TR",
      ShortName: "Türkçe",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "0069cee9-16ca-4a5a-aab9-dd58af96ca96", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "6848ee3f-e83c-44d5-8901-116a069d8efa",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 364094, PropertyName: "value", Value: "Teklif Listesi" },
        { Id: 412428, PropertyName: "label", Value: "Profil" },
        { Id: 668195, PropertyName: "label", Value: "Çıkış Yap" },
        { Id: 692070, PropertyName: "value", Value: "Sipariş oluşturulanları da göster" },
        { Id: 438082, PropertyName: "placeholder", Value: "Sıralama" },
        { Id: 663436, PropertyName: "placeholder", Value: "A-z" },
        { Id: 738662, PropertyName: "placeholder", Value: "Ara..." },
        { Id: 702503, PropertyName: "label", Value: "Seçilen Tekliflere Sipariş Oluştur" },
        { Id: 348605, PropertyName: "nodatafoundmessage", Value: "Teklif Kaydı Bulunamadı." },
        { Id: 27045, PropertyName: "title", Value: "Hızlı Teklif" },
        { Id: 547015, PropertyName: "title", Value: "Talep No" },
        { Id: 266088, PropertyName: "value", Value: "[datafield:talep_no]" },
        { Id: 476271, PropertyName: "title", Value: "Teklif No" },
        { Id: 391582, PropertyName: "value", Value: "[datafield:teklif_no]" },
        { Id: 71915, PropertyName: "title", Value: "Sipariş No" },
        { Id: 192399, PropertyName: "value", Value: "[datafield:siparis_no]" },
        { Id: 833287, PropertyName: "title", Value: "Muhatap" },
        { Id: 534463, PropertyName: "value", Value: "[datafield:muhatap]" },
        { Id: 256601, PropertyName: "title", Value: "Kitap Adı" },
        { Id: 744431, PropertyName: "value", Value: "[datafield:kitapad]" },
        { Id: 87263, PropertyName: "title", Value: "Ebat" },
        { Id: 795594, PropertyName: "value", Value: "[datafield:kitapboyuttanim]" },
        { Id: 200788, PropertyName: "title", Value: "Kağıt" },
        { Id: 83668, PropertyName: "value", Value: "[datafield:icbaskikagitcinsi]" },
        { Id: 289198, PropertyName: "title", Value: "Kağıt Gramaj" },
        { Id: 632508, PropertyName: "value", Value: "[datafield:icbaskikagitgramaj]" },
        { Id: 266875, PropertyName: "title", Value: "Kapak" },
        { Id: 185384, PropertyName: "value", Value: "[datafield:kapakcinsi]" },
        { Id: 671667, PropertyName: "title", Value: "Kapak Gramaj" },
        { Id: 669374, PropertyName: "value", Value: "[datafield:kapakgramaj]" },
        { Id: 981067, PropertyName: "title", Value: "Cilt Biçimi" },
        { Id: 83420, PropertyName: "value", Value: "[datafield:ciltbicimi]" },
        { Id: 484624, PropertyName: "title", Value: "Renk" },
        { Id: 766214, PropertyName: "value", Value: "[datafield:icbaskirenk]" },
        { Id: 680042, PropertyName: "title", Value: "Selefon" },
        { Id: 26418, PropertyName: "value", Value: "[datafield:selefon]" },
        { Id: 216170, PropertyName: "title", Value: "Sayfa Sayısı" },
        { Id: 833569, PropertyName: "value", Value: "[datafield:sayfasayisi]" },
        { Id: 52036, PropertyName: "title", Value: "Baskı Adet" },
        { Id: 257784, PropertyName: "value", Value: "[datafield:baskiadet]" },
        { Id: 467153, PropertyName: "title", Value: "Teklif Tarihi" },
        { Id: 658945, PropertyName: "value", Value: "[datafield:tekliftarihi]" },
        { Id: 558595, PropertyName: "title", Value: "Geçerlilik Tarihi" },
        { Id: 586928, PropertyName: "value", Value: "[datafield:teklifgecerliliktarihi]" },
        { Id: 421555, PropertyName: "title", Value: "Teklif Fiyatı" },
        { Id: 863036, PropertyName: "value", Value: "[datafield:tekliffiyat]" },
        { Id: 243022, PropertyName: "title", Value: "Eser Bilgisi" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";
  EseriOnaylananTeklifListMatbaaRecuringAction_30_Interval: any;

  constructor(props) {
    super(props);
    this.eserionaylanantekliflistmatbaa_438082_value_kuikaSelectBoxRef = React.createRef();
    this.eserionaylanantekliflistmatbaa_663436_value_kuikaSelectBoxRef = React.createRef();
    this.eserionaylanantekliflistmatbaa_348605_value_kuikaTableRef = React.createRef();
    this.eserionaylanantekliflistmatbaa_880048_value_kuikaPaginationRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      NAVIGATE: "",
      Logout: false,
      goToSignInActivity: "",
      MyContext: [],
      RoleSadeceGoruntule: [],
      TeklifSeciliIdTempDelete: 0,
      TeklifEserOnaylananList: [],
      setPTotalCount01: [],
      TeklifEserOnaylananListToExcel: [],
      ExportExcel: "",
      getTeklifSeciliIdTempCount: [],
      getTeklifSeciliIdTempUyeCount: [],
      Notify: false,
      getTeklifSeciliIdTempControl: [],
      TeklifSeciliIdTempUpdate: 0,
      dataSource_438082: [
        { key: "TeklifNo", text: "Teklif No" },
        { key: "TeklifTarihi", text: "Teklif Tarihi" },
        { key: "SiparisNo", text: "Sipariş No" }
      ],
      dataSource_663436: [
        { key: "ASC", text: "A-Z" },
        { key: "DESC", text: "Z-A" }
      ],
      isComp702503Visible: "hidden",
      isComp13077Visible: "hidden",
      isComp138668Visible: "hidden",
      isComp178302Visible: "hidden",
      isComp243022Visible: "hidden",
      isComp285705Visible: "hidden",
      isComp787023Visible: "hidden",
      isComp29106Visible: "hidden",
      isComp5970Visible: "hidden",
      isComp989604Visible: "hidden",
      isComp246514Visible: "hidden",
      isComp708380Visible: "hidden",
      isComp633574Visible: "hidden",
      isComp942659Visible: "hidden"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("eserionaylanantekliflistmatbaa", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.EseriOnaylananTeklifListMatbaaPageInit();
    }

    if (false) {
      if (false) {
        await this.EseriOnaylananTeklifListMatbaaRecuringAction_30_();
      }
      this.EseriOnaylananTeklifListMatbaaRecuringAction_30_Interval = setInterval(async () => {
        await this.EseriOnaylananTeklifListMatbaaRecuringAction_30_();
      }, 30000);
    }

    KuikaAppManager.calculateAndSetBodyHeight("eserionaylanantekliflistmatbaa", "");
  }

  componentWillUnmount() {
    if (this.EseriOnaylananTeklifListMatbaaRecuringAction_30_Interval) {
      clearInterval(this.EseriOnaylananTeklifListMatbaaRecuringAction_30_Interval);
    }
  }

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("eserionaylanantekliflistmatbaa", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.EseriOnaylananTeklifListMatbaaPageInit();
    }

    if (prevState.isPageInitLoaded !== this.state.isPageInitLoaded && this.state.isPageInitLoaded === true) {
      if (false && true) {
        await this.EseriOnaylananTeklifListMatbaaRecuringAction_30_();
      }
      this.EseriOnaylananTeklifListMatbaaRecuringAction_30_Interval = setInterval(async () => {
        await this.EseriOnaylananTeklifListMatbaaRecuringAction_30_();
      }, 30000);
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      eserionaylanantekliflistmatbaa_801926_value: this.state.MyContext?.at?.(0)?.profilFoto ?? undefined,
      eserionaylanantekliflistmatbaa_833365_value: false
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  EseriOnaylananTeklifListMatbaaPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      MuhatapmiFirmami_3: ReactSystemFunctions.convertToTypeByName("Firma", "string"),
      SiparisOlusturulanGoster_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "eserionaylanantekliflistmatbaa_833365_value", "value", "", "", "")
        ),
        "boolean"
      ),
      ara_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "eserionaylanantekliflistmatbaa_738662_value", "value", "", "", "")
        ),
        "string"
      ),
      OrderByColumn_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "eserionaylanantekliflistmatbaa_438082_value", "value", "", "key", "")
        ),
        "string"
      ),
      AscDesc_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "eserionaylanantekliflistmatbaa_663436_value", "value", "", "key", "")
        ),
        "string"
      ),
      currentpage_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "eserionaylanantekliflistmatbaa_880048_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "eserionaylanantekliflistmatbaa_880048_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EseriOnaylananTeklifListMatbaa/EseriOnaylananTeklifListMatbaaPageInit",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    formVars.eserionaylanantekliflistmatbaa_801926_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0
        ? stateVars.MyContext[0].profilFoto
        : this.state.MyContext?.length > 0
        ? this.state.MyContext[0].profilFoto
        : null
    );
    formVars.eserionaylanantekliflistmatbaa_172357_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0
        ? stateVars.MyContext[0].fullName
        : this.state.MyContext?.length > 0
        ? this.state.MyContext[0].fullName
        : null
    );
    formVars.eserionaylanantekliflistmatbaa_590045_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0
        ? stateVars.MyContext[0].uyeAd
        : this.state.MyContext?.length > 0
        ? this.state.MyContext[0].uyeAd
        : null
    );
    stateVars.RoleSadeceGoruntule = result?.data.roleSadeceGoruntule;
    stateVars.isComp702503Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg042
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp243022Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg042
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.TeklifSeciliIdTempDelete = result?.data.teklifSeciliIdTempDelete;
    stateVars.TeklifEserOnaylananList = result?.data.teklifEserOnaylananList;

    stateVars.setPTotalCount01 = result?.data.setPTotalCount01;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EseriOnaylananTeklifListMatbaaPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EseriOnaylananTeklifListMatbaaPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.eserionaylanantekliflistmatbaa_801926_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0
        ? stateVars.MyContext[0].profilFoto
        : this.state.MyContext?.length > 0
        ? this.state.MyContext[0].profilFoto
        : null
    );

    formVars.eserionaylanantekliflistmatbaa_172357_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0
        ? stateVars.MyContext[0].fullName
        : this.state.MyContext?.length > 0
        ? this.state.MyContext[0].fullName
        : null
    );

    formVars.eserionaylanantekliflistmatbaa_590045_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0
        ? stateVars.MyContext[0].uyeAd
        : this.state.MyContext?.length > 0
        ? this.state.MyContext[0].uyeAd
        : null
    );

    stateVars.isComp702503Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg042
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";

    stateVars.dataSource_348605 = this.state.TeklifEserOnaylananList;
    stateVars.isComp13077Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "eserionaylanantekliflistmatbaa_348605_value", "isTeklifSecili"),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp138668Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "eserionaylanantekliflistmatbaa_348605_value", "isTeklifSecili"),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp178302Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "eserionaylanantekliflistmatbaa_348605_value", "hizliTeklif"),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp243022Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg042
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp285705Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "eserionaylanantekliflistmatbaa_348605_value", "isMatbaaTempIconsVisibility"),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp787023Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          "eserionaylanantekliflistmatbaa_348605_value",
          "isMatbaaOnayBeklemedeIconsVisibility"
        ),
        "11"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp29106Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          "eserionaylanantekliflistmatbaa_348605_value",
          "isMatbaaGeriDonduIconsVisibility"
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp5970Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          "eserionaylanantekliflistmatbaa_348605_value",
          "isMatbaaReddedildiIconsVisibility"
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp989604Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          "eserionaylanantekliflistmatbaa_348605_value",
          "isMatbaaOnaylandiIconsVisibility"
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp246514Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          "eserionaylanantekliflistmatbaa_348605_value",
          "isMatbaaOnayBeklemedeIconsVisibility"
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp708380Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "eserionaylanantekliflistmatbaa_348605_value", "isHizliOnayIconsVisibility"),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp633574Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "eserionaylanantekliflistmatbaa_348605_value", "isInceleIconsVisibility"),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp942659Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          "eserionaylanantekliflistmatbaa_348605_value",
          "isMatbaaInceleIconsVisibility"
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.eserionaylanantekliflistmatbaa_880048_total = ReactSystemFunctions.value(
      this,
      stateVars.setPTotalCount01?.length > 0
        ? stateVars.setPTotalCount01[0].totalCount
        : this.state.setPTotalCount01?.length > 0
        ? this.state.setPTotalCount01[0].totalCount
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  EseriOnaylananTeklifListMatbaaComponent_54788_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "EseriOnaylananTeklifListMatbaa",
      "LeftMenu",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "242761",
      null,
      "left",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EseriOnaylananTeklifListMatbaaComponent_412428_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "EseriOnaylananTeklifListMatbaa",
      "Profil",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "918196",
      null,
      "right",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EseriOnaylananTeklifListMatbaaComponent_668195_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.Logout = await ReactSystemFunctions.logout();
    stateVars.tmpBoolResult = await ReactSystemFunctions.goToSignInActivity(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EseriOnaylananTeklifListMatbaaComponent_833365_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      MuhatapmiFirmami_0: ReactSystemFunctions.convertToTypeByName("Firma", "string"),
      UyeId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyeId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyeId
            : null
        ),
        "Guid"
      ),
      SiparisOlusturulanGoster_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "eserionaylanantekliflistmatbaa_833365_value", "value", "", "", "")
        ),
        "boolean"
      ),
      ara_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "eserionaylanantekliflistmatbaa_738662_value", "value", "", "", "")
        ),
        "string"
      ),
      OrderByColumn_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "eserionaylanantekliflistmatbaa_438082_value", "value", "", "key", "")
        ),
        "string"
      ),
      AscDesc_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "eserionaylanantekliflistmatbaa_663436_value", "value", "", "key", "")
        ),
        "string"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "eserionaylanantekliflistmatbaa_880048_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "eserionaylanantekliflistmatbaa_880048_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EseriOnaylananTeklifListMatbaa/EseriOnaylananTeklifListMatbaaComponent_833365_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.TeklifEserOnaylananList = result?.data.teklifEserOnaylananList;

    stateVars.setPTotalCount01 = result?.data.setPTotalCount01;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EseriOnaylananTeklifListMatbaaComponent_833365_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EseriOnaylananTeklifListMatbaaComponent_833365_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_348605 = this.state.TeklifEserOnaylananList;
    formVars.eserionaylanantekliflistmatbaa_880048_total = ReactSystemFunctions.value(
      this,
      stateVars.setPTotalCount01?.length > 0
        ? stateVars.setPTotalCount01[0].totalCount
        : this.state.setPTotalCount01?.length > 0
        ? this.state.setPTotalCount01[0].totalCount
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EseriOnaylananTeklifListMatbaaComponent_438082_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      MuhatapmiFirmami_0: ReactSystemFunctions.convertToTypeByName("Firma", "string"),
      UyeId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyeId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyeId
            : null
        ),
        "Guid"
      ),
      SiparisOlusturulanGoster_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "eserionaylanantekliflistmatbaa_833365_value", "value", "", "", "")
        ),
        "boolean"
      ),
      ara_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "eserionaylanantekliflistmatbaa_738662_value", "value", "", "", "")
        ),
        "string"
      ),
      OrderByColumn_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "eserionaylanantekliflistmatbaa_438082_value", "value", "", "key", "")
        ),
        "string"
      ),
      AscDesc_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "eserionaylanantekliflistmatbaa_663436_value", "value", "", "key", "")
        ),
        "string"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "eserionaylanantekliflistmatbaa_880048_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "eserionaylanantekliflistmatbaa_880048_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EseriOnaylananTeklifListMatbaa/EseriOnaylananTeklifListMatbaaComponent_438082_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.TeklifEserOnaylananList = result?.data.teklifEserOnaylananList;

    stateVars.setPTotalCount01 = result?.data.setPTotalCount01;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EseriOnaylananTeklifListMatbaaComponent_438082_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EseriOnaylananTeklifListMatbaaComponent_438082_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_348605 = this.state.TeklifEserOnaylananList;
    formVars.eserionaylanantekliflistmatbaa_880048_total = ReactSystemFunctions.value(
      this,
      stateVars.setPTotalCount01?.length > 0
        ? stateVars.setPTotalCount01[0].totalCount
        : this.state.setPTotalCount01?.length > 0
        ? this.state.setPTotalCount01[0].totalCount
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EseriOnaylananTeklifListMatbaaComponent_663436_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      MuhatapmiFirmami_0: ReactSystemFunctions.convertToTypeByName("Firma", "string"),
      UyeId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyeId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyeId
            : null
        ),
        "Guid"
      ),
      SiparisOlusturulanGoster_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "eserionaylanantekliflistmatbaa_833365_value", "value", "", "", "")
        ),
        "boolean"
      ),
      ara_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "eserionaylanantekliflistmatbaa_738662_value", "value", "", "", "")
        ),
        "string"
      ),
      OrderByColumn_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "eserionaylanantekliflistmatbaa_438082_value", "value", "", "key", "")
        ),
        "string"
      ),
      AscDesc_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "eserionaylanantekliflistmatbaa_663436_value", "value", "", "key", "")
        ),
        "string"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "eserionaylanantekliflistmatbaa_880048_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "eserionaylanantekliflistmatbaa_880048_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EseriOnaylananTeklifListMatbaa/EseriOnaylananTeklifListMatbaaComponent_663436_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.TeklifEserOnaylananList = result?.data.teklifEserOnaylananList;

    stateVars.setPTotalCount01 = result?.data.setPTotalCount01;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EseriOnaylananTeklifListMatbaaComponent_663436_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EseriOnaylananTeklifListMatbaaComponent_663436_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_348605 = this.state.TeklifEserOnaylananList;
    formVars.eserionaylanantekliflistmatbaa_880048_total = ReactSystemFunctions.value(
      this,
      stateVars.setPTotalCount01?.length > 0
        ? stateVars.setPTotalCount01[0].totalCount
        : this.state.setPTotalCount01?.length > 0
        ? this.state.setPTotalCount01[0].totalCount
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EseriOnaylananTeklifListMatbaaComponent_699955_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      MuhatapmiFirmami_0: ReactSystemFunctions.convertToTypeByName("Firma", "string"),
      UyeId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyeId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyeId
            : null
        ),
        "Guid"
      ),
      SiparisOlusturulanGoster_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "eserionaylanantekliflistmatbaa_833365_value", "value", "", "", "")
        ),
        "boolean"
      ),
      ara_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "eserionaylanantekliflistmatbaa_738662_value", "value", "", "", "")
        ),
        "string"
      ),
      OrderByColumn_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "eserionaylanantekliflistmatbaa_438082_value", "value", "", "key", "")
        ),
        "string"
      ),
      AscDesc_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "eserionaylanantekliflistmatbaa_663436_value", "value", "", "key", "")
        ),
        "string"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "eserionaylanantekliflistmatbaa_880048_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "eserionaylanantekliflistmatbaa_880048_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EseriOnaylananTeklifListMatbaa/EseriOnaylananTeklifListMatbaaComponent_699955_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.TeklifEserOnaylananList = result?.data.teklifEserOnaylananList;

    stateVars.setPTotalCount01 = result?.data.setPTotalCount01;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EseriOnaylananTeklifListMatbaaComponent_699955_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EseriOnaylananTeklifListMatbaaComponent_699955_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_348605 = this.state.TeklifEserOnaylananList;
    formVars.eserionaylanantekliflistmatbaa_880048_total = ReactSystemFunctions.value(
      this,
      stateVars.setPTotalCount01?.length > 0
        ? stateVars.setPTotalCount01[0].totalCount
        : this.state.setPTotalCount01?.length > 0
        ? this.state.setPTotalCount01[0].totalCount
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EseriOnaylananTeklifListMatbaaComponent_490181_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      MuhatapmiFirmami_0: ReactSystemFunctions.convertToTypeByName("Firma", "string"),
      UyeId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyeId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyeId
            : null
        ),
        "Guid"
      ),
      SiparisOlusturulanGoster_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "eserionaylanantekliflistmatbaa_833365_value", "value", "", "", "")
        ),
        "boolean"
      ),
      ara_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "eserionaylanantekliflistmatbaa_738662_value", "value", "", "", "")
        ),
        "string"
      ),
      OrderByColumn_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "eserionaylanantekliflistmatbaa_438082_value", "value", "", "key", "")
        ),
        "string"
      ),
      AscDesc_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "eserionaylanantekliflistmatbaa_663436_value", "value", "", "key", "")
        ),
        "string"
      ),
      header_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      footer_1: ReactSystemFunctions.convertToTypeByName("", "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EseriOnaylananTeklifListMatbaa/EseriOnaylananTeklifListMatbaaComponent_490181_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.TeklifEserOnaylananListToExcel = result?.data.teklifEserOnaylananListToExcel;
    stateVars.ExportExcel = result?.data.exportExcel;
    ReactSystemFunctions.downloadFile(result?.data?.exportExcel);
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  EseriOnaylananTeklifListMatbaaComponent_702503_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UserId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kUserId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kUserId
            : null
        ),
        "Guid"
      ),
      UserId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kUserId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kUserId
            : null
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EseriOnaylananTeklifListMatbaa/EseriOnaylananTeklifListMatbaaComponent_702503_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getTeklifSeciliIdTempCount = result?.data.getTeklifSeciliIdTempCount;
    stateVars.getTeklifSeciliIdTempUyeCount = result?.data.getTeklifSeciliIdTempUyeCount;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EseriOnaylananTeklifListMatbaaComponent_702503_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EseriOnaylananTeklifListMatbaaComponent_702503_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getTeklifSeciliIdTempCount?.length > 0
            ? stateVars.getTeklifSeciliIdTempCount[0].tempCount
            : this.state.getTeklifSeciliIdTempCount?.length > 0
            ? this.state.getTeklifSeciliIdTempCount[0].tempCount
            : null
        ),
        "0"
      )
    ) {
      stateVars.Notify = await ReactSystemFunctions.notify(
        this,
        ReactSystemFunctions.translateCustomActions(
          this.ml,
          "342fd0c6_5e2e_0fc7_eb39_e06f7b9a404f_notify",
          this.defaultML,
          "Teklif Seçilmeli!"
        ),
        "info",
        "bottom-right",
        2,
        null,
        null,
        null
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EseriOnaylananTeklifListMatbaaComponent_702503_onClick2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EseriOnaylananTeklifListMatbaaComponent_702503_onClick2_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  EseriOnaylananTeklifListMatbaaComponent_702503_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.getTeklifSeciliIdTempUyeCount?.length > 0
            ? stateVars.getTeklifSeciliIdTempUyeCount[0].tempUyeCount
            : this.state.getTeklifSeciliIdTempUyeCount?.length > 0
            ? this.state.getTeklifSeciliIdTempUyeCount[0].tempUyeCount
            : null
        ),
        "1"
      )
    ) {
      stateVars.Notify = await ReactSystemFunctions.notify(
        this,
        ReactSystemFunctions.translateCustomActions(
          this.ml,
          "88869aac_6e25_4672_3618_63f1de814833_notify",
          this.defaultML,
          "Farklı Muhatap İsimleri Seçilmiş!"
        ),
        "info",
        "bottom-right",
        2,
        null,
        null,
        null
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EseriOnaylananTeklifListMatbaaComponent_702503_onClick3_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EseriOnaylananTeklifListMatbaaComponent_702503_onClick3_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  EseriOnaylananTeklifListMatbaaComponent_702503_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      SeciliCount_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.getTeklifSeciliIdTempCount?.length > 0
            ? stateVars.getTeklifSeciliIdTempCount[0].tempCount
            : this.state.getTeklifSeciliIdTempCount?.length > 0
            ? this.state.getTeklifSeciliIdTempCount[0].tempCount
            : null
        ),
        "number"
      ),
      UyeCount_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.getTeklifSeciliIdTempUyeCount?.length > 0
            ? stateVars.getTeklifSeciliIdTempUyeCount[0].tempUyeCount
            : this.state.getTeklifSeciliIdTempUyeCount?.length > 0
            ? this.state.getTeklifSeciliIdTempUyeCount[0].tempUyeCount
            : null
        ),
        "number"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EseriOnaylananTeklifListMatbaa/EseriOnaylananTeklifListMatbaaComponent_702503_onClick3_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getTeklifSeciliIdTempControl = result?.data.getTeklifSeciliIdTempControl;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EseriOnaylananTeklifListMatbaaComponent_702503_onClick4_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EseriOnaylananTeklifListMatbaaComponent_702503_onClick4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getTeklifSeciliIdTempControl?.length > 0
            ? stateVars.getTeklifSeciliIdTempControl[0].tempControl
            : this.state.getTeklifSeciliIdTempControl?.length > 0
            ? this.state.getTeklifSeciliIdTempControl[0].tempControl
            : null
        ),
        "1"
      )
    ) {
      stateVars.Confirm = await Swal.fire({
        title: ReactSystemFunctions.translateCustomActions(
          this.ml,
          "6a09c811_214e_859c_2750_fcbfed98b470_confirmation",
          this.defaultML,
          "Seçilen Tekliflere Sipariş Oluşturulacak! İşleme Devam Edilsin mi?"
        ),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText:
          localStorage.getItem("ml") === "tr_TR"
            ? "Onayla"
            : localStorage.getItem("ml") === "fr_FR"
            ? "Confirmer"
            : "Confirm",
        cancelButtonText:
          localStorage.getItem("ml") === "tr_TR"
            ? "Vazgeç"
            : localStorage.getItem("ml") === "fr_FR"
            ? "Annuler"
            : "Cancel",
        customClass: {
          title: "swal2-font-poppins"
        }
      });

      if (stateVars.Confirm.isConfirmed) {
        KuikaAppManager.prepareForNavigation();
        KuikaAppManager.addToPageInputVariables("TSiparisKart", "TaslakmiKartmi", "Taslak");
        KuikaAppManager.addToPageInputVariables("TSiparisKart", "SiparisKartId", null);
        KuikaAppManager.addToPageInputVariables("TSiparisKart", "NewEditDisplay", "N");
        KuikaAppManager.addToPageInputVariables("TSiparisKart", "FirmaAd", "-");

        stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
          this,
          undefined,
          "EseriOnaylananTeklifListMatbaa",
          "TSiparisKart",
          "",
          "7d969ddf-5801-4eb6-b92b-12be35bdd565",
          "19049",
          null,
          "right",
          null,
          "75%",
          "100vh",
          true
        );

        this.props.form.setFieldsValue(formVars);
        this.setState(stateVars);
        KuikaAppManager.hideSpinner(this);
      }
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  EseriOnaylananTeklifListMatbaaComponent_13077_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      TalepUyeId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "eserionaylanantekliflistmatbaa_348605_value", "talepUyeId"),
        "Guid"
      ),
      TeklifKartId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "eserionaylanantekliflistmatbaa_348605_value", "teklifKartId"),
        "Guid"
      ),
      UserId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kUserId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kUserId
            : null
        ),
        "Guid"
      ),
      MuhatapmiFirmami_1: ReactSystemFunctions.convertToTypeByName("Firma", "string"),
      UyeId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyeId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyeId
            : null
        ),
        "Guid"
      ),
      SiparisOlusturulanGoster_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "eserionaylanantekliflistmatbaa_833365_value", "value", "", "", "")
        ),
        "boolean"
      ),
      ara_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "eserionaylanantekliflistmatbaa_738662_value", "value", "", "", "")
        ),
        "string"
      ),
      OrderByColumn_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "eserionaylanantekliflistmatbaa_438082_value", "value", "", "key", "")
        ),
        "string"
      ),
      AscDesc_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "eserionaylanantekliflistmatbaa_663436_value", "value", "", "key", "")
        ),
        "string"
      ),
      currentpage_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "eserionaylanantekliflistmatbaa_880048_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "eserionaylanantekliflistmatbaa_880048_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EseriOnaylananTeklifListMatbaa/EseriOnaylananTeklifListMatbaaComponent_13077_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.TeklifSeciliIdTempUpdate = result?.data.teklifSeciliIdTempUpdate;
    stateVars.TeklifEserOnaylananList = result?.data.teklifEserOnaylananList;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EseriOnaylananTeklifListMatbaaComponent_13077_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EseriOnaylananTeklifListMatbaaComponent_13077_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_348605 = this.state.TeklifEserOnaylananList;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EseriOnaylananTeklifListMatbaaComponent_138668_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      TalepUyeId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "eserionaylanantekliflistmatbaa_348605_value", "talepUyeId"),
        "Guid"
      ),
      TeklifKartId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "eserionaylanantekliflistmatbaa_348605_value", "teklifKartId"),
        "Guid"
      ),
      UserId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kUserId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kUserId
            : null
        ),
        "Guid"
      ),
      MuhatapmiFirmami_1: ReactSystemFunctions.convertToTypeByName("Firma", "string"),
      UyeId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyeId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyeId
            : null
        ),
        "Guid"
      ),
      SiparisOlusturulanGoster_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "eserionaylanantekliflistmatbaa_833365_value", "value", "", "", "")
        ),
        "boolean"
      ),
      ara_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "eserionaylanantekliflistmatbaa_738662_value", "value", "", "", "")
        ),
        "string"
      ),
      OrderByColumn_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "eserionaylanantekliflistmatbaa_438082_value", "value", "", "key", "")
        ),
        "string"
      ),
      AscDesc_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "eserionaylanantekliflistmatbaa_663436_value", "value", "", "key", "")
        ),
        "string"
      ),
      currentpage_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "eserionaylanantekliflistmatbaa_880048_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "eserionaylanantekliflistmatbaa_880048_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EseriOnaylananTeklifListMatbaa/EseriOnaylananTeklifListMatbaaComponent_138668_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.TeklifSeciliIdTempUpdate = result?.data.teklifSeciliIdTempUpdate;
    stateVars.TeklifEserOnaylananList = result?.data.teklifEserOnaylananList;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EseriOnaylananTeklifListMatbaaComponent_138668_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EseriOnaylananTeklifListMatbaaComponent_138668_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_348605 = this.state.TeklifEserOnaylananList;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EseriOnaylananTeklifListMatbaaComponent_246514_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "EserBilgileri",
      "prmTeklifTalepKartID",
      ReactSystemFunctions.value(this, "eserionaylanantekliflistmatbaa_348605_value", "teklifTalepKartId")
    );
    KuikaAppManager.addToPageInputVariables("EserBilgileri", "NewEditDisplay", "BOE");
    KuikaAppManager.addToPageInputVariables("EserBilgileri", "prmKitapAd", null);
    KuikaAppManager.addToPageInputVariables(
      "EserBilgileri",
      "teklifNo",
      ReactSystemFunctions.value(this, "eserionaylanantekliflistmatbaa_348605_value", "teklif_No")
    );
    KuikaAppManager.addToPageInputVariables(
      "EserBilgileri",
      "uyeAd",
      ReactSystemFunctions.value(this, "eserionaylanantekliflistmatbaa_348605_value", "muhatap")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "EseriOnaylananTeklifListMatbaa",
      "EserBilgileri",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "249342",
      null,
      "right",
      null,
      "50%",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EseriOnaylananTeklifListMatbaaComponent_708380_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "TeklifVermeKart",
      "prmTeklifTalepID",
      ReactSystemFunctions.value(this, "eserionaylanantekliflistmatbaa_348605_value", "teklifTalepKartId")
    );
    KuikaAppManager.addToPageInputVariables("TeklifVermeKart", "NewEditDisplay", "D");
    KuikaAppManager.addToPageInputVariables(
      "TeklifVermeKart",
      "prmUID",
      ReactSystemFunctions.value(this, "eserionaylanantekliflistmatbaa_348605_value", "uyeId")
    );
    KuikaAppManager.addToPageInputVariables("TeklifVermeKart", "prmMuhatapMiFirmaMi", "Firma");

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "EseriOnaylananTeklifListMatbaa",
      "TeklifVermeKart",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "571986",
      null,
      "right",
      null,
      "50%",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EseriOnaylananTeklifListMatbaaComponent_625771_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "TeklifVermeKart",
      "prmTeklifTalepID",
      ReactSystemFunctions.value(this, "eserionaylanantekliflistmatbaa_348605_value", "teklifTalepKartId")
    );
    KuikaAppManager.addToPageInputVariables("TeklifVermeKart", "NewEditDisplay", "D");
    KuikaAppManager.addToPageInputVariables(
      "TeklifVermeKart",
      "prmUID",
      ReactSystemFunctions.value(this, "eserionaylanantekliflistmatbaa_348605_value", "uyeId")
    );
    KuikaAppManager.addToPageInputVariables("TeklifVermeKart", "prmMuhatapMiFirmaMi", "Firma");

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "EseriOnaylananTeklifListMatbaa",
      "TeklifVermeKart",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "348381",
      null,
      "right",
      null,
      "50%",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EseriOnaylananTeklifListMatbaaComponent_942659_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "EserBilgileri",
      "prmTeklifTalepKartID",
      ReactSystemFunctions.value(this, "eserionaylanantekliflistmatbaa_348605_value", "teklifTalepKartId")
    );
    KuikaAppManager.addToPageInputVariables("EserBilgileri", "NewEditDisplay", "D");
    KuikaAppManager.addToPageInputVariables("EserBilgileri", "prmKitapAd", null);
    KuikaAppManager.addToPageInputVariables(
      "EserBilgileri",
      "teklifNo",
      ReactSystemFunctions.value(this, "eserionaylanantekliflistmatbaa_348605_value", "teklif_No")
    );
    KuikaAppManager.addToPageInputVariables(
      "EserBilgileri",
      "uyeAd",
      ReactSystemFunctions.value(this, "eserionaylanantekliflistmatbaa_348605_value", "firma")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "EseriOnaylananTeklifListMatbaa",
      "EserBilgileri",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "180819",
      null,
      "right",
      null,
      "50%",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EseriOnaylananTeklifListMatbaaComponent_880048_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      MuhatapmiFirmami_0: ReactSystemFunctions.convertToTypeByName("Firma", "string"),
      UyeId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyeId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyeId
            : null
        ),
        "Guid"
      ),
      SiparisOlusturulanGoster_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "eserionaylanantekliflistmatbaa_833365_value", "value", "", "", "")
        ),
        "boolean"
      ),
      ara_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "eserionaylanantekliflistmatbaa_738662_value", "value", "", "", "")
        ),
        "string"
      ),
      OrderByColumn_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "eserionaylanantekliflistmatbaa_438082_value", "value", "", "key", "")
        ),
        "string"
      ),
      AscDesc_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "eserionaylanantekliflistmatbaa_663436_value", "value", "", "key", "")
        ),
        "string"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "eserionaylanantekliflistmatbaa_880048_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "eserionaylanantekliflistmatbaa_880048_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EseriOnaylananTeklifListMatbaa/EseriOnaylananTeklifListMatbaaComponent_880048_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.TeklifEserOnaylananList = result?.data.teklifEserOnaylananList;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EseriOnaylananTeklifListMatbaaComponent_880048_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EseriOnaylananTeklifListMatbaaComponent_880048_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_348605 = this.state.TeklifEserOnaylananList;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EseriOnaylananTeklifListMatbaaRecuringAction_30_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      MuhatapmiFirmami_1: ReactSystemFunctions.convertToTypeByName("Firma", "string"),
      SiparisOlusturulanGoster_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "eserionaylanantekliflistmatbaa_833365_value", "value", "", "", "")
        ),
        "boolean"
      ),
      ara_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "eserionaylanantekliflistmatbaa_738662_value", "value", "", "", "")
        ),
        "string"
      ),
      OrderByColumn_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "eserionaylanantekliflistmatbaa_438082_value", "value", "", "key", "")
        ),
        "string"
      ),
      AscDesc_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "eserionaylanantekliflistmatbaa_663436_value", "value", "", "key", "")
        ),
        "string"
      ),
      currentpage_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "eserionaylanantekliflistmatbaa_880048_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "eserionaylanantekliflistmatbaa_880048_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    if (false) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EseriOnaylananTeklifListMatbaa/EseriOnaylananTeklifListMatbaaRecuringAction_30_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    formVars.eserionaylanantekliflistmatbaa_801926_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0
        ? stateVars.MyContext[0].profilFoto
        : this.state.MyContext?.length > 0
        ? this.state.MyContext[0].profilFoto
        : null
    );
    formVars.eserionaylanantekliflistmatbaa_172357_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0
        ? stateVars.MyContext[0].fullName
        : this.state.MyContext?.length > 0
        ? this.state.MyContext[0].fullName
        : null
    );
    formVars.eserionaylanantekliflistmatbaa_590045_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0
        ? stateVars.MyContext[0].uyeAd
        : this.state.MyContext?.length > 0
        ? this.state.MyContext[0].uyeAd
        : null
    );
    stateVars.TeklifEserOnaylananList = result?.data.teklifEserOnaylananList;

    stateVars.setPTotalCount01 = result?.data.setPTotalCount01;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EseriOnaylananTeklifListMatbaaRecuringAction_30_1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EseriOnaylananTeklifListMatbaaRecuringAction_30_1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.eserionaylanantekliflistmatbaa_801926_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0
        ? stateVars.MyContext[0].profilFoto
        : this.state.MyContext?.length > 0
        ? this.state.MyContext[0].profilFoto
        : null
    );

    formVars.eserionaylanantekliflistmatbaa_172357_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0
        ? stateVars.MyContext[0].fullName
        : this.state.MyContext?.length > 0
        ? this.state.MyContext[0].fullName
        : null
    );

    formVars.eserionaylanantekliflistmatbaa_590045_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0
        ? stateVars.MyContext[0].uyeAd
        : this.state.MyContext?.length > 0
        ? this.state.MyContext[0].uyeAd
        : null
    );

    stateVars.dataSource_348605 = this.state.TeklifEserOnaylananList;
    formVars.eserionaylanantekliflistmatbaa_880048_total = ReactSystemFunctions.value(
      this,
      stateVars.setPTotalCount01?.length > 0
        ? stateVars.setPTotalCount01[0].totalCount
        : this.state.setPTotalCount01?.length > 0
        ? this.state.setPTotalCount01[0].totalCount
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [242761, 918196, 19049, 249342, 571986, 348381, 180819] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.EseriOnaylananTeklifListMatbaaPageInit();
    }
  }
}
