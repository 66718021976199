import { FormInstance } from "antd/es/form";
import moment from "moment";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface ISikayetKart_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface ISikayetKart_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  MyContext: any[];
  MyContext_dummy: any[];
  GenelParametreler: any[];
  GenelParametreler_dummy: any[];
  DestekKategoriAll: any[];
  DestekKategoriAll_dummy: any[];
  SikayetById: any[];
  SikayetById_dummy: any[];
  OneriSikayetKategori: any[];
  OneriSikayetKategori_dummy: any[];
  SikayetKartOneriSikayetSelectBox: any[];
  SikayetKartOneriSikayetSelectBox_dummy: any[];
  DestekKategoriByOneriSikayet: any[];
  DestekKategoriByOneriSikayet_dummy: any[];
  ChangeVisibilityOf: string;
  ChangeVisibilityOf_dummy: string;
  ChangeEnabledOf: any;
  ChangeEnabledOf_dummy: any;
  GoBack: any;
  GoBack_dummy: any;
  SikayetSave: number;
  SikayetSave_dummy: number;
  SendMailSettings: any[];
  SendMailSettings_dummy: any[];
  SendMail: boolean;
  SendMail_dummy: boolean;
  UyelikTipiLowercase: any[];
  UyelikTipiLowercase_dummy: any[];
  SikayetOneriLowercase: any[];
  SikayetOneriLowercase_dummy: any[];
  isComp638119Visible: "visible" | "hidden";
  isComp696074Visible: "visible" | "hidden";
  isComp65720Visible: "visible" | "hidden";
  isComp389159Visible: "visible" | "hidden";
  isComp188190Enabled: "enabled" | "disabled";
  isComp739640Enabled: "enabled" | "disabled";
  isComp218205Enabled: "enabled" | "disabled";
  isComp132764Enabled: "enabled" | "disabled";
}

export class SikayetKart_ScreenBase extends React.PureComponent<ISikayetKart_ScreenProps, any> {
  sikayetkart_218205_value_kuikaSelectBoxRef: React.RefObject<any>;
  sikayetkart_132764_value_kuikaSelectBoxRef: React.RefObject<any>;
  ml = [
    {
      Id: "41cd36a4-5b07-cfca-4f7f-f48019ff4ff3",
      Name: "tr_TR",
      ShortName: "Türkçe",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "0069cee9-16ca-4a5a-aab9-dd58af96ca96", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "b1d39ed9-5992-4914-9659-c56cb35a42dc",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 186114, PropertyName: "value", Value: "Yardım ve Destek" },
        {
          Id: 65720,
          PropertyName: "value",
          Value:
            "Lütfen karşılaştığınız sorun veya almak istediğiniz destekle ilgili detayları aşağıdaki form aracılığıyla bizimle paylaşın.  Ekibimiz talebinizi inceleyerek, çözümlenmesi için en kısa sürede size geri dönüş yapacaktır. Doğru ve eksiksiz bilgi vermeniz, size daha hızlı yardımcı olmamızı sağlayacaktır. İlginiz ve anlayışınız için teşekkür ederiz."
        },
        {
          Id: 389159,
          PropertyName: "value",
          Value:
            "Bu ekranda kullanıcıların gönderdiği yardım ve destek taleplerini görebilirsiniz. Lütfen her talebi dikkatlice inceleyerek uygun yanıtı verin. Cevaplarınızı hızlı ve net bir şekilde ileterek kullanıcı memnuniyetini artırabilirsiniz. Gösterdiğiniz özen ve çaba için teşekkür ederiz."
        },
        { Id: 27617, PropertyName: "value", Value: "Başlık" },
        { Id: 617210, PropertyName: "value", Value: "Öneri / Şikayet" },
        { Id: 218205, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 925857, PropertyName: "value", Value: "Kategori" },
        { Id: 132764, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 411767, PropertyName: "value", Value: "Açıklama" },
        { Id: 736493, PropertyName: "value", Value: "Cevap" },
        { Id: 590823, PropertyName: "placeholder", Value: "Giriniz..." },
        { Id: 81611, PropertyName: "label", Value: "Cevapla" },
        { Id: 890290, PropertyName: "label", Value: "Vazgeç" },
        { Id: 977832, PropertyName: "label", Value: "Oluştur" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.sikayetkart_218205_value_kuikaSelectBoxRef = React.createRef();
    this.sikayetkart_132764_value_kuikaSelectBoxRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      MyContext: [],
      GenelParametreler: [],
      DestekKategoriAll: [],
      SikayetById: [],
      OneriSikayetKategori: [],
      SikayetKartOneriSikayetSelectBox: [],
      DestekKategoriByOneriSikayet: [],
      ChangeVisibilityOf: "",
      ChangeEnabledOf: "",
      GoBack: "",
      SikayetSave: 0,
      SendMailSettings: [],
      SendMail: false,
      UyelikTipiLowercase: [],
      SikayetOneriLowercase: [],
      isComp638119Visible: "hidden",
      isComp696074Visible: "hidden",
      isComp65720Visible: "hidden",
      isComp389159Visible: "hidden",
      isComp188190Enabled: "enabled",
      isComp739640Enabled: "enabled",
      isComp218205Enabled: "enabled",
      isComp132764Enabled: "enabled"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("sikayetkart", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.SikayetKartPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("sikayetkart", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("sikayetkart", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.SikayetKartPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      sikayetkart_188190_value: this.state.SikayetById?.at?.(0)?.baslik ?? undefined,
      sikayetkart_218205_value: this.state.SikayetById?.at?.(0)?.oneriSikayetID ?? undefined,
      sikayetkart_132764_value: this.state.SikayetById?.at?.(0)?.kategoriID ?? undefined,
      sikayetkart_739640_value: this.state.SikayetById?.at?.(0)?.metin ?? undefined,
      sikayetkart_590823_value: this.state.SikayetById?.at?.(0)?.cevap ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  SikayetKartPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      Id_3: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.SikayetId ?? this.props.screenInputs.sikayetid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "SikayetKart/SikayetKartPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    stateVars.isComp65720Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].rolName
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].rolName
            : null
        ),
        "ADMIIN"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp389159Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].rolName
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].rolName
            : null
        ),
        "ADMIIN"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.GenelParametreler = result?.data.genelParametreler;
    stateVars.DestekKategoriAll = result?.data.destekKategoriAll;
    stateVars.SikayetById = result?.data.sikayetById;
    formVars.sikayetkart_188190_value = ReactSystemFunctions.toString(
      this,
      stateVars.SikayetById?.length > 0
        ? stateVars.SikayetById[0].baslik
        : this.state.SikayetById?.length > 0
        ? this.state.SikayetById[0].baslik
        : null
    );
    formVars.sikayetkart_218205_value =
      stateVars.SikayetById?.length > 0
        ? stateVars.SikayetById[0].oneriSikayetID
        : this.state.SikayetById?.length > 0
        ? this.state.SikayetById[0].oneriSikayetID
        : null;
    formVars.sikayetkart_218205_options = stateVars.SikayetKartOneriSikayetSelectBox;
    formVars.sikayetkart_132764_value =
      stateVars.SikayetById?.length > 0
        ? stateVars.SikayetById[0].kategoriID
        : this.state.SikayetById?.length > 0
        ? this.state.SikayetById[0].kategoriID
        : null;
    formVars.sikayetkart_132764_options = stateVars.DestekKategoriByOneriSikayet;
    formVars.sikayetkart_739640_value = ReactSystemFunctions.toString(
      this,
      stateVars.SikayetById?.length > 0
        ? stateVars.SikayetById[0].metin
        : this.state.SikayetById?.length > 0
        ? this.state.SikayetById[0].metin
        : null
    );
    formVars.sikayetkart_590823_value = ReactSystemFunctions.toString(
      this,
      stateVars.SikayetById?.length > 0
        ? stateVars.SikayetById[0].cevap
        : this.state.SikayetById?.length > 0
        ? this.state.SikayetById[0].cevap
        : null
    );
    stateVars.OneriSikayetKategori = result?.data.oneriSikayetKategori;
    stateVars.SikayetKartOneriSikayetSelectBox = result?.data.sikayetKartOneriSikayetSelectBox;

    formVars.sikayetkart_218205_value =
      stateVars.SikayetById?.length > 0
        ? stateVars.SikayetById[0].oneriSikayetID
        : this.state.SikayetById?.length > 0
        ? this.state.SikayetById[0].oneriSikayetID
        : null;
    formVars.sikayetkart_218205_options = stateVars.SikayetKartOneriSikayetSelectBox;
    stateVars.DestekKategoriByOneriSikayet = result?.data.destekKategoriByOneriSikayet;

    formVars.sikayetkart_132764_value =
      stateVars.SikayetById?.length > 0
        ? stateVars.SikayetById[0].kategoriID
        : this.state.SikayetById?.length > 0
        ? this.state.SikayetById[0].kategoriID
        : null;
    formVars.sikayetkart_132764_options = stateVars.DestekKategoriByOneriSikayet;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.SikayetKartPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SikayetKartPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].rolName
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].rolName
            : null
        ),
        "ADMIIN"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp638119Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SikayetKartPageInit2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SikayetKartPageInit2_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  SikayetKartPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].rolName
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].rolName
            : null
        ),
        "ADMIIN"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp696074Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SikayetKartPageInit3_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SikayetKartPageInit3_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  SikayetKartPageInit3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].rolName
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].rolName
            : null
        ),
        "ADMIIN"
      )
    ) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp188190Enabled", "disabled");

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SikayetKartPageInit4_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SikayetKartPageInit4_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  SikayetKartPageInit4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].rolName
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].rolName
            : null
        ),
        "ADMIIN"
      )
    ) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp739640Enabled", "disabled");

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SikayetKartPageInit5_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SikayetKartPageInit5_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  SikayetKartPageInit5_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].rolName
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].rolName
            : null
        ),
        "ADMIIN"
      )
    ) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp218205Enabled", "disabled");

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SikayetKartPageInit6_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SikayetKartPageInit6_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  SikayetKartPageInit6_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].rolName
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].rolName
            : null
        ),
        "ADMIIN"
      )
    ) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp132764Enabled", "disabled");

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SikayetKartPageInit7_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SikayetKartPageInit7_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  SikayetKartPageInit7_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.isComp65720Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].rolName
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].rolName
            : null
        ),
        "ADMIIN"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp389159Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].rolName
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].rolName
            : null
        ),
        "ADMIIN"
      ) === true
        ? "visible"
        : "hidden";
    formVars.sikayetkart_188190_value = ReactSystemFunctions.toString(
      this,
      stateVars.SikayetById?.length > 0
        ? stateVars.SikayetById[0].baslik
        : this.state.SikayetById?.length > 0
        ? this.state.SikayetById[0].baslik
        : null
    );

    formVars.sikayetkart_218205_value = ReactSystemFunctions.toString(
      this,
      stateVars.SikayetById?.length > 0
        ? stateVars.SikayetById[0].oneriSikayetID
        : this.state.SikayetById?.length > 0
        ? this.state.SikayetById[0].oneriSikayetID
        : null
    );

    stateVars.dataSource_218205 = this.state.SikayetKartOneriSikayetSelectBox;
    stateVars.dataSource_218205 = this.state.SikayetKartOneriSikayetSelectBox;
    formVars.sikayetkart_132764_value = ReactSystemFunctions.toString(
      this,
      stateVars.SikayetById?.length > 0
        ? stateVars.SikayetById[0].kategoriID
        : this.state.SikayetById?.length > 0
        ? this.state.SikayetById[0].kategoriID
        : null
    );

    stateVars.dataSource_132764 = this.state.DestekKategoriByOneriSikayet;
    stateVars.dataSource_132764 = this.state.DestekKategoriByOneriSikayet;
    formVars.sikayetkart_739640_value = ReactSystemFunctions.toString(
      this,
      stateVars.SikayetById?.length > 0
        ? stateVars.SikayetById[0].metin
        : this.state.SikayetById?.length > 0
        ? this.state.SikayetById[0].metin
        : null
    );

    formVars.sikayetkart_590823_value = ReactSystemFunctions.toString(
      this,
      stateVars.SikayetById?.length > 0
        ? stateVars.SikayetById[0].cevap
        : this.state.SikayetById?.length > 0
        ? this.state.SikayetById[0].cevap
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  SikayetKartComponent_709644_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SikayetKartComponent_218205_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "sikayetkart_218205_value",
            "value",
            "SikayetKartOneriSikayetSelectBox",
            "id",
            ""
          )
        ),
        null
      ),
      message: "*",
      formName: "sikayetkart_218205_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      OneriSikayetKategoriID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "sikayetkart_218205_value",
            "value",
            "SikayetKartOneriSikayetSelectBox",
            "id",
            "id"
          )
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "SikayetKart/SikayetKartComponent_218205_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.DestekKategoriByOneriSikayet = result?.data.destekKategoriByOneriSikayet;

    formVars.sikayetkart_132764_options = stateVars.DestekKategoriByOneriSikayet;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.SikayetKartComponent_218205_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SikayetKartComponent_218205_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_132764 = this.state.DestekKategoriByOneriSikayet;
    stateVars.dataSource_132764 = this.state.DestekKategoriByOneriSikayet;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SikayetKartComponent_81611_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "sikayetkart_590823_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "sikayetkart_590823_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "sikayetkart_132764_value",
            "value",
            "DestekKategoriByOneriSikayet",
            "id",
            ""
          )
        ),
        null
      ),
      message: "*",
      formName: "sikayetkart_132764_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "sikayetkart_218205_value",
            "value",
            "SikayetKartOneriSikayetSelectBox",
            "id",
            ""
          )
        ),
        null
      ),
      message: "*",
      formName: "sikayetkart_218205_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      AdminAdi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].fullName
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].fullName
            : null
        ),
        "string"
      ),
      Baslik_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.SikayetById?.length > 0
            ? stateVars.SikayetById[0].baslik
            : this.state.SikayetById?.length > 0
            ? this.state.SikayetById[0].baslik
            : null
        ),
        "string"
      ),
      Cevap_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "sikayetkart_590823_value", "value", "", "", "")
        ),
        "string"
      ),
      CevapZaman_0: ReactSystemFunctions.convertToTypeByName(moment().format("YYYY-MM-DDTHH:mm:ss"), "Date"),
      KUserID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.SikayetById?.length > 0
            ? stateVars.SikayetById[0].kUserID
            : this.state.SikayetById?.length > 0
            ? this.state.SikayetById[0].kUserID
            : null
        ),
        "Guid"
      ),
      Metin_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.SikayetById?.length > 0
            ? stateVars.SikayetById[0].metin
            : this.state.SikayetById?.length > 0
            ? this.state.SikayetById[0].metin
            : null
        ),
        "string"
      ),
      UyeID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.SikayetById?.length > 0
            ? stateVars.SikayetById[0].uyeID
            : this.state.SikayetById?.length > 0
            ? this.state.SikayetById[0].uyeID
            : null
        ),
        "Guid"
      ),
      Zaman_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.SikayetById?.length > 0
            ? stateVars.SikayetById[0].zaman
            : this.state.SikayetById?.length > 0
            ? this.state.SikayetById[0].zaman
            : null
        ),
        "Date"
      ),
      KategoriID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "sikayetkart_132764_value",
            "value",
            "DestekKategoriByOneriSikayet",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      OneriSikayetID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "sikayetkart_218205_value",
            "value",
            "SikayetKartOneriSikayetSelectBox",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.SikayetId ?? this.props.screenInputs.sikayetid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "SikayetKart/SikayetKartComponent_81611_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SikayetSave = result?.data.sikayetSave;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.SikayetKartComponent_81611_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SikayetKartComponent_81611_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      actionkey_0: ReactSystemFunctions.convertToTypeByName("sikayetcevapla", "string"),
      uyeliktipi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyelikTipi
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyelikTipi
            : null
        ),
        "string"
      ),
      numara_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.GenelParametreler?.length > 0
            ? stateVars.GenelParametreler[0].destekTelNo
            : this.state.GenelParametreler?.length > 0
            ? this.state.GenelParametreler[0].destekTelNo
            : null
        ),
        "string"
      )
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "sikayetkart_218205_value",
            "value",
            "SikayetKartOneriSikayetSelectBox",
            "id",
            "id"
          )
        ),
        "00000000-0000-0000-0000-000000000002"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(this, "SikayetKart/SikayetKartComponent_81611_onClick1_", localVar);
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.SendMailSettings = result?.data.sendMailSettings;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SikayetKartComponent_81611_onClick2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SikayetKartComponent_81611_onClick2_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SikayetKartComponent_81611_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      actionkey_0: ReactSystemFunctions.convertToTypeByName("onericevapla", "string"),
      uyeliktipi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyelikTipi
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyelikTipi
            : null
        ),
        "string"
      ),
      numara_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.GenelParametreler?.length > 0
            ? stateVars.GenelParametreler[0].destekTelNo
            : this.state.GenelParametreler?.length > 0
            ? this.state.GenelParametreler[0].destekTelNo
            : null
        ),
        "string"
      )
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "sikayetkart_218205_value",
            "value",
            "SikayetKartOneriSikayetSelectBox",
            "id",
            "id"
          )
        ),
        "00000000-0000-0000-0000-000000000001"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(this, "SikayetKart/SikayetKartComponent_81611_onClick2_", localVar);
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.SendMailSettings = result?.data.sendMailSettings;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SikayetKartComponent_81611_onClick3_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SikayetKartComponent_81611_onClick3_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SikayetKartComponent_81611_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "sikayetkart_218205_value",
            "value",
            "SikayetKartOneriSikayetSelectBox",
            "id",
            ""
          )
        ),
        null
      ),
      message: "*",
      formName: "sikayetkart_218205_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      templateId_0: ReactSystemFunctions.convertToTypeByName("2e54c9af-447b-45a7-96a4-529c4595034b", "string"),
      receivers_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.SikayetById?.length > 0
            ? stateVars.SikayetById[0].kullanicimail
            : this.state.SikayetById?.length > 0
            ? this.state.SikayetById[0].kullanicimail
            : null
        ),
        "string"
      ),
      subject_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.SendMailSettings?.length > 0
            ? stateVars.SendMailSettings[0].mailSubject
            : this.state.SendMailSettings?.length > 0
            ? this.state.SendMailSettings[0].mailSubject
            : null
        ),
        "string"
      ),
      sender_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      cc_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      bcc_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentFileName_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentBase64_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      language_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      url_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.SendMailSettings?.length > 0
            ? stateVars.SendMailSettings[0].urLink
            : this.state.SendMailSettings?.length > 0
            ? this.state.SendMailSettings[0].urLink
            : null
        ),
        "string"
      ),
      PublinkDestekTelNo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.GenelParametreler?.length > 0
            ? stateVars.GenelParametreler[0].destekTelNo
            : this.state.GenelParametreler?.length > 0
            ? this.state.GenelParametreler[0].destekTelNo
            : null
        ),
        "string"
      ),
      sikayetoneri_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "sikayetkart_218205_value",
            "value",
            "SikayetKartOneriSikayetSelectBox",
            "id",
            "tanim"
          )
        ),
        "string"
      ),
      UyeIsmi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.SikayetById?.length > 0
            ? stateVars.SikayetById[0].firstName
            : this.state.SikayetById?.length > 0
            ? this.state.SikayetById[0].firstName
            : null
        ),
        "string"
      ),
      UyeSoyad_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.SikayetById?.length > 0
            ? stateVars.SikayetById[0].lastName
            : this.state.SikayetById?.length > 0
            ? this.state.SikayetById[0].lastName
            : null
        ),
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "SikayetKart/SikayetKartComponent_81611_onClick3_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SendMail = result?.data.sendMail;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.SikayetKartComponent_81611_onClick4_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SikayetKartComponent_81611_onClick4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SikayetKartComponent_890290_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SikayetKartComponent_977832_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "sikayetkart_188190_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "sikayetkart_188190_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "sikayetkart_739640_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "sikayetkart_739640_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "sikayetkart_132764_value",
            "value",
            "DestekKategoriByOneriSikayet",
            "id",
            ""
          )
        ),
        null
      ),
      message: "*",
      formName: "sikayetkart_132764_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "sikayetkart_218205_value",
            "value",
            "SikayetKartOneriSikayetSelectBox",
            "id",
            ""
          )
        ),
        null
      ),
      message: "*",
      formName: "sikayetkart_218205_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      AdminAdi_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      Baslik_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "sikayetkart_188190_value", "value", "", "", "")
        ),
        "string"
      ),
      Cevap_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      CevapZaman_0: ReactSystemFunctions.convertToTypeByName(null, "Date"),
      KUserID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kUserId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kUserId
            : null
        ),
        "Guid"
      ),
      Metin_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "sikayetkart_739640_value", "value", "", "", "")
        ),
        "string"
      ),
      UyeID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyeId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyeId
            : null
        ),
        "Guid"
      ),
      Zaman_0: ReactSystemFunctions.convertToTypeByName(moment().format("YYYY-MM-DDTHH:mm:ss"), "Date"),
      KategoriID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "sikayetkart_132764_value",
            "value",
            "DestekKategoriByOneriSikayet",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      OneriSikayetID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "sikayetkart_218205_value",
            "value",
            "SikayetKartOneriSikayetSelectBox",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.SikayetId ?? this.props.screenInputs.sikayetid,
        "Guid"
      ),
      UyelikTipi_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyelikTipi
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyelikTipi
            : null
        ),
        "string"
      ),
      SikayetOneri_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "sikayetkart_218205_value",
            "value",
            "SikayetKartOneriSikayetSelectBox",
            "id",
            "tanim"
          )
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "SikayetKart/SikayetKartComponent_977832_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SikayetSave = result?.data.sikayetSave;
    stateVars.UyelikTipiLowercase = result?.data.uyelikTipiLowercase;
    stateVars.SikayetOneriLowercase = result?.data.sikayetOneriLowercase;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.SikayetKartComponent_977832_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SikayetKartComponent_977832_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      actionkey_0: ReactSystemFunctions.convertToTypeByName("sikayetolustur", "string"),
      uyeliktipi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyelikTipi
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyelikTipi
            : null
        ),
        "string"
      ),
      numara_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.GenelParametreler?.length > 0
            ? stateVars.GenelParametreler[0].destekTelNo
            : this.state.GenelParametreler?.length > 0
            ? this.state.GenelParametreler[0].destekTelNo
            : null
        ),
        "string"
      )
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "sikayetkart_218205_value",
            "value",
            "SikayetKartOneriSikayetSelectBox",
            "id",
            "id"
          )
        ),
        "00000000-0000-0000-0000-000000000002"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "SikayetKart/SikayetKartComponent_977832_onClick1_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.SendMailSettings = result?.data.sendMailSettings;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SikayetKartComponent_977832_onClick2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SikayetKartComponent_977832_onClick2_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SikayetKartComponent_977832_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      actionkey_0: ReactSystemFunctions.convertToTypeByName("oneriolustur", "string"),
      uyeliktipi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyelikTipi
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyelikTipi
            : null
        ),
        "string"
      ),
      numara_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.GenelParametreler?.length > 0
            ? stateVars.GenelParametreler[0].destekTelNo
            : this.state.GenelParametreler?.length > 0
            ? this.state.GenelParametreler[0].destekTelNo
            : null
        ),
        "string"
      )
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "sikayetkart_218205_value",
            "value",
            "SikayetKartOneriSikayetSelectBox",
            "id",
            "id"
          )
        ),
        "00000000-0000-0000-0000-000000000001"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "SikayetKart/SikayetKartComponent_977832_onClick2_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.SendMailSettings = result?.data.sendMailSettings;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SikayetKartComponent_977832_onClick3_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SikayetKartComponent_977832_onClick3_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SikayetKartComponent_977832_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      templateId_0: ReactSystemFunctions.convertToTypeByName("2894e500-f942-42db-9f95-58dbc4faa96a", "string"),
      receivers_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.GenelParametreler?.length > 0
            ? stateVars.GenelParametreler[0].adminMail
            : this.state.GenelParametreler?.length > 0
            ? this.state.GenelParametreler[0].adminMail
            : null
        ),
        "string"
      ),
      subject_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.SendMailSettings?.length > 0
            ? stateVars.SendMailSettings[0].mailSubject
            : this.state.SendMailSettings?.length > 0
            ? this.state.SendMailSettings[0].mailSubject
            : null
        ),
        "string"
      ),
      sender_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      cc_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      bcc_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentFileName_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentBase64_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      language_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      url_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.SendMailSettings?.length > 0
            ? stateVars.SendMailSettings[0].urLink
            : this.state.SendMailSettings?.length > 0
            ? this.state.SendMailSettings[0].urLink
            : null
        ),
        "string"
      ),
      PublinkDestekTelNo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.GenelParametreler?.length > 0
            ? stateVars.GenelParametreler[0].destekTelNo
            : this.state.GenelParametreler?.length > 0
            ? this.state.GenelParametreler[0].destekTelNo
            : null
        ),
        "string"
      ),
      FirmaAdi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyeAd
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyeAd
            : null
        ),
        "string"
      ),
      UyeKategorisi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.UyelikTipiLowercase?.length > 0
            ? stateVars.UyelikTipiLowercase[0].uyelikTipiLower
            : this.state.UyelikTipiLowercase?.length > 0
            ? this.state.UyelikTipiLowercase[0].uyelikTipiLower
            : null
        ),
        "string"
      ),
      sikayetoneri_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.SikayetOneriLowercase?.length > 0
            ? stateVars.SikayetOneriLowercase[0].sikayetOneriLower
            : this.state.SikayetOneriLowercase?.length > 0
            ? this.state.SikayetOneriLowercase[0].sikayetOneriLower
            : null
        ),
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "SikayetKart/SikayetKartComponent_977832_onClick3_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SendMail = result?.data.sendMail;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.SikayetKartComponent_977832_onClick4_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SikayetKartComponent_977832_onClick4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
