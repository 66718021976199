import { FormInstance } from "antd/es/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IEserDetayKapakCinsi_Form_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IEserDetayKapakCinsi_Form_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GoBack: any;
  GoBack_dummy: any;
  EserDetay_KapakCinsiSelectById: any[];
  EserDetay_KapakCinsiSelectById_dummy: any[];
  EserDetay_KapakCinsiSave: number;
  EserDetay_KapakCinsiSave_dummy: number;
  isComp4720319Visible: "visible" | "hidden";
}

export class EserDetayKapakCinsi_Form_ScreenBase extends React.PureComponent<
  IEserDetayKapakCinsi_Form_ScreenProps,
  any
> {
  ml = [
    {
      Id: "41cd36a4-5b07-cfca-4f7f-f48019ff4ff3",
      Name: "tr_TR",
      ShortName: "Türkçe",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "0069cee9-16ca-4a5a-aab9-dd58af96ca96", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "4b7ed104-fb4a-4f6c-ae00-aaccde28a9f1",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 796540, PropertyName: "value", Value: "Kapak Cinsi" },
        { Id: 5582612, PropertyName: "value", Value: "Kapak Cinsi" },
        { Id: 6058867, PropertyName: "value", Value: "Kapak cinsi key" },
        { Id: 8239, PropertyName: "value", Value: "Aktif" },
        { Id: 9544, PropertyName: "label", Value: "Kaydet" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GoBack: "",
      EserDetay_KapakCinsiSelectById: [],
      EserDetay_KapakCinsiSave: 0,
      isComp4720319Visible: "hidden"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("eserdetaykapakcinsi_form", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.EserDetayKapakCinsi_FormPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("eserdetaykapakcinsi_form", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("eserdetaykapakcinsi_form", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.EserDetayKapakCinsi_FormPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      eserdetaykapakcinsi_form_7097003_value:
        this.state.EserDetay_KapakCinsiSelectById?.at?.(0)?.kapakCinsi ?? undefined,
      eserdetaykapakcinsi_form_7034747_value:
        this.state.EserDetay_KapakCinsiSelectById?.at?.(0)?.kapakCinsiKey ?? undefined,
      eserdetaykapakcinsi_form_331959_value: this.state.EserDetay_KapakCinsiSelectById?.at?.(0)?.aktifMi ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  EserDetayKapakCinsi_FormPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.Id ?? this.props.screenInputs.id, "Guid")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EserDetayKapakCinsi_Form/EserDetayKapakCinsi_FormPageInit",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.EserDetay_KapakCinsiSelectById = result?.data.eserDetay_KapakCinsiSelectById;
    formVars.eserdetaykapakcinsi_form_7097003_value = ReactSystemFunctions.toString(
      this,
      stateVars.EserDetay_KapakCinsiSelectById?.length > 0
        ? stateVars.EserDetay_KapakCinsiSelectById[0].kapakCinsi
        : this.state.EserDetay_KapakCinsiSelectById?.length > 0
        ? this.state.EserDetay_KapakCinsiSelectById[0].kapakCinsi
        : null
    );
    formVars.eserdetaykapakcinsi_form_7034747_value = ReactSystemFunctions.toString(
      this,
      stateVars.EserDetay_KapakCinsiSelectById?.length > 0
        ? stateVars.EserDetay_KapakCinsiSelectById[0].kapakCinsiKey
        : this.state.EserDetay_KapakCinsiSelectById?.length > 0
        ? this.state.EserDetay_KapakCinsiSelectById[0].kapakCinsiKey
        : null
    );
    formVars.eserdetaykapakcinsi_form_331959_value = ReactSystemFunctions.value(
      this,
      stateVars.EserDetay_KapakCinsiSelectById?.length > 0
        ? stateVars.EserDetay_KapakCinsiSelectById[0].aktifMi
        : this.state.EserDetay_KapakCinsiSelectById?.length > 0
        ? this.state.EserDetay_KapakCinsiSelectById[0].aktifMi
        : null
    );
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EserDetayKapakCinsi_FormPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EserDetayKapakCinsi_FormPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.eserdetaykapakcinsi_form_7097003_value = ReactSystemFunctions.toString(
      this,
      stateVars.EserDetay_KapakCinsiSelectById?.length > 0
        ? stateVars.EserDetay_KapakCinsiSelectById[0].kapakCinsi
        : this.state.EserDetay_KapakCinsiSelectById?.length > 0
        ? this.state.EserDetay_KapakCinsiSelectById[0].kapakCinsi
        : null
    );

    formVars.eserdetaykapakcinsi_form_7034747_value = ReactSystemFunctions.toString(
      this,
      stateVars.EserDetay_KapakCinsiSelectById?.length > 0
        ? stateVars.EserDetay_KapakCinsiSelectById[0].kapakCinsiKey
        : this.state.EserDetay_KapakCinsiSelectById?.length > 0
        ? this.state.EserDetay_KapakCinsiSelectById[0].kapakCinsiKey
        : null
    );

    formVars.eserdetaykapakcinsi_form_331959_value = ReactSystemFunctions.value(
      this,
      stateVars.EserDetay_KapakCinsiSelectById?.length > 0
        ? stateVars.EserDetay_KapakCinsiSelectById[0].aktifMi
        : this.state.EserDetay_KapakCinsiSelectById?.length > 0
        ? this.state.EserDetay_KapakCinsiSelectById[0].aktifMi
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  EserDetayKapakCinsi_FormComponent_968274_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EserDetayKapakCinsi_FormComponent_9544_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      AktifMi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "eserdetaykapakcinsi_form_331959_value", "value", "", "", "")
        ),
        "boolean"
      ),
      KapakCinsi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "eserdetaykapakcinsi_form_7097003_value", "value", "", "", "")
        ),
        "string"
      ),
      KapakCinsiKey_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "eserdetaykapakcinsi_form_7034747_value", "value", "", "", "")
        ),
        "number"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.Id ?? this.props.screenInputs.id, "Guid")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EserDetayKapakCinsi_Form/EserDetayKapakCinsi_FormComponent_9544_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.EserDetay_KapakCinsiSave = result?.data.eserDetay_KapakCinsiSave;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EserDetayKapakCinsi_FormComponent_9544_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EserDetayKapakCinsi_FormComponent_9544_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
