import { FormInstance } from "antd/es/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IEserDetaySelefon_Form_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IEserDetaySelefon_Form_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GoBack: any;
  GoBack_dummy: any;
  EserDetay_SelefonSelectById: any[];
  EserDetay_SelefonSelectById_dummy: any[];
  EserDetay_SelefonSave: number;
  EserDetay_SelefonSave_dummy: number;
  isComp7952580Visible: "visible" | "hidden";
  isComp129082Visible: "visible" | "hidden";
}

export class EserDetaySelefon_Form_ScreenBase extends React.PureComponent<IEserDetaySelefon_Form_ScreenProps, any> {
  ml = [
    {
      Id: "41cd36a4-5b07-cfca-4f7f-f48019ff4ff3",
      Name: "tr_TR",
      ShortName: "Türkçe",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "0069cee9-16ca-4a5a-aab9-dd58af96ca96", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "0095215f-616a-4535-a767-7c00d7dee9c0",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 796540, PropertyName: "value", Value: "Selefon" },
        { Id: 1194620, PropertyName: "value", Value: "Selefon" },
        { Id: 3474112, PropertyName: "value", Value: "Selefon key" },
        { Id: 374723, PropertyName: "value", Value: "Aktif" },
        { Id: 9544, PropertyName: "label", Value: "Kaydet" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GoBack: "",
      EserDetay_SelefonSelectById: [],
      EserDetay_SelefonSave: 0,
      isComp7952580Visible: "hidden",
      isComp129082Visible: "hidden"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("eserdetayselefon_form", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.EserDetaySelefon_FormPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("eserdetayselefon_form", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("eserdetayselefon_form", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.EserDetaySelefon_FormPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      eserdetayselefon_form_5801660_value: this.state.EserDetay_SelefonSelectById?.at?.(0)?.selefon ?? undefined,
      eserdetayselefon_form_2030385_value: this.state.EserDetay_SelefonSelectById?.at?.(0)?.selefonKey ?? undefined,
      eserdetayselefon_form_603861_value: this.state.EserDetay_SelefonSelectById?.at?.(0)?.aktifMi ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  EserDetaySelefon_FormPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.Id ?? this.props.screenInputs.id, "Guid")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EserDetaySelefon_Form/EserDetaySelefon_FormPageInit",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.EserDetay_SelefonSelectById = result?.data.eserDetay_SelefonSelectById;
    formVars.eserdetayselefon_form_5801660_value = ReactSystemFunctions.toString(
      this,
      stateVars.EserDetay_SelefonSelectById?.length > 0
        ? stateVars.EserDetay_SelefonSelectById[0].selefon
        : this.state.EserDetay_SelefonSelectById?.length > 0
        ? this.state.EserDetay_SelefonSelectById[0].selefon
        : null
    );
    formVars.eserdetayselefon_form_2030385_value = ReactSystemFunctions.toString(
      this,
      stateVars.EserDetay_SelefonSelectById?.length > 0
        ? stateVars.EserDetay_SelefonSelectById[0].selefonKey
        : this.state.EserDetay_SelefonSelectById?.length > 0
        ? this.state.EserDetay_SelefonSelectById[0].selefonKey
        : null
    );
    formVars.eserdetayselefon_form_603861_value = ReactSystemFunctions.value(
      this,
      stateVars.EserDetay_SelefonSelectById?.length > 0
        ? stateVars.EserDetay_SelefonSelectById[0].aktifMi
        : this.state.EserDetay_SelefonSelectById?.length > 0
        ? this.state.EserDetay_SelefonSelectById[0].aktifMi
        : null
    );
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EserDetaySelefon_FormPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EserDetaySelefon_FormPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.eserdetayselefon_form_5801660_value = ReactSystemFunctions.toString(
      this,
      stateVars.EserDetay_SelefonSelectById?.length > 0
        ? stateVars.EserDetay_SelefonSelectById[0].selefon
        : this.state.EserDetay_SelefonSelectById?.length > 0
        ? this.state.EserDetay_SelefonSelectById[0].selefon
        : null
    );

    formVars.eserdetayselefon_form_2030385_value = ReactSystemFunctions.toString(
      this,
      stateVars.EserDetay_SelefonSelectById?.length > 0
        ? stateVars.EserDetay_SelefonSelectById[0].selefonKey
        : this.state.EserDetay_SelefonSelectById?.length > 0
        ? this.state.EserDetay_SelefonSelectById[0].selefonKey
        : null
    );

    formVars.eserdetayselefon_form_603861_value = ReactSystemFunctions.value(
      this,
      stateVars.EserDetay_SelefonSelectById?.length > 0
        ? stateVars.EserDetay_SelefonSelectById[0].aktifMi
        : this.state.EserDetay_SelefonSelectById?.length > 0
        ? this.state.EserDetay_SelefonSelectById[0].aktifMi
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  EserDetaySelefon_FormComponent_968274_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EserDetaySelefon_FormComponent_9544_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      AktifMi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "eserdetayselefon_form_603861_value", "value", "", "", "")
        ),
        "boolean"
      ),
      Selefon_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "eserdetayselefon_form_5801660_value", "value", "", "", "")
        ),
        "string"
      ),
      SelefonKey_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "eserdetayselefon_form_2030385_value", "value", "", "", "")
        ),
        "number"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.Id ?? this.props.screenInputs.id, "Guid")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EserDetaySelefon_Form/EserDetaySelefon_FormComponent_9544_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.EserDetay_SelefonSave = result?.data.eserDetay_SelefonSave;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EserDetaySelefon_FormComponent_9544_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EserDetaySelefon_FormComponent_9544_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
