import { FormInstance } from "antd/es/form";
import { Guid } from "guid-typescript";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IPopupBandrolYapistirma_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IPopupBandrolYapistirma_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  MyContext: any[];
  MyContext_dummy: any[];
  BandrolYapistirmaById: any[];
  BandrolYapistirmaById_dummy: any[];
  GoBack: any;
  GoBack_dummy: any;
  ChangeEnabledOf: any;
  ChangeEnabledOf_dummy: any;
  EserDetay_BandrolStokSUM_UretimKontrol: number;
  EserDetay_BandrolStokSUM_UretimKontrol_dummy: number;
  SaveRecord: number;
  SaveRecord_dummy: number;
  getEserBilgileriIdByTeklifTalepKartID: any[];
  getEserBilgileriIdByTeklifTalepKartID_dummy: any[];
  BandrolStokSaveByBandrolYapistirmaID: number;
  BandrolStokSaveByBandrolYapistirmaID_dummy: number;
  UretimTamamlandiEnabledKontrol: any[];
  UretimTamamlandiEnabledKontrol_dummy: any[];
  isComp49781Enabled: "enabled" | "disabled";
  isComp618825Enabled: "enabled" | "disabled";
  isComp306016Enabled: "enabled" | "disabled";
  isComp136662Enabled: "enabled" | "disabled";
}

export class PopupBandrolYapistirma_ScreenBase extends React.PureComponent<IPopupBandrolYapistirma_ScreenProps, any> {
  popupbandrolyapistirma_49781_value_kuikaDateRef: React.RefObject<any>;
  popupbandrolyapistirma_136662_value_kuikaDateRef: React.RefObject<any>;
  ml = [
    {
      Id: "41cd36a4-5b07-cfca-4f7f-f48019ff4ff3",
      Name: "tr_TR",
      ShortName: "Türkçe",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "0069cee9-16ca-4a5a-aab9-dd58af96ca96", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "569b579e-2990-4fd7-8f3e-ff792aa89753",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 837654, PropertyName: "value", Value: "Bandrol Yapıştırma" },
        { Id: 637163, PropertyName: "value", Value: "Bandrol Yapıştırıldı" },
        { Id: 83184, PropertyName: "value", Value: "Yapıştırılma Tarihi" },
        { Id: 49781, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 86441, PropertyName: "value", Value: "Bandrol Yapıştırma Adet" },
        { Id: 768755, PropertyName: "value", Value: "Bandrol Fire Adet" },
        { Id: 229743, PropertyName: "value", Value: "Depo Teslim Edildi" },
        { Id: 376904, PropertyName: "value", Value: "Teslim Tarihi" },
        { Id: 136662, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 390415, PropertyName: "label", Value: "Kaydet" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.popupbandrolyapistirma_49781_value_kuikaDateRef = React.createRef();
    this.popupbandrolyapistirma_136662_value_kuikaDateRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      MyContext: [],
      BandrolYapistirmaById: [],
      GoBack: "",
      ChangeEnabledOf: "",
      EserDetay_BandrolStokSUM_UretimKontrol: 0,
      SaveRecord: false,
      getEserBilgileriIdByTeklifTalepKartID: [],
      BandrolStokSaveByBandrolYapistirmaID: 0,
      UretimTamamlandiEnabledKontrol: [],
      isComp49781Enabled: "disabled",
      isComp618825Enabled: "disabled",
      isComp306016Enabled: "disabled",
      isComp136662Enabled: "disabled"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("popupbandrolyapistirma", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.PopupBandrolYapistirmaPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("popupbandrolyapistirma", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("popupbandrolyapistirma", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.PopupBandrolYapistirmaPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      popupbandrolyapistirma_906699_value: this.state.BandrolYapistirmaById?.at?.(0)?.bandrolIslemiYapildi ?? undefined,
      popupbandrolyapistirma_49781_value: this.state.BandrolYapistirmaById?.at?.(0)?.bandrolTarihi ?? undefined,
      popupbandrolyapistirma_618825_value:
        this.state.BandrolYapistirmaById?.at?.(0)?.bandrolAdetYapistirma ?? undefined,
      popupbandrolyapistirma_306016_value: this.state.BandrolYapistirmaById?.at?.(0)?.bandrolAdetFire ?? undefined,
      popupbandrolyapistirma_29228_value: this.state.BandrolYapistirmaById?.at?.(0)?.depoTeslimiYapildi ?? undefined,
      popupbandrolyapistirma_136662_value: this.state.BandrolYapistirmaById?.at?.(0)?.depoTeslimTarihi ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  PopupBandrolYapistirmaPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmId ?? this.props.screenInputs.prmid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "PopupBandrolYapistirma/PopupBandrolYapistirmaPageInit",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    stateVars.BandrolYapistirmaById = result?.data.bandrolYapistirmaById;
    formVars.popupbandrolyapistirma_906699_value = ReactSystemFunctions.value(
      this,
      stateVars.BandrolYapistirmaById?.length > 0
        ? stateVars.BandrolYapistirmaById[0].bandrolIslemiYapildi
        : this.state.BandrolYapistirmaById?.length > 0
        ? this.state.BandrolYapistirmaById[0].bandrolIslemiYapildi
        : null
    );
    formVars.popupbandrolyapistirma_49781_value = ReactSystemFunctions.value(
      this,
      stateVars.BandrolYapistirmaById?.length > 0
        ? stateVars.BandrolYapistirmaById[0].bandrolTarihi
        : this.state.BandrolYapistirmaById?.length > 0
        ? this.state.BandrolYapistirmaById[0].bandrolTarihi
        : null
    );
    formVars.popupbandrolyapistirma_49781_disablebeforedate =
      this.props.screenInputs.dteBicak ?? this.props.screenInputs.dtebicak;
    formVars.popupbandrolyapistirma_618825_value = ReactSystemFunctions.value(
      this,
      stateVars.BandrolYapistirmaById?.length > 0
        ? stateVars.BandrolYapistirmaById[0].bandrolAdetYapistirma
        : this.state.BandrolYapistirmaById?.length > 0
        ? this.state.BandrolYapistirmaById[0].bandrolAdetYapistirma
        : null
    );
    formVars.popupbandrolyapistirma_306016_value = ReactSystemFunctions.value(
      this,
      stateVars.BandrolYapistirmaById?.length > 0
        ? stateVars.BandrolYapistirmaById[0].bandrolAdetFire
        : this.state.BandrolYapistirmaById?.length > 0
        ? this.state.BandrolYapistirmaById[0].bandrolAdetFire
        : null
    );
    formVars.popupbandrolyapistirma_29228_value = ReactSystemFunctions.value(
      this,
      stateVars.BandrolYapistirmaById?.length > 0
        ? stateVars.BandrolYapistirmaById[0].depoTeslimiYapildi
        : this.state.BandrolYapistirmaById?.length > 0
        ? this.state.BandrolYapistirmaById[0].depoTeslimiYapildi
        : null
    );
    formVars.popupbandrolyapistirma_136662_value = ReactSystemFunctions.value(
      this,
      stateVars.BandrolYapistirmaById?.length > 0
        ? stateVars.BandrolYapistirmaById[0].depoTeslimTarihi
        : this.state.BandrolYapistirmaById?.length > 0
        ? this.state.BandrolYapistirmaById[0].depoTeslimTarihi
        : null
    );
    formVars.popupbandrolyapistirma_136662_disablebeforedate = ReactSystemFunctions.value(
      this,
      stateVars.BandrolYapistirmaById?.length > 0
        ? stateVars.BandrolYapistirmaById[0].bandrolTarihi
        : this.state.BandrolYapistirmaById?.length > 0
        ? this.state.BandrolYapistirmaById[0].bandrolTarihi
        : null
    );
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.PopupBandrolYapistirmaPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  PopupBandrolYapistirmaPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.popupbandrolyapistirma_906699_value = ReactSystemFunctions.value(
      this,
      stateVars.BandrolYapistirmaById?.length > 0
        ? stateVars.BandrolYapistirmaById[0].bandrolIslemiYapildi
        : this.state.BandrolYapistirmaById?.length > 0
        ? this.state.BandrolYapistirmaById[0].bandrolIslemiYapildi
        : null
    );

    formVars.popupbandrolyapistirma_49781_value = ReactSystemFunctions.value(
      this,
      stateVars.BandrolYapistirmaById?.length > 0
        ? stateVars.BandrolYapistirmaById[0].bandrolTarihi
        : this.state.BandrolYapistirmaById?.length > 0
        ? this.state.BandrolYapistirmaById[0].bandrolTarihi
        : null
    );

    stateVars.isComp49781Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "popupbandrolyapistirma_906699_value", "value", "", "", "")
        ),
        true
      ) === true
        ? "enabled"
        : "disabled";
    formVars.popupbandrolyapistirma_618825_value = ReactSystemFunctions.value(
      this,
      stateVars.BandrolYapistirmaById?.length > 0
        ? stateVars.BandrolYapistirmaById[0].bandrolAdetYapistirma
        : this.state.BandrolYapistirmaById?.length > 0
        ? this.state.BandrolYapistirmaById[0].bandrolAdetYapistirma
        : null
    );

    stateVars.isComp618825Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "popupbandrolyapistirma_906699_value", "value", "", "", "")
        ),
        true
      ) === true
        ? "enabled"
        : "disabled";
    formVars.popupbandrolyapistirma_306016_value = ReactSystemFunctions.value(
      this,
      stateVars.BandrolYapistirmaById?.length > 0
        ? stateVars.BandrolYapistirmaById[0].bandrolAdetFire
        : this.state.BandrolYapistirmaById?.length > 0
        ? this.state.BandrolYapistirmaById[0].bandrolAdetFire
        : null
    );

    stateVars.isComp306016Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "popupbandrolyapistirma_906699_value", "value", "", "", "")
        ),
        true
      ) === true
        ? "enabled"
        : "disabled";
    formVars.popupbandrolyapistirma_29228_value = ReactSystemFunctions.value(
      this,
      stateVars.BandrolYapistirmaById?.length > 0
        ? stateVars.BandrolYapistirmaById[0].depoTeslimiYapildi
        : this.state.BandrolYapistirmaById?.length > 0
        ? this.state.BandrolYapistirmaById[0].depoTeslimiYapildi
        : null
    );

    formVars.popupbandrolyapistirma_136662_value = ReactSystemFunctions.value(
      this,
      stateVars.BandrolYapistirmaById?.length > 0
        ? stateVars.BandrolYapistirmaById[0].depoTeslimTarihi
        : this.state.BandrolYapistirmaById?.length > 0
        ? this.state.BandrolYapistirmaById[0].depoTeslimTarihi
        : null
    );
    formVars.popupbandrolyapistirma_136662_disableBeforeDate = ReactSystemFunctions.value(
      this,
      stateVars.BandrolYapistirmaById?.length > 0
        ? stateVars.BandrolYapistirmaById[0].bandrolTarihi
        : this.state.BandrolYapistirmaById?.length > 0
        ? this.state.BandrolYapistirmaById[0].bandrolTarihi
        : null
    );

    formVars.popupbandrolyapistirma_136662_value = ReactSystemFunctions.value(
      this,
      stateVars.BandrolYapistirmaById?.length > 0
        ? stateVars.BandrolYapistirmaById[0].depoTeslimTarihi
        : this.state.BandrolYapistirmaById?.length > 0
        ? this.state.BandrolYapistirmaById[0].depoTeslimTarihi
        : null
    );
    formVars.popupbandrolyapistirma_136662_disableBeforeDate = ReactSystemFunctions.value(
      this,
      stateVars.BandrolYapistirmaById?.length > 0
        ? stateVars.BandrolYapistirmaById[0].bandrolTarihi
        : this.state.BandrolYapistirmaById?.length > 0
        ? this.state.BandrolYapistirmaById[0].bandrolTarihi
        : null
    );

    stateVars.isComp136662Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "popupbandrolyapistirma_29228_value", "value", "", "", "")
        ),
        true
      ) === true
        ? "enabled"
        : "disabled";

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  PopupBandrolYapistirmaComponent_180153_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  PopupBandrolYapistirmaComponent_906699_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "popupbandrolyapistirma_906699_value", "value", "", "", "")
        ),
        true
      )
    ) {
      KuikaAppManager.showSpinner(this);

      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp49781Enabled", "enabled");

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.PopupBandrolYapistirmaComponent_906699_onChange1_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.PopupBandrolYapistirmaComponent_906699_onChange1_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  PopupBandrolYapistirmaComponent_906699_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "popupbandrolyapistirma_906699_value", "value", "", "", "")
        ),
        true
      )
    ) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp618825Enabled", "enabled");

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.PopupBandrolYapistirmaComponent_906699_onChange2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.PopupBandrolYapistirmaComponent_906699_onChange2_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  PopupBandrolYapistirmaComponent_906699_onChange2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "popupbandrolyapistirma_906699_value", "value", "", "", "")
        ),
        true
      )
    ) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp306016Enabled", "enabled");

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  PopupBandrolYapistirmaComponent_29228_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "popupbandrolyapistirma_29228_value", "value", "", "", "")
        ),
        true
      )
    ) {
      KuikaAppManager.showSpinner(this);

      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp136662Enabled", "enabled");

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  PopupBandrolYapistirmaComponent_390415_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "popupbandrolyapistirma_618825_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "popupbandrolyapistirma_618825_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "popupbandrolyapistirma_906699_value", "value", "", "", "")
        ),
        true
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "popupbandrolyapistirma_306016_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "popupbandrolyapistirma_306016_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "popupbandrolyapistirma_906699_value", "value", "", "", "")
        ),
        true
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      EserBilgileriID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      ),
      UyeID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyeId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyeId
            : null
        ),
        "Guid"
      ),
      RolName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].rolName
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].rolName
            : null
        ),
        "string"
      ),
      Yapistirma_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "popupbandrolyapistirma_618825_value", "value", "", "", "")
        ),
        "number"
      ),
      Fire_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "popupbandrolyapistirma_306016_value", "value", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);
    if (
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.NewEditDisplay ?? this.props.screenInputs.neweditdisplay,
        "N"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "PopupBandrolYapistirma/PopupBandrolYapistirmaComponent_390415_onClick",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.EserDetay_BandrolStokSUM_UretimKontrol = result?.data.eserDetay_BandrolStokSUM_UretimKontrol;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.PopupBandrolYapistirmaComponent_390415_onClick1_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.PopupBandrolYapistirmaComponent_390415_onClick1_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  PopupBandrolYapistirmaComponent_390415_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "popupbandrolyapistirma_306016_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "popupbandrolyapistirma_306016_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "popupbandrolyapistirma_906699_value", "value", "", "", "")
        ),
        true
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "popupbandrolyapistirma_618825_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "popupbandrolyapistirma_618825_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "popupbandrolyapistirma_906699_value", "value", "", "", "")
        ),
        true
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "popupbandrolyapistirma_49781_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "popupbandrolyapistirma_49781_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "popupbandrolyapistirma_906699_value", "value", "", "", "")
        ),
        true
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "popupbandrolyapistirma_906699_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "popupbandrolyapistirma_906699_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "popupbandrolyapistirma_29228_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "popupbandrolyapistirma_136662_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "popupbandrolyapistirma_29228_value", "value", "", "", "")
        ),
        true
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmId ?? this.props.screenInputs.prmid,
        "Guid"
      ),
      BandrolAdetFire_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "popupbandrolyapistirma_306016_value", "value", "", "", "")
        ),
        "number"
      ),
      BandrolAdetYapistirma_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "popupbandrolyapistirma_618825_value", "value", "", "", "")
        ),
        "number"
      ),
      BandrolTarihi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "popupbandrolyapistirma_49781_value", "value", "", "", "")
        ),
        "Date"
      ),
      TeklifTalepKartID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      ),
      BandrolIslemiYapildi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "popupbandrolyapistirma_906699_value", "value", "", "", "")
        ),
        "boolean"
      ),
      DepoTeslimiYapildi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "popupbandrolyapistirma_29228_value", "value", "", "", "")
        ),
        "boolean"
      ),
      DepoTeslimTarihi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "popupbandrolyapistirma_136662_value", "value", "", "", "")
        ),
        "Date"
      ),
      TeklifTalepKartId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      ),
      Adet_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "popupbandrolyapistirma_618825_value", "value", "", "", "")
        ),
        "number"
      ),
      GCAciklama_2: ReactSystemFunctions.convertToTypeByName("Üretim Bandrol Yapıştırma", "string"),
      GCTarih_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "popupbandrolyapistirma_49781_value", "value", "", "", "")
        ),
        "Date"
      ),
      StokGC_2: ReactSystemFunctions.convertToTypeByName("C", "string"),
      UyeID_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyeId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyeId
            : null
        ),
        "Guid"
      ),
      BandrolYapistirmaID_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmId ?? this.props.screenInputs.prmid,
        "Guid"
      ),
      Id_2: ReactSystemFunctions.convertToTypeByName(Guid.create().toString(), "Guid"),
      Adet_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "popupbandrolyapistirma_306016_value", "value", "", "", "")
        ),
        "number"
      ),
      GCAciklama_3: ReactSystemFunctions.convertToTypeByName("Üretim Bandrol Fire", "string"),
      GCTarih_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "popupbandrolyapistirma_49781_value", "value", "", "", "")
        ),
        "Date"
      ),
      StokGC_3: ReactSystemFunctions.convertToTypeByName("C", "string"),
      UyeID_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyeId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyeId
            : null
        ),
        "Guid"
      ),
      BandrolYapistirmaID_3: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmId ?? this.props.screenInputs.prmid,
        "Guid"
      ),
      Id_3: ReactSystemFunctions.convertToTypeByName(Guid.create().toString(), "Guid"),
      TeklifTalepKartId_4: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "PopupBandrolYapistirma/PopupBandrolYapistirmaComponent_390415_onClick1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_1;
    stateVars.getEserBilgileriIdByTeklifTalepKartID = result?.data.getEserBilgileriIdByTeklifTalepKartID;
    stateVars.BandrolStokSaveByBandrolYapistirmaID = result?.data.bandrolStokSaveByBandrolYapistirmaID;
    stateVars.BandrolStokSaveByBandrolYapistirmaID = result?.data.bandrolStokSaveByBandrolYapistirmaID;
    stateVars.UretimTamamlandiEnabledKontrol = result?.data.uretimTamamlandiEnabledKontrol;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.PopupBandrolYapistirmaComponent_390415_onClick2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  PopupBandrolYapistirmaComponent_390415_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
