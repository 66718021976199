import { FormInstance } from "antd/es/form";
import { Guid } from "guid-typescript";
import moment from "moment";
import React from "react";
import Swal from "sweetalert2";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface ITSiparisKart_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface ITSiparisKart_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GoBack: any;
  GoBack_dummy: any;
  MyContext: any[];
  MyContext_dummy: any[];
  getTempTalepUyeId: any[];
  getTempTalepUyeId_dummy: any[];
  SetValueOf: any;
  SetValueOf_dummy: any;
  TaslakSiparisBaslikBilgi: any[];
  TaslakSiparisBaslikBilgi_dummy: any[];
  TaslakSiparisTeklifList: any[];
  TaslakSiparisTeklifList_dummy: any[];
  TaslakSiparisTeklifListSUM: any[];
  TaslakSiparisTeklifListSUM_dummy: any[];
  TaslakSiparisTeklifListCount: any[];
  TaslakSiparisTeklifListCount_dummy: any[];
  SatisTuruAll: any[];
  SatisTuruAll_dummy: any[];
  SiparisKartDuzeltmeNotuBySiparisKartID: any[];
  SiparisKartDuzeltmeNotuBySiparisKartID_dummy: any[];
  GenelParametreler: any[];
  GenelParametreler_dummy: any[];
  EserBilgileri_TeklifEdenKisiEmail: any[];
  EserBilgileri_TeklifEdenKisiEmail_dummy: any[];
  ChangeEnabledOf: any;
  ChangeEnabledOf_dummy: any;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  SaveRecord: number;
  SaveRecord_dummy: number;
  getGuid: any[];
  getGuid_dummy: any[];
  SiparisKartSave: number;
  SiparisKartSave_dummy: number;
  SiparisDetayInsert: number;
  SiparisDetayInsert_dummy: number;
  TeklifSeciliIdTempDelete: number;
  TeklifSeciliIdTempDelete_dummy: number;
  Notify: boolean;
  Notify_dummy: boolean;
  getSiparisNoBySiparisKartId: any[];
  getSiparisNoBySiparisKartId_dummy: any[];
  SendMailSettings: any[];
  SendMailSettings_dummy: any[];
  getKUserbyUserName: any[];
  getKUserbyUserName_dummy: any[];
  UyelikTipiLowercase: any[];
  UyelikTipiLowercase_dummy: any[];
  SendMail: boolean;
  SendMail_dummy: boolean;
  SiparisMusteriOnay: number;
  SiparisMusteriOnay_dummy: number;
  isComp448710Visible: "visible" | "hidden";
  isComp251583Visible: "visible" | "hidden";
  isComp409379Visible: "visible" | "hidden";
  isComp933399Visible: "visible" | "hidden";
  isComp643963Visible: "visible" | "hidden";
  isComp354461Visible: "visible" | "hidden";
  isComp151729Visible: "visible" | "hidden";
  isComp608307Enabled: "enabled" | "disabled";
  isComp312646Enabled: "enabled" | "disabled";
  isComp583315Enabled: "enabled" | "disabled";
  isComp309717Enabled: "enabled" | "disabled";
  isComp881644Enabled: "enabled" | "disabled";
  isComp232664Enabled: "enabled" | "disabled";
  isComp252187Enabled: "enabled" | "disabled";
  isComp661093Enabled: "enabled" | "disabled";
  isComp999050Enabled: "enabled" | "disabled";
  isComp947648Enabled: "enabled" | "disabled";
  isComp419193Enabled: "enabled" | "disabled";
  isComp169180Enabled: "enabled" | "disabled";
  isComp738903Enabled: "enabled" | "disabled";
  isComp731864Enabled: "enabled" | "disabled";
  isComp823113Enabled: "enabled" | "disabled";
  isComp313721Enabled: "enabled" | "disabled";
  isComp73972Enabled: "enabled" | "disabled";
  isComp970873Enabled: "enabled" | "disabled";
}

export class TSiparisKart_ScreenBase extends React.PureComponent<ITSiparisKart_ScreenProps, any> {
  tsipariskart_312646_value_kuikaDateRef: React.RefObject<any>;
  tsipariskart_881644_value_kuikaSelectBoxRef: React.RefObject<any>;
  tsipariskart_348605_value_kuikaTableRef: React.RefObject<any>;
  tsipariskart_880048_value_kuikaPaginationRef: React.RefObject<any>;
  tsipariskart_115097_value_kuikaTableRef: React.RefObject<any>;
  ml = [
    {
      Id: "41cd36a4-5b07-cfca-4f7f-f48019ff4ff3",
      Name: "tr_TR",
      ShortName: "Türkçe",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "0069cee9-16ca-4a5a-aab9-dd58af96ca96", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "4fe7bfa4-08b9-408a-8e12-19490b3fa523",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 364094, PropertyName: "value", Value: "Sipariş Formu" },
        { Id: 181720, PropertyName: "value", Value: "Sipariş Başlığı" },
        { Id: 886084, PropertyName: "value", Value: "Müşteri" },
        { Id: 348394, PropertyName: "value", Value: "Belge No" },
        { Id: 608307, PropertyName: "placeholder", Value: "Otomatik oluşturulacak!" },
        { Id: 554893, PropertyName: "value", Value: "Fatura Ünvanı" },
        { Id: 649640, PropertyName: "value", Value: "Tarih" },
        { Id: 278513, PropertyName: "value", Value: "Telefon / Faks" },
        { Id: 116308, PropertyName: "value", Value: "Teklif Geçerlilk" },
        { Id: 458487, PropertyName: "value", Value: "Vergi Dairesi / No" },
        { Id: 189745, PropertyName: "value", Value: "Karşı Sipariş ?" },
        { Id: 394882, PropertyName: "value", Value: "Referans Teklif No" },
        { Id: 631384, PropertyName: "value", Value: "Satış Türü" },
        { Id: 630504, PropertyName: "value", Value: "Adres" },
        { Id: 561253, PropertyName: "value", Value: "Satış Sorumlusu" },
        { Id: 99105, PropertyName: "value", Value: "Sipariş İçeriği" },
        { Id: 348605, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 27045, PropertyName: "title", Value: "Teklif No" },
        { Id: 736543, PropertyName: "value", Value: "[datafield:teklif_no]" },
        { Id: 833287, PropertyName: "title", Value: "Ürün Kodu ve Tanımı" },
        { Id: 534463, PropertyName: "value", Value: "[datafield:kitapad]" },
        { Id: 330977, PropertyName: "value", Value: "[datafield:infoebat]" },
        { Id: 145461, PropertyName: "value", Value: "[datafield:infosayfasayisi]" },
        { Id: 765857, PropertyName: "value", Value: "[datafield:infoicbaskikagit]" },
        { Id: 433783, PropertyName: "value", Value: "[datafield:infoicbaskigramaj]" },
        { Id: 414184, PropertyName: "value", Value: "[datafield:infokapakcinsi]" },
        { Id: 44338, PropertyName: "value", Value: "[datafield:infokapakgramaj]" },
        { Id: 831405, PropertyName: "value", Value: "[datafield:inforenk]" },
        { Id: 774409, PropertyName: "value", Value: "[datafield:infociltbicimi]" },
        { Id: 772805, PropertyName: "value", Value: "[datafield:infoselefon]" },
        { Id: 256601, PropertyName: "title", Value: "Miktar" },
        { Id: 744431, PropertyName: "value", Value: "[datafield:baskiadet]" },
        { Id: 52036, PropertyName: "title", Value: "Birim" },
        { Id: 467153, PropertyName: "title", Value: "Birim Fiyat (TRY)" },
        { Id: 658945, PropertyName: "value", Value: "[datafield:teklifbirimfiyatytl]" },
        { Id: 558595, PropertyName: "title", Value: "Tutar (TRY)" },
        { Id: 586928, PropertyName: "value", Value: "[datafield:teklifindirimlifiyatytl]" },
        { Id: 421555, PropertyName: "title", Value: "KDV" },
        { Id: 863036, PropertyName: "value", Value: "[datafield:kdv]" },
        { Id: 205833, PropertyName: "title", Value: "KDV li Tutar (TRY)" },
        { Id: 268800, PropertyName: "value", Value: "[datafield:teklifkdvlifiyatytl]" },
        { Id: 400748, PropertyName: "value", Value: "Net Tutar" },
        { Id: 653212, PropertyName: "value", Value: "KDV" },
        { Id: 728535, PropertyName: "value", Value: "Genel Toplam" },
        { Id: 217672, PropertyName: "value", Value: "Teslim ve Sevk Bilgileri" },
        { Id: 80413, PropertyName: "value", Value: "Taşıma Notu" },
        { Id: 90496, PropertyName: "value", Value: "Taşıma Ödeme Türü" },
        { Id: 336958, PropertyName: "value", Value: "Sevk Planı" },
        { Id: 129379, PropertyName: "value", Value: "Ödeme Bilgileri" },
        { Id: 794883, PropertyName: "value", Value: "Ödeme Koşulu" },
        { Id: 529376, PropertyName: "value", Value: "Uygulanacak Kur" },
        { Id: 249818, PropertyName: "value", Value: "Genel Açıklama" },
        { Id: 627081, PropertyName: "value", Value: "Açıklama" },
        { Id: 303599, PropertyName: "value", Value: "Düzeltme Notları" },
        { Id: 177991, PropertyName: "placeholder", Value: "Düzeltme isteği açıklaması giriniz." },
        { Id: 772233, PropertyName: "label", Value: "Açıklama Ekle" },
        { Id: 115097, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 550863, PropertyName: "title", Value: "Düzeltme Notları Tarihçe" },
        { Id: 226193, PropertyName: "value", Value: "[datafield:duzeltmenotu]" },
        { Id: 356474, PropertyName: "value", Value: "[datafield:userinfo]" },
        { Id: 702503, PropertyName: "label", Value: "Taslak Sipariş Oluştur" },
        { Id: 833155, PropertyName: "label", Value: "Kaydet & Müşteriye Onaya Gönder" },
        { Id: 126479, PropertyName: "label", Value: "Kaydet & Müşteriye Onaya Gönder" },
        { Id: 842163, PropertyName: "label", Value: "Düzeltme İçin Geri Gönder" },
        { Id: 155808, PropertyName: "label", Value: "Red Et" },
        { Id: 235855, PropertyName: "label", Value: "Onayla" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.tsipariskart_312646_value_kuikaDateRef = React.createRef();
    this.tsipariskart_881644_value_kuikaSelectBoxRef = React.createRef();
    this.tsipariskart_348605_value_kuikaTableRef = React.createRef();
    this.tsipariskart_880048_value_kuikaPaginationRef = React.createRef();
    this.tsipariskart_115097_value_kuikaTableRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GoBack: "",
      MyContext: [],
      getTempTalepUyeId: [],
      SetValueOf: "",
      TaslakSiparisBaslikBilgi: [],
      TaslakSiparisTeklifList: [],
      TaslakSiparisTeklifListSUM: [],
      TaslakSiparisTeklifListCount: [],
      SatisTuruAll: [],
      SiparisKartDuzeltmeNotuBySiparisKartID: [],
      GenelParametreler: [],
      EserBilgileri_TeklifEdenKisiEmail: [],
      ChangeEnabledOf: "",
      NAVIGATE: "",
      SaveRecord: false,
      getGuid: [],
      SiparisKartSave: 0,
      SiparisDetayInsert: 0,
      TeklifSeciliIdTempDelete: 0,
      Notify: false,
      getSiparisNoBySiparisKartId: [],
      SendMailSettings: [],
      getKUserbyUserName: [],
      UyelikTipiLowercase: [],
      SendMail: false,
      SiparisMusteriOnay: 0,
      isComp448710Visible: "hidden",
      isComp251583Visible: "hidden",
      isComp409379Visible: "hidden",
      isComp933399Visible: "hidden",
      isComp643963Visible: "hidden",
      isComp354461Visible: "hidden",
      isComp151729Visible: "hidden",
      isComp608307Enabled: "enabled",
      isComp312646Enabled: "enabled",
      isComp583315Enabled: "enabled",
      isComp309717Enabled: "enabled",
      isComp881644Enabled: "enabled",
      isComp232664Enabled: "enabled",
      isComp252187Enabled: "enabled",
      isComp661093Enabled: "enabled",
      isComp999050Enabled: "enabled",
      isComp947648Enabled: "enabled",
      isComp419193Enabled: "enabled",
      isComp169180Enabled: "enabled",
      isComp738903Enabled: "enabled",
      isComp731864Enabled: "enabled",
      isComp823113Enabled: "enabled",
      isComp313721Enabled: "enabled",
      isComp73972Enabled: "enabled",
      isComp970873Enabled: "enabled"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("tsipariskart", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.TSiparisKartPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("tsipariskart", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("tsipariskart", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.TSiparisKartPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      tsipariskart_252187_value: this.state.TaslakSiparisBaslikBilgi?.at?.(0)?.muhatap ?? undefined,
      tsipariskart_608307_value: this.state.TaslakSiparisBaslikBilgi?.at?.(0)?.siparis_No ?? undefined,
      tsipariskart_661093_value: this.state.TaslakSiparisBaslikBilgi?.at?.(0)?.muhatap ?? undefined,
      tsipariskart_999050_value: this.state.TaslakSiparisBaslikBilgi?.at?.(0)?.telFaks ?? undefined,
      tsipariskart_583315_value: this.state.TaslakSiparisBaslikBilgi?.at?.(0)?.teklifGecerlilik ?? undefined,
      tsipariskart_947648_value: this.state.TaslakSiparisBaslikBilgi?.at?.(0)?.vergiDNo ?? undefined,
      tsipariskart_419193_value: this.state.TaslakSiparisBaslikBilgi?.at?.(0)?.teklif_Nos ?? undefined,
      tsipariskart_881644_value: this.state.TaslakSiparisBaslikBilgi?.at?.(0)?.satisTuruID ?? undefined,
      tsipariskart_169180_value: this.state.TaslakSiparisBaslikBilgi?.at?.(0)?.adresDetay ?? undefined,
      tsipariskart_232664_value: this.state.MyContext?.at?.(0)?.fullName ?? undefined,
      tsipariskart_738903_value: this.state.TaslakSiparisBaslikBilgi?.at?.(0)?.nakliyeNot ?? undefined,
      tsipariskart_731864_value: this.state.TaslakSiparisBaslikBilgi?.at?.(0)?.nakliyeOdemeTuru ?? undefined,
      tsipariskart_970873_value: this.state.TaslakSiparisBaslikBilgi?.at?.(0)?.sevkPlan ?? undefined,
      tsipariskart_823113_value: this.state.TaslakSiparisBaslikBilgi?.at?.(0)?.digerKosul ?? undefined,
      tsipariskart_313721_value: this.state.TaslakSiparisBaslikBilgi?.at?.(0)?.kur ?? undefined,
      tsipariskart_73972_value: this.state.TaslakSiparisBaslikBilgi?.at?.(0)?.aciklama ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  TSiparisKartPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "TSiparisKart/TSiparisKartPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    formVars.tsipariskart_232664_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0
        ? stateVars.MyContext[0].fullName
        : this.state.MyContext?.length > 0
        ? this.state.MyContext[0].fullName
        : null
    );
    stateVars.getTempTalepUyeId = result?.data.getTempTalepUyeId;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TSiparisKartPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TSiparisKartPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "tsipariskart_312646_value",
      moment().format("YYYY-MM-DDTHH:mm:ss"),
      null
    );

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TSiparisKartPageInit2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  TSiparisKartPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      TaslakmiKartmi_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.TaslakmiKartmi ?? this.props.screenInputs.taslakmikartmi,
        "string"
      ),
      TaslakUserId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kUserId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kUserId
            : null
        ),
        "Guid"
      ),
      SiparisKartId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.SiparisKartId ?? this.props.screenInputs.sipariskartid,
        "Guid"
      ),
      TaslakmiKartmi_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.TaslakmiKartmi ?? this.props.screenInputs.taslakmikartmi,
        "string"
      ),
      UserId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kUserId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kUserId
            : null
        ),
        "Guid"
      ),
      UyeId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyeId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyeId
            : null
        ),
        "Guid"
      ),
      SiparisKartId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.SiparisKartId ?? this.props.screenInputs.sipariskartid,
        "Guid"
      ),
      currentpage_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tsipariskart_880048_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tsipariskart_880048_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      TaslakmiKartmi_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.TaslakmiKartmi ?? this.props.screenInputs.taslakmikartmi,
        "string"
      ),
      UserId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kUserId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kUserId
            : null
        ),
        "Guid"
      ),
      UyeId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyeId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyeId
            : null
        ),
        "Guid"
      ),
      SiparisKartId_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.SiparisKartId ?? this.props.screenInputs.sipariskartid,
        "Guid"
      ),
      TaslakmiKartmi_3: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.TaslakmiKartmi ?? this.props.screenInputs.taslakmikartmi,
        "string"
      ),
      UserId_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kUserId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kUserId
            : null
        ),
        "Guid"
      ),
      UyeId_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyeId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyeId
            : null
        ),
        "Guid"
      ),
      SiparisKartId_3: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.SiparisKartId ?? this.props.screenInputs.sipariskartid,
        "Guid"
      ),
      SiparisKartID_5: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.SiparisKartId ?? this.props.screenInputs.sipariskartid,
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "TSiparisKart/TSiparisKartPageInit2_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.TaslakSiparisBaslikBilgi = result?.data.taslakSiparisBaslikBilgi;
    formVars.tsipariskart_252187_value = ReactSystemFunctions.toString(
      this,
      stateVars.TaslakSiparisBaslikBilgi?.length > 0
        ? stateVars.TaslakSiparisBaslikBilgi[0].muhatap
        : this.state.TaslakSiparisBaslikBilgi?.length > 0
        ? this.state.TaslakSiparisBaslikBilgi[0].muhatap
        : null
    );
    formVars.tsipariskart_608307_value = ReactSystemFunctions.toString(
      this,
      stateVars.TaslakSiparisBaslikBilgi?.length > 0
        ? stateVars.TaslakSiparisBaslikBilgi[0].siparis_No
        : this.state.TaslakSiparisBaslikBilgi?.length > 0
        ? this.state.TaslakSiparisBaslikBilgi[0].siparis_No
        : null
    );
    formVars.tsipariskart_661093_value = ReactSystemFunctions.toString(
      this,
      stateVars.TaslakSiparisBaslikBilgi?.length > 0
        ? stateVars.TaslakSiparisBaslikBilgi[0].muhatap
        : this.state.TaslakSiparisBaslikBilgi?.length > 0
        ? this.state.TaslakSiparisBaslikBilgi[0].muhatap
        : null
    );
    formVars.tsipariskart_999050_value = ReactSystemFunctions.toString(
      this,
      stateVars.TaslakSiparisBaslikBilgi?.length > 0
        ? stateVars.TaslakSiparisBaslikBilgi[0].telFaks
        : this.state.TaslakSiparisBaslikBilgi?.length > 0
        ? this.state.TaslakSiparisBaslikBilgi[0].telFaks
        : null
    );
    formVars.tsipariskart_583315_value = ReactSystemFunctions.toString(
      this,
      stateVars.TaslakSiparisBaslikBilgi?.length > 0
        ? stateVars.TaslakSiparisBaslikBilgi[0].teklifGecerlilik
        : this.state.TaslakSiparisBaslikBilgi?.length > 0
        ? this.state.TaslakSiparisBaslikBilgi[0].teklifGecerlilik
        : null
    );
    formVars.tsipariskart_947648_value = ReactSystemFunctions.toString(
      this,
      stateVars.TaslakSiparisBaslikBilgi?.length > 0
        ? stateVars.TaslakSiparisBaslikBilgi[0].vergiDNo
        : this.state.TaslakSiparisBaslikBilgi?.length > 0
        ? this.state.TaslakSiparisBaslikBilgi[0].vergiDNo
        : null
    );
    formVars.tsipariskart_419193_value = ReactSystemFunctions.toString(
      this,
      stateVars.TaslakSiparisBaslikBilgi?.length > 0
        ? stateVars.TaslakSiparisBaslikBilgi[0].teklif_Nos
        : this.state.TaslakSiparisBaslikBilgi?.length > 0
        ? this.state.TaslakSiparisBaslikBilgi[0].teklif_Nos
        : null
    );
    formVars.tsipariskart_881644_value =
      stateVars.TaslakSiparisBaslikBilgi?.length > 0
        ? stateVars.TaslakSiparisBaslikBilgi[0].satisTuruID
        : this.state.TaslakSiparisBaslikBilgi?.length > 0
        ? this.state.TaslakSiparisBaslikBilgi[0].satisTuruID
        : null;
    formVars.tsipariskart_881644_options = stateVars.SatisTuruAll;
    formVars.tsipariskart_169180_value = ReactSystemFunctions.toString(
      this,
      stateVars.TaslakSiparisBaslikBilgi?.length > 0
        ? stateVars.TaslakSiparisBaslikBilgi[0].adresDetay
        : this.state.TaslakSiparisBaslikBilgi?.length > 0
        ? this.state.TaslakSiparisBaslikBilgi[0].adresDetay
        : null
    );
    formVars.tsipariskart_738903_value = ReactSystemFunctions.toString(
      this,
      stateVars.TaslakSiparisBaslikBilgi?.length > 0
        ? stateVars.TaslakSiparisBaslikBilgi[0].nakliyeNot
        : this.state.TaslakSiparisBaslikBilgi?.length > 0
        ? this.state.TaslakSiparisBaslikBilgi[0].nakliyeNot
        : null
    );
    formVars.tsipariskart_731864_value = ReactSystemFunctions.toString(
      this,
      stateVars.TaslakSiparisBaslikBilgi?.length > 0
        ? stateVars.TaslakSiparisBaslikBilgi[0].nakliyeOdemeTuru
        : this.state.TaslakSiparisBaslikBilgi?.length > 0
        ? this.state.TaslakSiparisBaslikBilgi[0].nakliyeOdemeTuru
        : null
    );
    formVars.tsipariskart_970873_value = ReactSystemFunctions.toString(
      this,
      stateVars.TaslakSiparisBaslikBilgi?.length > 0
        ? stateVars.TaslakSiparisBaslikBilgi[0].sevkPlan
        : this.state.TaslakSiparisBaslikBilgi?.length > 0
        ? this.state.TaslakSiparisBaslikBilgi[0].sevkPlan
        : null
    );
    formVars.tsipariskart_823113_value = ReactSystemFunctions.toString(
      this,
      stateVars.TaslakSiparisBaslikBilgi?.length > 0
        ? stateVars.TaslakSiparisBaslikBilgi[0].digerKosul
        : this.state.TaslakSiparisBaslikBilgi?.length > 0
        ? this.state.TaslakSiparisBaslikBilgi[0].digerKosul
        : null
    );
    formVars.tsipariskart_313721_value = ReactSystemFunctions.value(
      this,
      stateVars.TaslakSiparisBaslikBilgi?.length > 0
        ? stateVars.TaslakSiparisBaslikBilgi[0].kur
        : this.state.TaslakSiparisBaslikBilgi?.length > 0
        ? this.state.TaslakSiparisBaslikBilgi[0].kur
        : null
    );
    formVars.tsipariskart_73972_value = ReactSystemFunctions.toString(
      this,
      stateVars.TaslakSiparisBaslikBilgi?.length > 0
        ? stateVars.TaslakSiparisBaslikBilgi[0].aciklama
        : this.state.TaslakSiparisBaslikBilgi?.length > 0
        ? this.state.TaslakSiparisBaslikBilgi[0].aciklama
        : null
    );
    stateVars.isComp933399Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.TaslakSiparisBaslikBilgi?.length > 0
            ? stateVars.TaslakSiparisBaslikBilgi[0].isRowDuzeltmeNotuVisibility
            : this.state.TaslakSiparisBaslikBilgi?.length > 0
            ? this.state.TaslakSiparisBaslikBilgi[0].isRowDuzeltmeNotuVisibility
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp643963Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.TaslakSiparisBaslikBilgi?.length > 0
            ? stateVars.TaslakSiparisBaslikBilgi[0].isRowTaslakOlusturVisibility
            : this.state.TaslakSiparisBaslikBilgi?.length > 0
            ? this.state.TaslakSiparisBaslikBilgi[0].isRowTaslakOlusturVisibility
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp354461Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.TaslakSiparisBaslikBilgi?.length > 0
            ? stateVars.TaslakSiparisBaslikBilgi[0].isRowOnayaGonderVisibility
            : this.state.TaslakSiparisBaslikBilgi?.length > 0
            ? this.state.TaslakSiparisBaslikBilgi[0].isRowOnayaGonderVisibility
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp151729Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.TaslakSiparisBaslikBilgi?.length > 0
            ? stateVars.TaslakSiparisBaslikBilgi[0].isRowOnayVisibility
            : this.state.TaslakSiparisBaslikBilgi?.length > 0
            ? this.state.TaslakSiparisBaslikBilgi[0].isRowOnayVisibility
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.TaslakSiparisTeklifList = result?.data.taslakSiparisTeklifList;

    stateVars.TaslakSiparisTeklifListSUM = result?.data.taslakSiparisTeklifListSUM;
    formVars.tsipariskart_362455_value = ReactSystemFunctions.toString(
      this,
      stateVars.TaslakSiparisTeklifListSUM?.length > 0
        ? stateVars.TaslakSiparisTeklifListSUM[0].sumTeklifIndirimliFiyatYTL
        : this.state.TaslakSiparisTeklifListSUM?.length > 0
        ? this.state.TaslakSiparisTeklifListSUM[0].sumTeklifIndirimliFiyatYTL
        : null
    );
    formVars.tsipariskart_284046_value = ReactSystemFunctions.toString(
      this,
      stateVars.TaslakSiparisTeklifListSUM?.length > 0
        ? stateVars.TaslakSiparisTeklifListSUM[0].sumTeklifKDVFiyatYTL
        : this.state.TaslakSiparisTeklifListSUM?.length > 0
        ? this.state.TaslakSiparisTeklifListSUM[0].sumTeklifKDVFiyatYTL
        : null
    );
    formVars.tsipariskart_450385_value = ReactSystemFunctions.toString(
      this,
      stateVars.TaslakSiparisTeklifListSUM?.length > 0
        ? stateVars.TaslakSiparisTeklifListSUM[0].sumTeklifKDVliFiyatYTL
        : this.state.TaslakSiparisTeklifListSUM?.length > 0
        ? this.state.TaslakSiparisTeklifListSUM[0].sumTeklifKDVliFiyatYTL
        : null
    );
    stateVars.TaslakSiparisTeklifListCount = result?.data.taslakSiparisTeklifListCount;
    stateVars.SatisTuruAll = result?.data.satisTuruAll;

    formVars.tsipariskart_881644_value =
      stateVars.TaslakSiparisBaslikBilgi?.length > 0
        ? stateVars.TaslakSiparisBaslikBilgi[0].satisTuruID
        : this.state.TaslakSiparisBaslikBilgi?.length > 0
        ? this.state.TaslakSiparisBaslikBilgi[0].satisTuruID
        : null;
    formVars.tsipariskart_881644_options = stateVars.SatisTuruAll;
    stateVars.SiparisKartDuzeltmeNotuBySiparisKartID = result?.data.siparisKartDuzeltmeNotuBySiparisKartID;

    stateVars.GenelParametreler = result?.data.genelParametreler;
    stateVars.EserBilgileri_TeklifEdenKisiEmail = result?.data.eserBilgileri_TeklifEdenKisiEmail;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TSiparisKartPageInit3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TSiparisKartPageInit3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp608307Enabled", "disabled");

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TSiparisKartPageInit4_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  TSiparisKartPageInit4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.NewEditDisplay ?? this.props.screenInputs.neweditdisplay,
        "D"
      )
    ) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp312646Enabled", "disabled");

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TSiparisKartPageInit5_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TSiparisKartPageInit5_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  TSiparisKartPageInit5_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.NewEditDisplay ?? this.props.screenInputs.neweditdisplay,
        "D"
      )
    ) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp583315Enabled", "disabled");

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TSiparisKartPageInit6_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TSiparisKartPageInit6_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  TSiparisKartPageInit6_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.NewEditDisplay ?? this.props.screenInputs.neweditdisplay,
        "D"
      )
    ) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp309717Enabled", "disabled");

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TSiparisKartPageInit7_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TSiparisKartPageInit7_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  TSiparisKartPageInit7_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.NewEditDisplay ?? this.props.screenInputs.neweditdisplay,
        "D"
      )
    ) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp881644Enabled", "disabled");

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TSiparisKartPageInit8_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TSiparisKartPageInit8_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  TSiparisKartPageInit8_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp232664Enabled", "disabled");

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TSiparisKartPageInit9_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  TSiparisKartPageInit9_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.NewEditDisplay ?? this.props.screenInputs.neweditdisplay,
        "D"
      )
    ) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp252187Enabled", "disabled");

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TSiparisKartPageInit10_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TSiparisKartPageInit10_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  TSiparisKartPageInit10_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.NewEditDisplay ?? this.props.screenInputs.neweditdisplay,
        "D"
      )
    ) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp661093Enabled", "disabled");

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TSiparisKartPageInit11_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TSiparisKartPageInit11_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  TSiparisKartPageInit11_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.NewEditDisplay ?? this.props.screenInputs.neweditdisplay,
        "D"
      )
    ) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp999050Enabled", "disabled");

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TSiparisKartPageInit12_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TSiparisKartPageInit12_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  TSiparisKartPageInit12_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.NewEditDisplay ?? this.props.screenInputs.neweditdisplay,
        "D"
      )
    ) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp947648Enabled", "disabled");

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TSiparisKartPageInit13_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TSiparisKartPageInit13_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  TSiparisKartPageInit13_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp419193Enabled", "disabled");

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TSiparisKartPageInit14_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  TSiparisKartPageInit14_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.NewEditDisplay ?? this.props.screenInputs.neweditdisplay,
        "D"
      )
    ) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp169180Enabled", "disabled");

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TSiparisKartPageInit15_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TSiparisKartPageInit15_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  TSiparisKartPageInit15_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.NewEditDisplay ?? this.props.screenInputs.neweditdisplay,
        "D"
      )
    ) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp738903Enabled", "disabled");

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TSiparisKartPageInit16_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TSiparisKartPageInit16_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  TSiparisKartPageInit16_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.NewEditDisplay ?? this.props.screenInputs.neweditdisplay,
        "D"
      )
    ) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp731864Enabled", "disabled");

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TSiparisKartPageInit17_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TSiparisKartPageInit17_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  TSiparisKartPageInit17_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.NewEditDisplay ?? this.props.screenInputs.neweditdisplay,
        "D"
      )
    ) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp823113Enabled", "disabled");

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TSiparisKartPageInit18_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TSiparisKartPageInit18_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  TSiparisKartPageInit18_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.NewEditDisplay ?? this.props.screenInputs.neweditdisplay,
        "D"
      )
    ) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp313721Enabled", "disabled");

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TSiparisKartPageInit19_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TSiparisKartPageInit19_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  TSiparisKartPageInit19_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.NewEditDisplay ?? this.props.screenInputs.neweditdisplay,
        "D"
      )
    ) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp73972Enabled", "disabled");

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TSiparisKartPageInit20_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TSiparisKartPageInit20_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  TSiparisKartPageInit20_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.NewEditDisplay ?? this.props.screenInputs.neweditdisplay,
        "D"
      )
    ) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp881644Enabled", "disabled");

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TSiparisKartPageInit21_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TSiparisKartPageInit21_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  TSiparisKartPageInit21_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.NewEditDisplay ?? this.props.screenInputs.neweditdisplay,
        "D"
      )
    ) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp970873Enabled", "disabled");

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TSiparisKartPageInit22_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TSiparisKartPageInit22_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  TSiparisKartPageInit22_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.tsipariskart_252187_value = ReactSystemFunctions.toString(
      this,
      stateVars.TaslakSiparisBaslikBilgi?.length > 0
        ? stateVars.TaslakSiparisBaslikBilgi[0].muhatap
        : this.state.TaslakSiparisBaslikBilgi?.length > 0
        ? this.state.TaslakSiparisBaslikBilgi[0].muhatap
        : null
    );

    formVars.tsipariskart_608307_value = ReactSystemFunctions.toString(
      this,
      stateVars.TaslakSiparisBaslikBilgi?.length > 0
        ? stateVars.TaslakSiparisBaslikBilgi[0].siparis_No
        : this.state.TaslakSiparisBaslikBilgi?.length > 0
        ? this.state.TaslakSiparisBaslikBilgi[0].siparis_No
        : null
    );

    formVars.tsipariskart_661093_value = ReactSystemFunctions.toString(
      this,
      stateVars.TaslakSiparisBaslikBilgi?.length > 0
        ? stateVars.TaslakSiparisBaslikBilgi[0].muhatap
        : this.state.TaslakSiparisBaslikBilgi?.length > 0
        ? this.state.TaslakSiparisBaslikBilgi[0].muhatap
        : null
    );

    formVars.tsipariskart_999050_value = ReactSystemFunctions.toString(
      this,
      stateVars.TaslakSiparisBaslikBilgi?.length > 0
        ? stateVars.TaslakSiparisBaslikBilgi[0].telFaks
        : this.state.TaslakSiparisBaslikBilgi?.length > 0
        ? this.state.TaslakSiparisBaslikBilgi[0].telFaks
        : null
    );

    formVars.tsipariskart_583315_value = ReactSystemFunctions.toString(
      this,
      stateVars.TaslakSiparisBaslikBilgi?.length > 0
        ? stateVars.TaslakSiparisBaslikBilgi[0].teklifGecerlilik
        : this.state.TaslakSiparisBaslikBilgi?.length > 0
        ? this.state.TaslakSiparisBaslikBilgi[0].teklifGecerlilik
        : null
    );

    formVars.tsipariskart_947648_value = ReactSystemFunctions.toString(
      this,
      stateVars.TaslakSiparisBaslikBilgi?.length > 0
        ? stateVars.TaslakSiparisBaslikBilgi[0].vergiDNo
        : this.state.TaslakSiparisBaslikBilgi?.length > 0
        ? this.state.TaslakSiparisBaslikBilgi[0].vergiDNo
        : null
    );

    formVars.tsipariskart_419193_value = ReactSystemFunctions.toString(
      this,
      stateVars.TaslakSiparisBaslikBilgi?.length > 0
        ? stateVars.TaslakSiparisBaslikBilgi[0].teklif_Nos
        : this.state.TaslakSiparisBaslikBilgi?.length > 0
        ? this.state.TaslakSiparisBaslikBilgi[0].teklif_Nos
        : null
    );

    formVars.tsipariskart_881644_value = ReactSystemFunctions.toString(
      this,
      stateVars.TaslakSiparisBaslikBilgi?.length > 0
        ? stateVars.TaslakSiparisBaslikBilgi[0].satisTuruID
        : this.state.TaslakSiparisBaslikBilgi?.length > 0
        ? this.state.TaslakSiparisBaslikBilgi[0].satisTuruID
        : null
    );

    stateVars.dataSource_881644 = this.state.SatisTuruAll;
    stateVars.dataSource_881644 = this.state.SatisTuruAll;
    formVars.tsipariskart_169180_value = ReactSystemFunctions.toString(
      this,
      stateVars.TaslakSiparisBaslikBilgi?.length > 0
        ? stateVars.TaslakSiparisBaslikBilgi[0].adresDetay
        : this.state.TaslakSiparisBaslikBilgi?.length > 0
        ? this.state.TaslakSiparisBaslikBilgi[0].adresDetay
        : null
    );

    formVars.tsipariskart_232664_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0
        ? stateVars.MyContext[0].fullName
        : this.state.MyContext?.length > 0
        ? this.state.MyContext[0].fullName
        : null
    );

    stateVars.dataSource_348605 = this.state.TaslakSiparisTeklifList;

    stateVars.isComp251583Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "tsipariskart_348605_value", "isInceleIconsVisibility"),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.tsipariskart_880048_total = ReactSystemFunctions.value(
      this,
      stateVars.TaslakSiparisTeklifListCount?.length > 0
        ? stateVars.TaslakSiparisTeklifListCount[0].totalCount
        : this.state.TaslakSiparisTeklifListCount?.length > 0
        ? this.state.TaslakSiparisTeklifListCount[0].totalCount
        : null
    );

    formVars.tsipariskart_362455_value = ReactSystemFunctions.toString(
      this,
      stateVars.TaslakSiparisTeklifListSUM?.length > 0
        ? stateVars.TaslakSiparisTeklifListSUM[0].sumTeklifIndirimliFiyatYTL
        : this.state.TaslakSiparisTeklifListSUM?.length > 0
        ? this.state.TaslakSiparisTeklifListSUM[0].sumTeklifIndirimliFiyatYTL
        : null
    );

    formVars.tsipariskart_284046_value = ReactSystemFunctions.toString(
      this,
      stateVars.TaslakSiparisTeklifListSUM?.length > 0
        ? stateVars.TaslakSiparisTeklifListSUM[0].sumTeklifKDVFiyatYTL
        : this.state.TaslakSiparisTeklifListSUM?.length > 0
        ? this.state.TaslakSiparisTeklifListSUM[0].sumTeklifKDVFiyatYTL
        : null
    );

    formVars.tsipariskart_450385_value = ReactSystemFunctions.toString(
      this,
      stateVars.TaslakSiparisTeklifListSUM?.length > 0
        ? stateVars.TaslakSiparisTeklifListSUM[0].sumTeklifKDVliFiyatYTL
        : this.state.TaslakSiparisTeklifListSUM?.length > 0
        ? this.state.TaslakSiparisTeklifListSUM[0].sumTeklifKDVliFiyatYTL
        : null
    );

    formVars.tsipariskart_738903_value = ReactSystemFunctions.toString(
      this,
      stateVars.TaslakSiparisBaslikBilgi?.length > 0
        ? stateVars.TaslakSiparisBaslikBilgi[0].nakliyeNot
        : this.state.TaslakSiparisBaslikBilgi?.length > 0
        ? this.state.TaslakSiparisBaslikBilgi[0].nakliyeNot
        : null
    );

    formVars.tsipariskart_731864_value = ReactSystemFunctions.toString(
      this,
      stateVars.TaslakSiparisBaslikBilgi?.length > 0
        ? stateVars.TaslakSiparisBaslikBilgi[0].nakliyeOdemeTuru
        : this.state.TaslakSiparisBaslikBilgi?.length > 0
        ? this.state.TaslakSiparisBaslikBilgi[0].nakliyeOdemeTuru
        : null
    );

    formVars.tsipariskart_970873_value = ReactSystemFunctions.toString(
      this,
      stateVars.TaslakSiparisBaslikBilgi?.length > 0
        ? stateVars.TaslakSiparisBaslikBilgi[0].sevkPlan
        : this.state.TaslakSiparisBaslikBilgi?.length > 0
        ? this.state.TaslakSiparisBaslikBilgi[0].sevkPlan
        : null
    );

    formVars.tsipariskart_823113_value = ReactSystemFunctions.toString(
      this,
      stateVars.TaslakSiparisBaslikBilgi?.length > 0
        ? stateVars.TaslakSiparisBaslikBilgi[0].digerKosul
        : this.state.TaslakSiparisBaslikBilgi?.length > 0
        ? this.state.TaslakSiparisBaslikBilgi[0].digerKosul
        : null
    );

    formVars.tsipariskart_313721_value = ReactSystemFunctions.value(
      this,
      stateVars.TaslakSiparisBaslikBilgi?.length > 0
        ? stateVars.TaslakSiparisBaslikBilgi[0].kur
        : this.state.TaslakSiparisBaslikBilgi?.length > 0
        ? this.state.TaslakSiparisBaslikBilgi[0].kur
        : null
    );

    formVars.tsipariskart_73972_value = ReactSystemFunctions.toString(
      this,
      stateVars.TaslakSiparisBaslikBilgi?.length > 0
        ? stateVars.TaslakSiparisBaslikBilgi[0].aciklama
        : this.state.TaslakSiparisBaslikBilgi?.length > 0
        ? this.state.TaslakSiparisBaslikBilgi[0].aciklama
        : null
    );

    stateVars.isComp933399Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.TaslakSiparisBaslikBilgi?.length > 0
            ? stateVars.TaslakSiparisBaslikBilgi[0].isRowDuzeltmeNotuVisibility
            : this.state.TaslakSiparisBaslikBilgi?.length > 0
            ? this.state.TaslakSiparisBaslikBilgi[0].isRowDuzeltmeNotuVisibility
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";

    stateVars.dataSource_115097 = this.state.SiparisKartDuzeltmeNotuBySiparisKartID;
    stateVars.isComp643963Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.TaslakSiparisBaslikBilgi?.length > 0
            ? stateVars.TaslakSiparisBaslikBilgi[0].isRowTaslakOlusturVisibility
            : this.state.TaslakSiparisBaslikBilgi?.length > 0
            ? this.state.TaslakSiparisBaslikBilgi[0].isRowTaslakOlusturVisibility
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp354461Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.TaslakSiparisBaslikBilgi?.length > 0
            ? stateVars.TaslakSiparisBaslikBilgi[0].isRowOnayaGonderVisibility
            : this.state.TaslakSiparisBaslikBilgi?.length > 0
            ? this.state.TaslakSiparisBaslikBilgi[0].isRowOnayaGonderVisibility
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp151729Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.TaslakSiparisBaslikBilgi?.length > 0
            ? stateVars.TaslakSiparisBaslikBilgi[0].isRowOnayVisibility
            : this.state.TaslakSiparisBaslikBilgi?.length > 0
            ? this.state.TaslakSiparisBaslikBilgi[0].isRowOnayVisibility
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  TSiparisKartComponent_628675_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TSiparisKartComponent_625771_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "TeklifVermeKart",
      "prmTeklifTalepID",
      ReactSystemFunctions.value(this, "tsipariskart_348605_value", "teklifTalepKartId")
    );
    KuikaAppManager.addToPageInputVariables("TeklifVermeKart", "NewEditDisplay", "D");
    KuikaAppManager.addToPageInputVariables(
      "TeklifVermeKart",
      "prmUID",
      ReactSystemFunctions.value(this, "tsipariskart_348605_value", "uyeId")
    );
    KuikaAppManager.addToPageInputVariables("TeklifVermeKart", "prmMuhatapMiFirmaMi", "Firma");

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "TSiparisKart",
      "TeklifVermeKart",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "348381",
      null,
      "right",
      null,
      "50%",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TSiparisKartComponent_942659_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "EserBilgileri",
      "prmTeklifTalepKartID",
      ReactSystemFunctions.value(this, "tsipariskart_348605_value", "teklifTalepKartId")
    );
    KuikaAppManager.addToPageInputVariables("EserBilgileri", "NewEditDisplay", "D");
    KuikaAppManager.addToPageInputVariables("EserBilgileri", "prmKitapAd", null);
    KuikaAppManager.addToPageInputVariables("EserBilgileri", "teklifNo", "-");
    KuikaAppManager.addToPageInputVariables("EserBilgileri", "uyeAd", "-");

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "TSiparisKart",
      "EserBilgileri",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "180819",
      null,
      "right",
      null,
      "50%",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TSiparisKartComponent_880048_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      TaslakmiKartmi_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.TaslakmiKartmi ?? this.props.screenInputs.taslakmikartmi,
        "string"
      ),
      UserId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kUserId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kUserId
            : null
        ),
        "Guid"
      ),
      UyeId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyeId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyeId
            : null
        ),
        "Guid"
      ),
      SiparisKartId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.SiparisKartId ?? this.props.screenInputs.sipariskartid,
        "Guid"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tsipariskart_880048_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tsipariskart_880048_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "TSiparisKart/TSiparisKartComponent_880048_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.TaslakSiparisTeklifList = result?.data.taslakSiparisTeklifList;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TSiparisKartComponent_880048_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TSiparisKartComponent_880048_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_348605 = this.state.TaslakSiparisTeklifList;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TSiparisKartComponent_772233_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tsipariskart_177991_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "tsipariskart_177991_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(Guid.create().toString(), "Guid"),
      DuzeltmeNotu_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tsipariskart_177991_value", "value", "", "", "")
        ),
        "string"
      ),
      NotKisiID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kUserId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kUserId
            : null
        ),
        "Guid"
      ),
      NotTarihi_0: ReactSystemFunctions.convertToTypeByName(moment().format("YYYY-MM-DDTHH:mm:ss"), "Date"),
      SiparisKartID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.SiparisKartId ?? this.props.screenInputs.sipariskartid,
        "Guid"
      ),
      SiparisKartID_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.SiparisKartId ?? this.props.screenInputs.sipariskartid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "TSiparisKart/TSiparisKartComponent_772233_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_1;
    stateVars.SiparisKartDuzeltmeNotuBySiparisKartID = result?.data.siparisKartDuzeltmeNotuBySiparisKartID;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TSiparisKartComponent_772233_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TSiparisKartComponent_772233_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "tsipariskart_177991_value", "", null);

    stateVars.dataSource_115097 = this.state.SiparisKartDuzeltmeNotuBySiparisKartID;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TSiparisKartComponent_702503_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      prmID_0: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
      UyeID_0: ReactSystemFunctions.convertToTypeByName(null, "Guid")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "TSiparisKart/TSiparisKartComponent_702503_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getGuid = result?.data.getGuid;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TSiparisKartComponent_702503_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TSiparisKartComponent_702503_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Aciklama_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tsipariskart_73972_value", "value", "", "", "")
        ),
        "string"
      ),
      NakliyeNot_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tsipariskart_738903_value", "value", "", "", "")
        ),
        "string"
      ),
      NakliyeOdemeTuru_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tsipariskart_731864_value", "value", "", "", "")
        ),
        "string"
      ),
      SiparisDurumID_0: ReactSystemFunctions.convertToTypeByName("00000000-0000-0000-0000-000000000000", "Guid"),
      OdemeKosulu_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tsipariskart_823113_value", "value", "", "", "")
        ),
        "string"
      ),
      Kur_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toDouble(
          this,
          ReactSystemFunctions.value(this, "tsipariskart_313721_value", "value", "", "", "")
        ),
        "number"
      ),
      TeklifGecerlilik_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tsipariskart_583315_value", "value", "", "", "")
        ),
        "string"
      ),
      SatisTuruID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tsipariskart_881644_value", "value", "SatisTuruAll", "id", "id")
        ),
        "Guid"
      ),
      SevkPlan_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tsipariskart_970873_value", "value", "", "", "")
        ),
        "string"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.getGuid?.length > 0
            ? stateVars.getGuid[0].getGuid
            : this.state.getGuid?.length > 0
            ? this.state.getGuid[0].getGuid
            : null
        ),
        "Guid"
      ),
      SiparisOlusturmaKisiID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kUserId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kUserId
            : null
        ),
        "Guid"
      ),
      SiparisOlusturmaTarihi_0: ReactSystemFunctions.convertToTypeByName(
        moment().format("YYYY-MM-DDTHH:mm:ss"),
        "Date"
      ),
      TalepUyeID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.getTempTalepUyeId?.length > 0
            ? stateVars.getTempTalepUyeId[0].talepUyeId
            : this.state.getTempTalepUyeId?.length > 0
            ? this.state.getTempTalepUyeId[0].talepUyeId
            : null
        ),
        "Guid"
      ),
      TeklifUyeID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyeId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyeId
            : null
        ),
        "Guid"
      ),
      TeklifNos_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tsipariskart_419193_value", "value", "", "", "")
        ),
        "string"
      ),
      Adres_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tsipariskart_169180_value", "value", "", "", "")
        ),
        "string"
      ),
      SiparisKartID_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.getGuid?.length > 0
            ? stateVars.getGuid[0].getGuid
            : this.state.getGuid?.length > 0
            ? this.state.getGuid[0].getGuid
            : null
        ),
        "Guid"
      ),
      UserId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kUserId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kUserId
            : null
        ),
        "Guid"
      ),
      UserId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kUserId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kUserId
            : null
        ),
        "Guid"
      )
    };

    stateVars.Confirm = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "9d8def9b_5fee_c622_5b80_3994a6118188_confirmation",
        this.defaultML,
        "Sipariş Taslak Olarak Kayıt Edilecek; İşleme Devam Edilsin mi?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel",
      customClass: {
        title: "swal2-font-poppins"
      }
    });

    if (stateVars.Confirm.isConfirmed) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "TSiparisKart/TSiparisKartComponent_702503_onClick1_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.SiparisKartSave = result?.data.siparisKartSave;
      stateVars.SiparisDetayInsert = result?.data.siparisDetayInsert;
      stateVars.TeklifSeciliIdTempDelete = result?.data.teklifSeciliIdTempDelete;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TSiparisKartComponent_702503_onClick2_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TSiparisKartComponent_702503_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "0d8e3c3d_2af3_3ab9_3e09_dc6849b1bc2e_notify",
        this.defaultML,
        "Sipariş Oluşturuldu!"
      ),
      "success",
      "bottom-right",
      1,
      null,
      null,
      null
    );
    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TSiparisKartComponent_833155_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      prmID_0: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
      UyeID_0: ReactSystemFunctions.convertToTypeByName(null, "Guid")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "TSiparisKart/TSiparisKartComponent_833155_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getGuid = result?.data.getGuid;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TSiparisKartComponent_833155_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TSiparisKartComponent_833155_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Aciklama_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tsipariskart_73972_value", "value", "", "", "")
        ),
        "string"
      ),
      NakliyeNot_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tsipariskart_738903_value", "value", "", "", "")
        ),
        "string"
      ),
      NakliyeOdemeTuru_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tsipariskart_731864_value", "value", "", "", "")
        ),
        "string"
      ),
      SiparisDurumID_0: ReactSystemFunctions.convertToTypeByName("00000000-0000-0000-0000-000000000000", "Guid"),
      OdemeKosulu_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tsipariskart_823113_value", "value", "", "", "")
        ),
        "string"
      ),
      Kur_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toDouble(
          this,
          ReactSystemFunctions.value(this, "tsipariskart_313721_value", "value", "", "", "")
        ),
        "number"
      ),
      TeklifGecerlilik_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tsipariskart_583315_value", "value", "", "", "")
        ),
        "string"
      ),
      SatisTuruID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tsipariskart_881644_value", "value", "SatisTuruAll", "id", "id")
        ),
        "Guid"
      ),
      SevkPlan_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tsipariskart_970873_value", "value", "", "", "")
        ),
        "string"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.getGuid?.length > 0
            ? stateVars.getGuid[0].getGuid
            : this.state.getGuid?.length > 0
            ? this.state.getGuid[0].getGuid
            : null
        ),
        "Guid"
      ),
      SiparisOlusturmaKisiID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kUserId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kUserId
            : null
        ),
        "Guid"
      ),
      SiparisOlusturmaTarihi_0: ReactSystemFunctions.convertToTypeByName(
        moment().format("YYYY-MM-DDTHH:mm:ss"),
        "Date"
      ),
      TalepUyeID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.getTempTalepUyeId?.length > 0
            ? stateVars.getTempTalepUyeId[0].talepUyeId
            : this.state.getTempTalepUyeId?.length > 0
            ? this.state.getTempTalepUyeId[0].talepUyeId
            : null
        ),
        "Guid"
      ),
      TeklifUyeID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyeId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyeId
            : null
        ),
        "Guid"
      ),
      TeklifNos_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tsipariskart_419193_value", "value", "", "", "")
        ),
        "string"
      ),
      Adres_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tsipariskart_169180_value", "value", "", "", "")
        ),
        "string"
      ),
      SiparisKartID_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.getGuid?.length > 0
            ? stateVars.getGuid[0].getGuid
            : this.state.getGuid?.length > 0
            ? this.state.getGuid[0].getGuid
            : null
        ),
        "Guid"
      ),
      UserId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kUserId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kUserId
            : null
        ),
        "Guid"
      ),
      UserId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kUserId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kUserId
            : null
        ),
        "Guid"
      ),
      Aciklama_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tsipariskart_73972_value", "value", "", "", "")
        ),
        "string"
      ),
      NakliyeNot_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tsipariskart_738903_value", "value", "", "", "")
        ),
        "string"
      ),
      NakliyeOdemeTuru_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tsipariskart_731864_value", "value", "", "", "")
        ),
        "string"
      ),
      SiparisDurumID_3: ReactSystemFunctions.convertToTypeByName("00000000-0000-0000-0000-000000000001", "Guid"),
      OdemeKosulu_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tsipariskart_823113_value", "value", "", "", "")
        ),
        "string"
      ),
      Kur_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toDouble(
          this,
          ReactSystemFunctions.value(this, "tsipariskart_313721_value", "value", "", "", "")
        ),
        "number"
      ),
      TeklifGecerlilik_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tsipariskart_583315_value", "value", "", "", "")
        ),
        "string"
      ),
      SatisTuruID_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tsipariskart_881644_value", "value", "SatisTuruAll", "id", "id")
        ),
        "Guid"
      ),
      SevkPlan_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tsipariskart_970873_value", "value", "", "", "")
        ),
        "string"
      ),
      Id_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.getGuid?.length > 0
            ? stateVars.getGuid[0].getGuid
            : this.state.getGuid?.length > 0
            ? this.state.getGuid[0].getGuid
            : null
        ),
        "Guid"
      ),
      SiparisOlusturmaKisiID_3: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
      SiparisOlusturmaTarihi_3: ReactSystemFunctions.convertToTypeByName(null, "Date"),
      TalepUyeID_3: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
      TeklifUyeID_3: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
      TeklifNos_3: ReactSystemFunctions.convertToTypeByName(null, "string"),
      Adres_3: ReactSystemFunctions.convertToTypeByName(null, "string"),
      SiparisKartId_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.getGuid?.length > 0
            ? stateVars.getGuid[0].getGuid
            : this.state.getGuid?.length > 0
            ? this.state.getGuid[0].getGuid
            : null
        ),
        "Guid"
      )
    };

    stateVars.Confirm = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "30ec4ad7_1739_7a59_f56c_29695ebf6010_confirmation",
        this.defaultML,
        "Sipariş Müşteriye Onaya Gönderilecek; İşleme Devam Edilsin mi?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel",
      customClass: {
        title: "swal2-font-poppins"
      }
    });

    if (stateVars.Confirm.isConfirmed) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "TSiparisKart/TSiparisKartComponent_833155_onClick1_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.SiparisKartSave = result?.data.siparisKartSave;
      stateVars.SiparisDetayInsert = result?.data.siparisDetayInsert;
      stateVars.TeklifSeciliIdTempDelete = result?.data.teklifSeciliIdTempDelete;
      stateVars.SiparisKartSave = result?.data.siparisKartSave;
      stateVars.getSiparisNoBySiparisKartId = result?.data.getSiparisNoBySiparisKartId;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TSiparisKartComponent_833155_onClick2_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TSiparisKartComponent_833155_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "93574535_9ad5_4284_5f73_998a31f6170c_notify",
        this.defaultML,
        "Sipariş Müşteriye Onaya Gönderildi!"
      ),
      "success",
      "bottom-right",
      1,
      null,
      null,
      null
    );

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TSiparisKartComponent_833155_onClick3_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  TSiparisKartComponent_833155_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      actionkey_0: ReactSystemFunctions.convertToTypeByName("taslaksiparis", "string"),
      uyeliktipi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyelikTipi
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyelikTipi
            : null
        ),
        "string"
      ),
      numara_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.getSiparisNoBySiparisKartId?.length > 0
            ? stateVars.getSiparisNoBySiparisKartId[0].siparis_No
            : this.state.getSiparisNoBySiparisKartId?.length > 0
            ? this.state.getSiparisNoBySiparisKartId[0].siparis_No
            : null
        ),
        "string"
      ),
      UserName_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.TaslakSiparisBaslikBilgi?.length > 0
            ? stateVars.TaslakSiparisBaslikBilgi[0].userName
            : this.state.TaslakSiparisBaslikBilgi?.length > 0
            ? this.state.TaslakSiparisBaslikBilgi[0].userName
            : null
        ),
        "string"
      ),
      UyelikTipi_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyelikTipi
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyelikTipi
            : null
        ),
        "string"
      ),
      templateId_3: ReactSystemFunctions.convertToTypeByName("7d4a6a07-d7e3-4962-9271-2167bfcad8bd", "string"),
      receivers_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.TaslakSiparisBaslikBilgi?.length > 0
            ? stateVars.TaslakSiparisBaslikBilgi[0].userName
            : this.state.TaslakSiparisBaslikBilgi?.length > 0
            ? this.state.TaslakSiparisBaslikBilgi[0].userName
            : null
        ),
        "string"
      ),
      sender_3: ReactSystemFunctions.convertToTypeByName("", "string"),
      cc_3: ReactSystemFunctions.convertToTypeByName("", "string"),
      bcc_3: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentFileName_3: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentBase64_3: ReactSystemFunctions.convertToTypeByName("", "string"),
      PublinkDestekTelNo_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.GenelParametreler?.length > 0
            ? stateVars.GenelParametreler[0].destekTelNo
            : this.state.GenelParametreler?.length > 0
            ? this.state.GenelParametreler[0].destekTelNo
            : null
        ),
        "string"
      ),
      FirmaAdi_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyeAd
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyeAd
            : null
        ),
        "string"
      ),
      language_3: ReactSystemFunctions.convertToTypeByName("", "string")
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "TSiparisKart/TSiparisKartComponent_833155_onClick3_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SendMailSettings = result?.data.sendMailSettings;
    stateVars.getKUserbyUserName = result?.data.getKUserbyUserName;
    stateVars.UyelikTipiLowercase = result?.data.uyelikTipiLowercase;
    stateVars.SendMail = result?.data.sendMail;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TSiparisKartComponent_833155_onClick4_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TSiparisKartComponent_833155_onClick4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TSiparisKartComponent_126479_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Aciklama_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tsipariskart_73972_value", "value", "", "", "")
        ),
        "string"
      ),
      NakliyeNot_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tsipariskart_738903_value", "value", "", "", "")
        ),
        "string"
      ),
      NakliyeOdemeTuru_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tsipariskart_731864_value", "value", "", "", "")
        ),
        "string"
      ),
      SiparisDurumID_0: ReactSystemFunctions.convertToTypeByName("00000000-0000-0000-0000-000000000001", "Guid"),
      OdemeKosulu_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tsipariskart_823113_value", "value", "", "", "")
        ),
        "string"
      ),
      Kur_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toDouble(
          this,
          ReactSystemFunctions.value(this, "tsipariskart_313721_value", "value", "", "", "")
        ),
        "number"
      ),
      TeklifGecerlilik_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tsipariskart_583315_value", "value", "", "", "")
        ),
        "string"
      ),
      SatisTuruID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tsipariskart_881644_value", "value", "SatisTuruAll", "id", "id")
        ),
        "Guid"
      ),
      SevkPlan_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tsipariskart_970873_value", "value", "", "", "")
        ),
        "string"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.SiparisKartId ?? this.props.screenInputs.sipariskartid,
        "Guid"
      ),
      SiparisOlusturmaKisiID_0: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
      SiparisOlusturmaTarihi_0: ReactSystemFunctions.convertToTypeByName(null, "Date"),
      TalepUyeID_0: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
      TeklifUyeID_0: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
      TeklifNos_0: ReactSystemFunctions.convertToTypeByName(null, "string"),
      Adres_0: ReactSystemFunctions.convertToTypeByName(null, "string"),
      SiparisKartId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.SiparisKartId ?? this.props.screenInputs.sipariskartid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    stateVars.Confirm = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "aa43d061_a6b0_ba05_4577_64036108ea7e_confirmation",
        this.defaultML,
        "Sipariş Müşteriye Onaya Gönderilecek; İşleme Devam Edilsin mi?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel",
      customClass: {
        title: "swal2-font-poppins"
      }
    });

    if (stateVars.Confirm.isConfirmed) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "TSiparisKart/TSiparisKartComponent_126479_onClick",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.SiparisKartSave = result?.data.siparisKartSave;
      stateVars.getSiparisNoBySiparisKartId = result?.data.getSiparisNoBySiparisKartId;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TSiparisKartComponent_126479_onClick1_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TSiparisKartComponent_126479_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "1024817d_9226_0bf5_17e3_dfe5d7774b2e_notify",
        this.defaultML,
        "Sipariş Müşteriye Onaya Gönderildi!"
      ),
      "success",
      "bottom-right",
      1,
      null,
      null,
      null
    );

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TSiparisKartComponent_126479_onClick2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  TSiparisKartComponent_126479_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      actionkey_0: ReactSystemFunctions.convertToTypeByName("taslaksiparis", "string"),
      uyeliktipi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyelikTipi
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyelikTipi
            : null
        ),
        "string"
      ),
      numara_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.getSiparisNoBySiparisKartId?.length > 0
            ? stateVars.getSiparisNoBySiparisKartId[0].siparis_No
            : this.state.getSiparisNoBySiparisKartId?.length > 0
            ? this.state.getSiparisNoBySiparisKartId[0].siparis_No
            : null
        ),
        "string"
      ),
      UserName_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.TaslakSiparisBaslikBilgi?.length > 0
            ? stateVars.TaslakSiparisBaslikBilgi[0].userName
            : this.state.TaslakSiparisBaslikBilgi?.length > 0
            ? this.state.TaslakSiparisBaslikBilgi[0].userName
            : null
        ),
        "string"
      ),
      UyelikTipi_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyelikTipi
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyelikTipi
            : null
        ),
        "string"
      ),
      templateId_3: ReactSystemFunctions.convertToTypeByName("7d4a6a07-d7e3-4962-9271-2167bfcad8bd", "string"),
      receivers_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.TaslakSiparisBaslikBilgi?.length > 0
            ? stateVars.TaslakSiparisBaslikBilgi[0].userName
            : this.state.TaslakSiparisBaslikBilgi?.length > 0
            ? this.state.TaslakSiparisBaslikBilgi[0].userName
            : null
        ),
        "string"
      ),
      sender_3: ReactSystemFunctions.convertToTypeByName("", "string"),
      cc_3: ReactSystemFunctions.convertToTypeByName("", "string"),
      bcc_3: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentFileName_3: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentBase64_3: ReactSystemFunctions.convertToTypeByName("", "string"),
      PublinkDestekTelNo_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.GenelParametreler?.length > 0
            ? stateVars.GenelParametreler[0].destekTelNo
            : this.state.GenelParametreler?.length > 0
            ? this.state.GenelParametreler[0].destekTelNo
            : null
        ),
        "string"
      ),
      FirmaAdi_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyeAd
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyeAd
            : null
        ),
        "string"
      ),
      language_3: ReactSystemFunctions.convertToTypeByName("", "string")
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "TSiparisKart/TSiparisKartComponent_126479_onClick2_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SendMailSettings = result?.data.sendMailSettings;
    stateVars.getKUserbyUserName = result?.data.getKUserbyUserName;
    stateVars.UyelikTipiLowercase = result?.data.uyelikTipiLowercase;
    stateVars.SendMail = result?.data.sendMail;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TSiparisKartComponent_126479_onClick3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TSiparisKartComponent_126479_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TSiparisKartComponent_842163_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      SiparisOnay_0: ReactSystemFunctions.convertToTypeByName(null, "boolean"),
      SiparisOnayTarihi_0: ReactSystemFunctions.convertToTypeByName(moment().format("YYYY-MM-DDTHH:mm:ss"), "Date"),
      SiparisOnayKisi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kUserId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kUserId
            : null
        ),
        "Guid"
      ),
      SiparisKartId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.SiparisKartId ?? this.props.screenInputs.sipariskartid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    stateVars.Confirm = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "89e5001e_4abe_394e_eeff_782a53eb7ebf_confirmation",
        this.defaultML,
        "Açıklama Girildi İse Sipariş Düzeltme İçin Geri Gönderilecek, İşleme Devam Edilsin mi?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel",
      customClass: {
        title: "swal2-font-poppins"
      }
    });

    if (stateVars.Confirm.isConfirmed) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "TSiparisKart/TSiparisKartComponent_842163_onClick",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.SiparisMusteriOnay = result?.data.siparisMusteriOnay;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TSiparisKartComponent_842163_onClick1_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TSiparisKartComponent_842163_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "d3658358_58b3_86e2_631b_b969a74f2e71_notify",
        this.defaultML,
        "Sipariş Düzeltme İçin Geri Gönderildi!"
      ),
      "success",
      "bottom-right",
      1,
      null,
      null,
      null
    );

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TSiparisKartComponent_842163_onClick2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  TSiparisKartComponent_842163_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      actionkey_0: ReactSystemFunctions.convertToTypeByName("siparistaslakrevizyon", "string"),
      uyeliktipi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyelikTipi
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyelikTipi
            : null
        ),
        "string"
      ),
      numara_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.TaslakSiparisBaslikBilgi?.length > 0
            ? stateVars.TaslakSiparisBaslikBilgi[0].siparis_No
            : this.state.TaslakSiparisBaslikBilgi?.length > 0
            ? this.state.TaslakSiparisBaslikBilgi[0].siparis_No
            : null
        ),
        "string"
      ),
      templateId_1: ReactSystemFunctions.convertToTypeByName("05d92b63-e353-47ee-a36c-0a5ad2046974", "string"),
      receivers_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.EserBilgileri_TeklifEdenKisiEmail?.length > 0
            ? stateVars.EserBilgileri_TeklifEdenKisiEmail[0].userName
            : this.state.EserBilgileri_TeklifEdenKisiEmail?.length > 0
            ? this.state.EserBilgileri_TeklifEdenKisiEmail[0].userName
            : null
        ),
        "string"
      ),
      sender_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      cc_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      bcc_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentFileName_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentBase64_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      PublinkDestekTelNo_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.GenelParametreler?.length > 0
            ? stateVars.GenelParametreler[0].destekTelNo
            : this.state.GenelParametreler?.length > 0
            ? this.state.GenelParametreler[0].destekTelNo
            : null
        ),
        "string"
      ),
      UyeIsmi_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.EserBilgileri_TeklifEdenKisiEmail?.length > 0
            ? stateVars.EserBilgileri_TeklifEdenKisiEmail[0].firstname
            : this.state.EserBilgileri_TeklifEdenKisiEmail?.length > 0
            ? this.state.EserBilgileri_TeklifEdenKisiEmail[0].firstname
            : null
        ),
        "string"
      ),
      UyeSoyad_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.EserBilgileri_TeklifEdenKisiEmail?.length > 0
            ? stateVars.EserBilgileri_TeklifEdenKisiEmail[0].lastName
            : this.state.EserBilgileri_TeklifEdenKisiEmail?.length > 0
            ? this.state.EserBilgileri_TeklifEdenKisiEmail[0].lastName
            : null
        ),
        "string"
      ),
      language_1: ReactSystemFunctions.convertToTypeByName("", "string")
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "TSiparisKart/TSiparisKartComponent_842163_onClick2_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SendMailSettings = result?.data.sendMailSettings;
    stateVars.SendMail = result?.data.sendMail;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TSiparisKartComponent_842163_onClick3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TSiparisKartComponent_842163_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TSiparisKartComponent_155808_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      SiparisOnay_0: ReactSystemFunctions.convertToTypeByName(false, "boolean"),
      SiparisOnayTarihi_0: ReactSystemFunctions.convertToTypeByName(moment().format("YYYY-MM-DDTHH:mm:ss"), "Date"),
      SiparisOnayKisi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kUserId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kUserId
            : null
        ),
        "Guid"
      ),
      SiparisKartId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.SiparisKartId ?? this.props.screenInputs.sipariskartid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "TSiparisKart/TSiparisKartComponent_155808_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SiparisMusteriOnay = result?.data.siparisMusteriOnay;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TSiparisKartComponent_155808_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TSiparisKartComponent_155808_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "c9b54462_2439_ea52_4dad_fe19a66222e6_notify",
        this.defaultML,
        "Sipariş Red Edildi!"
      ),
      "success",
      "bottom-right",
      1,
      null,
      null,
      null
    );

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TSiparisKartComponent_155808_onClick2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  TSiparisKartComponent_155808_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      actionkey_0: ReactSystemFunctions.convertToTypeByName("siparistaslakred", "string"),
      uyeliktipi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyelikTipi
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyelikTipi
            : null
        ),
        "string"
      ),
      numara_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.TaslakSiparisBaslikBilgi?.length > 0
            ? stateVars.TaslakSiparisBaslikBilgi[0].siparis_No
            : this.state.TaslakSiparisBaslikBilgi?.length > 0
            ? this.state.TaslakSiparisBaslikBilgi[0].siparis_No
            : null
        ),
        "string"
      ),
      templateId_1: ReactSystemFunctions.convertToTypeByName("8c085a16-2d21-41e3-8f6d-5ad1f6741ec2", "string"),
      receivers_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.EserBilgileri_TeklifEdenKisiEmail?.length > 0
            ? stateVars.EserBilgileri_TeklifEdenKisiEmail[0].userName
            : this.state.EserBilgileri_TeklifEdenKisiEmail?.length > 0
            ? this.state.EserBilgileri_TeklifEdenKisiEmail[0].userName
            : null
        ),
        "string"
      ),
      sender_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      cc_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      bcc_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentFileName_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentBase64_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      PublinkDestekTelNo_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.GenelParametreler?.length > 0
            ? stateVars.GenelParametreler[0].destekTelNo
            : this.state.GenelParametreler?.length > 0
            ? this.state.GenelParametreler[0].destekTelNo
            : null
        ),
        "string"
      ),
      UyeIsmi_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.EserBilgileri_TeklifEdenKisiEmail?.length > 0
            ? stateVars.EserBilgileri_TeklifEdenKisiEmail[0].firstname
            : this.state.EserBilgileri_TeklifEdenKisiEmail?.length > 0
            ? this.state.EserBilgileri_TeklifEdenKisiEmail[0].firstname
            : null
        ),
        "string"
      ),
      UyeSoyad_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.EserBilgileri_TeklifEdenKisiEmail?.length > 0
            ? stateVars.EserBilgileri_TeklifEdenKisiEmail[0].lastName
            : this.state.EserBilgileri_TeklifEdenKisiEmail?.length > 0
            ? this.state.EserBilgileri_TeklifEdenKisiEmail[0].lastName
            : null
        ),
        "string"
      ),
      language_1: ReactSystemFunctions.convertToTypeByName("", "string")
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "TSiparisKart/TSiparisKartComponent_155808_onClick2_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SendMailSettings = result?.data.sendMailSettings;
    stateVars.SendMail = result?.data.sendMail;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TSiparisKartComponent_155808_onClick3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TSiparisKartComponent_155808_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TSiparisKartComponent_235855_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      SiparisOnay_0: ReactSystemFunctions.convertToTypeByName(true, "boolean"),
      SiparisOnayTarihi_0: ReactSystemFunctions.convertToTypeByName(moment().format("YYYY-MM-DDTHH:mm:ss"), "Date"),
      SiparisOnayKisi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kUserId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kUserId
            : null
        ),
        "Guid"
      ),
      SiparisKartId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.SiparisKartId ?? this.props.screenInputs.sipariskartid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "TSiparisKart/TSiparisKartComponent_235855_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SiparisMusteriOnay = result?.data.siparisMusteriOnay;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TSiparisKartComponent_235855_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TSiparisKartComponent_235855_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "9b72a481_e5ef_5ae9_a233_94a4aee2dd67_notify",
        this.defaultML,
        "Sipariş Onaylandı!"
      ),
      "success",
      "bottom-right",
      1,
      "",
      "",
      0
    );

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TSiparisKartComponent_235855_onClick2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  TSiparisKartComponent_235855_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      actionkey_0: ReactSystemFunctions.convertToTypeByName("siparistaslakonay", "string"),
      uyeliktipi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyelikTipi
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyelikTipi
            : null
        ),
        "string"
      ),
      numara_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.TaslakSiparisBaslikBilgi?.length > 0
            ? stateVars.TaslakSiparisBaslikBilgi[0].siparis_No
            : this.state.TaslakSiparisBaslikBilgi?.length > 0
            ? this.state.TaslakSiparisBaslikBilgi[0].siparis_No
            : null
        ),
        "string"
      ),
      UyelikTipi_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyelikTipi
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyelikTipi
            : null
        ),
        "string"
      ),
      templateId_2: ReactSystemFunctions.convertToTypeByName("c7ee1d7e-fbb2-43a6-86ce-5abb2968d470", "string"),
      receivers_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.EserBilgileri_TeklifEdenKisiEmail?.length > 0
            ? stateVars.EserBilgileri_TeklifEdenKisiEmail[0].userName
            : this.state.EserBilgileri_TeklifEdenKisiEmail?.length > 0
            ? this.state.EserBilgileri_TeklifEdenKisiEmail[0].userName
            : null
        ),
        "string"
      ),
      sender_2: ReactSystemFunctions.convertToTypeByName("", "string"),
      cc_2: ReactSystemFunctions.convertToTypeByName("", "string"),
      bcc_2: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentFileName_2: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentBase64_2: ReactSystemFunctions.convertToTypeByName("", "string"),
      PublinkDestekTelNo_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.GenelParametreler?.length > 0
            ? stateVars.GenelParametreler[0].destekTelNo
            : this.state.GenelParametreler?.length > 0
            ? this.state.GenelParametreler[0].destekTelNo
            : null
        ),
        "string"
      ),
      UyeIsmi_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.EserBilgileri_TeklifEdenKisiEmail?.length > 0
            ? stateVars.EserBilgileri_TeklifEdenKisiEmail[0].firstname
            : this.state.EserBilgileri_TeklifEdenKisiEmail?.length > 0
            ? this.state.EserBilgileri_TeklifEdenKisiEmail[0].firstname
            : null
        ),
        "string"
      ),
      UyeSoyad_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.EserBilgileri_TeklifEdenKisiEmail?.length > 0
            ? stateVars.EserBilgileri_TeklifEdenKisiEmail[0].lastName
            : this.state.EserBilgileri_TeklifEdenKisiEmail?.length > 0
            ? this.state.EserBilgileri_TeklifEdenKisiEmail[0].lastName
            : null
        ),
        "string"
      ),
      FirmaAdi_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyeAd
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyeAd
            : null
        ),
        "string"
      ),
      language_2: ReactSystemFunctions.convertToTypeByName("", "string")
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "TSiparisKart/TSiparisKartComponent_235855_onClick2_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SendMailSettings = result?.data.sendMailSettings;
    stateVars.UyelikTipiLowercase = result?.data.uyelikTipiLowercase;
    stateVars.SendMail = result?.data.sendMail;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TSiparisKartComponent_235855_onClick3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TSiparisKartComponent_235855_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TSiparisKartComponent_257784_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [348381, 180819] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.TSiparisKartPageInit();
    }
  }
}
