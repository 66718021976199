import { FormInstance } from "antd/es/form";
import moment from "moment";
import React from "react";
import Swal from "sweetalert2";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface ITeklifTalepKart_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface ITeklifTalepKart_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GoBack: any;
  GoBack_dummy: any;
  MyContext: any[];
  MyContext_dummy: any[];
  KullaniciList: any[];
  KullaniciList_dummy: any[];
  EserDetay_CiltBicimiAll: any[];
  EserDetay_CiltBicimiAll_dummy: any[];
  EserDetay_SelefonAll: any[];
  EserDetay_SelefonAll_dummy: any[];
  EserDetay_IcBaskiRenkAll: any[];
  EserDetay_IcBaskiRenkAll_dummy: any[];
  EserDetay_KapakCinsiAll: any[];
  EserDetay_KapakCinsiAll_dummy: any[];
  EserDetay_IcBaskiKagitCinsiAll: any[];
  EserDetay_IcBaskiKagitCinsiAll_dummy: any[];
  EserDetay_KitapBoyutAll: any[];
  EserDetay_KitapBoyutAll_dummy: any[];
  TeklifAlinacakFirmaByUyelikTipi: any[];
  TeklifAlinacakFirmaByUyelikTipi_dummy: any[];
  NakliyeDurumAll: any[];
  NakliyeDurumAll_dummy: any[];
  TeklifTalepKartById: any[];
  TeklifTalepKartById_dummy: any[];
  EserDetay_IcBaskiKagitGramajByKagitCinsi: any[];
  EserDetay_IcBaskiKagitGramajByKagitCinsi_dummy: any[];
  EserDetay_KapakGramajByKapakCinsi: any[];
  EserDetay_KapakGramajByKapakCinsi_dummy: any[];
  ChangeEnabledOf: any;
  ChangeEnabledOf_dummy: any;
  SetValueOf: any;
  SetValueOf_dummy: any;
  GenelParametreler: any[];
  GenelParametreler_dummy: any[];
  TeklifTalepKartSave: number;
  TeklifTalepKartSave_dummy: number;
  Notify: boolean;
  Notify_dummy: boolean;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  FirmaSendMailAdress: any[];
  FirmaSendMailAdress_dummy: any[];
  getTalepNoUserByTeklifTalepKartId: any[];
  getTalepNoUserByTeklifTalepKartId_dummy: any[];
  UyelikTipiLowercase: any[];
  UyelikTipiLowercase_dummy: any[];
  SendMailSettings: any[];
  SendMailSettings_dummy: any[];
  SendMail: boolean;
  SendMail_dummy: boolean;
  isComp182940Visible: "visible" | "hidden";
  isComp371097Visible: "visible" | "hidden";
  isComp92196Visible: "visible" | "hidden";
  isComp282467Visible: "visible" | "hidden";
  isComp406100Enabled: "enabled" | "disabled";
  isComp976483Enabled: "enabled" | "disabled";
  isComp448472Enabled: "enabled" | "disabled";
  isComp181252Enabled: "enabled" | "disabled";
  isComp570219Enabled: "enabled" | "disabled";
  isComp253304Enabled: "enabled" | "disabled";
  isComp81822Enabled: "enabled" | "disabled";
  isComp454757Enabled: "enabled" | "disabled";
  isComp923446Enabled: "enabled" | "disabled";
  isComp214220Enabled: "enabled" | "disabled";
  isComp195740Enabled: "enabled" | "disabled";
  isComp738987Enabled: "enabled" | "disabled";
  isComp673902Enabled: "enabled" | "disabled";
  isComp990679Enabled: "enabled" | "disabled";
  isComp608589Enabled: "enabled" | "disabled";
}

export class TeklifTalepKart_ScreenBase extends React.PureComponent<ITeklifTalepKart_ScreenProps, any> {
  tekliftalepkart_976483_value_kuikaMultiSelectRef: React.RefObject<any>;
  tekliftalepkart_406100_value_kuikaSelectBoxRef: React.RefObject<any>;
  tekliftalepkart_923446_value_kuikaSelectBoxRef: React.RefObject<any>;
  tekliftalepkart_570219_value_kuikaSelectBoxRef: React.RefObject<any>;
  tekliftalepkart_195740_value_kuikaSelectBoxRef: React.RefObject<any>;
  tekliftalepkart_253304_value_kuikaSelectBoxRef: React.RefObject<any>;
  tekliftalepkart_738987_value_kuikaSelectBoxRef: React.RefObject<any>;
  tekliftalepkart_81822_value_kuikaSelectBoxRef: React.RefObject<any>;
  tekliftalepkart_673902_value_kuikaSelectBoxRef: React.RefObject<any>;
  tekliftalepkart_454757_value_kuikaSelectBoxRef: React.RefObject<any>;
  tekliftalepkart_990679_value_kuikaSelectBoxRef: React.RefObject<any>;
  ml = [
    {
      Id: "41cd36a4-5b07-cfca-4f7f-f48019ff4ff3",
      Name: "tr_TR",
      ShortName: "Türkçe",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "0069cee9-16ca-4a5a-aab9-dd58af96ca96", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "9fc557bd-ed5c-4973-ab98-2f8441423c02",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 319201, PropertyName: "value", Value: "Teklif Talep İçeriği" },
        { Id: 477032, PropertyName: "value", Value: "Teklif Alınacak Firma" },
        { Id: 640494, PropertyName: "value", Value: "Teklif Muhatabı (Yayıncı, Yazar vb)" },
        { Id: 104062, PropertyName: "value", Value: "Kitap Adı" },
        { Id: 717379, PropertyName: "value", Value: "Kitap Boyutu" },
        { Id: 641427, PropertyName: "value", Value: "Sayfa Sayısı" },
        { Id: 181252, PropertyName: "placeholder", Value: "15" },
        { Id: 917444, PropertyName: "value", Value: "Baskı Adet" },
        { Id: 214220, PropertyName: "placeholder", Value: "15" },
        { Id: 500920, PropertyName: "value", Value: "Kullanılacak İç Kağıt Cinsi" },
        { Id: 631285, PropertyName: "value", Value: "Kullanılacak İç Kağıt Gramajı" },
        { Id: 120178, PropertyName: "value", Value: "Kullanılacak Kapak Cinsi" },
        { Id: 904765, PropertyName: "value", Value: "Kullanılacak Kapak Gramajı" },
        { Id: 871761, PropertyName: "value", Value: "İç Baskı Renk (Siyah Beyaz/Renkli)" },
        { Id: 919228, PropertyName: "value", Value: "Kullanılacak Kapak Selefonu" },
        { Id: 428624, PropertyName: "value", Value: "Cilt Biçimi" },
        { Id: 241924, PropertyName: "value", Value: "Nakliye" },
        {
          Id: 73496,
          PropertyName: "title",
          Value:
            "Kitabın derlemeye tabii olması durumunda girilen adet içerisinde derleme nüshalarının da dahil edilerek giriş yapılmasına ilişkin bir uyarı koyulmalıdır. Ayrıca Baskı Adedi minimum 50 olacak şekilde sınırlama getirilmelidir."
        },
        {
          Id: 371097,
          PropertyName: "value",
          Value:
            "Kitabın derlemeye tabii olması durumunda girilen adet içerisinde derleme nüshalarının da dahil edilerek giriş yapılmasına ilişkin bir uyarı koyulmalıdır. Ayrıca Baskı Adedi minimum 50 olacak şekilde sınırlama getirilmelidir."
        },
        { Id: 576902, PropertyName: "value", Value: "Notlar" },
        { Id: 93718, PropertyName: "value", Value: "Doküman Ekleme (görsel vb)" },
        { Id: 350470, PropertyName: "value", Value: "Görsel Ekle" },
        { Id: 464630, PropertyName: "value", Value: "Görsel Ekle" },
        {
          Id: 783887,
          PropertyName: "value",
          Value: "Teklif Kabul sonrası Kapak/PDF yükleme ile beraber kalan bilgi girişleri gerçekleştirilebilir."
        },
        { Id: 592092, PropertyName: "label", Value: "Taslak Olarak Kaydet" },
        { Id: 508444, PropertyName: "label", Value: "Kaydet & Gönder" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.tekliftalepkart_976483_value_kuikaMultiSelectRef = React.createRef();
    this.tekliftalepkart_406100_value_kuikaSelectBoxRef = React.createRef();
    this.tekliftalepkart_923446_value_kuikaSelectBoxRef = React.createRef();
    this.tekliftalepkart_570219_value_kuikaSelectBoxRef = React.createRef();
    this.tekliftalepkart_195740_value_kuikaSelectBoxRef = React.createRef();
    this.tekliftalepkart_253304_value_kuikaSelectBoxRef = React.createRef();
    this.tekliftalepkart_738987_value_kuikaSelectBoxRef = React.createRef();
    this.tekliftalepkart_81822_value_kuikaSelectBoxRef = React.createRef();
    this.tekliftalepkart_673902_value_kuikaSelectBoxRef = React.createRef();
    this.tekliftalepkart_454757_value_kuikaSelectBoxRef = React.createRef();
    this.tekliftalepkart_990679_value_kuikaSelectBoxRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GoBack: "",
      MyContext: [],
      KullaniciList: [],
      EserDetay_CiltBicimiAll: [],
      EserDetay_SelefonAll: [],
      EserDetay_IcBaskiRenkAll: [],
      EserDetay_KapakCinsiAll: [],
      EserDetay_IcBaskiKagitCinsiAll: [],
      EserDetay_KitapBoyutAll: [],
      TeklifAlinacakFirmaByUyelikTipi: [],
      NakliyeDurumAll: [],
      TeklifTalepKartById: [],
      EserDetay_IcBaskiKagitGramajByKagitCinsi: [],
      EserDetay_KapakGramajByKapakCinsi: [],
      ChangeEnabledOf: "",
      SetValueOf: "",
      GenelParametreler: [],
      TeklifTalepKartSave: 0,
      Notify: false,
      NAVIGATE: "",
      FirmaSendMailAdress: [],
      getTalepNoUserByTeklifTalepKartId: [],
      UyelikTipiLowercase: [],
      SendMailSettings: [],
      SendMail: false,
      isComp182940Visible: "hidden",
      isComp371097Visible: "hidden",
      isComp92196Visible: "hidden",
      isComp282467Visible: "hidden",
      isComp406100Enabled: "enabled",
      isComp976483Enabled: "enabled",
      isComp448472Enabled: "enabled",
      isComp181252Enabled: "enabled",
      isComp570219Enabled: "enabled",
      isComp253304Enabled: "enabled",
      isComp81822Enabled: "enabled",
      isComp454757Enabled: "enabled",
      isComp923446Enabled: "enabled",
      isComp214220Enabled: "enabled",
      isComp195740Enabled: "enabled",
      isComp738987Enabled: "enabled",
      isComp673902Enabled: "enabled",
      isComp990679Enabled: "enabled",
      isComp608589Enabled: "enabled"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("tekliftalepkart", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.TeklifTalepKartPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("tekliftalepkart", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("tekliftalepkart", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.TeklifTalepKartPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      tekliftalepkart_976483_value: this.state.TeklifTalepKartById?.at?.(0)?.teklifTalepEdilenFirmalar ?? undefined,
      tekliftalepkart_448472_value: this.state.TeklifTalepKartById?.at?.(0)?.kitapAd ?? undefined,
      tekliftalepkart_923446_value: this.state.TeklifTalepKartById?.at?.(0)?.kitapBoyutID ?? undefined,
      tekliftalepkart_181252_value: this.state.TeklifTalepKartById?.at?.(0)?.sayfaSayisi ?? undefined,
      tekliftalepkart_214220_value: this.state.TeklifTalepKartById?.at?.(0)?.baskiAdet ?? undefined,
      tekliftalepkart_570219_value: this.state.TeklifTalepKartById?.at?.(0)?.icBaskiKagitCinsiID ?? undefined,
      tekliftalepkart_195740_value: this.state.TeklifTalepKartById?.at?.(0)?.icBaskiKagitGramajID ?? undefined,
      tekliftalepkart_253304_value: this.state.TeklifTalepKartById?.at?.(0)?.kapakCinsiID ?? undefined,
      tekliftalepkart_738987_value: this.state.TeklifTalepKartById?.at?.(0)?.kapakGramajID ?? undefined,
      tekliftalepkart_81822_value: this.state.TeklifTalepKartById?.at?.(0)?.icBaskiRenkID ?? undefined,
      tekliftalepkart_673902_value: this.state.TeklifTalepKartById?.at?.(0)?.selefonID ?? undefined,
      tekliftalepkart_454757_value: this.state.TeklifTalepKartById?.at?.(0)?.ciltBicimiID ?? undefined,
      tekliftalepkart_990679_value: this.state.TeklifTalepKartById?.at?.(0)?.nakliyeDurumID ?? undefined,
      tekliftalepkart_608589_value: this.state.TeklifTalepKartById?.at?.(0)?.aciklama ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  TeklifTalepKartPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      UserName_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      UyelikTipi_8: ReactSystemFunctions.convertToTypeByName(2, "number"),
      Id_10: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmID ?? this.props.screenInputs.prmid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "TeklifTalepKart/TeklifTalepKartPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    stateVars.KullaniciList = result?.data.kullaniciList;

    formVars.tekliftalepkart_406100_options = stateVars.KullaniciList;
    stateVars.EserDetay_CiltBicimiAll = result?.data.eserDetay_CiltBicimiAll;

    formVars.tekliftalepkart_454757_value =
      stateVars.TeklifTalepKartById?.length > 0
        ? stateVars.TeklifTalepKartById[0].ciltBicimiID
        : this.state.TeklifTalepKartById?.length > 0
        ? this.state.TeklifTalepKartById[0].ciltBicimiID
        : null;
    formVars.tekliftalepkart_454757_options = stateVars.EserDetay_CiltBicimiAll;
    stateVars.EserDetay_SelefonAll = result?.data.eserDetay_SelefonAll;

    formVars.tekliftalepkart_673902_value =
      stateVars.TeklifTalepKartById?.length > 0
        ? stateVars.TeklifTalepKartById[0].selefonID
        : this.state.TeklifTalepKartById?.length > 0
        ? this.state.TeklifTalepKartById[0].selefonID
        : null;
    formVars.tekliftalepkart_673902_options = stateVars.EserDetay_SelefonAll;
    stateVars.EserDetay_IcBaskiRenkAll = result?.data.eserDetay_IcBaskiRenkAll;

    formVars.tekliftalepkart_81822_value =
      stateVars.TeklifTalepKartById?.length > 0
        ? stateVars.TeklifTalepKartById[0].icBaskiRenkID
        : this.state.TeklifTalepKartById?.length > 0
        ? this.state.TeklifTalepKartById[0].icBaskiRenkID
        : null;
    formVars.tekliftalepkart_81822_options = stateVars.EserDetay_IcBaskiRenkAll;
    stateVars.EserDetay_KapakCinsiAll = result?.data.eserDetay_KapakCinsiAll;

    formVars.tekliftalepkart_253304_value =
      stateVars.TeklifTalepKartById?.length > 0
        ? stateVars.TeklifTalepKartById[0].kapakCinsiID
        : this.state.TeklifTalepKartById?.length > 0
        ? this.state.TeklifTalepKartById[0].kapakCinsiID
        : null;
    formVars.tekliftalepkart_253304_options = stateVars.EserDetay_KapakCinsiAll;
    stateVars.EserDetay_IcBaskiKagitCinsiAll = result?.data.eserDetay_IcBaskiKagitCinsiAll;

    formVars.tekliftalepkart_570219_value =
      stateVars.TeklifTalepKartById?.length > 0
        ? stateVars.TeklifTalepKartById[0].icBaskiKagitCinsiID
        : this.state.TeklifTalepKartById?.length > 0
        ? this.state.TeklifTalepKartById[0].icBaskiKagitCinsiID
        : null;
    formVars.tekliftalepkart_570219_options = stateVars.EserDetay_IcBaskiKagitCinsiAll;
    stateVars.EserDetay_KitapBoyutAll = result?.data.eserDetay_KitapBoyutAll;

    formVars.tekliftalepkart_923446_value =
      stateVars.TeklifTalepKartById?.length > 0
        ? stateVars.TeklifTalepKartById[0].kitapBoyutID
        : this.state.TeklifTalepKartById?.length > 0
        ? this.state.TeklifTalepKartById[0].kitapBoyutID
        : null;
    formVars.tekliftalepkart_923446_options = stateVars.EserDetay_KitapBoyutAll;
    stateVars.TeklifAlinacakFirmaByUyelikTipi = result?.data.teklifAlinacakFirmaByUyelikTipi;

    formVars.tekliftalepkart_976483_value =
      stateVars.TeklifTalepKartById?.length > 0
        ? stateVars.TeklifTalepKartById[0].teklifTalepEdilenFirmalar
        : this.state.TeklifTalepKartById?.length > 0
        ? this.state.TeklifTalepKartById[0].teklifTalepEdilenFirmalar
        : null;
    formVars.tekliftalepkart_976483_options = stateVars.TeklifAlinacakFirmaByUyelikTipi;
    stateVars.NakliyeDurumAll = result?.data.nakliyeDurumAll;

    formVars.tekliftalepkart_990679_value =
      stateVars.TeklifTalepKartById?.length > 0
        ? stateVars.TeklifTalepKartById[0].nakliyeDurumID
        : this.state.TeklifTalepKartById?.length > 0
        ? this.state.TeklifTalepKartById[0].nakliyeDurumID
        : null;
    formVars.tekliftalepkart_990679_options = stateVars.NakliyeDurumAll;
    stateVars.TeklifTalepKartById = result?.data.teklifTalepKartById;
    formVars.tekliftalepkart_976483_value =
      stateVars.TeklifTalepKartById?.length > 0
        ? stateVars.TeklifTalepKartById[0].teklifTalepEdilenFirmalar
        : this.state.TeklifTalepKartById?.length > 0
        ? this.state.TeklifTalepKartById[0].teklifTalepEdilenFirmalar
        : null;
    formVars.tekliftalepkart_976483_options = stateVars.TeklifAlinacakFirmaByUyelikTipi;
    formVars.tekliftalepkart_448472_value = ReactSystemFunctions.toString(
      this,
      stateVars.TeklifTalepKartById?.length > 0
        ? stateVars.TeklifTalepKartById[0].kitapAd
        : this.state.TeklifTalepKartById?.length > 0
        ? this.state.TeklifTalepKartById[0].kitapAd
        : null
    );
    formVars.tekliftalepkart_923446_value =
      stateVars.TeklifTalepKartById?.length > 0
        ? stateVars.TeklifTalepKartById[0].kitapBoyutID
        : this.state.TeklifTalepKartById?.length > 0
        ? this.state.TeklifTalepKartById[0].kitapBoyutID
        : null;
    formVars.tekliftalepkart_923446_options = stateVars.EserDetay_KitapBoyutAll;
    formVars.tekliftalepkart_181252_value = ReactSystemFunctions.value(
      this,
      stateVars.TeklifTalepKartById?.length > 0
        ? stateVars.TeklifTalepKartById[0].sayfaSayisi
        : this.state.TeklifTalepKartById?.length > 0
        ? this.state.TeklifTalepKartById[0].sayfaSayisi
        : null
    );
    formVars.tekliftalepkart_214220_value = ReactSystemFunctions.value(
      this,
      stateVars.TeklifTalepKartById?.length > 0
        ? stateVars.TeklifTalepKartById[0].baskiAdet
        : this.state.TeklifTalepKartById?.length > 0
        ? this.state.TeklifTalepKartById[0].baskiAdet
        : null
    );
    formVars.tekliftalepkart_570219_value =
      stateVars.TeklifTalepKartById?.length > 0
        ? stateVars.TeklifTalepKartById[0].icBaskiKagitCinsiID
        : this.state.TeklifTalepKartById?.length > 0
        ? this.state.TeklifTalepKartById[0].icBaskiKagitCinsiID
        : null;
    formVars.tekliftalepkart_570219_options = stateVars.EserDetay_IcBaskiKagitCinsiAll;
    formVars.tekliftalepkart_195740_value =
      stateVars.TeklifTalepKartById?.length > 0
        ? stateVars.TeklifTalepKartById[0].icBaskiKagitGramajID
        : this.state.TeklifTalepKartById?.length > 0
        ? this.state.TeklifTalepKartById[0].icBaskiKagitGramajID
        : null;
    formVars.tekliftalepkart_195740_options = stateVars.EserDetay_IcBaskiKagitGramajByKagitCinsi;
    formVars.tekliftalepkart_253304_value =
      stateVars.TeklifTalepKartById?.length > 0
        ? stateVars.TeklifTalepKartById[0].kapakCinsiID
        : this.state.TeklifTalepKartById?.length > 0
        ? this.state.TeklifTalepKartById[0].kapakCinsiID
        : null;
    formVars.tekliftalepkart_253304_options = stateVars.EserDetay_KapakCinsiAll;
    formVars.tekliftalepkart_738987_value =
      stateVars.TeklifTalepKartById?.length > 0
        ? stateVars.TeklifTalepKartById[0].kapakGramajID
        : this.state.TeklifTalepKartById?.length > 0
        ? this.state.TeklifTalepKartById[0].kapakGramajID
        : null;
    formVars.tekliftalepkart_738987_options = stateVars.EserDetay_KapakGramajByKapakCinsi;
    formVars.tekliftalepkart_81822_value =
      stateVars.TeklifTalepKartById?.length > 0
        ? stateVars.TeklifTalepKartById[0].icBaskiRenkID
        : this.state.TeklifTalepKartById?.length > 0
        ? this.state.TeklifTalepKartById[0].icBaskiRenkID
        : null;
    formVars.tekliftalepkart_81822_options = stateVars.EserDetay_IcBaskiRenkAll;
    formVars.tekliftalepkart_673902_value =
      stateVars.TeklifTalepKartById?.length > 0
        ? stateVars.TeklifTalepKartById[0].selefonID
        : this.state.TeklifTalepKartById?.length > 0
        ? this.state.TeklifTalepKartById[0].selefonID
        : null;
    formVars.tekliftalepkart_673902_options = stateVars.EserDetay_SelefonAll;
    formVars.tekliftalepkart_454757_value =
      stateVars.TeklifTalepKartById?.length > 0
        ? stateVars.TeklifTalepKartById[0].ciltBicimiID
        : this.state.TeklifTalepKartById?.length > 0
        ? this.state.TeklifTalepKartById[0].ciltBicimiID
        : null;
    formVars.tekliftalepkart_454757_options = stateVars.EserDetay_CiltBicimiAll;
    formVars.tekliftalepkart_990679_value =
      stateVars.TeklifTalepKartById?.length > 0
        ? stateVars.TeklifTalepKartById[0].nakliyeDurumID
        : this.state.TeklifTalepKartById?.length > 0
        ? this.state.TeklifTalepKartById[0].nakliyeDurumID
        : null;
    formVars.tekliftalepkart_990679_options = stateVars.NakliyeDurumAll;
    formVars.tekliftalepkart_608589_value = ReactSystemFunctions.toString(
      this,
      stateVars.TeklifTalepKartById?.length > 0
        ? stateVars.TeklifTalepKartById[0].aciklama
        : this.state.TeklifTalepKartById?.length > 0
        ? this.state.TeklifTalepKartById[0].aciklama
        : null
    );
    stateVars.EserDetay_IcBaskiKagitGramajByKagitCinsi = result?.data.eserDetay_IcBaskiKagitGramajByKagitCinsi;

    formVars.tekliftalepkart_195740_value =
      stateVars.TeklifTalepKartById?.length > 0
        ? stateVars.TeklifTalepKartById[0].icBaskiKagitGramajID
        : this.state.TeklifTalepKartById?.length > 0
        ? this.state.TeklifTalepKartById[0].icBaskiKagitGramajID
        : null;
    formVars.tekliftalepkart_195740_options = stateVars.EserDetay_IcBaskiKagitGramajByKagitCinsi;
    stateVars.EserDetay_KapakGramajByKapakCinsi = result?.data.eserDetay_KapakGramajByKapakCinsi;

    formVars.tekliftalepkart_738987_value =
      stateVars.TeklifTalepKartById?.length > 0
        ? stateVars.TeklifTalepKartById[0].kapakGramajID
        : this.state.TeklifTalepKartById?.length > 0
        ? this.state.TeklifTalepKartById[0].kapakGramajID
        : null;
    formVars.tekliftalepkart_738987_options = stateVars.EserDetay_KapakGramajByKapakCinsi;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TeklifTalepKartPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TeklifTalepKartPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp406100Enabled", "disabled");

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TeklifTalepKartPageInit2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  TeklifTalepKartPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.NewEditDisplay ?? this.props.screenInputs.neweditdisplay,
        "N"
      )
    ) {
      stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
        this,
        "tekliftalepkart_406100_value",
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyeId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyeId
            : null
        ),
        null,
        "UyeId"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TeklifTalepKartPageInit3_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TeklifTalepKartPageInit3_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  TeklifTalepKartPageInit3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isNotEqualTo(
        this.props.screenInputs.NewEditDisplay ?? this.props.screenInputs.neweditdisplay,
        "N"
      )
    ) {
      stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
        this,
        "tekliftalepkart_406100_value",
        ReactSystemFunctions.toString(
          this,
          stateVars.TeklifTalepKartById?.length > 0
            ? stateVars.TeklifTalepKartById[0].teklifTalepEdenUyeID
            : this.state.TeklifTalepKartById?.length > 0
            ? this.state.TeklifTalepKartById[0].teklifTalepEdenUyeID
            : null
        ),
        null,
        "UyeId"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TeklifTalepKartPageInit4_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TeklifTalepKartPageInit4_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  TeklifTalepKartPageInit4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.NewEditDisplay ?? this.props.screenInputs.neweditdisplay,
        "D"
      )
    ) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp976483Enabled", "disabled");

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TeklifTalepKartPageInit5_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TeklifTalepKartPageInit5_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  TeklifTalepKartPageInit5_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.NewEditDisplay ?? this.props.screenInputs.neweditdisplay,
        "D"
      )
    ) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp448472Enabled", "disabled");

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TeklifTalepKartPageInit6_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TeklifTalepKartPageInit6_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  TeklifTalepKartPageInit6_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.NewEditDisplay ?? this.props.screenInputs.neweditdisplay,
        "D"
      )
    ) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp181252Enabled", "disabled");

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TeklifTalepKartPageInit7_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TeklifTalepKartPageInit7_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  TeklifTalepKartPageInit7_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.NewEditDisplay ?? this.props.screenInputs.neweditdisplay,
        "D"
      )
    ) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp570219Enabled", "disabled");

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TeklifTalepKartPageInit8_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TeklifTalepKartPageInit8_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  TeklifTalepKartPageInit8_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.NewEditDisplay ?? this.props.screenInputs.neweditdisplay,
        "D"
      )
    ) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp253304Enabled", "disabled");

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TeklifTalepKartPageInit9_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TeklifTalepKartPageInit9_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  TeklifTalepKartPageInit9_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.NewEditDisplay ?? this.props.screenInputs.neweditdisplay,
        "D"
      )
    ) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp81822Enabled", "disabled");

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TeklifTalepKartPageInit10_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TeklifTalepKartPageInit10_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  TeklifTalepKartPageInit10_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.NewEditDisplay ?? this.props.screenInputs.neweditdisplay,
        "D"
      )
    ) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp454757Enabled", "disabled");

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TeklifTalepKartPageInit11_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TeklifTalepKartPageInit11_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  TeklifTalepKartPageInit11_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.NewEditDisplay ?? this.props.screenInputs.neweditdisplay,
        "D"
      )
    ) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp923446Enabled", "disabled");

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TeklifTalepKartPageInit12_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TeklifTalepKartPageInit12_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  TeklifTalepKartPageInit12_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.NewEditDisplay ?? this.props.screenInputs.neweditdisplay,
        "D"
      )
    ) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp214220Enabled", "disabled");

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TeklifTalepKartPageInit13_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TeklifTalepKartPageInit13_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  TeklifTalepKartPageInit13_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.NewEditDisplay ?? this.props.screenInputs.neweditdisplay,
        "D"
      )
    ) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp195740Enabled", "disabled");

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TeklifTalepKartPageInit14_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TeklifTalepKartPageInit14_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  TeklifTalepKartPageInit14_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.NewEditDisplay ?? this.props.screenInputs.neweditdisplay,
        "D"
      )
    ) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp738987Enabled", "disabled");

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TeklifTalepKartPageInit15_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TeklifTalepKartPageInit15_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  TeklifTalepKartPageInit15_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.NewEditDisplay ?? this.props.screenInputs.neweditdisplay,
        "D"
      )
    ) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp673902Enabled", "disabled");

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TeklifTalepKartPageInit16_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TeklifTalepKartPageInit16_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  TeklifTalepKartPageInit16_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.NewEditDisplay ?? this.props.screenInputs.neweditdisplay,
        "D"
      )
    ) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp990679Enabled", "disabled");

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TeklifTalepKartPageInit17_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TeklifTalepKartPageInit17_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  TeklifTalepKartPageInit17_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.NewEditDisplay ?? this.props.screenInputs.neweditdisplay,
        "D"
      )
    ) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp608589Enabled", "disabled");

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TeklifTalepKartPageInit18_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TeklifTalepKartPageInit18_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  TeklifTalepKartPageInit18_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {};

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "TeklifTalepKart/TeklifTalepKartPageInit18_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GenelParametreler = result?.data.genelParametreler;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TeklifTalepKartPageInit19_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TeklifTalepKartPageInit19_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.tekliftalepkart_976483_value = ReactSystemFunctions.toString(
      this,
      stateVars.TeklifTalepKartById?.length > 0
        ? stateVars.TeklifTalepKartById[0].teklifTalepEdilenFirmalar
        : this.state.TeklifTalepKartById?.length > 0
        ? this.state.TeklifTalepKartById[0].teklifTalepEdilenFirmalar
        : null
    );

    stateVars.dataSource_976483 = this.state.TeklifAlinacakFirmaByUyelikTipi;
    stateVars.dataSource_976483 = this.state.TeklifAlinacakFirmaByUyelikTipi;

    stateVars.dataSource_406100 = this.state.KullaniciList;
    stateVars.dataSource_406100 = this.state.KullaniciList;
    formVars.tekliftalepkart_448472_value = ReactSystemFunctions.toString(
      this,
      stateVars.TeklifTalepKartById?.length > 0
        ? stateVars.TeklifTalepKartById[0].kitapAd
        : this.state.TeklifTalepKartById?.length > 0
        ? this.state.TeklifTalepKartById[0].kitapAd
        : null
    );

    formVars.tekliftalepkart_923446_value = ReactSystemFunctions.toString(
      this,
      stateVars.TeklifTalepKartById?.length > 0
        ? stateVars.TeklifTalepKartById[0].kitapBoyutID
        : this.state.TeklifTalepKartById?.length > 0
        ? this.state.TeklifTalepKartById[0].kitapBoyutID
        : null
    );

    stateVars.dataSource_923446 = this.state.EserDetay_KitapBoyutAll;
    stateVars.dataSource_923446 = this.state.EserDetay_KitapBoyutAll;
    formVars.tekliftalepkart_181252_value = ReactSystemFunctions.value(
      this,
      stateVars.TeklifTalepKartById?.length > 0
        ? stateVars.TeklifTalepKartById[0].sayfaSayisi
        : this.state.TeklifTalepKartById?.length > 0
        ? this.state.TeklifTalepKartById[0].sayfaSayisi
        : null
    );

    formVars.tekliftalepkart_214220_value = ReactSystemFunctions.value(
      this,
      stateVars.TeklifTalepKartById?.length > 0
        ? stateVars.TeklifTalepKartById[0].baskiAdet
        : this.state.TeklifTalepKartById?.length > 0
        ? this.state.TeklifTalepKartById[0].baskiAdet
        : null
    );

    formVars.tekliftalepkart_570219_value = ReactSystemFunctions.toString(
      this,
      stateVars.TeklifTalepKartById?.length > 0
        ? stateVars.TeklifTalepKartById[0].icBaskiKagitCinsiID
        : this.state.TeklifTalepKartById?.length > 0
        ? this.state.TeklifTalepKartById[0].icBaskiKagitCinsiID
        : null
    );

    stateVars.dataSource_570219 = this.state.EserDetay_IcBaskiKagitCinsiAll;
    stateVars.dataSource_570219 = this.state.EserDetay_IcBaskiKagitCinsiAll;
    formVars.tekliftalepkart_195740_value = ReactSystemFunctions.toString(
      this,
      stateVars.TeklifTalepKartById?.length > 0
        ? stateVars.TeklifTalepKartById[0].icBaskiKagitGramajID
        : this.state.TeklifTalepKartById?.length > 0
        ? this.state.TeklifTalepKartById[0].icBaskiKagitGramajID
        : null
    );

    stateVars.dataSource_195740 = this.state.EserDetay_IcBaskiKagitGramajByKagitCinsi;
    stateVars.dataSource_195740 = this.state.EserDetay_IcBaskiKagitGramajByKagitCinsi;
    formVars.tekliftalepkart_253304_value = ReactSystemFunctions.toString(
      this,
      stateVars.TeklifTalepKartById?.length > 0
        ? stateVars.TeklifTalepKartById[0].kapakCinsiID
        : this.state.TeklifTalepKartById?.length > 0
        ? this.state.TeklifTalepKartById[0].kapakCinsiID
        : null
    );

    stateVars.dataSource_253304 = this.state.EserDetay_KapakCinsiAll;
    stateVars.dataSource_253304 = this.state.EserDetay_KapakCinsiAll;
    formVars.tekliftalepkart_738987_value = ReactSystemFunctions.toString(
      this,
      stateVars.TeklifTalepKartById?.length > 0
        ? stateVars.TeklifTalepKartById[0].kapakGramajID
        : this.state.TeklifTalepKartById?.length > 0
        ? this.state.TeklifTalepKartById[0].kapakGramajID
        : null
    );

    stateVars.dataSource_738987 = this.state.EserDetay_KapakGramajByKapakCinsi;
    stateVars.dataSource_738987 = this.state.EserDetay_KapakGramajByKapakCinsi;
    formVars.tekliftalepkart_81822_value = ReactSystemFunctions.toString(
      this,
      stateVars.TeklifTalepKartById?.length > 0
        ? stateVars.TeklifTalepKartById[0].icBaskiRenkID
        : this.state.TeklifTalepKartById?.length > 0
        ? this.state.TeklifTalepKartById[0].icBaskiRenkID
        : null
    );

    stateVars.dataSource_81822 = this.state.EserDetay_IcBaskiRenkAll;
    stateVars.dataSource_81822 = this.state.EserDetay_IcBaskiRenkAll;
    formVars.tekliftalepkart_673902_value = ReactSystemFunctions.toString(
      this,
      stateVars.TeklifTalepKartById?.length > 0
        ? stateVars.TeklifTalepKartById[0].selefonID
        : this.state.TeklifTalepKartById?.length > 0
        ? this.state.TeklifTalepKartById[0].selefonID
        : null
    );

    stateVars.dataSource_673902 = this.state.EserDetay_SelefonAll;
    stateVars.dataSource_673902 = this.state.EserDetay_SelefonAll;
    formVars.tekliftalepkart_454757_value = ReactSystemFunctions.toString(
      this,
      stateVars.TeklifTalepKartById?.length > 0
        ? stateVars.TeklifTalepKartById[0].ciltBicimiID
        : this.state.TeklifTalepKartById?.length > 0
        ? this.state.TeklifTalepKartById[0].ciltBicimiID
        : null
    );

    stateVars.dataSource_454757 = this.state.EserDetay_CiltBicimiAll;
    stateVars.dataSource_454757 = this.state.EserDetay_CiltBicimiAll;
    formVars.tekliftalepkart_990679_value = ReactSystemFunctions.toString(
      this,
      stateVars.TeklifTalepKartById?.length > 0
        ? stateVars.TeklifTalepKartById[0].nakliyeDurumID
        : this.state.TeklifTalepKartById?.length > 0
        ? this.state.TeklifTalepKartById[0].nakliyeDurumID
        : null
    );

    stateVars.dataSource_990679 = this.state.NakliyeDurumAll;
    stateVars.dataSource_990679 = this.state.NakliyeDurumAll;
    formVars.tekliftalepkart_608589_value = ReactSystemFunctions.toString(
      this,
      stateVars.TeklifTalepKartById?.length > 0
        ? stateVars.TeklifTalepKartById[0].aciklama
        : this.state.TeklifTalepKartById?.length > 0
        ? this.state.TeklifTalepKartById[0].aciklama
        : null
    );

    stateVars.isComp282467Visible =
      ReactSystemFunctions.isNotEqualTo(
        this.props.screenInputs.NewEditDisplay ?? this.props.screenInputs.neweditdisplay,
        "D"
      ) === true
        ? "visible"
        : "hidden";

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  TeklifTalepKartComponent_694983_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TeklifTalepKartComponent_570219_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "tekliftalepkart_570219_value",
            "value",
            "EserDetay_IcBaskiKagitCinsiAll",
            "id",
            ""
          )
        ),
        null
      ),
      message: "*",
      formName: "tekliftalepkart_570219_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      IcBaskiKagitCinsiID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "tekliftalepkart_570219_value",
            "value",
            "EserDetay_IcBaskiKagitCinsiAll",
            "id",
            "id"
          )
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "TeklifTalepKart/TeklifTalepKartComponent_570219_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.EserDetay_IcBaskiKagitGramajByKagitCinsi = result?.data.eserDetay_IcBaskiKagitGramajByKagitCinsi;

    formVars.tekliftalepkart_195740_options = stateVars.EserDetay_IcBaskiKagitGramajByKagitCinsi;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TeklifTalepKartComponent_570219_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TeklifTalepKartComponent_570219_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_195740 = this.state.EserDetay_IcBaskiKagitGramajByKagitCinsi;
    stateVars.dataSource_195740 = this.state.EserDetay_IcBaskiKagitGramajByKagitCinsi;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TeklifTalepKartComponent_253304_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tekliftalepkart_253304_value", "value", "EserDetay_KapakCinsiAll", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "tekliftalepkart_253304_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      KapakCinsi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "tekliftalepkart_253304_value",
            "value",
            "EserDetay_KapakCinsiAll",
            "id",
            "id"
          )
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "TeklifTalepKart/TeklifTalepKartComponent_253304_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.EserDetay_KapakGramajByKapakCinsi = result?.data.eserDetay_KapakGramajByKapakCinsi;

    formVars.tekliftalepkart_738987_options = stateVars.EserDetay_KapakGramajByKapakCinsi;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TeklifTalepKartComponent_253304_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TeklifTalepKartComponent_253304_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_738987 = this.state.EserDetay_KapakGramajByKapakCinsi;
    stateVars.dataSource_738987 = this.state.EserDetay_KapakGramajByKapakCinsi;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TeklifTalepKartComponent_592092_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      BaskiAdet_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tekliftalepkart_214220_value", "value", "", "", "")
        ),
        "number"
      ),
      CiltBicimiID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "tekliftalepkart_454757_value",
            "value",
            "EserDetay_CiltBicimiAll",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      IcBaskiKagitCinsiID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "tekliftalepkart_570219_value",
            "value",
            "EserDetay_IcBaskiKagitCinsiAll",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      IcBaskiKagitGramajID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "tekliftalepkart_195740_value",
            "value",
            "EserDetay_IcBaskiKagitGramajByKagitCinsi",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      IcBaskiRenkID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "tekliftalepkart_81822_value",
            "value",
            "EserDetay_IcBaskiRenkAll",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      KapakCinsiID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "tekliftalepkart_253304_value",
            "value",
            "EserDetay_KapakCinsiAll",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      KapakGramajID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "tekliftalepkart_738987_value",
            "value",
            "EserDetay_KapakGramajByKapakCinsi",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      KitapAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tekliftalepkart_448472_value", "value", "", "", "")
        ),
        "string"
      ),
      KitapBoyutID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "tekliftalepkart_923446_value",
            "value",
            "EserDetay_KitapBoyutAll",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      NakliyeDurumID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tekliftalepkart_990679_value", "value", "NakliyeDurumAll", "id", "id")
        ),
        "Guid"
      ),
      Aciklama_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tekliftalepkart_608589_value", "value", "", "", "")
        ),
        "string"
      ),
      SayfaSayisi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tekliftalepkart_181252_value", "value", "", "", "")
        ),
        "number"
      ),
      SelefonID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tekliftalepkart_673902_value", "value", "EserDetay_SelefonAll", "id", "id")
        ),
        "Guid"
      ),
      TeklifDurumID_0: ReactSystemFunctions.convertToTypeByName("00000000-0000-0000-0000-000000000000", "Guid"),
      TeklifTalepEdenKisiID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tekliftalepkart_406100_value", "value", "KullaniciList", "uyeId", "kUserId")
        ),
        "Guid"
      ),
      TeklifTalepEdenUyeID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tekliftalepkart_406100_value", "value", "KullaniciList", "uyeId", "uyeId")
        ),
        "Guid"
      ),
      TeklifTalepEdilenFirmalar_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "tekliftalepkart_976483_value",
            "value",
            "TeklifAlinacakFirmaByUyelikTipi",
            "uyeFormId",
            "uyeFormId"
          )
        ),
        "string"
      ),
      TeklifTarihi_0: ReactSystemFunctions.convertToTypeByName(moment().format("YYYY-MM-DDTHH:mm:ss"), "Date"),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmID ?? this.props.screenInputs.prmid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    stateVars.Confirm = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "48733324_a063_3eb5_af57_3fae307dbade_confirmation",
        this.defaultML,
        "Talep Taslak Olarak Kayıt Edilecek; İşleme Devam Edilsin mi?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel",
      customClass: {
        title: "swal2-font-poppins"
      }
    });

    if (stateVars.Confirm.isConfirmed) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "TeklifTalepKart/TeklifTalepKartComponent_592092_onClick",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.TeklifTalepKartSave = result?.data.teklifTalepKartSave;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TeklifTalepKartComponent_592092_onClick1_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TeklifTalepKartComponent_592092_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "63d4bb9e_480c_e5a4_ab66_babef391eea8_notify",
        this.defaultML,
        "Taslak Olarak Kaydedildi!"
      ),
      "success",
      "bottom-right",
      1,
      null,
      null,
      null
    );

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TeklifTalepKartComponent_592092_onClick2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  TeklifTalepKartComponent_592092_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.HizlimiListemi ?? this.props.screenInputs.hizlimilistemi,
        "H"
      )
    ) {
      KuikaAppManager.prepareForNavigation();
      KuikaAppManager.addToPageInputVariables("TeklifTalepList", "tabIndex", 1);

      stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
        this,
        this.TeklifTalepKartComponent_592092_onClick3_,
        "TeklifTalepKart",
        "TeklifTalepList",
        "",
        "currentPage",
        null,
        null,
        null,
        null,
        null,
        null,
        true
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TeklifTalepKartComponent_592092_onClick3_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  TeklifTalepKartComponent_592092_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.HizlimiListemi ?? this.props.screenInputs.hizlimilistemi,
        "L"
      )
    ) {
      stateVars.GoBack = await ReactSystemFunctions.goBack(this);

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  TeklifTalepKartComponent_508444_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tekliftalepkart_214220_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "tekliftalepkart_214220_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tekliftalepkart_454757_value", "value", "EserDetay_CiltBicimiAll", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "tekliftalepkart_454757_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "tekliftalepkart_570219_value",
            "value",
            "EserDetay_IcBaskiKagitCinsiAll",
            "id",
            ""
          )
        ),
        null
      ),
      message: "*",
      formName: "tekliftalepkart_570219_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "tekliftalepkart_195740_value",
            "value",
            "EserDetay_IcBaskiKagitGramajByKagitCinsi",
            "id",
            ""
          )
        ),
        null
      ),
      message: "*",
      formName: "tekliftalepkart_195740_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tekliftalepkart_81822_value", "value", "EserDetay_IcBaskiRenkAll", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "tekliftalepkart_81822_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tekliftalepkart_253304_value", "value", "EserDetay_KapakCinsiAll", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "tekliftalepkart_253304_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "tekliftalepkart_738987_value",
            "value",
            "EserDetay_KapakGramajByKapakCinsi",
            "id",
            ""
          )
        ),
        null
      ),
      message: "*",
      formName: "tekliftalepkart_738987_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tekliftalepkart_923446_value", "value", "EserDetay_KitapBoyutAll", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "tekliftalepkart_923446_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tekliftalepkart_990679_value", "value", "NakliyeDurumAll", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "tekliftalepkart_990679_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tekliftalepkart_181252_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "tekliftalepkart_181252_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tekliftalepkart_673902_value", "value", "EserDetay_SelefonAll", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "tekliftalepkart_673902_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tekliftalepkart_406100_value", "value", "KullaniciList", "uyeId", "")
        ),
        null
      ),
      message: "*",
      formName: "tekliftalepkart_406100_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      BaskiAdet_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tekliftalepkart_214220_value", "value", "", "", "")
        ),
        "number"
      ),
      CiltBicimiID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "tekliftalepkart_454757_value",
            "value",
            "EserDetay_CiltBicimiAll",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      IcBaskiKagitCinsiID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "tekliftalepkart_570219_value",
            "value",
            "EserDetay_IcBaskiKagitCinsiAll",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      IcBaskiKagitGramajID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "tekliftalepkart_195740_value",
            "value",
            "EserDetay_IcBaskiKagitGramajByKagitCinsi",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      IcBaskiRenkID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "tekliftalepkart_81822_value",
            "value",
            "EserDetay_IcBaskiRenkAll",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      KapakCinsiID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "tekliftalepkart_253304_value",
            "value",
            "EserDetay_KapakCinsiAll",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      KapakGramajID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "tekliftalepkart_738987_value",
            "value",
            "EserDetay_KapakGramajByKapakCinsi",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      KitapAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tekliftalepkart_448472_value", "value", "", "", "")
        ),
        "string"
      ),
      KitapBoyutID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "tekliftalepkart_923446_value",
            "value",
            "EserDetay_KitapBoyutAll",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      NakliyeDurumID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tekliftalepkart_990679_value", "value", "NakliyeDurumAll", "id", "id")
        ),
        "Guid"
      ),
      Aciklama_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tekliftalepkart_608589_value", "value", "", "", "")
        ),
        "string"
      ),
      SayfaSayisi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tekliftalepkart_181252_value", "value", "", "", "")
        ),
        "number"
      ),
      SelefonID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tekliftalepkart_673902_value", "value", "EserDetay_SelefonAll", "id", "id")
        ),
        "Guid"
      ),
      TeklifDurumID_0: ReactSystemFunctions.convertToTypeByName("00000000-0000-0000-0000-000000000001", "Guid"),
      TeklifTalepEdenKisiID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tekliftalepkart_406100_value", "value", "KullaniciList", "uyeId", "kUserId")
        ),
        "Guid"
      ),
      TeklifTalepEdenUyeID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tekliftalepkart_406100_value", "value", "KullaniciList", "uyeId", "uyeId")
        ),
        "Guid"
      ),
      TeklifTalepEdilenFirmalar_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "tekliftalepkart_976483_value",
            "value",
            "TeklifAlinacakFirmaByUyelikTipi",
            "uyeFormId",
            "uyeFormId"
          )
        ),
        "string"
      ),
      TeklifTarihi_0: ReactSystemFunctions.convertToTypeByName(moment().format("YYYY-MM-DDTHH:mm:ss"), "Date"),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmID ?? this.props.screenInputs.prmid,
        "Guid"
      ),
      FirmaIds_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "tekliftalepkart_976483_value",
            "value",
            "TeklifAlinacakFirmaByUyelikTipi",
            "uyeFormId",
            "id"
          )
        ),
        "string"
      ),
      TeklifTalepKartId_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmID ?? this.props.screenInputs.prmid,
        "Guid"
      ),
      UyelikTipi_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyelikTipi
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyelikTipi
            : null
        ),
        "string"
      ),
      actionkey_4: ReactSystemFunctions.convertToTypeByName("tekliftalepolustur", "string"),
      uyeliktipi_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyelikTipi
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyelikTipi
            : null
        ),
        "string"
      ),
      templateId_5: ReactSystemFunctions.convertToTypeByName("34ca40a5-b7c7-4d08-b013-647a51685368", "string"),
      receivers_5: ReactSystemFunctions.convertToTypeByName("info@publink.com.tr", "string"),
      sender_5: ReactSystemFunctions.convertToTypeByName("", "string"),
      cc_5: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentFileName_5: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentBase64_5: ReactSystemFunctions.convertToTypeByName("", "string"),
      UyeIsmi_5: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyeAd
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyeAd
            : null
        ),
        "string"
      ),
      UyeSoyad_5: ReactSystemFunctions.convertToTypeByName("", "string"),
      PublinkDestekTelNo_5: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.GenelParametreler?.length > 0
            ? stateVars.GenelParametreler[0].destekTelNo
            : this.state.GenelParametreler?.length > 0
            ? this.state.GenelParametreler[0].destekTelNo
            : null
        ),
        "string"
      ),
      language_5: ReactSystemFunctions.convertToTypeByName("", "string")
    };

    KuikaAppManager.showSpinner(this);

    stateVars.Confirm = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "5ff83657_50c8_9797_8d56_1a7f2b7823a9_confirmation",
        this.defaultML,
        "Talep Teklif Alınacak Firmalara Gönderilecek; İşleme Devam Edilsin mi?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel",
      customClass: {
        title: "swal2-font-poppins"
      }
    });

    if (stateVars.Confirm.isConfirmed) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "TeklifTalepKart/TeklifTalepKartComponent_508444_onClick",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.TeklifTalepKartSave = result?.data.teklifTalepKartSave;
      stateVars.FirmaSendMailAdress = result?.data.firmaSendMailAdress;
      stateVars.getTalepNoUserByTeklifTalepKartId = result?.data.getTalepNoUserByTeklifTalepKartId;
      stateVars.UyelikTipiLowercase = result?.data.uyelikTipiLowercase;
      stateVars.SendMailSettings = result?.data.sendMailSettings;
      stateVars.SendMail = result?.data.sendMail;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TeklifTalepKartComponent_508444_onClick1_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TeklifTalepKartComponent_508444_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "26883cbb_338f_8d98_240a_fda764ce99c1_notify",
        this.defaultML,
        "Teklif Talebi Gönderildi!"
      ),
      "success",
      "bottom-right",
      1,
      "",
      "",
      0
    );

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TeklifTalepKartComponent_508444_onClick2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  TeklifTalepKartComponent_508444_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.HizlimiListemi ?? this.props.screenInputs.hizlimilistemi,
        "H"
      )
    ) {
      KuikaAppManager.prepareForNavigation();
      KuikaAppManager.addToPageInputVariables("TeklifTalepList", "tabIndex", 1);

      stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
        this,
        this.TeklifTalepKartComponent_508444_onClick3_,
        "TeklifTalepKart",
        "TeklifTalepList",
        "",
        "currentPage",
        null,
        null,
        null,
        null,
        null,
        null,
        true
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TeklifTalepKartComponent_508444_onClick3_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  TeklifTalepKartComponent_508444_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.HizlimiListemi ?? this.props.screenInputs.hizlimilistemi,
        "L"
      )
    ) {
      stateVars.GoBack = await ReactSystemFunctions.goBack(this);

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [,] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.TeklifTalepKartPageInit();
    }
  }
}
