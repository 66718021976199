import { Form, Spin, Typography } from "antd/es";
import { connect } from "react-redux";
import {
  Button,
  Header,
  HorizontalStack,
  Icon,
  Image,
  KCol,
  KContentMenu,
  KContentMenuItem,
  KContentMenuPanel,
  KDrawer,
  KPanel,
  KRow,
  KTable,
  KTableColumn,
  KTableHeader,
  KTableRow,
  Label,
  TextArea,
  TextInput
} from "../../components/web-components";
import { KSelectBox } from "../../kuika";
import {
  hideImportMappedExcelModal,
  hideIyzicoDrawer,
  hidePhotoTake,
  hideStripeDrawer,
  showImportMappedExcelModal,
  showIyzicoDrawer,
  showPhotoTake,
  showStripeDrawer
} from "../../redux/modals/actions";
import { AppState } from "../../redux/root-reducer";
import { withContext } from "../../shared/hoc/with-context";
import { withForm } from "../../shared/hoc/with-form";
import { withGoogleLogin } from "../../shared/hoc/with-google-login";
import { withHistory } from "../../shared/hoc/with-history";
import { withScreenInput } from "../../shared/hoc/with-screen-input";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";
import { MusteriTicariBilgileri_ScreenBase } from "./musteriticaribilgileri-base";

import { LeftMenu_Screen } from "../leftmenu/leftmenu";
import { PDFViewerFormsImza_Screen } from "../pdfviewerformsimza/pdfviewerformsimza";
import { PDFViewerFormsPOPSozlesme_Screen } from "../pdfviewerformspopsozlesme/pdfviewerformspopsozlesme";
import { PDFViewerFormsTicariSicilGazete_Screen } from "../pdfviewerformsticarisicilgazete/pdfviewerformsticarisicilgazete";
import { PDFViewerFormsVergiLevhasi_Screen } from "../pdfviewerformsvergilevhasi/pdfviewerformsvergilevhasi";

declare let window: any;
const { Title, Paragraph, Text, Link } = Typography;
const menuMap = [];

class MusteriTicariBilgileri_Screen extends MusteriTicariBilgileri_ScreenBase {
  render() {
    if (this.state.isPageVisible === false) {
      return <></>;
    }
    return (
      <>
        <Spin spinning={this.state.spinnerCount > 0}>
          <Form
            form={this.props.form}
            name="basic"
            initialValues={{
              musteriticaribilgileri_801926_value: null,
              musteriticaribilgileri_936868_value: this.state.UyelikFormuById?.at?.(0)?.sirketAd ?? undefined,
              musteriticaribilgileri_657536_value:
                this.state.UyelikFormuById?.at?.(0)?.popSozlesmesiDosyaAd ?? undefined,
              musteriticaribilgileri_546287_value: this.state.UyelikFormuById?.at?.(0)?.cityID ?? undefined,
              musteriticaribilgileri_818036_value:
                this.state.UyelikFormuById?.at?.(0)?.imzaSirkuleriDosyaAd ?? undefined,
              musteriticaribilgileri_100145_value: this.state.UyelikFormuById?.at?.(0)?.districtID ?? undefined,
              musteriticaribilgileri_527071_value:
                this.state.UyelikFormuById?.at?.(0)?.ticariSicilGazetesiDosyaAd ?? undefined,
              musteriticaribilgileri_909820_value: this.state.UyelikFormuById?.at?.(0)?.adres ?? undefined,
              musteriticaribilgileri_398233_value:
                this.state.UyelikFormuById?.at?.(0)?.vergiLevhasiDosyaAd ?? undefined,
              musteriticaribilgileri_753737_value: this.state.UyelikFormuById?.at?.(0)?.vergiDairesi ?? undefined,
              musteriticaribilgileri_7580_value: this.state.UyelikFormuById?.at?.(0)?.vergiKimlikNo ?? undefined,
              musteriticaribilgileri_711154_value: undefined
            }}
            onValuesChange={(changedValues, allValues) => {
              this.clearFieldValidation(changedValues);
            }}
          >
            <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
              <Header
                id="musteriticaribilgileri_header"
                style={
                  {
                    borderStyle: "solid",
                    borderTopWidth: 0,
                    borderRightWidth: 0,
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    backgroundColor: "rgba(255, 255, 255, 1)",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    backgroundPosition: "left",
                    paddingTop: 0,
                    paddingRight: 0,
                    paddingBottom: 0,
                    paddingLeft: 0,
                    fontFamily: "Poppins",
                    fontWeight: 500,
                    fontSize: "14px",
                    color: "rgba(22, 48, 32, 1)"
                  } as any
                }
              >
                <KRow
                  id="684466"
                  align="top"
                  justify="start"
                  horizontalGutter={0}
                  verticalGutter={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 8,
                      paddingRight: 8,
                      paddingBottom: 8,
                      paddingLeft: 8,
                      alignItems: "flex-start",
                      textAlign: "-webkit-left",
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(22, 48, 32, 1)"
                    } as any
                  }
                >
                  <KCol
                    id="101364"
                    xs={8}
                    sm={8}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={8}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 8,
                        paddingRight: 8,
                        paddingBottom: 8,
                        paddingLeft: 8,
                        alignItems: "center",
                        textAlign: "-webkit-left",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "14px",
                        color: "rgba(22, 48, 32, 1)"
                      } as any
                    }
                  >
                    <Icon
                      id="54788"
                      onClick={(e?: any) => {
                        if (e && e.stopPropagation) e.stopPropagation();
                        this.MusteriTicariBilgileriComponent_54788_onClick();
                      }}
                      showCursorPointer
                      visibility={this.state.isComp54788Visible}
                      visibilityCondition={(rowData: object) =>
                        ReactSystemFunctions.isEqualTo(this.state.MyContext[0]?.powerUserOnay?.toString(), "true")
                      }
                      iconName="menu"
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 0,
                          paddingRight: 0,
                          paddingBottom: 0,
                          paddingLeft: 0,
                          alignItems: "center",
                          textAlign: "-webkit-left",
                          display: "inline",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "24px",
                          color: "rgba(2, 145, 201, 1)",
                          letterSpacing: "1px"
                        } as any
                      }
                    ></Icon>
                  </KCol>

                  <KCol
                    id="123219"
                    xs={16}
                    sm={16}
                    md={16}
                    lg={16}
                    xl={16}
                    xxl={16}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 8,
                        paddingRight: 8,
                        paddingBottom: 8,
                        paddingLeft: 8,
                        alignItems: "center",
                        textAlign: "-webkit-right",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "14px",
                        color: "rgba(22, 48, 32, 1)"
                      } as any
                    }
                  >
                    <KContentMenu
                      id="759726"
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 0,
                          paddingRight: 0,
                          paddingBottom: 0,
                          paddingLeft: 0,
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "rgba(22, 48, 32, 1)"
                        } as any
                      }
                    >
                      <KContentMenuPanel
                        id="708484"
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(22, 48, 32, 1)"
                          } as any
                        }
                      >
                        <HorizontalStack
                          id="825746"
                          direction="horizontal"
                          size={12}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 0,
                              paddingLeft: 0,
                              alignItems: "center",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(22, 48, 32, 1)"
                            } as any
                          }
                        >
                          <Form.Item className="kFormItem" name="musteriticaribilgileri_801926_value">
                            <Image
                              id="801926"
                              zoomOnClick={false}
                              imageFit="fill"
                              style={
                                {
                                  borderTopLeftRadius: 200,
                                  borderTopRightRadius: 200,
                                  borderBottomRightRadius: 200,
                                  borderBottomLeftRadius: 200,
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 0,
                                  paddingLeft: 0,
                                  width: "42px",
                                  height: "42px",
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(22, 48, 32, 1)"
                                } as any
                              }
                            ></Image>
                          </Form.Item>

                          <Label
                            id="172357"
                            value={this.state.MyContext?.at?.(0)?.fullName ?? undefined}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                display: "inline",
                                fontFamily: "Poppins",
                                fontWeight: 400,
                                fontSize: "16px",
                                color: "rgba(22, 48, 32, 1)"
                              } as any
                            }
                          ></Label>

                          <Icon
                            id="946607"
                            iconName="arrow_drop_down"
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                display: "inline",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "20px",
                                color: "rgba(2, 145, 201, 1)",
                                letterSpacing: "1px"
                              } as any
                            }
                          ></Icon>
                        </HorizontalStack>
                      </KContentMenuPanel>

                      <KContentMenuItem
                        id="412428"
                        onClick={(e?: any) => {
                          if (e && e.stopPropagation) e.stopPropagation();
                          this.MusteriTicariBilgileriComponent_412428_onClick();
                        }}
                        showCursorPointer
                        label={ReactSystemFunctions.translate(this.ml, 412428, "label", this.defaultML)}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            backgroundColor: "rgba(255, 255, 255, 1)",
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "left",
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(22, 48, 32, 1)"
                          } as any
                        }
                      ></KContentMenuItem>

                      <KContentMenuItem
                        id="668195"
                        onClick={(e?: any) => {
                          if (e && e.stopPropagation) e.stopPropagation();
                          this.MusteriTicariBilgileriComponent_668195_onClick();
                        }}
                        showCursorPointer
                        label={ReactSystemFunctions.translate(this.ml, 668195, "label", this.defaultML)}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            backgroundColor: "rgba(255, 255, 255, 1)",
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "left",
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(245, 34, 45, 1)"
                          } as any
                        }
                      ></KContentMenuItem>
                    </KContentMenu>
                  </KCol>
                </KRow>
              </Header>

              <div
                id="musteriticaribilgileri_body"
                style={
                  {
                    backgroundColor: "rgba(244, 244, 244, 1)",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    backgroundPosition: "left",
                    display: "block"
                  } as any
                }
              >
                <KRow
                  id="736978"
                  align="top"
                  justify="start"
                  horizontalGutter={0}
                  verticalGutter={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 8,
                      paddingRight: 8,
                      paddingBottom: 8,
                      paddingLeft: 8,
                      alignItems: "flex-start",
                      textAlign: "-webkit-left",
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(22, 48, 32, 1)"
                    } as any
                  }
                >
                  <KCol
                    id="54434"
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={24}
                    xxl={24}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 8,
                        paddingRight: 8,
                        paddingBottom: 8,
                        paddingLeft: 8,
                        alignItems: "flex-start",
                        textAlign: "-webkit-center",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "14px",
                        color: "rgba(22, 48, 32, 1)"
                      } as any
                    }
                  >
                    <KPanel
                      id="675949"
                      scrolling="hidden"
                      scrollRadius={false}
                      style={
                        {
                          borderTopLeftRadius: 4,
                          borderTopRightRadius: 4,
                          borderBottomRightRadius: 4,
                          borderBottomLeftRadius: 4,
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          backgroundColor: "rgba(255, 255, 255, 1)",
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "contain",
                          backgroundPosition: "left",
                          paddingTop: 0,
                          paddingRight: 0,
                          paddingBottom: 0,
                          paddingLeft: 0,
                          maxWidth: "1200px",
                          minWidth: "800px",
                          textAlign: "-webkit-center",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "rgba(22, 48, 32, 1)"
                        } as any
                      }
                    >
                      <KRow
                        id="607421"
                        align="top"
                        justify="start"
                        horizontalGutter={0}
                        verticalGutter={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            alignItems: "flex-start",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(22, 48, 32, 1)"
                          } as any
                        }
                      >
                        <KCol
                          id="386864"
                          xs={21}
                          sm={21}
                          md={21}
                          lg={21}
                          xl={21}
                          xxl={21}
                          order={0}
                          pull={0}
                          push={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              alignItems: "flex-start",
                              textAlign: "-webkit-left",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(22, 48, 32, 1)"
                            } as any
                          }
                        >
                          <HorizontalStack
                            id="892940"
                            direction="horizontal"
                            size={6}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(22, 48, 32, 1)"
                              } as any
                            }
                          >
                            <Label
                              id="619404"
                              value={ReactSystemFunctions.translate(this.ml, 619404, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 0,
                                  paddingLeft: 0,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 400,
                                  fontSize: "16px",
                                  color: "rgba(250, 140, 22, 1)"
                                } as any
                              }
                            ></Label>
                          </HorizontalStack>
                        </KCol>

                        <KCol
                          id="26457"
                          xs={3}
                          sm={3}
                          md={3}
                          lg={3}
                          xl={3}
                          xxl={3}
                          order={0}
                          pull={0}
                          push={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              alignItems: "flex-start",
                              textAlign: "-webkit-right",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(22, 48, 32, 1)"
                            } as any
                          }
                        >
                          <Icon
                            id="377345"
                            onClick={(e?: any) => {
                              if (e && e.stopPropagation) e.stopPropagation();
                              this.MusteriTicariBilgileriComponent_377345_onClick();
                            }}
                            showCursorPointer
                            visibility={this.state.isComp377345Visible}
                            visibilityCondition={(rowData: object) =>
                              ReactSystemFunctions.isNotEmpty(this.props.screenInputs.prmID, "")
                            }
                            iconName="clear"
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                display: "inline",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "24px",
                                color: "rgba(0, 0, 0, 1)",
                                letterSpacing: "1px"
                              } as any
                            }
                          ></Icon>
                        </KCol>
                      </KRow>

                      <KRow
                        id="920244"
                        align="top"
                        justify="start"
                        horizontalGutter={0}
                        verticalGutter={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 0,
                            paddingRight: 8,
                            paddingBottom: 0,
                            paddingLeft: 8,
                            alignItems: "flex-start",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(22, 48, 32, 1)"
                          } as any
                        }
                      >
                        <KCol
                          id="911822"
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                          xxl={12}
                          order={0}
                          pull={0}
                          push={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(22, 48, 32, 1)"
                            } as any
                          }
                        >
                          <KRow
                            id="494831"
                            align="top"
                            justify="start"
                            horizontalGutter={0}
                            verticalGutter={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(22, 48, 32, 1)"
                              } as any
                            }
                          >
                            <KCol
                              id="587379"
                              xs={8}
                              sm={8}
                              md={8}
                              lg={8}
                              xl={8}
                              xxl={8}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 8,
                                  paddingBottom: 0,
                                  paddingLeft: 0,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(22, 48, 32, 1)"
                                } as any
                              }
                            >
                              <Label
                                id="532883"
                                value={ReactSystemFunctions.translate(this.ml, 532883, "value", this.defaultML)}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 0,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 400,
                                    fontSize: "12px",
                                    color: "rgba(186, 187, 187, 1)",
                                    lineHeight: "20px"
                                  } as any
                                }
                              ></Label>
                            </KCol>

                            <KCol
                              id="412096"
                              xs={16}
                              sm={16}
                              md={16}
                              lg={16}
                              xl={16}
                              xxl={16}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 0,
                                  paddingLeft: 8,
                                  alignItems: "flex-start",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(22, 48, 32, 1)"
                                } as any
                              }
                            >
                              <Form.Item className="kFormItem" name="musteriticaribilgileri_936868_value">
                                <TextInput
                                  id="936868"
                                  placeholder={ReactSystemFunctions.translate(
                                    this.ml,
                                    936868,
                                    "placeholder",
                                    this.defaultML
                                  )}
                                  allowClear={false}
                                  bordered={true}
                                  disabled={false}
                                  type="text"
                                  iconColor="rgba(0, 0, 0, 1)"
                                  formatter="None"
                                  isCharOnly={false}
                                  autoComplete={true}
                                  style={
                                    {
                                      borderTopLeftRadius: 4,
                                      borderTopRightRadius: 4,
                                      borderBottomRightRadius: 4,
                                      borderBottomLeftRadius: 4,
                                      borderTopWidth: 1,
                                      borderRightWidth: 1,
                                      borderBottomWidth: 1,
                                      borderLeftWidth: 1,
                                      borderColor: "rgba(218, 218, 218, 1)",
                                      borderStyle: "solid",
                                      paddingTop: 8,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "block",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                ></TextInput>
                              </Form.Item>
                            </KCol>
                          </KRow>
                        </KCol>

                        <KCol
                          id="479037"
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                          xxl={12}
                          order={0}
                          pull={0}
                          push={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              alignItems: "flex-start",
                              textAlign: "-webkit-left",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(22, 48, 32, 1)"
                            } as any
                          }
                        >
                          <KRow
                            id="336064"
                            align="top"
                            justify="start"
                            horizontalGutter={0}
                            verticalGutter={0}
                            style={
                              {
                                borderTopLeftRadius: 4,
                                borderTopRightRadius: 4,
                                borderBottomRightRadius: 4,
                                borderBottomLeftRadius: 4,
                                borderTopWidth: 1,
                                borderRightWidth: 1,
                                borderBottomWidth: 1,
                                borderLeftWidth: 1,
                                borderColor: "rgba(218, 218, 218, 1)",
                                borderStyle: "solid",
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                alignItems: "flex-start",
                                textAlign: "-webkit-left",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(22, 48, 32, 1)"
                              } as any
                            }
                          >
                            <KCol
                              id="918280"
                              xs={24}
                              sm={24}
                              md={24}
                              lg={24}
                              xl={24}
                              xxl={24}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 8,
                                  paddingBottom: 0,
                                  paddingLeft: 0,
                                  alignItems: "flex-start",
                                  textAlign: "-webkit-right",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(22, 48, 32, 1)"
                                } as any
                              }
                            >
                              <KRow
                                id="322014"
                                align="top"
                                justify="start"
                                horizontalGutter={0}
                                verticalGutter={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 0,
                                    paddingLeft: 0,
                                    alignItems: "flex-start",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <KCol
                                  id="212093"
                                  xs={16}
                                  sm={16}
                                  md={16}
                                  lg={16}
                                  xl={16}
                                  xxl={16}
                                  order={0}
                                  pull={0}
                                  push={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                >
                                  <Form.Item className="kFormItem" name="musteriticaribilgileri_657536_value">
                                    <TextInput
                                      id="657536"
                                      editability={this.state.isComp657536Enabled}
                                      placeholder={ReactSystemFunctions.translate(
                                        this.ml,
                                        657536,
                                        "placeholder",
                                        this.defaultML
                                      )}
                                      allowClear={false}
                                      bordered={true}
                                      disabled={false}
                                      type="text"
                                      iconColor="rgba(0, 0, 0, 1)"
                                      formatter="None"
                                      isCharOnly={false}
                                      autoComplete={true}
                                      style={
                                        {
                                          borderTopWidth: 1,
                                          borderRightWidth: 1,
                                          borderBottomWidth: 1,
                                          borderLeftWidth: 1,
                                          borderColor: "rgba(255, 255, 255, 1)",
                                          borderStyle: "solid",
                                          paddingTop: 8,
                                          paddingRight: 8,
                                          paddingBottom: 8,
                                          paddingLeft: 8,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "block",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(22, 48, 32, 1)"
                                        } as any
                                      }
                                    ></TextInput>
                                  </Form.Item>
                                </KCol>

                                <KCol
                                  id="876807"
                                  xs={8}
                                  sm={8}
                                  md={8}
                                  lg={8}
                                  xl={8}
                                  xxl={8}
                                  order={0}
                                  pull={0}
                                  push={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-right",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                >
                                  <HorizontalStack
                                    id="60289"
                                    direction="horizontal"
                                    size={12}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 0,
                                        paddingLeft: 0,
                                        alignItems: "center",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(22, 48, 32, 1)"
                                      } as any
                                    }
                                  >
                                    <Icon
                                      id="206453"
                                      onClick={(e?: any) => {
                                        if (e && e.stopPropagation) e.stopPropagation();
                                        this.MusteriTicariBilgileriComponent_206453_onClick();
                                      }}
                                      showCursorPointer
                                      visibility={this.state.isComp206453Visible}
                                      visibilityCondition={(rowData: object) =>
                                        ReactSystemFunctions.isNotEqualTo(
                                          this.state.MyContext[0]?.rolName?.toString(),
                                          "POWERUSER"
                                        )
                                      }
                                      iconName="picture_as_pdf"
                                      hoverText="İmzalı POP Müşteri Ticari Sözleşmesi indir"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "20px",
                                          color: "rgba(22, 48, 32, 1)",
                                          letterSpacing: "1px"
                                        } as any
                                      }
                                    ></Icon>

                                    <Icon
                                      id="400170"
                                      onClick={(e?: any) => {
                                        if (e && e.stopPropagation) e.stopPropagation();
                                        this.MusteriTicariBilgileriComponent_400170_onClick();
                                      }}
                                      showCursorPointer
                                      visibility={this.state.isComp400170Visible}
                                      visibilityCondition={(rowData: object) =>
                                        ReactSystemFunctions.isNotEqualTo(
                                          this.state.MyContext[0]?.rolName?.toString(),
                                          "POWERUSER"
                                        )
                                      }
                                      iconName="upload"
                                      hoverText="PDF Yükle"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "20px",
                                          color: "rgba(22, 48, 32, 1)",
                                          letterSpacing: "1px"
                                        } as any
                                      }
                                    ></Icon>

                                    <Icon
                                      id="247610"
                                      onClick={(e?: any) => {
                                        if (e && e.stopPropagation) e.stopPropagation();
                                        this.MusteriTicariBilgileriComponent_247610_onClick();
                                      }}
                                      showCursorPointer
                                      iconName="remove_red_eye"
                                      hoverText="PDF Göster"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "20px",
                                          color: "rgba(22, 48, 32, 1)",
                                          letterSpacing: "1px"
                                        } as any
                                      }
                                    ></Icon>

                                    <Icon
                                      id="840893"
                                      onClick={(e?: any) => {
                                        if (e && e.stopPropagation) e.stopPropagation();
                                        this.MusteriTicariBilgileriComponent_840893_onClick();
                                      }}
                                      showCursorPointer
                                      visibility={this.state.isComp840893Visible}
                                      visibilityCondition={(rowData: object) =>
                                        ReactSystemFunctions.isNotEqualTo(
                                          this.state.MyContext[0]?.rolName?.toString(),
                                          "POWERUSER"
                                        )
                                      }
                                      iconName="download"
                                      hoverText="PDF İndir"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "20px",
                                          color: "rgba(22, 48, 32, 1)",
                                          letterSpacing: "1px"
                                        } as any
                                      }
                                    ></Icon>
                                  </HorizontalStack>
                                </KCol>
                              </KRow>
                            </KCol>
                          </KRow>
                        </KCol>
                      </KRow>

                      <KRow
                        id="332605"
                        align="top"
                        justify="start"
                        horizontalGutter={0}
                        verticalGutter={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 0,
                            paddingRight: 8,
                            paddingBottom: 0,
                            paddingLeft: 8,
                            alignItems: "flex-start",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(22, 48, 32, 1)"
                          } as any
                        }
                      >
                        <KCol
                          id="41988"
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                          xxl={12}
                          order={0}
                          pull={0}
                          push={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(22, 48, 32, 1)"
                            } as any
                          }
                        >
                          <KRow
                            id="279680"
                            align="top"
                            justify="start"
                            horizontalGutter={0}
                            verticalGutter={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(22, 48, 32, 1)"
                              } as any
                            }
                          >
                            <KCol
                              id="140105"
                              xs={8}
                              sm={8}
                              md={8}
                              lg={8}
                              xl={8}
                              xxl={8}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 8,
                                  paddingBottom: 0,
                                  paddingLeft: 0,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(22, 48, 32, 1)"
                                } as any
                              }
                            >
                              <Label
                                id="929728"
                                value={ReactSystemFunctions.translate(this.ml, 929728, "value", this.defaultML)}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 0,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 400,
                                    fontSize: "12px",
                                    color: "rgba(186, 187, 187, 1)",
                                    lineHeight: "20px"
                                  } as any
                                }
                              ></Label>
                            </KCol>

                            <KCol
                              id="351807"
                              xs={16}
                              sm={16}
                              md={16}
                              lg={16}
                              xl={16}
                              xxl={16}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 0,
                                  paddingLeft: 8,
                                  alignItems: "flex-start",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(22, 48, 32, 1)"
                                } as any
                              }
                            >
                              <Form.Item className="kFormItem" name="musteriticaribilgileri_546287_value">
                                <KSelectBox
                                  id="546287"
                                  onChange={(e?: any) => {
                                    if (e && e.stopPropagation) e.stopPropagation();
                                    this.MusteriTicariBilgileriComponent_546287_onChange();
                                  }}
                                  kuikaRef={this.musteriticaribilgileri_546287_value_kuikaSelectBoxRef}
                                  options={this.state.SelectCityAll}
                                  placeholder={ReactSystemFunctions.translate(
                                    this.ml,
                                    546287,
                                    "placeholder",
                                    this.defaultML
                                  )}
                                  allowClear={false}
                                  autoClearSearchValue={true}
                                  showSearch={false}
                                  onSearch={{}}
                                  widthType="fill"
                                  containsNullItem={false}
                                  sortBy="none"
                                  datavaluefield="id"
                                  datatextfield="city"
                                  style={
                                    {
                                      borderTopLeftRadius: 4,
                                      borderTopRightRadius: 4,
                                      borderBottomRightRadius: 4,
                                      borderBottomLeftRadius: 4,
                                      borderTopWidth: 1,
                                      borderRightWidth: 1,
                                      borderBottomWidth: 1,
                                      borderLeftWidth: 1,
                                      borderColor: "rgba(218, 218, 218, 1)",
                                      borderStyle: "solid",
                                      backgroundColor: "rgba(255, 255, 255, 1)",
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      paddingTop: 8,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "block",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                ></KSelectBox>
                              </Form.Item>
                            </KCol>
                          </KRow>
                        </KCol>

                        <KCol
                          id="178538"
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                          xxl={12}
                          order={0}
                          pull={0}
                          push={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              alignItems: "flex-start",
                              textAlign: "-webkit-left",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(22, 48, 32, 1)"
                            } as any
                          }
                        >
                          <KRow
                            id="952793"
                            align="top"
                            justify="start"
                            horizontalGutter={0}
                            verticalGutter={0}
                            style={
                              {
                                borderTopLeftRadius: 4,
                                borderTopRightRadius: 4,
                                borderBottomRightRadius: 4,
                                borderBottomLeftRadius: 4,
                                borderTopWidth: 1,
                                borderRightWidth: 1,
                                borderBottomWidth: 1,
                                borderLeftWidth: 1,
                                borderColor: "rgba(218, 218, 218, 1)",
                                borderStyle: "solid",
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                alignItems: "flex-start",
                                textAlign: "-webkit-left",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(22, 48, 32, 1)"
                              } as any
                            }
                          >
                            <KCol
                              id="23135"
                              xs={24}
                              sm={24}
                              md={24}
                              lg={24}
                              xl={24}
                              xxl={24}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 8,
                                  paddingBottom: 0,
                                  paddingLeft: 0,
                                  alignItems: "flex-start",
                                  textAlign: "-webkit-right",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(22, 48, 32, 1)"
                                } as any
                              }
                            >
                              <KRow
                                id="127045"
                                align="top"
                                justify="start"
                                horizontalGutter={0}
                                verticalGutter={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 0,
                                    paddingLeft: 0,
                                    alignItems: "flex-start",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <KCol
                                  id="920805"
                                  xs={16}
                                  sm={16}
                                  md={16}
                                  lg={16}
                                  xl={16}
                                  xxl={16}
                                  order={0}
                                  pull={0}
                                  push={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                >
                                  <Form.Item className="kFormItem" name="musteriticaribilgileri_818036_value">
                                    <TextInput
                                      id="818036"
                                      editability={this.state.isComp818036Enabled}
                                      placeholder={ReactSystemFunctions.translate(
                                        this.ml,
                                        818036,
                                        "placeholder",
                                        this.defaultML
                                      )}
                                      allowClear={false}
                                      bordered={true}
                                      disabled={false}
                                      type="text"
                                      iconColor="rgba(0, 0, 0, 1)"
                                      formatter="None"
                                      isCharOnly={false}
                                      autoComplete={true}
                                      style={
                                        {
                                          borderTopWidth: 1,
                                          borderRightWidth: 1,
                                          borderBottomWidth: 1,
                                          borderLeftWidth: 1,
                                          borderColor: "rgba(255, 255, 255, 1)",
                                          borderStyle: "solid",
                                          paddingTop: 8,
                                          paddingRight: 8,
                                          paddingBottom: 8,
                                          paddingLeft: 8,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "block",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(22, 48, 32, 1)"
                                        } as any
                                      }
                                    ></TextInput>
                                  </Form.Item>
                                </KCol>

                                <KCol
                                  id="240451"
                                  xs={8}
                                  sm={8}
                                  md={8}
                                  lg={8}
                                  xl={8}
                                  xxl={8}
                                  order={0}
                                  pull={0}
                                  push={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-right",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                >
                                  <HorizontalStack
                                    id="518462"
                                    direction="horizontal"
                                    size={12}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 0,
                                        paddingLeft: 0,
                                        alignItems: "center",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(22, 48, 32, 1)"
                                      } as any
                                    }
                                  >
                                    <Icon
                                      id="636004"
                                      onClick={(e?: any) => {
                                        if (e && e.stopPropagation) e.stopPropagation();
                                        this.MusteriTicariBilgileriComponent_636004_onClick();
                                      }}
                                      showCursorPointer
                                      visibility={this.state.isComp636004Visible}
                                      visibilityCondition={(rowData: object) =>
                                        ReactSystemFunctions.isNotEqualTo(
                                          this.state.MyContext[0]?.rolName?.toString(),
                                          "POWERUSER"
                                        )
                                      }
                                      iconName="upload"
                                      hoverText="PDF Yükle"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "20px",
                                          color: "rgba(22, 48, 32, 1)",
                                          letterSpacing: "1px"
                                        } as any
                                      }
                                    ></Icon>

                                    <Icon
                                      id="950303"
                                      onClick={(e?: any) => {
                                        if (e && e.stopPropagation) e.stopPropagation();
                                        this.MusteriTicariBilgileriComponent_950303_onClick();
                                      }}
                                      showCursorPointer
                                      iconName="remove_red_eye"
                                      hoverText="PDF Göster"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "20px",
                                          color: "rgba(22, 48, 32, 1)",
                                          letterSpacing: "1px"
                                        } as any
                                      }
                                    ></Icon>

                                    <Icon
                                      id="669749"
                                      onClick={(e?: any) => {
                                        if (e && e.stopPropagation) e.stopPropagation();
                                        this.MusteriTicariBilgileriComponent_669749_onClick();
                                      }}
                                      showCursorPointer
                                      visibility={this.state.isComp669749Visible}
                                      visibilityCondition={(rowData: object) =>
                                        ReactSystemFunctions.isNotEqualTo(
                                          this.state.MyContext[0]?.rolName?.toString(),
                                          "POWERUSER"
                                        )
                                      }
                                      iconName="download"
                                      hoverText="PDF İndir"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "20px",
                                          color: "rgba(22, 48, 32, 1)",
                                          letterSpacing: "1px"
                                        } as any
                                      }
                                    ></Icon>
                                  </HorizontalStack>
                                </KCol>
                              </KRow>
                            </KCol>
                          </KRow>
                        </KCol>
                      </KRow>

                      <KRow
                        id="88966"
                        align="top"
                        justify="start"
                        horizontalGutter={0}
                        verticalGutter={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 0,
                            paddingRight: 8,
                            paddingBottom: 0,
                            paddingLeft: 8,
                            alignItems: "flex-start",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(22, 48, 32, 1)"
                          } as any
                        }
                      >
                        <KCol
                          id="590134"
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                          xxl={12}
                          order={0}
                          pull={0}
                          push={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(22, 48, 32, 1)"
                            } as any
                          }
                        >
                          <KRow
                            id="562892"
                            align="top"
                            justify="start"
                            horizontalGutter={0}
                            verticalGutter={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(22, 48, 32, 1)"
                              } as any
                            }
                          >
                            <KCol
                              id="903350"
                              xs={8}
                              sm={8}
                              md={8}
                              lg={8}
                              xl={8}
                              xxl={8}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 8,
                                  paddingBottom: 0,
                                  paddingLeft: 0,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(22, 48, 32, 1)"
                                } as any
                              }
                            >
                              <Label
                                id="458210"
                                value={ReactSystemFunctions.translate(this.ml, 458210, "value", this.defaultML)}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 0,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 400,
                                    fontSize: "12px",
                                    color: "rgba(186, 187, 187, 1)",
                                    lineHeight: "20px"
                                  } as any
                                }
                              ></Label>
                            </KCol>

                            <KCol
                              id="73092"
                              xs={16}
                              sm={16}
                              md={16}
                              lg={16}
                              xl={16}
                              xxl={16}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 0,
                                  paddingLeft: 8,
                                  alignItems: "flex-start",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(22, 48, 32, 1)"
                                } as any
                              }
                            >
                              <Form.Item className="kFormItem" name="musteriticaribilgileri_100145_value">
                                <KSelectBox
                                  id="100145"
                                  kuikaRef={this.musteriticaribilgileri_100145_value_kuikaSelectBoxRef}
                                  options={this.state.SelectDistrictAll}
                                  placeholder={ReactSystemFunctions.translate(
                                    this.ml,
                                    100145,
                                    "placeholder",
                                    this.defaultML
                                  )}
                                  allowClear={false}
                                  autoClearSearchValue={true}
                                  showSearch={false}
                                  onSearch={{}}
                                  widthType="fill"
                                  containsNullItem={false}
                                  sortBy="none"
                                  datavaluefield="id"
                                  datatextfield="district"
                                  style={
                                    {
                                      borderTopLeftRadius: 4,
                                      borderTopRightRadius: 4,
                                      borderBottomRightRadius: 4,
                                      borderBottomLeftRadius: 4,
                                      borderTopWidth: 1,
                                      borderRightWidth: 1,
                                      borderBottomWidth: 1,
                                      borderLeftWidth: 1,
                                      borderColor: "rgba(218, 218, 218, 1)",
                                      borderStyle: "solid",
                                      backgroundColor: "rgba(255, 255, 255, 1)",
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      paddingTop: 8,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "block",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                ></KSelectBox>
                              </Form.Item>
                            </KCol>
                          </KRow>
                        </KCol>

                        <KCol
                          id="192233"
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                          xxl={12}
                          order={0}
                          pull={0}
                          push={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              alignItems: "flex-start",
                              textAlign: "-webkit-left",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(22, 48, 32, 1)"
                            } as any
                          }
                        >
                          <KRow
                            id="526582"
                            align="top"
                            justify="start"
                            horizontalGutter={0}
                            verticalGutter={0}
                            style={
                              {
                                borderTopLeftRadius: 4,
                                borderTopRightRadius: 4,
                                borderBottomRightRadius: 4,
                                borderBottomLeftRadius: 4,
                                borderTopWidth: 1,
                                borderRightWidth: 1,
                                borderBottomWidth: 1,
                                borderLeftWidth: 1,
                                borderColor: "rgba(218, 218, 218, 1)",
                                borderStyle: "solid",
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                alignItems: "flex-start",
                                textAlign: "-webkit-left",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(22, 48, 32, 1)"
                              } as any
                            }
                          >
                            <KCol
                              id="394210"
                              xs={24}
                              sm={24}
                              md={24}
                              lg={24}
                              xl={24}
                              xxl={24}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 8,
                                  paddingBottom: 0,
                                  paddingLeft: 0,
                                  alignItems: "flex-start",
                                  textAlign: "-webkit-right",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(22, 48, 32, 1)"
                                } as any
                              }
                            >
                              <KRow
                                id="717226"
                                align="top"
                                justify="start"
                                horizontalGutter={0}
                                verticalGutter={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 0,
                                    paddingLeft: 0,
                                    alignItems: "flex-start",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <KCol
                                  id="746747"
                                  xs={16}
                                  sm={16}
                                  md={16}
                                  lg={16}
                                  xl={16}
                                  xxl={16}
                                  order={0}
                                  pull={0}
                                  push={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                >
                                  <Form.Item className="kFormItem" name="musteriticaribilgileri_527071_value">
                                    <TextInput
                                      id="527071"
                                      editability={this.state.isComp527071Enabled}
                                      placeholder={ReactSystemFunctions.translate(
                                        this.ml,
                                        527071,
                                        "placeholder",
                                        this.defaultML
                                      )}
                                      allowClear={false}
                                      bordered={true}
                                      disabled={false}
                                      type="text"
                                      iconColor="rgba(0, 0, 0, 1)"
                                      formatter="None"
                                      isCharOnly={false}
                                      autoComplete={true}
                                      style={
                                        {
                                          borderTopWidth: 1,
                                          borderRightWidth: 1,
                                          borderBottomWidth: 1,
                                          borderLeftWidth: 1,
                                          borderColor: "rgba(255, 255, 255, 1)",
                                          borderStyle: "solid",
                                          paddingTop: 8,
                                          paddingRight: 8,
                                          paddingBottom: 8,
                                          paddingLeft: 8,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "block",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(22, 48, 32, 1)"
                                        } as any
                                      }
                                    ></TextInput>
                                  </Form.Item>
                                </KCol>

                                <KCol
                                  id="422868"
                                  xs={8}
                                  sm={8}
                                  md={8}
                                  lg={8}
                                  xl={8}
                                  xxl={8}
                                  order={0}
                                  pull={0}
                                  push={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-right",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                >
                                  <HorizontalStack
                                    id="517907"
                                    direction="horizontal"
                                    size={12}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 0,
                                        paddingLeft: 0,
                                        alignItems: "center",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(22, 48, 32, 1)"
                                      } as any
                                    }
                                  >
                                    <Icon
                                      id="541724"
                                      onClick={(e?: any) => {
                                        if (e && e.stopPropagation) e.stopPropagation();
                                        this.MusteriTicariBilgileriComponent_541724_onClick();
                                      }}
                                      showCursorPointer
                                      visibility={this.state.isComp541724Visible}
                                      visibilityCondition={(rowData: object) =>
                                        ReactSystemFunctions.isNotEqualTo(
                                          this.state.MyContext[0]?.rolName?.toString(),
                                          "POWERUSER"
                                        )
                                      }
                                      iconName="upload"
                                      hoverText="PDF Yükle"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "20px",
                                          color: "rgba(22, 48, 32, 1)",
                                          letterSpacing: "1px"
                                        } as any
                                      }
                                    ></Icon>

                                    <Icon
                                      id="34141"
                                      onClick={(e?: any) => {
                                        if (e && e.stopPropagation) e.stopPropagation();
                                        this.MusteriTicariBilgileriComponent_34141_onClick();
                                      }}
                                      showCursorPointer
                                      iconName="remove_red_eye"
                                      hoverText="PDF Göster"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "20px",
                                          color: "rgba(22, 48, 32, 1)",
                                          letterSpacing: "1px"
                                        } as any
                                      }
                                    ></Icon>

                                    <Icon
                                      id="278576"
                                      onClick={(e?: any) => {
                                        if (e && e.stopPropagation) e.stopPropagation();
                                        this.MusteriTicariBilgileriComponent_278576_onClick();
                                      }}
                                      showCursorPointer
                                      visibility={this.state.isComp278576Visible}
                                      visibilityCondition={(rowData: object) =>
                                        ReactSystemFunctions.isNotEqualTo(
                                          this.state.MyContext[0]?.rolName?.toString(),
                                          "POWERUSER"
                                        )
                                      }
                                      iconName="download"
                                      hoverText="PDF İndir"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "20px",
                                          color: "rgba(22, 48, 32, 1)",
                                          letterSpacing: "1px"
                                        } as any
                                      }
                                    ></Icon>
                                  </HorizontalStack>
                                </KCol>
                              </KRow>
                            </KCol>
                          </KRow>
                        </KCol>
                      </KRow>

                      <KRow
                        id="230778"
                        align="top"
                        justify="start"
                        horizontalGutter={0}
                        verticalGutter={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 0,
                            paddingRight: 8,
                            paddingBottom: 0,
                            paddingLeft: 8,
                            alignItems: "flex-start",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(22, 48, 32, 1)"
                          } as any
                        }
                      >
                        <KCol
                          id="19793"
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                          xxl={12}
                          order={0}
                          pull={0}
                          push={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(22, 48, 32, 1)"
                            } as any
                          }
                        >
                          <KRow
                            id="413823"
                            align="top"
                            justify="start"
                            horizontalGutter={0}
                            verticalGutter={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(22, 48, 32, 1)"
                              } as any
                            }
                          >
                            <KCol
                              id="75804"
                              xs={8}
                              sm={8}
                              md={8}
                              lg={8}
                              xl={8}
                              xxl={8}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 8,
                                  paddingBottom: 0,
                                  paddingLeft: 0,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(22, 48, 32, 1)"
                                } as any
                              }
                            >
                              <Label
                                id="158154"
                                value={ReactSystemFunctions.translate(this.ml, 158154, "value", this.defaultML)}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 0,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 400,
                                    fontSize: "12px",
                                    color: "rgba(186, 187, 187, 1)",
                                    lineHeight: "20px"
                                  } as any
                                }
                              ></Label>
                            </KCol>

                            <KCol
                              id="464551"
                              xs={16}
                              sm={16}
                              md={16}
                              lg={16}
                              xl={16}
                              xxl={16}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 0,
                                  paddingLeft: 8,
                                  alignItems: "flex-start",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(22, 48, 32, 1)"
                                } as any
                              }
                            >
                              <Form.Item className="kFormItem" name="musteriticaribilgileri_909820_value">
                                <TextInput
                                  id="909820"
                                  placeholder={ReactSystemFunctions.translate(
                                    this.ml,
                                    909820,
                                    "placeholder",
                                    this.defaultML
                                  )}
                                  allowClear={false}
                                  bordered={true}
                                  disabled={false}
                                  type="text"
                                  iconColor="rgba(0, 0, 0, 1)"
                                  formatter="None"
                                  isCharOnly={false}
                                  autoComplete={true}
                                  style={
                                    {
                                      borderTopLeftRadius: 4,
                                      borderTopRightRadius: 4,
                                      borderBottomRightRadius: 4,
                                      borderBottomLeftRadius: 4,
                                      borderTopWidth: 1,
                                      borderRightWidth: 1,
                                      borderBottomWidth: 1,
                                      borderLeftWidth: 1,
                                      borderColor: "rgba(218, 218, 218, 1)",
                                      borderStyle: "solid",
                                      paddingTop: 8,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "block",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                ></TextInput>
                              </Form.Item>
                            </KCol>
                          </KRow>
                        </KCol>

                        <KCol
                          id="657853"
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                          xxl={12}
                          order={0}
                          pull={0}
                          push={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              alignItems: "flex-start",
                              textAlign: "-webkit-left",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(22, 48, 32, 1)"
                            } as any
                          }
                        >
                          <KRow
                            id="514222"
                            align="top"
                            justify="start"
                            horizontalGutter={0}
                            verticalGutter={0}
                            style={
                              {
                                borderTopLeftRadius: 4,
                                borderTopRightRadius: 4,
                                borderBottomRightRadius: 4,
                                borderBottomLeftRadius: 4,
                                borderTopWidth: 1,
                                borderRightWidth: 1,
                                borderBottomWidth: 1,
                                borderLeftWidth: 1,
                                borderColor: "rgba(218, 218, 218, 1)",
                                borderStyle: "solid",
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                alignItems: "flex-start",
                                textAlign: "-webkit-left",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(22, 48, 32, 1)"
                              } as any
                            }
                          >
                            <KCol
                              id="426816"
                              xs={24}
                              sm={24}
                              md={24}
                              lg={24}
                              xl={24}
                              xxl={24}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 8,
                                  paddingBottom: 0,
                                  paddingLeft: 0,
                                  alignItems: "flex-start",
                                  textAlign: "-webkit-right",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(22, 48, 32, 1)"
                                } as any
                              }
                            >
                              <KRow
                                id="516364"
                                align="top"
                                justify="start"
                                horizontalGutter={0}
                                verticalGutter={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 0,
                                    paddingLeft: 0,
                                    alignItems: "flex-start",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <KCol
                                  id="183732"
                                  xs={16}
                                  sm={16}
                                  md={16}
                                  lg={16}
                                  xl={16}
                                  xxl={16}
                                  order={0}
                                  pull={0}
                                  push={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                >
                                  <Form.Item className="kFormItem" name="musteriticaribilgileri_398233_value">
                                    <TextInput
                                      id="398233"
                                      editability={this.state.isComp398233Enabled}
                                      placeholder={ReactSystemFunctions.translate(
                                        this.ml,
                                        398233,
                                        "placeholder",
                                        this.defaultML
                                      )}
                                      allowClear={false}
                                      bordered={true}
                                      disabled={false}
                                      type="text"
                                      iconColor="rgba(0, 0, 0, 1)"
                                      formatter="None"
                                      isCharOnly={false}
                                      autoComplete={true}
                                      style={
                                        {
                                          borderTopWidth: 1,
                                          borderRightWidth: 1,
                                          borderBottomWidth: 1,
                                          borderLeftWidth: 1,
                                          borderColor: "rgba(255, 255, 255, 1)",
                                          borderStyle: "solid",
                                          paddingTop: 8,
                                          paddingRight: 8,
                                          paddingBottom: 8,
                                          paddingLeft: 8,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "block",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(22, 48, 32, 1)"
                                        } as any
                                      }
                                    ></TextInput>
                                  </Form.Item>
                                </KCol>

                                <KCol
                                  id="500344"
                                  xs={8}
                                  sm={8}
                                  md={8}
                                  lg={8}
                                  xl={8}
                                  xxl={8}
                                  order={0}
                                  pull={0}
                                  push={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-right",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                >
                                  <HorizontalStack
                                    id="278243"
                                    direction="horizontal"
                                    size={12}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 0,
                                        paddingLeft: 0,
                                        alignItems: "center",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(22, 48, 32, 1)"
                                      } as any
                                    }
                                  >
                                    <Icon
                                      id="593191"
                                      onClick={(e?: any) => {
                                        if (e && e.stopPropagation) e.stopPropagation();
                                        this.MusteriTicariBilgileriComponent_593191_onClick();
                                      }}
                                      showCursorPointer
                                      visibility={this.state.isComp593191Visible}
                                      visibilityCondition={(rowData: object) =>
                                        ReactSystemFunctions.isNotEqualTo(
                                          this.state.MyContext[0]?.rolName?.toString(),
                                          "POWERUSER"
                                        )
                                      }
                                      iconName="upload"
                                      hoverText="PDF Yükle"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "20px",
                                          color: "rgba(22, 48, 32, 1)",
                                          letterSpacing: "1px"
                                        } as any
                                      }
                                    ></Icon>

                                    <Icon
                                      id="474024"
                                      onClick={(e?: any) => {
                                        if (e && e.stopPropagation) e.stopPropagation();
                                        this.MusteriTicariBilgileriComponent_474024_onClick();
                                      }}
                                      showCursorPointer
                                      iconName="remove_red_eye"
                                      hoverText="PDF Göster"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "20px",
                                          color: "rgba(22, 48, 32, 1)",
                                          letterSpacing: "1px"
                                        } as any
                                      }
                                    ></Icon>

                                    <Icon
                                      id="802707"
                                      onClick={(e?: any) => {
                                        if (e && e.stopPropagation) e.stopPropagation();
                                        this.MusteriTicariBilgileriComponent_802707_onClick();
                                      }}
                                      showCursorPointer
                                      visibility={this.state.isComp802707Visible}
                                      visibilityCondition={(rowData: object) =>
                                        ReactSystemFunctions.isNotEqualTo(
                                          this.state.MyContext[0]?.rolName?.toString(),
                                          "POWERUSER"
                                        )
                                      }
                                      iconName="download"
                                      hoverText="PDF İndir"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "20px",
                                          color: "rgba(22, 48, 32, 1)",
                                          letterSpacing: "1px"
                                        } as any
                                      }
                                    ></Icon>
                                  </HorizontalStack>
                                </KCol>
                              </KRow>
                            </KCol>
                          </KRow>
                        </KCol>
                      </KRow>

                      <KRow
                        id="310511"
                        align="top"
                        justify="start"
                        horizontalGutter={0}
                        verticalGutter={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 0,
                            paddingRight: 8,
                            paddingBottom: 0,
                            paddingLeft: 8,
                            alignItems: "flex-start",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(22, 48, 32, 1)"
                          } as any
                        }
                      >
                        <KCol
                          id="938729"
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                          xxl={12}
                          order={0}
                          pull={0}
                          push={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(22, 48, 32, 1)"
                            } as any
                          }
                        >
                          <KRow
                            id="942170"
                            align="top"
                            justify="start"
                            horizontalGutter={0}
                            verticalGutter={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(22, 48, 32, 1)"
                              } as any
                            }
                          >
                            <KCol
                              id="198760"
                              xs={8}
                              sm={8}
                              md={8}
                              lg={8}
                              xl={8}
                              xxl={8}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 8,
                                  paddingBottom: 0,
                                  paddingLeft: 0,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(22, 48, 32, 1)"
                                } as any
                              }
                            >
                              <Label
                                id="796351"
                                value={ReactSystemFunctions.translate(this.ml, 796351, "value", this.defaultML)}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 0,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 400,
                                    fontSize: "12px",
                                    color: "rgba(186, 187, 187, 1)",
                                    lineHeight: "20px"
                                  } as any
                                }
                              ></Label>
                            </KCol>

                            <KCol
                              id="642789"
                              xs={16}
                              sm={16}
                              md={16}
                              lg={16}
                              xl={16}
                              xxl={16}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 0,
                                  paddingLeft: 8,
                                  alignItems: "flex-start",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(22, 48, 32, 1)"
                                } as any
                              }
                            >
                              <Form.Item className="kFormItem" name="musteriticaribilgileri_753737_value">
                                <TextInput
                                  id="753737"
                                  placeholder={ReactSystemFunctions.translate(
                                    this.ml,
                                    753737,
                                    "placeholder",
                                    this.defaultML
                                  )}
                                  allowClear={false}
                                  bordered={true}
                                  disabled={false}
                                  type="text"
                                  iconColor="rgba(0, 0, 0, 1)"
                                  formatter="None"
                                  isCharOnly={false}
                                  autoComplete={true}
                                  style={
                                    {
                                      borderTopLeftRadius: 4,
                                      borderTopRightRadius: 4,
                                      borderBottomRightRadius: 4,
                                      borderBottomLeftRadius: 4,
                                      borderTopWidth: 1,
                                      borderRightWidth: 1,
                                      borderBottomWidth: 1,
                                      borderLeftWidth: 1,
                                      borderColor: "rgba(218, 218, 218, 1)",
                                      borderStyle: "solid",
                                      paddingTop: 8,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "block",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                ></TextInput>
                              </Form.Item>
                            </KCol>
                          </KRow>
                        </KCol>

                        <KCol
                          id="657157"
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                          xxl={12}
                          order={0}
                          pull={0}
                          push={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              alignItems: "flex-start",
                              textAlign: "-webkit-left",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(22, 48, 32, 1)"
                            } as any
                          }
                        ></KCol>
                      </KRow>

                      <KRow
                        id="564319"
                        align="top"
                        justify="start"
                        horizontalGutter={0}
                        verticalGutter={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 0,
                            paddingRight: 8,
                            paddingBottom: 0,
                            paddingLeft: 8,
                            alignItems: "flex-start",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(22, 48, 32, 1)"
                          } as any
                        }
                      >
                        <KCol
                          id="526660"
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                          xxl={12}
                          order={0}
                          pull={0}
                          push={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(22, 48, 32, 1)"
                            } as any
                          }
                        >
                          <KRow
                            id="916577"
                            align="top"
                            justify="start"
                            horizontalGutter={0}
                            verticalGutter={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(22, 48, 32, 1)"
                              } as any
                            }
                          >
                            <KCol
                              id="983961"
                              xs={8}
                              sm={8}
                              md={8}
                              lg={8}
                              xl={8}
                              xxl={8}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 8,
                                  paddingBottom: 0,
                                  paddingLeft: 0,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(22, 48, 32, 1)"
                                } as any
                              }
                            >
                              <Label
                                id="944498"
                                value={ReactSystemFunctions.translate(this.ml, 944498, "value", this.defaultML)}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 0,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 400,
                                    fontSize: "12px",
                                    color: "rgba(186, 187, 187, 1)",
                                    lineHeight: "20px"
                                  } as any
                                }
                              ></Label>
                            </KCol>

                            <KCol
                              id="561948"
                              xs={16}
                              sm={16}
                              md={16}
                              lg={16}
                              xl={16}
                              xxl={16}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 0,
                                  paddingLeft: 8,
                                  alignItems: "flex-start",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(22, 48, 32, 1)"
                                } as any
                              }
                            >
                              <Form.Item className="kFormItem" name="musteriticaribilgileri_7580_value">
                                <TextInput
                                  id="7580"
                                  placeholder={ReactSystemFunctions.translate(
                                    this.ml,
                                    7580,
                                    "placeholder",
                                    this.defaultML
                                  )}
                                  allowClear={false}
                                  bordered={true}
                                  disabled={false}
                                  type="text"
                                  iconColor="rgba(0, 0, 0, 1)"
                                  formatter="None"
                                  isCharOnly={false}
                                  allowedChars="0123456789"
                                  autoComplete={true}
                                  style={
                                    {
                                      borderTopLeftRadius: 4,
                                      borderTopRightRadius: 4,
                                      borderBottomRightRadius: 4,
                                      borderBottomLeftRadius: 4,
                                      borderTopWidth: 1,
                                      borderRightWidth: 1,
                                      borderBottomWidth: 1,
                                      borderLeftWidth: 1,
                                      borderColor: "rgba(218, 218, 218, 1)",
                                      borderStyle: "solid",
                                      paddingTop: 8,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "block",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                ></TextInput>
                              </Form.Item>
                            </KCol>
                          </KRow>
                        </KCol>

                        <KCol
                          id="986845"
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                          xxl={12}
                          order={0}
                          pull={0}
                          push={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              alignItems: "flex-start",
                              textAlign: "-webkit-left",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(22, 48, 32, 1)"
                            } as any
                          }
                        ></KCol>
                      </KRow>

                      <KRow
                        id="843507"
                        visibility={this.state.isComp843507Visible}
                        visibilityCondition={(rowData: object) =>
                          ReactSystemFunctions.isNotEmpty(this.state.UyelikFormuById[0]?.uyelikDurumKey?.toString(), "")
                        }
                        align="top"
                        justify="start"
                        horizontalGutter={0}
                        verticalGutter={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 0,
                            paddingRight: 8,
                            paddingBottom: 0,
                            paddingLeft: 8,
                            alignItems: "flex-start",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(22, 48, 32, 1)"
                          } as any
                        }
                      >
                        <KCol
                          id="341540"
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                          xxl={12}
                          order={0}
                          pull={0}
                          push={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(22, 48, 32, 1)"
                            } as any
                          }
                        >
                          <KRow
                            id="61687"
                            align="top"
                            justify="start"
                            horizontalGutter={0}
                            verticalGutter={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(22, 48, 32, 1)"
                              } as any
                            }
                          >
                            <KCol
                              id="66629"
                              xs={8}
                              sm={8}
                              md={8}
                              lg={8}
                              xl={8}
                              xxl={8}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 8,
                                  paddingBottom: 0,
                                  paddingLeft: 0,
                                  alignItems: "flex-start",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(22, 48, 32, 1)"
                                } as any
                              }
                            >
                              <Label
                                id="678690"
                                value={ReactSystemFunctions.translate(this.ml, 678690, "value", this.defaultML)}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 0,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 400,
                                    fontSize: "12px",
                                    color: "rgba(186, 187, 187, 1)",
                                    lineHeight: "20px"
                                  } as any
                                }
                              ></Label>
                            </KCol>

                            <KCol
                              id="778667"
                              xs={16}
                              sm={16}
                              md={16}
                              lg={16}
                              xl={16}
                              xxl={16}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 0,
                                  paddingLeft: 8,
                                  alignItems: "flex-start",
                                  textAlign: "-webkit-right",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(22, 48, 32, 1)"
                                } as any
                              }
                            >
                              <Form.Item className="kFormItem" name="musteriticaribilgileri_711154_value">
                                <TextArea
                                  id="711154"
                                  placeholder={ReactSystemFunctions.translate(
                                    this.ml,
                                    711154,
                                    "placeholder",
                                    this.defaultML
                                  )}
                                  allowClear={false}
                                  autoSize={false}
                                  showCount={false}
                                  formatter="None"
                                  readOnly={false}
                                  style={
                                    {
                                      borderTopLeftRadius: 4,
                                      borderTopRightRadius: 4,
                                      borderBottomRightRadius: 4,
                                      borderBottomLeftRadius: 4,
                                      borderTopWidth: 1,
                                      borderRightWidth: 1,
                                      borderBottomWidth: 1,
                                      borderLeftWidth: 1,
                                      borderColor: "rgba(218, 218, 218, 1)",
                                      borderStyle: "solid",
                                      backgroundColor: "rgba(255, 255, 255, 1)",
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      paddingTop: 8,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "block",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                ></TextArea>
                              </Form.Item>

                              <Button
                                id="470559"
                                onClick={(e?: any) => {
                                  if (e && e.stopPropagation) e.stopPropagation();
                                  this.MusteriTicariBilgileriComponent_470559_onClick();
                                }}
                                showCursorPointer
                                label={ReactSystemFunctions.translate(this.ml, 470559, "label", this.defaultML)}
                                size="middle"
                                loading={false}
                                ghost={false}
                                block={false}
                                htmlType="button"
                                iconPosition="left"
                                danger={false}
                                style={
                                  {
                                    borderTopLeftRadius: 4,
                                    borderTopRightRadius: 4,
                                    borderBottomRightRadius: 4,
                                    borderBottomLeftRadius: 4,
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundColor: "rgba(2, 145, 201, 1)",
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    paddingTop: 2,
                                    paddingRight: 16,
                                    paddingBottom: 2,
                                    paddingLeft: 16,
                                    alignItems: "center",
                                    textAlign: "-webkit-center",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(255, 255, 255, 1)",
                                    lineHeight: "22px"
                                  } as any
                                }
                              ></Button>

                              <KTable
                                id="930194"
                                kuikaRef={this.musteriticaribilgileri_930194_value_kuikaTableRef}
                                form={this.props.form}
                                dataSource={this.state.UyelikFormuDuzeltmeNotuByUyeID}
                                size="middle"
                                bordered={true}
                                showHeader={true}
                                loading={false}
                                nodatafoundmessage={ReactSystemFunctions.translate(
                                  this.ml,
                                  930194,
                                  "nodatafoundmessage",
                                  this.defaultML
                                )}
                                sorter={false}
                                pagination={false}
                                striped={false}
                                stripedColor="#F5F7FA"
                                insertRowActive={false}
                                searchable={false}
                                filtering={false}
                                transformedOnMobileResolution={false}
                                tableWidthMode="fit"
                                columnChooser={false}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 0,
                                    paddingLeft: 0,
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <KTableHeader
                                  id="171640"
                                  hideOnMobileResolution={false}
                                  textDirection="Default"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 8,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Poppins",
                                      fontWeight: 400,
                                      fontSize: "12px",
                                      color: "rgba(186, 187, 187, 1)",
                                      lineHeight: "20px"
                                    } as any
                                  }
                                ></KTableHeader>

                                <KTableRow
                                  id="978751"
                                  hoverFontColor="red"
                                  hoverBgColor="#F5F5F5"
                                  style={
                                    {
                                      borderTopWidth: 1,
                                      borderRightWidth: 1,
                                      borderBottomWidth: 1,
                                      borderLeftWidth: 1,
                                      borderColor: "rgba(218, 218, 218, 1)",
                                      borderStyle: "solid",
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                >
                                  <KTableColumn
                                    id="38323"
                                    title={ReactSystemFunctions.translate(this.ml, 38323, "title", this.defaultML)}
                                    alwaysVisibleOnMobileResolution={false}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(22, 48, 32, 1)"
                                      } as any
                                    }
                                  >
                                    <KRow
                                      id="920461"
                                      align="top"
                                      justify="start"
                                      horizontalGutter={0}
                                      verticalGutter={0}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(22, 48, 32, 1)"
                                        } as any
                                      }
                                    >
                                      <KCol
                                        id="63323"
                                        xs={24}
                                        sm={24}
                                        md={24}
                                        lg={24}
                                        xl={24}
                                        xxl={24}
                                        order={0}
                                        pull={0}
                                        push={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 8,
                                            paddingRight: 8,
                                            paddingBottom: 8,
                                            paddingLeft: 0,
                                            alignItems: "flex-start",
                                            textAlign: "-webkit-left",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(22, 48, 32, 1)"
                                          } as any
                                        }
                                      >
                                        <Label
                                          id="441811"
                                          value="[datafield:duzeltmeNotu]"
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 0,
                                              paddingLeft: 0,
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              display: "inline",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              color: "rgba(22, 48, 32, 1)"
                                            } as any
                                          }
                                        ></Label>
                                      </KCol>
                                    </KRow>

                                    <KRow
                                      id="601541"
                                      align="top"
                                      justify="start"
                                      horizontalGutter={0}
                                      verticalGutter={0}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(22, 48, 32, 1)"
                                        } as any
                                      }
                                    >
                                      <KCol
                                        id="239406"
                                        xs={24}
                                        sm={24}
                                        md={24}
                                        lg={24}
                                        xl={24}
                                        xxl={24}
                                        order={0}
                                        pull={0}
                                        push={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            textAlign: "-webkit-right",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(22, 48, 32, 1)"
                                          } as any
                                        }
                                      >
                                        <Label
                                          id="825776"
                                          value="[datafield:userInfo]"
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 0,
                                              paddingLeft: 0,
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              display: "inline",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "12px",
                                              color: "rgba(140, 140, 140, 1)"
                                            } as any
                                          }
                                        ></Label>
                                      </KCol>
                                    </KRow>
                                  </KTableColumn>
                                </KTableRow>
                              </KTable>
                            </KCol>
                          </KRow>
                        </KCol>

                        <KCol
                          id="340418"
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                          xxl={12}
                          order={0}
                          pull={0}
                          push={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              alignItems: "flex-start",
                              textAlign: "-webkit-left",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(22, 48, 32, 1)"
                            } as any
                          }
                        ></KCol>
                      </KRow>

                      <KRow
                        id="221266"
                        visibility={this.state.isComp221266Visible}
                        visibilityCondition={(rowData: object) =>
                          ReactSystemFunctions.isEmpty(this.props.screenInputs.prmID, "")
                        }
                        align="top"
                        justify="start"
                        horizontalGutter={0}
                        verticalGutter={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            alignItems: "flex-start",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(22, 48, 32, 1)"
                          } as any
                        }
                      >
                        <KCol
                          id="601554"
                          xs={24}
                          sm={24}
                          md={24}
                          lg={24}
                          xl={24}
                          xxl={24}
                          order={0}
                          pull={0}
                          push={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              alignItems: "center",
                              textAlign: "-webkit-right",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(22, 48, 32, 1)"
                            } as any
                          }
                        >
                          <HorizontalStack
                            id="915378"
                            direction="horizontal"
                            size={12}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(22, 48, 32, 1)"
                              } as any
                            }
                          >
                            <Button
                              id="970116"
                              onClick={(e?: any) => {
                                if (e && e.stopPropagation) e.stopPropagation();
                                this.MusteriTicariBilgileriComponent_970116_onClick();
                              }}
                              showCursorPointer
                              label={ReactSystemFunctions.translate(this.ml, 970116, "label", this.defaultML)}
                              size="middle"
                              loading={false}
                              ghost={false}
                              block={false}
                              htmlType="button"
                              iconPosition="left"
                              danger={false}
                              style={
                                {
                                  borderTopLeftRadius: 4,
                                  borderTopRightRadius: 4,
                                  borderBottomRightRadius: 4,
                                  borderBottomLeftRadius: 4,
                                  borderTopWidth: 1,
                                  borderRightWidth: 1,
                                  borderBottomWidth: 1,
                                  borderLeftWidth: 1,
                                  borderColor: "rgba(48, 77, 48, 1)",
                                  borderStyle: "solid",
                                  backgroundColor: "rgba(255, 255, 255, 1)",
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  paddingTop: 8,
                                  paddingRight: 16,
                                  paddingBottom: 8,
                                  paddingLeft: 16,
                                  alignItems: "center",
                                  textAlign: "-webkit-center",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "16px",
                                  color: "rgba(2, 145, 201, 1)",
                                  lineHeight: "22px"
                                } as any
                              }
                            ></Button>

                            <Button
                              id="887191"
                              onClick={(e?: any) => {
                                if (e && e.stopPropagation) e.stopPropagation();
                                this.MusteriTicariBilgileriComponent_887191_onClick();
                              }}
                              showCursorPointer
                              label={ReactSystemFunctions.translate(this.ml, 887191, "label", this.defaultML)}
                              size="middle"
                              loading={false}
                              ghost={false}
                              block={false}
                              htmlType="button"
                              iconPosition="left"
                              danger={false}
                              style={
                                {
                                  borderTopLeftRadius: 4,
                                  borderTopRightRadius: 4,
                                  borderBottomRightRadius: 4,
                                  borderBottomLeftRadius: 4,
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundColor: "rgba(2, 145, 201, 1)",
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  paddingTop: 8,
                                  paddingRight: 16,
                                  paddingBottom: 8,
                                  paddingLeft: 16,
                                  alignItems: "center",
                                  textAlign: "-webkit-center",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "16px",
                                  color: "rgba(255, 255, 255, 1)",
                                  lineHeight: "22px"
                                } as any
                              }
                            ></Button>
                          </HorizontalStack>
                        </KCol>
                      </KRow>

                      <KRow
                        id="831803"
                        visibility={this.state.isComp831803Visible}
                        visibilityCondition={(rowData: object) =>
                          ReactSystemFunctions.isNotEmpty(this.props.screenInputs.prmID, "")
                        }
                        align="top"
                        justify="start"
                        horizontalGutter={0}
                        verticalGutter={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            alignItems: "flex-start",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(22, 48, 32, 1)"
                          } as any
                        }
                      >
                        <KCol
                          id="423511"
                          xs={24}
                          sm={24}
                          md={24}
                          lg={24}
                          xl={24}
                          xxl={24}
                          order={0}
                          pull={0}
                          push={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              alignItems: "center",
                              textAlign: "-webkit-right",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(22, 48, 32, 1)"
                            } as any
                          }
                        >
                          <HorizontalStack
                            id="530856"
                            direction="horizontal"
                            size={12}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(22, 48, 32, 1)"
                              } as any
                            }
                          >
                            <Button
                              id="733654"
                              onClick={(e?: any) => {
                                if (e && e.stopPropagation) e.stopPropagation();
                                this.MusteriTicariBilgileriComponent_733654_onClick();
                              }}
                              showCursorPointer
                              label={ReactSystemFunctions.translate(this.ml, 733654, "label", this.defaultML)}
                              size="middle"
                              loading={false}
                              ghost={false}
                              block={false}
                              htmlType="button"
                              iconPosition="left"
                              danger={false}
                              style={
                                {
                                  borderTopLeftRadius: 4,
                                  borderTopRightRadius: 4,
                                  borderBottomRightRadius: 4,
                                  borderBottomLeftRadius: 4,
                                  borderTopWidth: 1,
                                  borderRightWidth: 1,
                                  borderBottomWidth: 1,
                                  borderLeftWidth: 1,
                                  borderColor: "rgba(48, 77, 48, 1)",
                                  borderStyle: "solid",
                                  backgroundColor: "rgba(255, 255, 255, 1)",
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  paddingTop: 8,
                                  paddingRight: 16,
                                  paddingBottom: 8,
                                  paddingLeft: 16,
                                  alignItems: "center",
                                  textAlign: "-webkit-center",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "16px",
                                  color: "rgba(2, 145, 201, 1)",
                                  lineHeight: "22px"
                                } as any
                              }
                            ></Button>

                            <Button
                              id="861793"
                              onClick={(e?: any) => {
                                if (e && e.stopPropagation) e.stopPropagation();
                                this.MusteriTicariBilgileriComponent_861793_onClick();
                              }}
                              showCursorPointer
                              label={ReactSystemFunctions.translate(this.ml, 861793, "label", this.defaultML)}
                              size="middle"
                              loading={false}
                              ghost={false}
                              block={false}
                              htmlType="button"
                              iconPosition="left"
                              danger={false}
                              style={
                                {
                                  borderTopLeftRadius: 4,
                                  borderTopRightRadius: 4,
                                  borderBottomRightRadius: 4,
                                  borderBottomLeftRadius: 4,
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundColor: "rgba(245, 34, 45, 1)",
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  paddingTop: 8,
                                  paddingRight: 16,
                                  paddingBottom: 8,
                                  paddingLeft: 16,
                                  alignItems: "center",
                                  textAlign: "-webkit-center",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "16px",
                                  color: "rgba(255, 255, 255, 1)",
                                  lineHeight: "22px"
                                } as any
                              }
                            ></Button>

                            <Button
                              id="956223"
                              onClick={(e?: any) => {
                                if (e && e.stopPropagation) e.stopPropagation();
                                this.MusteriTicariBilgileriComponent_956223_onClick();
                              }}
                              showCursorPointer
                              label={ReactSystemFunctions.translate(this.ml, 956223, "label", this.defaultML)}
                              size="middle"
                              loading={false}
                              ghost={false}
                              block={false}
                              htmlType="button"
                              iconPosition="left"
                              danger={false}
                              style={
                                {
                                  borderTopLeftRadius: 4,
                                  borderTopRightRadius: 4,
                                  borderBottomRightRadius: 4,
                                  borderBottomLeftRadius: 4,
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundColor: "rgba(2, 145, 201, 1)",
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  paddingTop: 8,
                                  paddingRight: 16,
                                  paddingBottom: 8,
                                  paddingLeft: 16,
                                  alignItems: "center",
                                  textAlign: "-webkit-center",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "16px",
                                  color: "rgba(255, 255, 255, 1)",
                                  lineHeight: "22px"
                                } as any
                              }
                            ></Button>
                          </HorizontalStack>
                        </KCol>
                      </KRow>
                    </KPanel>
                  </KCol>
                </KRow>

                <KDrawer
                  id="998212"
                  placement="left"
                  onClose={() => {
                    this.setState({ NavVisible998212: false }, () => {
                      this.callCallbackFunction(998212);
                    });
                  }}
                  visible={this.state.NavVisible998212}
                  width="380px"
                  height="100vh"
                  push={true}
                  children={
                    this.state.NavVisible998212 && (
                      <LeftMenu_Screen
                        onClose={() => {
                          this.setState({ NavVisible998212: false }, () => {
                            this.callCallbackFunction(998212);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                      />
                    )
                  }
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 0,
                      paddingRight: 0,
                      paddingBottom: 0,
                      paddingLeft: 0,
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(22, 48, 32, 1)"
                    } as any
                  }
                ></KDrawer>

                <KDrawer
                  id="830353"
                  placement="left"
                  onClose={() => {
                    this.setState({ NavVisible830353: false }, () => {
                      this.callCallbackFunction(830353);
                    });
                  }}
                  visible={this.state.NavVisible830353}
                  width="45%"
                  height="100vh"
                  push={true}
                  children={
                    this.state.NavVisible830353 && (
                      <PDFViewerFormsPOPSozlesme_Screen
                        onClose={() => {
                          this.setState({ NavVisible830353: false }, () => {
                            this.callCallbackFunction(830353);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                      />
                    )
                  }
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 0,
                      paddingRight: 0,
                      paddingBottom: 0,
                      paddingLeft: 0,
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(22, 48, 32, 1)"
                    } as any
                  }
                ></KDrawer>

                <KDrawer
                  id="805872"
                  placement="left"
                  onClose={() => {
                    this.setState({ NavVisible805872: false }, () => {
                      this.callCallbackFunction(805872);
                    });
                  }}
                  visible={this.state.NavVisible805872}
                  width="45%"
                  height="100vh"
                  push={true}
                  children={
                    this.state.NavVisible805872 && (
                      <PDFViewerFormsImza_Screen
                        onClose={() => {
                          this.setState({ NavVisible805872: false }, () => {
                            this.callCallbackFunction(805872);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                      />
                    )
                  }
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 0,
                      paddingRight: 0,
                      paddingBottom: 0,
                      paddingLeft: 0,
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(22, 48, 32, 1)"
                    } as any
                  }
                ></KDrawer>

                <KDrawer
                  id="537889"
                  placement="left"
                  onClose={() => {
                    this.setState({ NavVisible537889: false }, () => {
                      this.callCallbackFunction(537889);
                    });
                  }}
                  visible={this.state.NavVisible537889}
                  width="45%"
                  height="100vh"
                  push={true}
                  children={
                    this.state.NavVisible537889 && (
                      <PDFViewerFormsTicariSicilGazete_Screen
                        onClose={() => {
                          this.setState({ NavVisible537889: false }, () => {
                            this.callCallbackFunction(537889);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                      />
                    )
                  }
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 0,
                      paddingRight: 0,
                      paddingBottom: 0,
                      paddingLeft: 0,
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(22, 48, 32, 1)"
                    } as any
                  }
                ></KDrawer>

                <KDrawer
                  id="985913"
                  placement="left"
                  onClose={() => {
                    this.setState({ NavVisible985913: false }, () => {
                      this.callCallbackFunction(985913);
                    });
                  }}
                  visible={this.state.NavVisible985913}
                  width="45%"
                  height="100vh"
                  push={true}
                  children={
                    this.state.NavVisible985913 && (
                      <PDFViewerFormsVergiLevhasi_Screen
                        onClose={() => {
                          this.setState({ NavVisible985913: false }, () => {
                            this.callCallbackFunction(985913);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                      />
                    )
                  }
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 0,
                      paddingRight: 0,
                      paddingBottom: 0,
                      paddingLeft: 0,
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(22, 48, 32, 1)"
                    } as any
                  }
                ></KDrawer>
              </div>
            </div>
          </Form>
        </Spin>
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  return {
    spinnerCount: state.spinnerReducer.spinnerCount,
    photoTakeVisible: state.photoTakeReducer.visible,
    isImportMappedExcelModalVisible: state.importMappedExcelModalReducer.visible
  };
};
const mapDispatchToProps = (dispatch) => ({
  showPhotoTake: () => dispatch(showPhotoTake()),
  hidePhotoTake: () => dispatch(hidePhotoTake()),
  showStripeDrawer: (data, callback, that, future) => dispatch(showStripeDrawer(data, callback, that, future)),
  hideStripeDrawer: (data, callback, that) => dispatch(hideStripeDrawer(data, callback, that)),
  showIyzicoDrawer: (data, callback, that) => dispatch(showIyzicoDrawer(data, callback, that)),
  hideIyzicoDrawer: (data, callback, that) => dispatch(hideIyzicoDrawer(data, callback, that)),
  showImportMappedExcelModal: (dsId, tableName, primaryKey, columnMapping) =>
    dispatch(showImportMappedExcelModal(dsId, tableName, primaryKey, columnMapping)),
  hideImportMappedExcelModal: () => dispatch(hideImportMappedExcelModal())
});
const tmp = withGoogleLogin(
  withContext(
    withScreenInput(withForm(withHistory(connect(mapStateToProps, mapDispatchToProps)(MusteriTicariBilgileri_Screen))))
  )
);
export { tmp as MusteriTicariBilgileri_Screen };
//export default tmp;
//export { tmp as MusteriTicariBilgileri_Screen };
