import { FormInstance } from "antd/es/form";
import moment from "moment";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IDashBoardYayinci_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IDashBoardYayinci_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  Logout: boolean;
  Logout_dummy: boolean;
  goToSignInActivity: any;
  goToSignInActivity_dummy: any;
  KullaniciLocalStorageSave: number;
  KullaniciLocalStorageSave_dummy: number;
  DB_GetDateRanges: any[];
  DB_GetDateRanges_dummy: any[];
  SetValueOf: any;
  SetValueOf_dummy: any;
  TriggerEvent: string;
  TriggerEvent_dummy: string;
  DB_SiparisOnayCount: any[];
  DB_SiparisOnayCount_dummy: any[];
  DB_SiparisOnaylananCount: any[];
  DB_SiparisOnaylananCount_dummy: any[];
  DB_SiparisOnaylanmayanCount: any[];
  DB_SiparisOnaylanmayanCount_dummy: any[];
  DB_TalepOnaylananCount: any[];
  DB_TalepOnaylananCount_dummy: any[];
  DB_TalepOnaylanmayanCount: any[];
  DB_TalepOnaylanmayanCount_dummy: any[];
  DB_TalepTaslakCount: any[];
  DB_TalepTaslakCount_dummy: any[];
  DB_TaslakOnayCount: any[];
  DB_TaslakOnayCount_dummy: any[];
  DB_UretimBaslayanCount: any[];
  DB_UretimBaslayanCount_dummy: any[];
  DB_UretimBitenCount: any[];
  DB_UretimBitenCount_dummy: any[];
  DB_UretimTaslakCount: any[];
  DB_UretimTaslakCount_dummy: any[];
  DB_SiparisTaslakCount: any[];
  DB_SiparisTaslakCount_dummy: any[];
  ReadFromLocalStorage: string;
  ReadFromLocalStorage_dummy: string;
  LogSave: number;
  LogSave_dummy: number;
  MyContext: any[];
  MyContext_dummy: any[];
  RoleMenuGoster: any[];
  RoleMenuGoster_dummy: any[];
  DB_GetDateRangesNames: any[];
  DB_GetDateRangesNames_dummy: any[];
  KullaniciLocalStorageByUsername: any[];
  KullaniciLocalStorageByUsername_dummy: any[];
  DB_TeslimEdilmeyenCount: any[];
  DB_TeslimEdilmeyenCount_dummy: any[];
  DB_TeslimEdilenCount: any[];
  DB_TeslimEdilenCount_dummy: any[];
  SetLanguage: string;
  SetLanguage_dummy: string;
  isComp33023Visible: "visible" | "hidden";
  isComp693053Visible: "visible" | "hidden";
  isComp470601Visible: "visible" | "hidden";
  isComp615218Visible: "visible" | "hidden";
  isComp883646Visible: "visible" | "hidden";
  isComp866974Visible: "visible" | "hidden";
  isComp740755Visible: "visible" | "hidden";
  isComp591628Visible: "visible" | "hidden";
  isComp581992Visible: "visible" | "hidden";
  isComp65297Visible: "visible" | "hidden";
  isComp678235Visible: "visible" | "hidden";
  isComp512558Visible: "visible" | "hidden";
}

export class DashBoardYayinci_ScreenBase extends React.PureComponent<IDashBoardYayinci_ScreenProps, any> {
  dashboardyayinci_373746_value_kuikaSelectBoxRef: React.RefObject<any>;
  dashboardyayinci_944505_value_kuikaDateRef: React.RefObject<any>;
  dashboardyayinci_599621_value_kuikaDateRef: React.RefObject<any>;
  ml = [
    {
      Id: "41cd36a4-5b07-cfca-4f7f-f48019ff4ff3",
      Name: "tr_TR",
      ShortName: "Türkçe",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "0069cee9-16ca-4a5a-aab9-dd58af96ca96", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "58f62202-f36d-4aca-9a68-767cecf60bb3",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 564088, PropertyName: "value", Value: "Yayıncı Dashboard" },
        { Id: 881224, PropertyName: "label", Value: "Profil" },
        { Id: 310750, PropertyName: "label", Value: "Çıkış Yap" },
        { Id: 968981, PropertyName: "value", Value: "Dönem" },
        { Id: 373746, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 619306, PropertyName: "value", Value: "Başlangıç Tarihi" },
        { Id: 944505, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 299223, PropertyName: "value", Value: "Bitiş Tarihi" },
        { Id: 599621, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 57698, PropertyName: "value", Value: "Teklif" },
        { Id: 413462, PropertyName: "value", Value: "Taslak Teklif Sayısı" },
        { Id: 432925, PropertyName: "value", Value: "Onay Bekleyen Teklif Sayısı" },
        { Id: 259104, PropertyName: "value", Value: "Onaylanan Teklif Sayısı" },
        { Id: 504347, PropertyName: "value", Value: "Reddedilen Teklif Sayısı" },
        { Id: 343740, PropertyName: "value", Value: "Sipariş" },
        { Id: 245560, PropertyName: "value", Value: "Taslak Sipariş Sayısı" },
        { Id: 41718, PropertyName: "value", Value: "Onay Bekleyen Sipariş Sayısı" },
        { Id: 467854, PropertyName: "value", Value: "Onaylanan Sipariş Sayısı" },
        { Id: 841395, PropertyName: "value", Value: "Reddedilen Sipariş Sayısı" },
        { Id: 280583, PropertyName: "value", Value: "Üretim" },
        { Id: 485792, PropertyName: "value", Value: "Üretim İşlemleri Başlatılan" },
        { Id: 694617, PropertyName: "value", Value: "Üretim İşlemleri Biten" },
        { Id: 626414, PropertyName: "value", Value: "Depo/Sevkiyat" },
        { Id: 183586, PropertyName: "value", Value: "Sipariş Teslimatı Bekleyen" },
        { Id: 368744, PropertyName: "value", Value: "Sipariş Teslimatı Tamamlanan" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.dashboardyayinci_373746_value_kuikaSelectBoxRef = React.createRef();
    this.dashboardyayinci_944505_value_kuikaDateRef = React.createRef();
    this.dashboardyayinci_599621_value_kuikaDateRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      NAVIGATE: "",
      Logout: false,
      goToSignInActivity: "",
      KullaniciLocalStorageSave: 0,
      DB_GetDateRanges: [],
      SetValueOf: "",
      TriggerEvent: "",
      DB_SiparisOnayCount: [],
      DB_SiparisOnaylananCount: [],
      DB_SiparisOnaylanmayanCount: [],
      DB_TalepOnaylananCount: [],
      DB_TalepOnaylanmayanCount: [],
      DB_TalepTaslakCount: [],
      DB_TaslakOnayCount: [],
      DB_UretimBaslayanCount: [],
      DB_UretimBitenCount: [],
      DB_UretimTaslakCount: [],
      DB_SiparisTaslakCount: [],
      ReadFromLocalStorage: "",
      LogSave: 0,
      MyContext: [],
      RoleMenuGoster: [],
      DB_GetDateRangesNames: [],
      KullaniciLocalStorageByUsername: [],
      DB_TeslimEdilmeyenCount: [],
      DB_TeslimEdilenCount: [],
      SetLanguage: "",
      isComp33023Visible: "hidden",
      isComp693053Visible: "hidden",
      isComp470601Visible: "hidden",
      isComp615218Visible: "hidden",
      isComp883646Visible: "hidden",
      isComp866974Visible: "hidden",
      isComp740755Visible: "hidden",
      isComp591628Visible: "hidden",
      isComp581992Visible: "hidden",
      isComp65297Visible: "hidden",
      isComp678235Visible: "hidden",
      isComp512558Visible: "hidden"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("dashboardyayinci", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.DashBoardYayinciPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("dashboardyayinci", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("dashboardyayinci", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.DashBoardYayinciPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      dashboardyayinci_81624_value: this.state.MyContext?.at?.(0)?.profilFoto ?? undefined,
      dashboardyayinci_373746_value: this.state.KullaniciLocalStorageByUsername?.at?.(0)?.sonTarihAraligi ?? undefined,
      dashboardyayinci_944505_value: this.state.DB_GetDateRanges?.at?.(0)?.baslangic ?? undefined,
      dashboardyayinci_599621_value: this.state.DB_GetDateRanges?.at?.(0)?.bitis ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  DashBoardYayinciPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ReadFromLocalStorage = await ReactSystemFunctions.readFromLocalStorage("LogGuid");

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.DashBoardYayinciPageInit1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  DashBoardYayinciPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      KeyIdValue_0: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
      OperationName_0: ReactSystemFunctions.convertToTypeByName("Giriş Başarılı!", "string"),
      OperationTime_0: ReactSystemFunctions.convertToTypeByName(moment().format("YYYY-MM-DDTHH:mm:ss"), "Date"),
      TableName_0: ReactSystemFunctions.convertToTypeByName("Sisteme Giriş", "string"),
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toGuid(this, this.state.ReadFromLocalStorage),
        "Guid"
      ),
      UserName_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      userName_4: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "DashBoardYayinci/DashBoardYayinciPageInit1_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.LogSave = result?.data.logSave;
    stateVars.MyContext = result?.data.myContext;
    formVars.dashboardyayinci_81624_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0
        ? stateVars.MyContext[0].profilFoto
        : this.state.MyContext?.length > 0
        ? this.state.MyContext[0].profilFoto
        : null
    );
    formVars.dashboardyayinci_980974_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0
        ? stateVars.MyContext[0].fullName
        : this.state.MyContext?.length > 0
        ? this.state.MyContext[0].fullName
        : null
    );
    formVars.dashboardyayinci_610693_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0
        ? stateVars.MyContext[0].uyeAd
        : this.state.MyContext?.length > 0
        ? this.state.MyContext[0].uyeAd
        : null
    );
    stateVars.RoleMenuGoster = result?.data.roleMenuGoster;
    stateVars.isComp33023Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m021
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp693053Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m021
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp470601Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m021
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp615218Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m021
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp883646Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m043
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp866974Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m045
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp740755Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m047
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp591628Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m047
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp581992Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m072
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp65297Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m082
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp678235Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m071
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp512558Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m081
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.DB_GetDateRangesNames = result?.data.dB_GetDateRangesNames;

    formVars.dashboardyayinci_373746_value =
      stateVars.KullaniciLocalStorageByUsername?.length > 0
        ? stateVars.KullaniciLocalStorageByUsername[0].sonTarihAraligi
        : this.state.KullaniciLocalStorageByUsername?.length > 0
        ? this.state.KullaniciLocalStorageByUsername[0].sonTarihAraligi
        : null;
    formVars.dashboardyayinci_373746_options = stateVars.DB_GetDateRangesNames;
    stateVars.KullaniciLocalStorageByUsername = result?.data.kullaniciLocalStorageByUsername;
    formVars.dashboardyayinci_373746_value =
      stateVars.KullaniciLocalStorageByUsername?.length > 0
        ? stateVars.KullaniciLocalStorageByUsername[0].sonTarihAraligi
        : this.state.KullaniciLocalStorageByUsername?.length > 0
        ? this.state.KullaniciLocalStorageByUsername[0].sonTarihAraligi
        : null;
    formVars.dashboardyayinci_373746_options = stateVars.DB_GetDateRangesNames;
    stateVars.DB_GetDateRanges = result?.data.dB_GetDateRanges;
    formVars.dashboardyayinci_944505_value = ReactSystemFunctions.value(
      this,
      stateVars.DB_GetDateRanges?.length > 0
        ? stateVars.DB_GetDateRanges[0].baslangic
        : this.state.DB_GetDateRanges?.length > 0
        ? this.state.DB_GetDateRanges[0].baslangic
        : null
    );
    formVars.dashboardyayinci_599621_value = ReactSystemFunctions.value(
      this,
      stateVars.DB_GetDateRanges?.length > 0
        ? stateVars.DB_GetDateRanges[0].bitis
        : this.state.DB_GetDateRanges?.length > 0
        ? this.state.DB_GetDateRanges[0].bitis
        : null
    );
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.DashBoardYayinciPageInit2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  DashBoardYayinciPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "dashboardyayinci_944505_value",
      ReactSystemFunctions.value(
        this,
        stateVars.DB_GetDateRanges?.length > 0
          ? stateVars.DB_GetDateRanges[0].baslangic
          : this.state.DB_GetDateRanges?.length > 0
          ? this.state.DB_GetDateRanges[0].baslangic
          : null
      ),
      null
    );
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "dashboardyayinci_599621_value",
      ReactSystemFunctions.value(
        this,
        stateVars.DB_GetDateRanges?.length > 0
          ? stateVars.DB_GetDateRanges[0].bitis
          : this.state.DB_GetDateRanges?.length > 0
          ? this.state.DB_GetDateRanges[0].bitis
          : null
      ),
      null
    );

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.DashBoardYayinciPageInit3_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  DashBoardYayinciPageInit3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      UyeId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyeId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyeId
            : null
        ),
        "Guid"
      ),
      BasT_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyayinci_944505_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyayinci_599621_value", "value", "", "", "")
        ),
        "Date"
      ),
      UyeId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyeId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyeId
            : null
        ),
        "Guid"
      ),
      BasT_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyayinci_944505_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyayinci_599621_value", "value", "", "", "")
        ),
        "Date"
      ),
      UyeId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyeId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyeId
            : null
        ),
        "Guid"
      ),
      BasT_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyayinci_944505_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyayinci_599621_value", "value", "", "", "")
        ),
        "Date"
      ),
      UyeId_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyeId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyeId
            : null
        ),
        "Guid"
      ),
      BasT_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyayinci_944505_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyayinci_599621_value", "value", "", "", "")
        ),
        "Date"
      ),
      UyeId_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyeId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyeId
            : null
        ),
        "Guid"
      ),
      BasT_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyayinci_944505_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyayinci_599621_value", "value", "", "", "")
        ),
        "Date"
      ),
      UyeId_5: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyeId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyeId
            : null
        ),
        "Guid"
      ),
      BasT_5: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyayinci_944505_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_5: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyayinci_599621_value", "value", "", "", "")
        ),
        "Date"
      ),
      UyeId_6: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyeId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyeId
            : null
        ),
        "Guid"
      ),
      BasT_6: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyayinci_944505_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_6: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyayinci_599621_value", "value", "", "", "")
        ),
        "Date"
      ),
      UyeId_7: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyeId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyeId
            : null
        ),
        "Guid"
      ),
      BasT_7: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyayinci_944505_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_7: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyayinci_599621_value", "value", "", "", "")
        ),
        "Date"
      ),
      UyeId_8: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyeId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyeId
            : null
        ),
        "Guid"
      ),
      BasT_8: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyayinci_944505_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_8: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyayinci_599621_value", "value", "", "", "")
        ),
        "Date"
      ),
      UyeId_9: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyeId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyeId
            : null
        ),
        "Guid"
      ),
      BasT_9: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyayinci_944505_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_9: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyayinci_599621_value", "value", "", "", "")
        ),
        "Date"
      ),
      UyeId_10: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyeId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyeId
            : null
        ),
        "Guid"
      ),
      BasT_10: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyayinci_944505_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_10: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyayinci_599621_value", "value", "", "", "")
        ),
        "Date"
      ),
      UyeId_11: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyeId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyeId
            : null
        ),
        "Guid"
      ),
      BasT_11: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyayinci_944505_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_11: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyayinci_599621_value", "value", "", "", "")
        ),
        "Date"
      ),
      UyeId_12: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyeId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyeId
            : null
        ),
        "Guid"
      ),
      BasT_12: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyayinci_944505_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_12: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyayinci_599621_value", "value", "", "", "")
        ),
        "Date"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "DashBoardYayinci/DashBoardYayinciPageInit3_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.DB_SiparisOnayCount = result?.data.dB_SiparisOnayCount;
    formVars.dashboardyayinci_458955_value = ReactSystemFunctions.toString(
      this,
      stateVars.DB_SiparisOnayCount?.length > 0
        ? stateVars.DB_SiparisOnayCount[0].recordCount
        : this.state.DB_SiparisOnayCount?.length > 0
        ? this.state.DB_SiparisOnayCount[0].recordCount
        : null
    );
    stateVars.DB_SiparisOnaylananCount = result?.data.dB_SiparisOnaylananCount;
    formVars.dashboardyayinci_90162_value = ReactSystemFunctions.toString(
      this,
      stateVars.DB_SiparisOnaylananCount?.length > 0
        ? stateVars.DB_SiparisOnaylananCount[0].recordCount
        : this.state.DB_SiparisOnaylananCount?.length > 0
        ? this.state.DB_SiparisOnaylananCount[0].recordCount
        : null
    );
    stateVars.DB_SiparisOnaylanmayanCount = result?.data.dB_SiparisOnaylanmayanCount;
    formVars.dashboardyayinci_343134_value = ReactSystemFunctions.toString(
      this,
      stateVars.DB_SiparisOnaylanmayanCount?.length > 0
        ? stateVars.DB_SiparisOnaylanmayanCount[0].recordCount
        : this.state.DB_SiparisOnaylanmayanCount?.length > 0
        ? this.state.DB_SiparisOnaylanmayanCount[0].recordCount
        : null
    );
    stateVars.DB_TalepOnaylananCount = result?.data.dB_TalepOnaylananCount;
    formVars.dashboardyayinci_15610_value = ReactSystemFunctions.toString(
      this,
      stateVars.DB_TalepOnaylananCount?.length > 0
        ? stateVars.DB_TalepOnaylananCount[0].recordCount
        : this.state.DB_TalepOnaylananCount?.length > 0
        ? this.state.DB_TalepOnaylananCount[0].recordCount
        : null
    );
    stateVars.DB_TalepOnaylanmayanCount = result?.data.dB_TalepOnaylanmayanCount;
    formVars.dashboardyayinci_679666_value = ReactSystemFunctions.toString(
      this,
      stateVars.DB_TalepOnaylanmayanCount?.length > 0
        ? stateVars.DB_TalepOnaylanmayanCount[0].recordCount
        : this.state.DB_TalepOnaylanmayanCount?.length > 0
        ? this.state.DB_TalepOnaylanmayanCount[0].recordCount
        : null
    );
    stateVars.DB_TalepTaslakCount = result?.data.dB_TalepTaslakCount;
    formVars.dashboardyayinci_270943_value = ReactSystemFunctions.toString(
      this,
      stateVars.DB_TalepTaslakCount?.length > 0
        ? stateVars.DB_TalepTaslakCount[0].recordCount
        : this.state.DB_TalepTaslakCount?.length > 0
        ? this.state.DB_TalepTaslakCount[0].recordCount
        : null
    );
    stateVars.DB_TaslakOnayCount = result?.data.dB_TaslakOnayCount;
    formVars.dashboardyayinci_334687_value = ReactSystemFunctions.toString(
      this,
      stateVars.DB_TaslakOnayCount?.length > 0
        ? stateVars.DB_TaslakOnayCount[0].recordCount
        : this.state.DB_TaslakOnayCount?.length > 0
        ? this.state.DB_TaslakOnayCount[0].recordCount
        : null
    );
    stateVars.DB_UretimBaslayanCount = result?.data.dB_UretimBaslayanCount;
    formVars.dashboardyayinci_593065_value = ReactSystemFunctions.toString(
      this,
      stateVars.DB_UretimBaslayanCount?.length > 0
        ? stateVars.DB_UretimBaslayanCount[0].recordCount
        : this.state.DB_UretimBaslayanCount?.length > 0
        ? this.state.DB_UretimBaslayanCount[0].recordCount
        : null
    );
    stateVars.DB_UretimBitenCount = result?.data.dB_UretimBitenCount;
    formVars.dashboardyayinci_203160_value = ReactSystemFunctions.toString(
      this,
      stateVars.DB_UretimBitenCount?.length > 0
        ? stateVars.DB_UretimBitenCount[0].recordCount
        : this.state.DB_UretimBitenCount?.length > 0
        ? this.state.DB_UretimBitenCount[0].recordCount
        : null
    );
    stateVars.DB_UretimTaslakCount = result?.data.dB_UretimTaslakCount;
    stateVars.DB_SiparisTaslakCount = result?.data.dB_SiparisTaslakCount;
    formVars.dashboardyayinci_538580_value = ReactSystemFunctions.toString(
      this,
      stateVars.DB_SiparisTaslakCount?.length > 0
        ? stateVars.DB_SiparisTaslakCount[0].recordCount
        : this.state.DB_SiparisTaslakCount?.length > 0
        ? this.state.DB_SiparisTaslakCount[0].recordCount
        : null
    );
    stateVars.DB_TeslimEdilmeyenCount = result?.data.dB_TeslimEdilmeyenCount;
    formVars.dashboardyayinci_861758_value = ReactSystemFunctions.toString(
      this,
      stateVars.DB_TeslimEdilmeyenCount?.length > 0
        ? stateVars.DB_TeslimEdilmeyenCount[0].recordCount
        : this.state.DB_TeslimEdilmeyenCount?.length > 0
        ? this.state.DB_TeslimEdilmeyenCount[0].recordCount
        : null
    );
    stateVars.DB_TeslimEdilenCount = result?.data.dB_TeslimEdilenCount;
    formVars.dashboardyayinci_262765_value = ReactSystemFunctions.toString(
      this,
      stateVars.DB_TeslimEdilenCount?.length > 0
        ? stateVars.DB_TeslimEdilenCount[0].recordCount
        : this.state.DB_TeslimEdilenCount?.length > 0
        ? this.state.DB_TeslimEdilenCount[0].recordCount
        : null
    );
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.DashBoardYayinciPageInit4_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  DashBoardYayinciPageInit4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.SetLanguage = await ReactSystemFunctions.setLanguage(this, "tr_TR");
    formVars.dashboardyayinci_81624_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0
        ? stateVars.MyContext[0].profilFoto
        : this.state.MyContext?.length > 0
        ? this.state.MyContext[0].profilFoto
        : null
    );

    formVars.dashboardyayinci_980974_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0
        ? stateVars.MyContext[0].fullName
        : this.state.MyContext?.length > 0
        ? this.state.MyContext[0].fullName
        : null
    );

    formVars.dashboardyayinci_610693_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0
        ? stateVars.MyContext[0].uyeAd
        : this.state.MyContext?.length > 0
        ? this.state.MyContext[0].uyeAd
        : null
    );

    formVars.dashboardyayinci_373746_value = ReactSystemFunctions.toString(
      this,
      stateVars.KullaniciLocalStorageByUsername?.length > 0
        ? stateVars.KullaniciLocalStorageByUsername[0].sonTarihAraligi
        : this.state.KullaniciLocalStorageByUsername?.length > 0
        ? this.state.KullaniciLocalStorageByUsername[0].sonTarihAraligi
        : null
    );

    stateVars.dataSource_373746 = this.state.DB_GetDateRangesNames;
    stateVars.dataSource_373746 = this.state.DB_GetDateRangesNames;
    formVars.dashboardyayinci_944505_value = ReactSystemFunctions.value(
      this,
      stateVars.DB_GetDateRanges?.length > 0
        ? stateVars.DB_GetDateRanges[0].baslangic
        : this.state.DB_GetDateRanges?.length > 0
        ? this.state.DB_GetDateRanges[0].baslangic
        : null
    );

    formVars.dashboardyayinci_599621_value = ReactSystemFunctions.value(
      this,
      stateVars.DB_GetDateRanges?.length > 0
        ? stateVars.DB_GetDateRanges[0].bitis
        : this.state.DB_GetDateRanges?.length > 0
        ? this.state.DB_GetDateRanges[0].bitis
        : null
    );

    formVars.dashboardyayinci_270943_value = ReactSystemFunctions.toString(
      this,
      stateVars.DB_TalepTaslakCount?.length > 0
        ? stateVars.DB_TalepTaslakCount[0].recordCount
        : this.state.DB_TalepTaslakCount?.length > 0
        ? this.state.DB_TalepTaslakCount[0].recordCount
        : null
    );

    stateVars.isComp33023Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m021
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.dashboardyayinci_334687_value = ReactSystemFunctions.toString(
      this,
      stateVars.DB_TaslakOnayCount?.length > 0
        ? stateVars.DB_TaslakOnayCount[0].recordCount
        : this.state.DB_TaslakOnayCount?.length > 0
        ? this.state.DB_TaslakOnayCount[0].recordCount
        : null
    );

    stateVars.isComp693053Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m021
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.dashboardyayinci_15610_value = ReactSystemFunctions.toString(
      this,
      stateVars.DB_TalepOnaylananCount?.length > 0
        ? stateVars.DB_TalepOnaylananCount[0].recordCount
        : this.state.DB_TalepOnaylananCount?.length > 0
        ? this.state.DB_TalepOnaylananCount[0].recordCount
        : null
    );

    stateVars.isComp470601Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m021
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.dashboardyayinci_679666_value = ReactSystemFunctions.toString(
      this,
      stateVars.DB_TalepOnaylanmayanCount?.length > 0
        ? stateVars.DB_TalepOnaylanmayanCount[0].recordCount
        : this.state.DB_TalepOnaylanmayanCount?.length > 0
        ? this.state.DB_TalepOnaylanmayanCount[0].recordCount
        : null
    );

    stateVars.isComp615218Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m021
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.dashboardyayinci_538580_value = ReactSystemFunctions.toString(
      this,
      stateVars.DB_SiparisTaslakCount?.length > 0
        ? stateVars.DB_SiparisTaslakCount[0].recordCount
        : this.state.DB_SiparisTaslakCount?.length > 0
        ? this.state.DB_SiparisTaslakCount[0].recordCount
        : null
    );

    stateVars.isComp883646Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m043
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.dashboardyayinci_458955_value = ReactSystemFunctions.toString(
      this,
      stateVars.DB_SiparisOnayCount?.length > 0
        ? stateVars.DB_SiparisOnayCount[0].recordCount
        : this.state.DB_SiparisOnayCount?.length > 0
        ? this.state.DB_SiparisOnayCount[0].recordCount
        : null
    );

    stateVars.isComp866974Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m045
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.dashboardyayinci_90162_value = ReactSystemFunctions.toString(
      this,
      stateVars.DB_SiparisOnaylananCount?.length > 0
        ? stateVars.DB_SiparisOnaylananCount[0].recordCount
        : this.state.DB_SiparisOnaylananCount?.length > 0
        ? this.state.DB_SiparisOnaylananCount[0].recordCount
        : null
    );

    stateVars.isComp740755Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m047
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.dashboardyayinci_343134_value = ReactSystemFunctions.toString(
      this,
      stateVars.DB_SiparisOnaylanmayanCount?.length > 0
        ? stateVars.DB_SiparisOnaylanmayanCount[0].recordCount
        : this.state.DB_SiparisOnaylanmayanCount?.length > 0
        ? this.state.DB_SiparisOnaylanmayanCount[0].recordCount
        : null
    );

    stateVars.isComp591628Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m047
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.dashboardyayinci_593065_value = ReactSystemFunctions.toString(
      this,
      stateVars.DB_UretimBaslayanCount?.length > 0
        ? stateVars.DB_UretimBaslayanCount[0].recordCount
        : this.state.DB_UretimBaslayanCount?.length > 0
        ? this.state.DB_UretimBaslayanCount[0].recordCount
        : null
    );

    stateVars.isComp581992Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m072
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.dashboardyayinci_203160_value = ReactSystemFunctions.toString(
      this,
      stateVars.DB_UretimBitenCount?.length > 0
        ? stateVars.DB_UretimBitenCount[0].recordCount
        : this.state.DB_UretimBitenCount?.length > 0
        ? this.state.DB_UretimBitenCount[0].recordCount
        : null
    );

    stateVars.isComp65297Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m082
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.dashboardyayinci_861758_value = ReactSystemFunctions.toString(
      this,
      stateVars.DB_TeslimEdilmeyenCount?.length > 0
        ? stateVars.DB_TeslimEdilmeyenCount[0].recordCount
        : this.state.DB_TeslimEdilmeyenCount?.length > 0
        ? this.state.DB_TeslimEdilmeyenCount[0].recordCount
        : null
    );

    stateVars.isComp678235Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m071
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.dashboardyayinci_262765_value = ReactSystemFunctions.toString(
      this,
      stateVars.DB_TeslimEdilenCount?.length > 0
        ? stateVars.DB_TeslimEdilenCount[0].recordCount
        : this.state.DB_TeslimEdilenCount?.length > 0
        ? this.state.DB_TeslimEdilenCount[0].recordCount
        : null
    );

    stateVars.isComp512558Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m081
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  DashBoardYayinciComponent_284229_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "DashBoardYayinci",
      "LeftMenu",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "997406",
      null,
      "left",
      null,
      "480px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashBoardYayinciComponent_881224_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "DashBoardYayinci",
      "Profil",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "345236",
      null,
      "right",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashBoardYayinciComponent_310750_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.Logout = await ReactSystemFunctions.logout();
    stateVars.tmpBoolResult = await ReactSystemFunctions.goToSignInActivity(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashBoardYayinciComponent_373746_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      SonTarihAraligi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "dashboardyayinci_373746_value",
            "value",
            "DB_GetDateRangesNames",
            "rangeName",
            "rangeName"
          )
        ),
        "string"
      ),
      userName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      RangeName_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "dashboardyayinci_373746_value",
            "value",
            "DB_GetDateRangesNames",
            "rangeName",
            ""
          )
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "DashBoardYayinci/DashBoardYayinciComponent_373746_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.KullaniciLocalStorageSave = result?.data.kullaniciLocalStorageSave;
    stateVars.DB_GetDateRanges = result?.data.dB_GetDateRanges;
    formVars.dashboardyayinci_944505_value = ReactSystemFunctions.value(
      this,
      stateVars.DB_GetDateRanges?.length > 0
        ? stateVars.DB_GetDateRanges[0].baslangic
        : this.state.DB_GetDateRanges?.length > 0
        ? this.state.DB_GetDateRanges[0].baslangic
        : null
    );
    formVars.dashboardyayinci_599621_value = ReactSystemFunctions.value(
      this,
      stateVars.DB_GetDateRanges?.length > 0
        ? stateVars.DB_GetDateRanges[0].bitis
        : this.state.DB_GetDateRanges?.length > 0
        ? this.state.DB_GetDateRanges[0].bitis
        : null
    );
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.DashBoardYayinciComponent_373746_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  DashBoardYayinciComponent_373746_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "dashboardyayinci_944505_value",
      ReactSystemFunctions.value(
        this,
        stateVars.DB_GetDateRanges?.length > 0
          ? stateVars.DB_GetDateRanges[0].baslangic
          : this.state.DB_GetDateRanges?.length > 0
          ? this.state.DB_GetDateRanges[0].baslangic
          : null
      ),
      null
    );
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "dashboardyayinci_599621_value",
      ReactSystemFunctions.value(
        this,
        stateVars.DB_GetDateRanges?.length > 0
          ? stateVars.DB_GetDateRanges[0].bitis
          : this.state.DB_GetDateRanges?.length > 0
          ? this.state.DB_GetDateRanges[0].bitis
          : null
      ),
      null
    );
    await this.DashBoardYayinciPageInit();

    formVars.dashboardyayinci_944505_value = ReactSystemFunctions.value(
      this,
      stateVars.DB_GetDateRanges?.length > 0
        ? stateVars.DB_GetDateRanges[0].baslangic
        : this.state.DB_GetDateRanges?.length > 0
        ? this.state.DB_GetDateRanges[0].baslangic
        : null
    );

    formVars.dashboardyayinci_599621_value = ReactSystemFunctions.value(
      this,
      stateVars.DB_GetDateRanges?.length > 0
        ? stateVars.DB_GetDateRanges[0].bitis
        : this.state.DB_GetDateRanges?.length > 0
        ? this.state.DB_GetDateRanges[0].bitis
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashBoardYayinciComponent_944505_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UyeId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyeId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyeId
            : null
        ),
        "Guid"
      ),
      BasT_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyayinci_944505_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyayinci_599621_value", "value", "", "", "")
        ),
        "Date"
      ),
      UyeId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyeId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyeId
            : null
        ),
        "Guid"
      ),
      BasT_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyayinci_944505_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyayinci_599621_value", "value", "", "", "")
        ),
        "Date"
      ),
      UyeId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyeId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyeId
            : null
        ),
        "Guid"
      ),
      BasT_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyayinci_944505_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyayinci_599621_value", "value", "", "", "")
        ),
        "Date"
      ),
      UyeId_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyeId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyeId
            : null
        ),
        "Guid"
      ),
      BasT_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyayinci_944505_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyayinci_599621_value", "value", "", "", "")
        ),
        "Date"
      ),
      UyeId_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyeId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyeId
            : null
        ),
        "Guid"
      ),
      BasT_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyayinci_944505_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyayinci_599621_value", "value", "", "", "")
        ),
        "Date"
      ),
      UyeId_5: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyeId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyeId
            : null
        ),
        "Guid"
      ),
      BasT_5: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyayinci_944505_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_5: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyayinci_599621_value", "value", "", "", "")
        ),
        "Date"
      ),
      UyeId_6: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyeId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyeId
            : null
        ),
        "Guid"
      ),
      BasT_6: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyayinci_944505_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_6: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyayinci_599621_value", "value", "", "", "")
        ),
        "Date"
      ),
      UyeId_7: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyeId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyeId
            : null
        ),
        "Guid"
      ),
      BasT_7: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyayinci_944505_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_7: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyayinci_599621_value", "value", "", "", "")
        ),
        "Date"
      ),
      UyeId_8: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyeId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyeId
            : null
        ),
        "Guid"
      ),
      BasT_8: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyayinci_944505_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_8: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyayinci_599621_value", "value", "", "", "")
        ),
        "Date"
      ),
      UyeId_9: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyeId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyeId
            : null
        ),
        "Guid"
      ),
      BasT_9: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyayinci_944505_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_9: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyayinci_599621_value", "value", "", "", "")
        ),
        "Date"
      ),
      UyeId_10: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyeId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyeId
            : null
        ),
        "Guid"
      ),
      BasT_10: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyayinci_944505_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_10: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyayinci_599621_value", "value", "", "", "")
        ),
        "Date"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "DashBoardYayinci/DashBoardYayinciComponent_944505_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.DB_SiparisOnayCount = result?.data.dB_SiparisOnayCount;
    formVars.dashboardyayinci_458955_value = ReactSystemFunctions.toString(
      this,
      stateVars.DB_SiparisOnayCount?.length > 0
        ? stateVars.DB_SiparisOnayCount[0].recordCount
        : this.state.DB_SiparisOnayCount?.length > 0
        ? this.state.DB_SiparisOnayCount[0].recordCount
        : null
    );
    stateVars.DB_SiparisOnaylananCount = result?.data.dB_SiparisOnaylananCount;
    formVars.dashboardyayinci_90162_value = ReactSystemFunctions.toString(
      this,
      stateVars.DB_SiparisOnaylananCount?.length > 0
        ? stateVars.DB_SiparisOnaylananCount[0].recordCount
        : this.state.DB_SiparisOnaylananCount?.length > 0
        ? this.state.DB_SiparisOnaylananCount[0].recordCount
        : null
    );
    stateVars.DB_SiparisOnaylanmayanCount = result?.data.dB_SiparisOnaylanmayanCount;
    formVars.dashboardyayinci_343134_value = ReactSystemFunctions.toString(
      this,
      stateVars.DB_SiparisOnaylanmayanCount?.length > 0
        ? stateVars.DB_SiparisOnaylanmayanCount[0].recordCount
        : this.state.DB_SiparisOnaylanmayanCount?.length > 0
        ? this.state.DB_SiparisOnaylanmayanCount[0].recordCount
        : null
    );
    stateVars.DB_TalepOnaylananCount = result?.data.dB_TalepOnaylananCount;
    formVars.dashboardyayinci_15610_value = ReactSystemFunctions.toString(
      this,
      stateVars.DB_TalepOnaylananCount?.length > 0
        ? stateVars.DB_TalepOnaylananCount[0].recordCount
        : this.state.DB_TalepOnaylananCount?.length > 0
        ? this.state.DB_TalepOnaylananCount[0].recordCount
        : null
    );
    stateVars.DB_TalepOnaylanmayanCount = result?.data.dB_TalepOnaylanmayanCount;
    formVars.dashboardyayinci_679666_value = ReactSystemFunctions.toString(
      this,
      stateVars.DB_TalepOnaylanmayanCount?.length > 0
        ? stateVars.DB_TalepOnaylanmayanCount[0].recordCount
        : this.state.DB_TalepOnaylanmayanCount?.length > 0
        ? this.state.DB_TalepOnaylanmayanCount[0].recordCount
        : null
    );
    stateVars.DB_TalepTaslakCount = result?.data.dB_TalepTaslakCount;
    formVars.dashboardyayinci_270943_value = ReactSystemFunctions.toString(
      this,
      stateVars.DB_TalepTaslakCount?.length > 0
        ? stateVars.DB_TalepTaslakCount[0].recordCount
        : this.state.DB_TalepTaslakCount?.length > 0
        ? this.state.DB_TalepTaslakCount[0].recordCount
        : null
    );
    stateVars.DB_TaslakOnayCount = result?.data.dB_TaslakOnayCount;
    formVars.dashboardyayinci_334687_value = ReactSystemFunctions.toString(
      this,
      stateVars.DB_TaslakOnayCount?.length > 0
        ? stateVars.DB_TaslakOnayCount[0].recordCount
        : this.state.DB_TaslakOnayCount?.length > 0
        ? this.state.DB_TaslakOnayCount[0].recordCount
        : null
    );
    stateVars.DB_UretimBaslayanCount = result?.data.dB_UretimBaslayanCount;
    formVars.dashboardyayinci_593065_value = ReactSystemFunctions.toString(
      this,
      stateVars.DB_UretimBaslayanCount?.length > 0
        ? stateVars.DB_UretimBaslayanCount[0].recordCount
        : this.state.DB_UretimBaslayanCount?.length > 0
        ? this.state.DB_UretimBaslayanCount[0].recordCount
        : null
    );
    stateVars.DB_UretimBitenCount = result?.data.dB_UretimBitenCount;
    formVars.dashboardyayinci_203160_value = ReactSystemFunctions.toString(
      this,
      stateVars.DB_UretimBitenCount?.length > 0
        ? stateVars.DB_UretimBitenCount[0].recordCount
        : this.state.DB_UretimBitenCount?.length > 0
        ? this.state.DB_UretimBitenCount[0].recordCount
        : null
    );
    stateVars.DB_UretimTaslakCount = result?.data.dB_UretimTaslakCount;
    stateVars.DB_SiparisTaslakCount = result?.data.dB_SiparisTaslakCount;
    formVars.dashboardyayinci_538580_value = ReactSystemFunctions.toString(
      this,
      stateVars.DB_SiparisTaslakCount?.length > 0
        ? stateVars.DB_SiparisTaslakCount[0].recordCount
        : this.state.DB_SiparisTaslakCount?.length > 0
        ? this.state.DB_SiparisTaslakCount[0].recordCount
        : null
    );
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.DashBoardYayinciComponent_944505_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  DashBoardYayinciComponent_944505_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.dashboardyayinci_270943_value = ReactSystemFunctions.toString(
      this,
      stateVars.DB_TalepTaslakCount?.length > 0
        ? stateVars.DB_TalepTaslakCount[0].recordCount
        : this.state.DB_TalepTaslakCount?.length > 0
        ? this.state.DB_TalepTaslakCount[0].recordCount
        : null
    );

    formVars.dashboardyayinci_334687_value = ReactSystemFunctions.toString(
      this,
      stateVars.DB_TaslakOnayCount?.length > 0
        ? stateVars.DB_TaslakOnayCount[0].recordCount
        : this.state.DB_TaslakOnayCount?.length > 0
        ? this.state.DB_TaslakOnayCount[0].recordCount
        : null
    );

    formVars.dashboardyayinci_15610_value = ReactSystemFunctions.toString(
      this,
      stateVars.DB_TalepOnaylananCount?.length > 0
        ? stateVars.DB_TalepOnaylananCount[0].recordCount
        : this.state.DB_TalepOnaylananCount?.length > 0
        ? this.state.DB_TalepOnaylananCount[0].recordCount
        : null
    );

    formVars.dashboardyayinci_679666_value = ReactSystemFunctions.toString(
      this,
      stateVars.DB_TalepOnaylanmayanCount?.length > 0
        ? stateVars.DB_TalepOnaylanmayanCount[0].recordCount
        : this.state.DB_TalepOnaylanmayanCount?.length > 0
        ? this.state.DB_TalepOnaylanmayanCount[0].recordCount
        : null
    );

    formVars.dashboardyayinci_538580_value = ReactSystemFunctions.toString(
      this,
      stateVars.DB_SiparisTaslakCount?.length > 0
        ? stateVars.DB_SiparisTaslakCount[0].recordCount
        : this.state.DB_SiparisTaslakCount?.length > 0
        ? this.state.DB_SiparisTaslakCount[0].recordCount
        : null
    );

    formVars.dashboardyayinci_458955_value = ReactSystemFunctions.toString(
      this,
      stateVars.DB_SiparisOnayCount?.length > 0
        ? stateVars.DB_SiparisOnayCount[0].recordCount
        : this.state.DB_SiparisOnayCount?.length > 0
        ? this.state.DB_SiparisOnayCount[0].recordCount
        : null
    );

    formVars.dashboardyayinci_90162_value = ReactSystemFunctions.toString(
      this,
      stateVars.DB_SiparisOnaylananCount?.length > 0
        ? stateVars.DB_SiparisOnaylananCount[0].recordCount
        : this.state.DB_SiparisOnaylananCount?.length > 0
        ? this.state.DB_SiparisOnaylananCount[0].recordCount
        : null
    );

    formVars.dashboardyayinci_343134_value = ReactSystemFunctions.toString(
      this,
      stateVars.DB_SiparisOnaylanmayanCount?.length > 0
        ? stateVars.DB_SiparisOnaylanmayanCount[0].recordCount
        : this.state.DB_SiparisOnaylanmayanCount?.length > 0
        ? this.state.DB_SiparisOnaylanmayanCount[0].recordCount
        : null
    );

    formVars.dashboardyayinci_593065_value = ReactSystemFunctions.toString(
      this,
      stateVars.DB_UretimBaslayanCount?.length > 0
        ? stateVars.DB_UretimBaslayanCount[0].recordCount
        : this.state.DB_UretimBaslayanCount?.length > 0
        ? this.state.DB_UretimBaslayanCount[0].recordCount
        : null
    );

    formVars.dashboardyayinci_203160_value = ReactSystemFunctions.toString(
      this,
      stateVars.DB_UretimBitenCount?.length > 0
        ? stateVars.DB_UretimBitenCount[0].recordCount
        : this.state.DB_UretimBitenCount?.length > 0
        ? this.state.DB_UretimBitenCount[0].recordCount
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashBoardYayinciComponent_599621_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UyeId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyeId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyeId
            : null
        ),
        "Guid"
      ),
      BasT_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyayinci_944505_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyayinci_599621_value", "value", "", "", "")
        ),
        "Date"
      ),
      UyeId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyeId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyeId
            : null
        ),
        "Guid"
      ),
      BasT_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyayinci_944505_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyayinci_599621_value", "value", "", "", "")
        ),
        "Date"
      ),
      UyeId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyeId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyeId
            : null
        ),
        "Guid"
      ),
      BasT_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyayinci_944505_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyayinci_599621_value", "value", "", "", "")
        ),
        "Date"
      ),
      UyeId_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyeId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyeId
            : null
        ),
        "Guid"
      ),
      BasT_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyayinci_944505_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyayinci_599621_value", "value", "", "", "")
        ),
        "Date"
      ),
      UyeId_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyeId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyeId
            : null
        ),
        "Guid"
      ),
      BasT_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyayinci_944505_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyayinci_599621_value", "value", "", "", "")
        ),
        "Date"
      ),
      UyeId_5: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyeId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyeId
            : null
        ),
        "Guid"
      ),
      BasT_5: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyayinci_944505_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_5: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyayinci_599621_value", "value", "", "", "")
        ),
        "Date"
      ),
      UyeId_6: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyeId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyeId
            : null
        ),
        "Guid"
      ),
      BasT_6: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyayinci_944505_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_6: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyayinci_599621_value", "value", "", "", "")
        ),
        "Date"
      ),
      UyeId_7: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyeId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyeId
            : null
        ),
        "Guid"
      ),
      BasT_7: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyayinci_944505_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_7: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyayinci_599621_value", "value", "", "", "")
        ),
        "Date"
      ),
      UyeId_8: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyeId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyeId
            : null
        ),
        "Guid"
      ),
      BasT_8: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyayinci_944505_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_8: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyayinci_599621_value", "value", "", "", "")
        ),
        "Date"
      ),
      UyeId_9: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyeId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyeId
            : null
        ),
        "Guid"
      ),
      BasT_9: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyayinci_944505_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_9: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyayinci_599621_value", "value", "", "", "")
        ),
        "Date"
      ),
      UyeId_10: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyeId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyeId
            : null
        ),
        "Guid"
      ),
      BasT_10: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyayinci_944505_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_10: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardyayinci_599621_value", "value", "", "", "")
        ),
        "Date"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "DashBoardYayinci/DashBoardYayinciComponent_599621_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.DB_SiparisOnayCount = result?.data.dB_SiparisOnayCount;
    formVars.dashboardyayinci_458955_value = ReactSystemFunctions.toString(
      this,
      stateVars.DB_SiparisOnayCount?.length > 0
        ? stateVars.DB_SiparisOnayCount[0].recordCount
        : this.state.DB_SiparisOnayCount?.length > 0
        ? this.state.DB_SiparisOnayCount[0].recordCount
        : null
    );
    stateVars.DB_SiparisOnaylananCount = result?.data.dB_SiparisOnaylananCount;
    formVars.dashboardyayinci_90162_value = ReactSystemFunctions.toString(
      this,
      stateVars.DB_SiparisOnaylananCount?.length > 0
        ? stateVars.DB_SiparisOnaylananCount[0].recordCount
        : this.state.DB_SiparisOnaylananCount?.length > 0
        ? this.state.DB_SiparisOnaylananCount[0].recordCount
        : null
    );
    stateVars.DB_SiparisOnaylanmayanCount = result?.data.dB_SiparisOnaylanmayanCount;
    formVars.dashboardyayinci_343134_value = ReactSystemFunctions.toString(
      this,
      stateVars.DB_SiparisOnaylanmayanCount?.length > 0
        ? stateVars.DB_SiparisOnaylanmayanCount[0].recordCount
        : this.state.DB_SiparisOnaylanmayanCount?.length > 0
        ? this.state.DB_SiparisOnaylanmayanCount[0].recordCount
        : null
    );
    stateVars.DB_TalepOnaylananCount = result?.data.dB_TalepOnaylananCount;
    formVars.dashboardyayinci_15610_value = ReactSystemFunctions.toString(
      this,
      stateVars.DB_TalepOnaylananCount?.length > 0
        ? stateVars.DB_TalepOnaylananCount[0].recordCount
        : this.state.DB_TalepOnaylananCount?.length > 0
        ? this.state.DB_TalepOnaylananCount[0].recordCount
        : null
    );
    stateVars.DB_TalepOnaylanmayanCount = result?.data.dB_TalepOnaylanmayanCount;
    formVars.dashboardyayinci_679666_value = ReactSystemFunctions.toString(
      this,
      stateVars.DB_TalepOnaylanmayanCount?.length > 0
        ? stateVars.DB_TalepOnaylanmayanCount[0].recordCount
        : this.state.DB_TalepOnaylanmayanCount?.length > 0
        ? this.state.DB_TalepOnaylanmayanCount[0].recordCount
        : null
    );
    stateVars.DB_TalepTaslakCount = result?.data.dB_TalepTaslakCount;
    formVars.dashboardyayinci_270943_value = ReactSystemFunctions.toString(
      this,
      stateVars.DB_TalepTaslakCount?.length > 0
        ? stateVars.DB_TalepTaslakCount[0].recordCount
        : this.state.DB_TalepTaslakCount?.length > 0
        ? this.state.DB_TalepTaslakCount[0].recordCount
        : null
    );
    stateVars.DB_TaslakOnayCount = result?.data.dB_TaslakOnayCount;
    formVars.dashboardyayinci_334687_value = ReactSystemFunctions.toString(
      this,
      stateVars.DB_TaslakOnayCount?.length > 0
        ? stateVars.DB_TaslakOnayCount[0].recordCount
        : this.state.DB_TaslakOnayCount?.length > 0
        ? this.state.DB_TaslakOnayCount[0].recordCount
        : null
    );
    stateVars.DB_UretimBaslayanCount = result?.data.dB_UretimBaslayanCount;
    formVars.dashboardyayinci_593065_value = ReactSystemFunctions.toString(
      this,
      stateVars.DB_UretimBaslayanCount?.length > 0
        ? stateVars.DB_UretimBaslayanCount[0].recordCount
        : this.state.DB_UretimBaslayanCount?.length > 0
        ? this.state.DB_UretimBaslayanCount[0].recordCount
        : null
    );
    stateVars.DB_UretimBitenCount = result?.data.dB_UretimBitenCount;
    formVars.dashboardyayinci_203160_value = ReactSystemFunctions.toString(
      this,
      stateVars.DB_UretimBitenCount?.length > 0
        ? stateVars.DB_UretimBitenCount[0].recordCount
        : this.state.DB_UretimBitenCount?.length > 0
        ? this.state.DB_UretimBitenCount[0].recordCount
        : null
    );
    stateVars.DB_UretimTaslakCount = result?.data.dB_UretimTaslakCount;
    stateVars.DB_SiparisTaslakCount = result?.data.dB_SiparisTaslakCount;
    formVars.dashboardyayinci_538580_value = ReactSystemFunctions.toString(
      this,
      stateVars.DB_SiparisTaslakCount?.length > 0
        ? stateVars.DB_SiparisTaslakCount[0].recordCount
        : this.state.DB_SiparisTaslakCount?.length > 0
        ? this.state.DB_SiparisTaslakCount[0].recordCount
        : null
    );
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.DashBoardYayinciComponent_599621_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  DashBoardYayinciComponent_599621_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.dashboardyayinci_270943_value = ReactSystemFunctions.toString(
      this,
      stateVars.DB_TalepTaslakCount?.length > 0
        ? stateVars.DB_TalepTaslakCount[0].recordCount
        : this.state.DB_TalepTaslakCount?.length > 0
        ? this.state.DB_TalepTaslakCount[0].recordCount
        : null
    );

    formVars.dashboardyayinci_334687_value = ReactSystemFunctions.toString(
      this,
      stateVars.DB_TaslakOnayCount?.length > 0
        ? stateVars.DB_TaslakOnayCount[0].recordCount
        : this.state.DB_TaslakOnayCount?.length > 0
        ? this.state.DB_TaslakOnayCount[0].recordCount
        : null
    );

    formVars.dashboardyayinci_15610_value = ReactSystemFunctions.toString(
      this,
      stateVars.DB_TalepOnaylananCount?.length > 0
        ? stateVars.DB_TalepOnaylananCount[0].recordCount
        : this.state.DB_TalepOnaylananCount?.length > 0
        ? this.state.DB_TalepOnaylananCount[0].recordCount
        : null
    );

    formVars.dashboardyayinci_679666_value = ReactSystemFunctions.toString(
      this,
      stateVars.DB_TalepOnaylanmayanCount?.length > 0
        ? stateVars.DB_TalepOnaylanmayanCount[0].recordCount
        : this.state.DB_TalepOnaylanmayanCount?.length > 0
        ? this.state.DB_TalepOnaylanmayanCount[0].recordCount
        : null
    );

    formVars.dashboardyayinci_538580_value = ReactSystemFunctions.toString(
      this,
      stateVars.DB_SiparisTaslakCount?.length > 0
        ? stateVars.DB_SiparisTaslakCount[0].recordCount
        : this.state.DB_SiparisTaslakCount?.length > 0
        ? this.state.DB_SiparisTaslakCount[0].recordCount
        : null
    );

    formVars.dashboardyayinci_458955_value = ReactSystemFunctions.toString(
      this,
      stateVars.DB_SiparisOnayCount?.length > 0
        ? stateVars.DB_SiparisOnayCount[0].recordCount
        : this.state.DB_SiparisOnayCount?.length > 0
        ? this.state.DB_SiparisOnayCount[0].recordCount
        : null
    );

    formVars.dashboardyayinci_90162_value = ReactSystemFunctions.toString(
      this,
      stateVars.DB_SiparisOnaylananCount?.length > 0
        ? stateVars.DB_SiparisOnaylananCount[0].recordCount
        : this.state.DB_SiparisOnaylananCount?.length > 0
        ? this.state.DB_SiparisOnaylananCount[0].recordCount
        : null
    );

    formVars.dashboardyayinci_343134_value = ReactSystemFunctions.toString(
      this,
      stateVars.DB_SiparisOnaylanmayanCount?.length > 0
        ? stateVars.DB_SiparisOnaylanmayanCount[0].recordCount
        : this.state.DB_SiparisOnaylanmayanCount?.length > 0
        ? this.state.DB_SiparisOnaylanmayanCount[0].recordCount
        : null
    );

    formVars.dashboardyayinci_593065_value = ReactSystemFunctions.toString(
      this,
      stateVars.DB_UretimBaslayanCount?.length > 0
        ? stateVars.DB_UretimBaslayanCount[0].recordCount
        : this.state.DB_UretimBaslayanCount?.length > 0
        ? this.state.DB_UretimBaslayanCount[0].recordCount
        : null
    );

    formVars.dashboardyayinci_203160_value = ReactSystemFunctions.toString(
      this,
      stateVars.DB_UretimBitenCount?.length > 0
        ? stateVars.DB_UretimBitenCount[0].recordCount
        : this.state.DB_UretimBitenCount?.length > 0
        ? this.state.DB_UretimBitenCount[0].recordCount
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashBoardYayinciComponent_33023_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("TeklifTalepList", "tabIndex", 1);

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "DashBoardYayinci",
      "TeklifTalepList",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashBoardYayinciComponent_693053_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("TeklifTalepList", "tabIndex", 2);

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "DashBoardYayinci",
      "TeklifTalepList",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashBoardYayinciComponent_470601_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("TeklifTalepList", "tabIndex", 3);

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "DashBoardYayinci",
      "TeklifTalepList",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashBoardYayinciComponent_615218_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("TeklifTalepList", "tabIndex", 3);

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "DashBoardYayinci",
      "TeklifTalepList",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashBoardYayinciComponent_883646_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "DashBoardYayinci",
      "EseriOnaylananTeklifList",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashBoardYayinciComponent_866974_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "DashBoardYayinci",
      "MOnayBekleyenSiparisList",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashBoardYayinciComponent_740755_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "DashBoardYayinci",
      "SiparisList",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashBoardYayinciComponent_591628_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "DashBoardYayinci",
      "SiparisList",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashBoardYayinciComponent_581992_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("UretimList", "prmSiparisNo", "");
    KuikaAppManager.addToPageInputVariables("UretimList", "prmFiltre", "L");

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "DashBoardYayinci",
      "UretimList",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashBoardYayinciComponent_65297_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("UretimList", "prmSiparisNo", "");
    KuikaAppManager.addToPageInputVariables("UretimList", "prmFiltre", "B");

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "DashBoardYayinci",
      "UretimList",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashBoardYayinciComponent_678235_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("SiparisTeslimList", "prmFiltre", "L");

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "DashBoardYayinci",
      "SiparisTeslimList",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashBoardYayinciComponent_512558_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("SiparisTeslimList", "prmFiltre", "T");

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "DashBoardYayinci",
      "SiparisTeslimList",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [997406, 345236, , , , , , , , , , , ,] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.DashBoardYayinciPageInit();
    }
  }
}
