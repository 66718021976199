import { FormInstance } from "antd/es/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IBandrolStokHareket_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IBandrolStokHareket_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  MyContext: any[];
  MyContext_dummy: any[];
  EserDetay_BandrolStokById: any[];
  EserDetay_BandrolStokById_dummy: any[];
  GoBack: any;
  GoBack_dummy: any;
  EserDetay_BandrolStokSave: number;
  EserDetay_BandrolStokSave_dummy: number;
}

export class BandrolStokHareket_ScreenBase extends React.PureComponent<IBandrolStokHareket_ScreenProps, any> {
  bandrolstokhareket_451212_value_kuikaSelectBoxRef: React.RefObject<any>;
  bandrolstokhareket_49946_value_kuikaDateRef: React.RefObject<any>;
  ml = [
    {
      Id: "41cd36a4-5b07-cfca-4f7f-f48019ff4ff3",
      Name: "tr_TR",
      ShortName: "Türkçe",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "0069cee9-16ca-4a5a-aab9-dd58af96ca96", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "0d0b73c9-0d61-41d7-b10f-56a675df51ff",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 345442, PropertyName: "value", Value: "Stok Hareket Bilgileri" },
        { Id: 259236, PropertyName: "value", Value: "İşlem Tipi" },
        { Id: 451212, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 20141, PropertyName: "value", Value: "İşlem Tarihi" },
        { Id: 49946, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 649036, PropertyName: "value", Value: "İşlem Açıklama" },
        { Id: 777496, PropertyName: "placeholder", Value: "Giriniz..." },
        { Id: 177482, PropertyName: "value", Value: "Miktar (Adet)" },
        { Id: 442935, PropertyName: "placeholder", Value: "Giriniz..." },
        { Id: 750892, PropertyName: "label", Value: "Kaydet" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.bandrolstokhareket_451212_value_kuikaSelectBoxRef = React.createRef();
    this.bandrolstokhareket_49946_value_kuikaDateRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      MyContext: [],
      EserDetay_BandrolStokById: [],
      GoBack: "",
      EserDetay_BandrolStokSave: 0,
      dataSource_451212: [
        { key: "C", text: "Çıkış" },
        { key: "G", text: "Giriş" }
      ]
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("bandrolstokhareket", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.BandrolStokHareketPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("bandrolstokhareket", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("bandrolstokhareket", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.BandrolStokHareketPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      bandrolstokhareket_451212_value: this.state.EserDetay_BandrolStokById?.at?.(0)?.stokGC ?? undefined,
      bandrolstokhareket_49946_value: this.state.EserDetay_BandrolStokById?.at?.(0)?.gcTarih ?? undefined,
      bandrolstokhareket_777496_value: this.state.EserDetay_BandrolStokById?.at?.(0)?.gcAciklama ?? undefined,
      bandrolstokhareket_442935_value: this.state.EserDetay_BandrolStokById?.at?.(0)?.adet ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  BandrolStokHareketPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmID ?? this.props.screenInputs.prmid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "BandrolStokHareket/BandrolStokHareketPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    stateVars.EserDetay_BandrolStokById = result?.data.eserDetay_BandrolStokById;
    formVars.bandrolstokhareket_451212_value =
      stateVars.EserDetay_BandrolStokById?.length > 0
        ? stateVars.EserDetay_BandrolStokById[0].stokGC
        : this.state.EserDetay_BandrolStokById?.length > 0
        ? this.state.EserDetay_BandrolStokById[0].stokGC
        : null;
    formVars.bandrolstokhareket_49946_value = ReactSystemFunctions.value(
      this,
      stateVars.EserDetay_BandrolStokById?.length > 0
        ? stateVars.EserDetay_BandrolStokById[0].gcTarih
        : this.state.EserDetay_BandrolStokById?.length > 0
        ? this.state.EserDetay_BandrolStokById[0].gcTarih
        : null
    );
    formVars.bandrolstokhareket_777496_value = ReactSystemFunctions.toString(
      this,
      stateVars.EserDetay_BandrolStokById?.length > 0
        ? stateVars.EserDetay_BandrolStokById[0].gcAciklama
        : this.state.EserDetay_BandrolStokById?.length > 0
        ? this.state.EserDetay_BandrolStokById[0].gcAciklama
        : null
    );
    formVars.bandrolstokhareket_442935_value = ReactSystemFunctions.value(
      this,
      stateVars.EserDetay_BandrolStokById?.length > 0
        ? stateVars.EserDetay_BandrolStokById[0].adet
        : this.state.EserDetay_BandrolStokById?.length > 0
        ? this.state.EserDetay_BandrolStokById[0].adet
        : null
    );
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.BandrolStokHareketPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  BandrolStokHareketPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.bandrolstokhareket_451212_value = ReactSystemFunctions.toString(
      this,
      stateVars.EserDetay_BandrolStokById?.length > 0
        ? stateVars.EserDetay_BandrolStokById[0].stokGC
        : this.state.EserDetay_BandrolStokById?.length > 0
        ? this.state.EserDetay_BandrolStokById[0].stokGC
        : null
    );

    formVars.bandrolstokhareket_49946_value = ReactSystemFunctions.value(
      this,
      stateVars.EserDetay_BandrolStokById?.length > 0
        ? stateVars.EserDetay_BandrolStokById[0].gcTarih
        : this.state.EserDetay_BandrolStokById?.length > 0
        ? this.state.EserDetay_BandrolStokById[0].gcTarih
        : null
    );

    formVars.bandrolstokhareket_777496_value = ReactSystemFunctions.toString(
      this,
      stateVars.EserDetay_BandrolStokById?.length > 0
        ? stateVars.EserDetay_BandrolStokById[0].gcAciklama
        : this.state.EserDetay_BandrolStokById?.length > 0
        ? this.state.EserDetay_BandrolStokById[0].gcAciklama
        : null
    );

    formVars.bandrolstokhareket_442935_value = ReactSystemFunctions.value(
      this,
      stateVars.EserDetay_BandrolStokById?.length > 0
        ? stateVars.EserDetay_BandrolStokById[0].adet
        : this.state.EserDetay_BandrolStokById?.length > 0
        ? this.state.EserDetay_BandrolStokById[0].adet
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  BandrolStokHareketComponent_652918_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  BandrolStokHareketComponent_750892_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bandrolstokhareket_442935_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "bandrolstokhareket_442935_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bandrolstokhareket_49946_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "bandrolstokhareket_49946_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bandrolstokhareket_451212_value", "value", "", "key", "")
        ),
        null
      ),
      message: "*",
      formName: "bandrolstokhareket_451212_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Adet_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bandrolstokhareket_442935_value", "value", "", "", "")
        ),
        "number"
      ),
      EserBilgileriID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmEserBilgiID ?? this.props.screenInputs.prmeserbilgiid,
        "Guid"
      ),
      GCAciklama_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bandrolstokhareket_777496_value", "value", "", "", "")
        ),
        "string"
      ),
      GCTarih_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bandrolstokhareket_49946_value", "value", "", "", "")
        ),
        "Date"
      ),
      StokGC_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bandrolstokhareket_451212_value", "value", "", "key", "")
        ),
        "string"
      ),
      UyeID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyeId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyeId
            : null
        ),
        "Guid"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmID ?? this.props.screenInputs.prmid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "BandrolStokHareket/BandrolStokHareketComponent_750892_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.EserDetay_BandrolStokSave = result?.data.eserDetay_BandrolStokSave;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.BandrolStokHareketComponent_750892_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  BandrolStokHareketComponent_750892_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
