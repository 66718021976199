import { FormInstance } from "antd/es/form";
import { Guid } from "guid-typescript";
import moment from "moment";
import React from "react";
import Swal from "sweetalert2";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface ITedarikciTicariBilgileri_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface ITedarikciTicariBilgileri_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  Logout: boolean;
  Logout_dummy: boolean;
  goToSignInActivity: any;
  goToSignInActivity_dummy: any;
  MyContext: any[];
  MyContext_dummy: any[];
  getGuid: any[];
  getGuid_dummy: any[];
  GenelParametrelerPDF: any[];
  GenelParametrelerPDF_dummy: any[];
  UyelikFormuById: any[];
  UyelikFormuById_dummy: any[];
  SelectCityAll: any[];
  SelectCityAll_dummy: any[];
  SelectDistrictAll: any[];
  SelectDistrictAll_dummy: any[];
  UyelikFormuDuzeltmeNotuByUyeID: any[];
  UyelikFormuDuzeltmeNotuByUyeID_dummy: any[];
  ChangeEnabledOf: any;
  ChangeEnabledOf_dummy: any;
  GoBack: any;
  GoBack_dummy: any;
  DownloadFile: any;
  DownloadFile_dummy: any;
  SaveRecord: number;
  SaveRecord_dummy: number;
  UploadFile: any;
  UploadFile_dummy: any;
  POPSozlesmesiUpdate: number;
  POPSozlesmesiUpdate_dummy: number;
  SetValueOf: any;
  SetValueOf_dummy: any;
  Notify: boolean;
  Notify_dummy: boolean;
  getDownloadGuid: any[];
  getDownloadGuid_dummy: any[];
  POPSozlesmesiById: any[];
  POPSozlesmesiById_dummy: any[];
  ImzaSirkuleriUpdate: number;
  ImzaSirkuleriUpdate_dummy: number;
  ImzaSirkuleriById: any[];
  ImzaSirkuleriById_dummy: any[];
  TicariSicilGazetesiUpdate: number;
  TicariSicilGazetesiUpdate_dummy: number;
  TicariSicilGazetesiById: any[];
  TicariSicilGazetesiById_dummy: any[];
  VergiLevhasiUpdate: number;
  VergiLevhasiUpdate_dummy: number;
  VergiLevhasiById: any[];
  VergiLevhasiById_dummy: any[];
  ValidasyonKontrol: any[];
  ValidasyonKontrol_dummy: any[];
  KullaniciDetayUserIdUyeIdUpdate: number;
  KullaniciDetayUserIdUyeIdUpdate_dummy: number;
  SendMailSettings: any[];
  SendMailSettings_dummy: any[];
  SendMail: boolean;
  SendMail_dummy: boolean;
  UpdateUserStartingScreen: string;
  UpdateUserStartingScreen_dummy: string;
  PowerUserOnay: number;
  PowerUserOnay_dummy: number;
  isComp54788Visible: "visible" | "hidden";
  isComp242832Visible: "visible" | "hidden";
  isComp584293Visible: "visible" | "hidden";
  isComp392724Visible: "visible" | "hidden";
  isComp73121Visible: "visible" | "hidden";
  isComp690340Visible: "visible" | "hidden";
  isComp91906Visible: "visible" | "hidden";
  isComp724555Visible: "visible" | "hidden";
  isComp751438Visible: "visible" | "hidden";
  isComp997918Visible: "visible" | "hidden";
  isComp795456Visible: "visible" | "hidden";
  isComp637979Visible: "visible" | "hidden";
  isComp221266Visible: "visible" | "hidden";
  isComp872149Visible: "visible" | "hidden";
  isComp983752Enabled: "enabled" | "disabled";
  isComp590460Enabled: "enabled" | "disabled";
  isComp576795Enabled: "enabled" | "disabled";
  isComp27422Enabled: "enabled" | "disabled";
}

export class TedarikciTicariBilgileri_ScreenBase extends React.PureComponent<
  ITedarikciTicariBilgileri_ScreenProps,
  any
> {
  tedarikciticaribilgileri_546287_value_kuikaSelectBoxRef: React.RefObject<any>;
  tedarikciticaribilgileri_100145_value_kuikaSelectBoxRef: React.RefObject<any>;
  tedarikciticaribilgileri_54204_value_kuikaTableRef: React.RefObject<any>;
  ml = [
    {
      Id: "41cd36a4-5b07-cfca-4f7f-f48019ff4ff3",
      Name: "tr_TR",
      ShortName: "Türkçe",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "0069cee9-16ca-4a5a-aab9-dd58af96ca96", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "f8f39181-9f1f-4109-9d2f-5dbe773b429e",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 412428, PropertyName: "label", Value: "Profil" },
        { Id: 668195, PropertyName: "label", Value: "Çıkış Yap" },
        { Id: 619404, PropertyName: "value", Value: "Tedarikçi Ticari Bilgileri" },
        { Id: 532883, PropertyName: "value", Value: "Şirket Adı" },
        { Id: 983752, PropertyName: "placeholder", Value: "İmzalı POP Tedarikçi Ticari Sözleşmesi" },
        { Id: 929728, PropertyName: "value", Value: "Şirket İl" },
        { Id: 590460, PropertyName: "placeholder", Value: "İmza Sirküleri" },
        { Id: 458210, PropertyName: "value", Value: "Şirket İlçe" },
        { Id: 576795, PropertyName: "placeholder", Value: "Ticari Sicil Gazetesi" },
        { Id: 158154, PropertyName: "value", Value: "Şirket Adres" },
        { Id: 27422, PropertyName: "placeholder", Value: "Vergi Levhası" },
        { Id: 796351, PropertyName: "value", Value: "Vergi Dairesi" },
        { Id: 944498, PropertyName: "value", Value: "Vergi Kimlik Numarası" },
        { Id: 578429, PropertyName: "value", Value: "Düzeltme Notları" },
        { Id: 899075, PropertyName: "placeholder", Value: "Düzeltme isteği açıklaması giriniz." },
        { Id: 948040, PropertyName: "label", Value: "Açıklama Ekle" },
        { Id: 54204, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 183535, PropertyName: "title", Value: "Düzeltme Notları Tarihçe" },
        { Id: 385907, PropertyName: "value", Value: "[datafield:duzeltmenotu]" },
        { Id: 935825, PropertyName: "value", Value: "[datafield:userinfo]" },
        { Id: 29276, PropertyName: "label", Value: "Taslak Olarak Kaydet" },
        { Id: 887191, PropertyName: "label", Value: "Kaydet & Onaya Gönder" },
        { Id: 797679, PropertyName: "label", Value: "Düzeltme İçin Geri Gönder" },
        { Id: 717263, PropertyName: "label", Value: "Red Et" },
        { Id: 232553, PropertyName: "label", Value: "Onayla" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.tedarikciticaribilgileri_546287_value_kuikaSelectBoxRef = React.createRef();
    this.tedarikciticaribilgileri_100145_value_kuikaSelectBoxRef = React.createRef();
    this.tedarikciticaribilgileri_54204_value_kuikaTableRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      NAVIGATE: "",
      Logout: false,
      goToSignInActivity: "",
      MyContext: [],
      getGuid: [],
      GenelParametrelerPDF: [],
      UyelikFormuById: [],
      SelectCityAll: [],
      SelectDistrictAll: [],
      UyelikFormuDuzeltmeNotuByUyeID: [],
      ChangeEnabledOf: "",
      GoBack: "",
      DownloadFile: "",
      SaveRecord: false,
      UploadFile: "",
      POPSozlesmesiUpdate: 0,
      SetValueOf: "",
      Notify: false,
      getDownloadGuid: [],
      POPSozlesmesiById: [],
      ImzaSirkuleriUpdate: 0,
      ImzaSirkuleriById: [],
      TicariSicilGazetesiUpdate: 0,
      TicariSicilGazetesiById: [],
      VergiLevhasiUpdate: 0,
      VergiLevhasiById: [],
      ValidasyonKontrol: [],
      KullaniciDetayUserIdUyeIdUpdate: 0,
      SendMailSettings: [],
      SendMail: false,
      UpdateUserStartingScreen: "",
      PowerUserOnay: 0,
      isComp54788Visible: "hidden",
      isComp242832Visible: "hidden",
      isComp584293Visible: "hidden",
      isComp392724Visible: "hidden",
      isComp73121Visible: "hidden",
      isComp690340Visible: "hidden",
      isComp91906Visible: "hidden",
      isComp724555Visible: "hidden",
      isComp751438Visible: "hidden",
      isComp997918Visible: "hidden",
      isComp795456Visible: "hidden",
      isComp637979Visible: "hidden",
      isComp221266Visible: "hidden",
      isComp872149Visible: "hidden",
      isComp983752Enabled: "enabled",
      isComp590460Enabled: "enabled",
      isComp576795Enabled: "enabled",
      isComp27422Enabled: "enabled"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("tedarikciticaribilgileri", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.TedarikciTicariBilgileriPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("tedarikciticaribilgileri", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("tedarikciticaribilgileri", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.TedarikciTicariBilgileriPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      tedarikciticaribilgileri_936868_value: this.state.UyelikFormuById?.at?.(0)?.sirketAd ?? undefined,
      tedarikciticaribilgileri_983752_value: this.state.UyelikFormuById?.at?.(0)?.popSozlesmesiDosyaAd ?? undefined,
      tedarikciticaribilgileri_546287_value: this.state.UyelikFormuById?.at?.(0)?.cityID ?? undefined,
      tedarikciticaribilgileri_590460_value: this.state.UyelikFormuById?.at?.(0)?.imzaSirkuleriDosyaAd ?? undefined,
      tedarikciticaribilgileri_100145_value: this.state.UyelikFormuById?.at?.(0)?.districtID ?? undefined,
      tedarikciticaribilgileri_576795_value:
        this.state.UyelikFormuById?.at?.(0)?.ticariSicilGazetesiDosyaAd ?? undefined,
      tedarikciticaribilgileri_909820_value: this.state.UyelikFormuById?.at?.(0)?.adres ?? undefined,
      tedarikciticaribilgileri_27422_value: this.state.UyelikFormuById?.at?.(0)?.vergiLevhasiDosyaAd ?? undefined,
      tedarikciticaribilgileri_158191_value: this.state.UyelikFormuById?.at?.(0)?.vergiDairesi ?? undefined,
      tedarikciticaribilgileri_541657_value: this.state.UyelikFormuById?.at?.(0)?.vergiKimlikNo ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  TedarikciTicariBilgileriPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      prmID_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmID ?? this.props.screenInputs.prmid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "TedarikciTicariBilgileri/TedarikciTicariBilgileriPageInit",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    formVars.tedarikciticaribilgileri_172357_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0
        ? stateVars.MyContext[0].fullName
        : this.state.MyContext?.length > 0
        ? this.state.MyContext[0].fullName
        : null
    );
    stateVars.isComp54788Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].powerUserOnay
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].powerUserOnay
            : null
        ),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp584293Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].rolName
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].rolName
            : null
        ),
        "POWERUSER"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp392724Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].rolName
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].rolName
            : null
        ),
        "POWERUSER"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp73121Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].rolName
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].rolName
            : null
        ),
        "POWERUSER"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp690340Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].rolName
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].rolName
            : null
        ),
        "POWERUSER"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp91906Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].rolName
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].rolName
            : null
        ),
        "POWERUSER"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp724555Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].rolName
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].rolName
            : null
        ),
        "POWERUSER"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp751438Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].rolName
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].rolName
            : null
        ),
        "POWERUSER"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp997918Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].rolName
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].rolName
            : null
        ),
        "POWERUSER"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp795456Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].rolName
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].rolName
            : null
        ),
        "POWERUSER"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.getGuid = result?.data.getGuid;
    stateVars.GenelParametrelerPDF = result?.data.genelParametrelerPDF;
    stateVars.UyelikFormuById = result?.data.uyelikFormuById;
    formVars.tedarikciticaribilgileri_936868_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0
        ? stateVars.UyelikFormuById[0].sirketAd
        : this.state.UyelikFormuById?.length > 0
        ? this.state.UyelikFormuById[0].sirketAd
        : null
    );
    formVars.tedarikciticaribilgileri_983752_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0
        ? stateVars.UyelikFormuById[0].popSozlesmesiDosyaAd
        : this.state.UyelikFormuById?.length > 0
        ? this.state.UyelikFormuById[0].popSozlesmesiDosyaAd
        : null
    );
    formVars.tedarikciticaribilgileri_546287_value =
      stateVars.UyelikFormuById?.length > 0
        ? stateVars.UyelikFormuById[0].cityID
        : this.state.UyelikFormuById?.length > 0
        ? this.state.UyelikFormuById[0].cityID
        : null;
    formVars.tedarikciticaribilgileri_546287_options = stateVars.SelectCityAll;
    formVars.tedarikciticaribilgileri_590460_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0
        ? stateVars.UyelikFormuById[0].imzaSirkuleriDosyaAd
        : this.state.UyelikFormuById?.length > 0
        ? this.state.UyelikFormuById[0].imzaSirkuleriDosyaAd
        : null
    );
    formVars.tedarikciticaribilgileri_100145_value =
      stateVars.UyelikFormuById?.length > 0
        ? stateVars.UyelikFormuById[0].districtID
        : this.state.UyelikFormuById?.length > 0
        ? this.state.UyelikFormuById[0].districtID
        : null;
    formVars.tedarikciticaribilgileri_100145_options = stateVars.SelectDistrictAll;
    formVars.tedarikciticaribilgileri_576795_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0
        ? stateVars.UyelikFormuById[0].ticariSicilGazetesiDosyaAd
        : this.state.UyelikFormuById?.length > 0
        ? this.state.UyelikFormuById[0].ticariSicilGazetesiDosyaAd
        : null
    );
    formVars.tedarikciticaribilgileri_909820_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0
        ? stateVars.UyelikFormuById[0].adres
        : this.state.UyelikFormuById?.length > 0
        ? this.state.UyelikFormuById[0].adres
        : null
    );
    formVars.tedarikciticaribilgileri_27422_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0
        ? stateVars.UyelikFormuById[0].vergiLevhasiDosyaAd
        : this.state.UyelikFormuById?.length > 0
        ? this.state.UyelikFormuById[0].vergiLevhasiDosyaAd
        : null
    );
    formVars.tedarikciticaribilgileri_158191_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0
        ? stateVars.UyelikFormuById[0].vergiDairesi
        : this.state.UyelikFormuById?.length > 0
        ? this.state.UyelikFormuById[0].vergiDairesi
        : null
    );
    formVars.tedarikciticaribilgileri_541657_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0
        ? stateVars.UyelikFormuById[0].vergiKimlikNo
        : this.state.UyelikFormuById?.length > 0
        ? this.state.UyelikFormuById[0].vergiKimlikNo
        : null
    );
    stateVars.isComp637979Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0
            ? stateVars.UyelikFormuById[0].uyelikDurumKey
            : this.state.UyelikFormuById?.length > 0
            ? this.state.UyelikFormuById[0].uyelikDurumKey
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.SelectCityAll = result?.data.selectCityAll;

    formVars.tedarikciticaribilgileri_546287_value =
      stateVars.UyelikFormuById?.length > 0
        ? stateVars.UyelikFormuById[0].cityID
        : this.state.UyelikFormuById?.length > 0
        ? this.state.UyelikFormuById[0].cityID
        : null;
    formVars.tedarikciticaribilgileri_546287_options = stateVars.SelectCityAll;
    stateVars.SelectDistrictAll = result?.data.selectDistrictAll;

    formVars.tedarikciticaribilgileri_100145_value =
      stateVars.UyelikFormuById?.length > 0
        ? stateVars.UyelikFormuById[0].districtID
        : this.state.UyelikFormuById?.length > 0
        ? this.state.UyelikFormuById[0].districtID
        : null;
    formVars.tedarikciticaribilgileri_100145_options = stateVars.SelectDistrictAll;
    stateVars.UyelikFormuDuzeltmeNotuByUyeID = result?.data.uyelikFormuDuzeltmeNotuByUyeID;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TedarikciTicariBilgileriPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TedarikciTicariBilgileriPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp983752Enabled", "disabled");
    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp590460Enabled", "disabled");
    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp576795Enabled", "disabled");
    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp27422Enabled", "disabled");
    stateVars.isComp54788Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].powerUserOnay
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].powerUserOnay
            : null
        ),
        true
      ) === true
        ? "visible"
        : "hidden";
    formVars.tedarikciticaribilgileri_172357_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0
        ? stateVars.MyContext[0].fullName
        : this.state.MyContext?.length > 0
        ? this.state.MyContext[0].fullName
        : null
    );

    stateVars.isComp242832Visible =
      ReactSystemFunctions.isNotEmpty(this.props.screenInputs.prmID ?? this.props.screenInputs.prmid, null) === true
        ? "visible"
        : "hidden";
    formVars.tedarikciticaribilgileri_936868_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0
        ? stateVars.UyelikFormuById[0].sirketAd
        : this.state.UyelikFormuById?.length > 0
        ? this.state.UyelikFormuById[0].sirketAd
        : null
    );

    formVars.tedarikciticaribilgileri_983752_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0
        ? stateVars.UyelikFormuById[0].popSozlesmesiDosyaAd
        : this.state.UyelikFormuById?.length > 0
        ? this.state.UyelikFormuById[0].popSozlesmesiDosyaAd
        : null
    );

    stateVars.isComp584293Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].rolName
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].rolName
            : null
        ),
        "POWERUSER"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp392724Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].rolName
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].rolName
            : null
        ),
        "POWERUSER"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp73121Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].rolName
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].rolName
            : null
        ),
        "POWERUSER"
      ) === true
        ? "visible"
        : "hidden";
    formVars.tedarikciticaribilgileri_546287_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0
        ? stateVars.UyelikFormuById[0].cityID
        : this.state.UyelikFormuById?.length > 0
        ? this.state.UyelikFormuById[0].cityID
        : null
    );

    stateVars.dataSource_546287 = this.state.SelectCityAll;
    stateVars.dataSource_546287 = this.state.SelectCityAll;
    formVars.tedarikciticaribilgileri_590460_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0
        ? stateVars.UyelikFormuById[0].imzaSirkuleriDosyaAd
        : this.state.UyelikFormuById?.length > 0
        ? this.state.UyelikFormuById[0].imzaSirkuleriDosyaAd
        : null
    );

    stateVars.isComp690340Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].rolName
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].rolName
            : null
        ),
        "POWERUSER"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp91906Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].rolName
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].rolName
            : null
        ),
        "POWERUSER"
      ) === true
        ? "visible"
        : "hidden";
    formVars.tedarikciticaribilgileri_100145_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0
        ? stateVars.UyelikFormuById[0].districtID
        : this.state.UyelikFormuById?.length > 0
        ? this.state.UyelikFormuById[0].districtID
        : null
    );

    stateVars.dataSource_100145 = this.state.SelectDistrictAll;
    stateVars.dataSource_100145 = this.state.SelectDistrictAll;
    formVars.tedarikciticaribilgileri_576795_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0
        ? stateVars.UyelikFormuById[0].ticariSicilGazetesiDosyaAd
        : this.state.UyelikFormuById?.length > 0
        ? this.state.UyelikFormuById[0].ticariSicilGazetesiDosyaAd
        : null
    );

    stateVars.isComp724555Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].rolName
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].rolName
            : null
        ),
        "POWERUSER"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp751438Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].rolName
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].rolName
            : null
        ),
        "POWERUSER"
      ) === true
        ? "visible"
        : "hidden";
    formVars.tedarikciticaribilgileri_909820_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0
        ? stateVars.UyelikFormuById[0].adres
        : this.state.UyelikFormuById?.length > 0
        ? this.state.UyelikFormuById[0].adres
        : null
    );

    formVars.tedarikciticaribilgileri_27422_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0
        ? stateVars.UyelikFormuById[0].vergiLevhasiDosyaAd
        : this.state.UyelikFormuById?.length > 0
        ? this.state.UyelikFormuById[0].vergiLevhasiDosyaAd
        : null
    );

    stateVars.isComp997918Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].rolName
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].rolName
            : null
        ),
        "POWERUSER"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp795456Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].rolName
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].rolName
            : null
        ),
        "POWERUSER"
      ) === true
        ? "visible"
        : "hidden";
    formVars.tedarikciticaribilgileri_158191_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0
        ? stateVars.UyelikFormuById[0].vergiDairesi
        : this.state.UyelikFormuById?.length > 0
        ? this.state.UyelikFormuById[0].vergiDairesi
        : null
    );

    formVars.tedarikciticaribilgileri_541657_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0
        ? stateVars.UyelikFormuById[0].vergiKimlikNo
        : this.state.UyelikFormuById?.length > 0
        ? this.state.UyelikFormuById[0].vergiKimlikNo
        : null
    );

    stateVars.isComp637979Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0
            ? stateVars.UyelikFormuById[0].uyelikDurumKey
            : this.state.UyelikFormuById?.length > 0
            ? this.state.UyelikFormuById[0].uyelikDurumKey
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";

    stateVars.dataSource_54204 = this.state.UyelikFormuDuzeltmeNotuByUyeID;
    stateVars.isComp221266Visible =
      ReactSystemFunctions.isEmpty(this.props.screenInputs.prmID ?? this.props.screenInputs.prmid, null) === true
        ? "visible"
        : "hidden";
    stateVars.isComp872149Visible =
      ReactSystemFunctions.isNotEmpty(this.props.screenInputs.prmID ?? this.props.screenInputs.prmid, null) === true
        ? "visible"
        : "hidden";

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  TedarikciTicariBilgileriComponent_54788_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "TedarikciTicariBilgileri",
      "LeftMenu",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "412866",
      null,
      "left",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TedarikciTicariBilgileriComponent_412428_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "TedarikciTicariBilgileri",
      "Profil",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TedarikciTicariBilgileriComponent_668195_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.Logout = await ReactSystemFunctions.logout();
    stateVars.tmpBoolResult = await ReactSystemFunctions.goToSignInActivity(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TedarikciTicariBilgileriComponent_242832_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TedarikciTicariBilgileriComponent_584293_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.DownloadFile = await ReactSystemFunctions.downloadFile(
      ReactSystemFunctions.toString(
        this,
        stateVars.GenelParametrelerPDF?.length > 0
          ? stateVars.GenelParametrelerPDF[0].ornekPOPSertifikaURLTedarikciTicari
          : this.state.GenelParametrelerPDF?.length > 0
          ? this.state.GenelParametrelerPDF[0].ornekPOPSertifikaURLTedarikciTicari
          : null
      ),
      "TedarikçiSozlesmesi.pdf"
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TedarikciTicariBilgileriComponent_392724_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tedarikciticaribilgileri_936868_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "tedarikciticaribilgileri_936868_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tedarikciticaribilgileri_546287_value", "value", "SelectCityAll", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "tedarikciticaribilgileri_546287_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "tedarikciticaribilgileri_100145_value",
            "value",
            "SelectDistrictAll",
            "id",
            ""
          )
        ),
        null
      ),
      message: "*",
      formName: "tedarikciticaribilgileri_100145_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tedarikciticaribilgileri_909820_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "tedarikciticaribilgileri_909820_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tedarikciticaribilgileri_158191_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "tedarikciticaribilgileri_158191_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tedarikciticaribilgileri_541657_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "tedarikciticaribilgileri_541657_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.getGuid?.length > 0
            ? stateVars.getGuid[0].getGuid
            : this.state.getGuid?.length > 0
            ? this.state.getGuid[0].getGuid
            : null
        ),
        "Guid"
      ),
      SirketAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tedarikciticaribilgileri_936868_value", "value", "", "", "")
        ),
        "string"
      ),
      CityID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "tedarikciticaribilgileri_546287_value",
            "value",
            "SelectCityAll",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      DistrictID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "tedarikciticaribilgileri_100145_value",
            "value",
            "SelectDistrictAll",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      Adres_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tedarikciticaribilgileri_909820_value", "value", "", "", "")
        ),
        "string"
      ),
      VergiDairesi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tedarikciticaribilgileri_158191_value", "value", "", "", "")
        ),
        "string"
      ),
      VergiKimlikNo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tedarikciticaribilgileri_541657_value", "value", "", "", "")
        ),
        "string"
      ),
      KUserId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kUserId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kUserId
            : null
        ),
        "Guid"
      ),
      BasvuruTarihi_0: ReactSystemFunctions.convertToTypeByName(moment().format("YYYY-MM-DDTHH:mm:ss"), "Date"),
      PowerUserOnay_0: ReactSystemFunctions.convertToTypeByName(false, "boolean"),
      UyelikDurumKey_0: ReactSystemFunctions.convertToTypeByName(null, "number")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "TedarikciTicariBilgileri/TedarikciTicariBilgileriComponent_392724_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_1;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TedarikciTicariBilgileriComponent_392724_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TedarikciTicariBilgileriComponent_392724_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.UploadFile = ((await ReactSystemFunctions.uploadFileWithInfo()) as any).data;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TedarikciTicariBilgileriComponent_392724_onClick2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  TedarikciTicariBilgileriComponent_392724_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      POPSozlesmesi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.data),
        "string"
      ),
      POPSozlesmesiDosyaAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.name),
        "string"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.getGuid?.length > 0
            ? stateVars.getGuid[0].getGuid
            : this.state.getGuid?.length > 0
            ? this.state.getGuid[0].getGuid
            : null
        ),
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "TedarikciTicariBilgileri/TedarikciTicariBilgileriComponent_392724_onClick2_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.POPSozlesmesiUpdate = result?.data.popSozlesmesiUpdate;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TedarikciTicariBilgileriComponent_392724_onClick3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TedarikciTicariBilgileriComponent_392724_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "tedarikciticaribilgileri_983752_value",
      ReactSystemFunctions.toString(this, this.state.UploadFile?.name),
      null
    );
    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "febf7d3f_5545_bac1_c1fc_24dc5fa36591_notify",
        this.defaultML,
        "Belge Yüklendi!"
      ),
      "success",
      "bottom-right",
      2,
      null,
      null,
      null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TedarikciTicariBilgileriComponent_577122_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      prmID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmID ?? this.props.screenInputs.prmid,
        "Guid"
      ),
      getGuid_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.getGuid?.length > 0
            ? stateVars.getGuid[0].getGuid
            : this.state.getGuid?.length > 0
            ? this.state.getGuid[0].getGuid
            : null
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "TedarikciTicariBilgileri/TedarikciTicariBilgileriComponent_577122_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getDownloadGuid = result?.data.getDownloadGuid;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TedarikciTicariBilgileriComponent_577122_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TedarikciTicariBilgileriComponent_577122_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "PDFViewerFormsPOPSozlesme",
      "prmGuid",
      ReactSystemFunctions.value(
        this,
        stateVars.getDownloadGuid?.length > 0
          ? stateVars.getDownloadGuid[0].getDownloadGuid
          : this.state.getDownloadGuid?.length > 0
          ? this.state.getDownloadGuid[0].getDownloadGuid
          : null
      )
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "TedarikciTicariBilgileri",
      "PDFViewerFormsPOPSozlesme",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "112560",
      null,
      "left",
      null,
      "45%",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TedarikciTicariBilgileriComponent_73121_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      prmID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmID ?? this.props.screenInputs.prmid,
        "Guid"
      ),
      getGuid_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.getGuid?.length > 0
            ? stateVars.getGuid[0].getGuid
            : this.state.getGuid?.length > 0
            ? this.state.getGuid[0].getGuid
            : null
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "TedarikciTicariBilgileri/TedarikciTicariBilgileriComponent_73121_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getDownloadGuid = result?.data.getDownloadGuid;
    stateVars.POPSozlesmesiById = result?.data.popSozlesmesiById;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TedarikciTicariBilgileriComponent_73121_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TedarikciTicariBilgileriComponent_73121_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.DownloadFile = await ReactSystemFunctions.downloadFile(
      ReactSystemFunctions.toString(
        this,
        stateVars.POPSozlesmesiById?.length > 0
          ? stateVars.POPSozlesmesiById[0].popSozlesmesi
          : this.state.POPSozlesmesiById?.length > 0
          ? this.state.POPSozlesmesiById[0].popSozlesmesi
          : null
      ),
      ReactSystemFunctions.toString(
        this,
        stateVars.POPSozlesmesiById?.length > 0
          ? stateVars.POPSozlesmesiById[0].popSozlesmesiDosyaAd
          : this.state.POPSozlesmesiById?.length > 0
          ? this.state.POPSozlesmesiById[0].popSozlesmesiDosyaAd
          : null
      )
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TedarikciTicariBilgileriComponent_546287_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tedarikciticaribilgileri_546287_value", "value", "SelectCityAll", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "tedarikciticaribilgileri_546287_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      CityID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "tedarikciticaribilgileri_546287_value",
            "value",
            "SelectCityAll",
            "id",
            "id"
          )
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "TedarikciTicariBilgileri/TedarikciTicariBilgileriComponent_546287_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SelectDistrictAll = result?.data.selectDistrictAll;

    formVars.tedarikciticaribilgileri_100145_options = stateVars.SelectDistrictAll;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TedarikciTicariBilgileriComponent_546287_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TedarikciTicariBilgileriComponent_546287_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_100145 = this.state.SelectDistrictAll;
    stateVars.dataSource_100145 = this.state.SelectDistrictAll;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TedarikciTicariBilgileriComponent_690340_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tedarikciticaribilgileri_936868_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "tedarikciticaribilgileri_936868_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tedarikciticaribilgileri_546287_value", "value", "SelectCityAll", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "tedarikciticaribilgileri_546287_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "tedarikciticaribilgileri_100145_value",
            "value",
            "SelectDistrictAll",
            "id",
            ""
          )
        ),
        null
      ),
      message: "*",
      formName: "tedarikciticaribilgileri_100145_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tedarikciticaribilgileri_909820_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "tedarikciticaribilgileri_909820_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tedarikciticaribilgileri_158191_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "tedarikciticaribilgileri_158191_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tedarikciticaribilgileri_541657_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "tedarikciticaribilgileri_541657_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.getGuid?.length > 0
            ? stateVars.getGuid[0].getGuid
            : this.state.getGuid?.length > 0
            ? this.state.getGuid[0].getGuid
            : null
        ),
        "Guid"
      ),
      SirketAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tedarikciticaribilgileri_936868_value", "value", "", "", "")
        ),
        "string"
      ),
      CityID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "tedarikciticaribilgileri_546287_value",
            "value",
            "SelectCityAll",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      DistrictID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "tedarikciticaribilgileri_100145_value",
            "value",
            "SelectDistrictAll",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      Adres_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tedarikciticaribilgileri_909820_value", "value", "", "", "")
        ),
        "string"
      ),
      VergiDairesi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tedarikciticaribilgileri_158191_value", "value", "", "", "")
        ),
        "string"
      ),
      VergiKimlikNo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tedarikciticaribilgileri_541657_value", "value", "", "", "")
        ),
        "string"
      ),
      KUserId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kUserId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kUserId
            : null
        ),
        "Guid"
      ),
      BasvuruTarihi_0: ReactSystemFunctions.convertToTypeByName(moment().format("YYYY-MM-DDTHH:mm:ss"), "Date"),
      PowerUserOnay_0: ReactSystemFunctions.convertToTypeByName(false, "boolean")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "TedarikciTicariBilgileri/TedarikciTicariBilgileriComponent_690340_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_2;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TedarikciTicariBilgileriComponent_690340_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TedarikciTicariBilgileriComponent_690340_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.UploadFile = ((await ReactSystemFunctions.uploadFileWithInfo()) as any).data;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TedarikciTicariBilgileriComponent_690340_onClick2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  TedarikciTicariBilgileriComponent_690340_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      ImzaSirkuleri_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.data),
        "string"
      ),
      ImzaSirkuleriDosyaAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.name),
        "string"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.getGuid?.length > 0
            ? stateVars.getGuid[0].getGuid
            : this.state.getGuid?.length > 0
            ? this.state.getGuid[0].getGuid
            : null
        ),
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "TedarikciTicariBilgileri/TedarikciTicariBilgileriComponent_690340_onClick2_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.ImzaSirkuleriUpdate = result?.data.imzaSirkuleriUpdate;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TedarikciTicariBilgileriComponent_690340_onClick3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TedarikciTicariBilgileriComponent_690340_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "tedarikciticaribilgileri_590460_value",
      ReactSystemFunctions.toString(this, this.state.UploadFile?.name),
      null
    );
    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "4ab46644_159c_1741_4353_2da262be4378_notify",
        this.defaultML,
        "Belge Yüklendi!"
      ),
      "success",
      "bottom-right",
      2,
      null,
      null,
      null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TedarikciTicariBilgileriComponent_428636_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      prmID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmID ?? this.props.screenInputs.prmid,
        "Guid"
      ),
      getGuid_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.getGuid?.length > 0
            ? stateVars.getGuid[0].getGuid
            : this.state.getGuid?.length > 0
            ? this.state.getGuid[0].getGuid
            : null
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "TedarikciTicariBilgileri/TedarikciTicariBilgileriComponent_428636_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getDownloadGuid = result?.data.getDownloadGuid;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TedarikciTicariBilgileriComponent_428636_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TedarikciTicariBilgileriComponent_428636_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "PDFViewerFormsImza",
      "prmGuid",
      ReactSystemFunctions.value(
        this,
        stateVars.getDownloadGuid?.length > 0
          ? stateVars.getDownloadGuid[0].getDownloadGuid
          : this.state.getDownloadGuid?.length > 0
          ? this.state.getDownloadGuid[0].getDownloadGuid
          : null
      )
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "TedarikciTicariBilgileri",
      "PDFViewerFormsImza",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "429152",
      null,
      "left",
      null,
      "45%",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TedarikciTicariBilgileriComponent_91906_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      prmID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmID ?? this.props.screenInputs.prmid,
        "Guid"
      ),
      getGuid_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.getGuid?.length > 0
            ? stateVars.getGuid[0].getGuid
            : this.state.getGuid?.length > 0
            ? this.state.getGuid[0].getGuid
            : null
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "TedarikciTicariBilgileri/TedarikciTicariBilgileriComponent_91906_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getDownloadGuid = result?.data.getDownloadGuid;
    stateVars.ImzaSirkuleriById = result?.data.imzaSirkuleriById;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TedarikciTicariBilgileriComponent_91906_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TedarikciTicariBilgileriComponent_91906_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.DownloadFile = await ReactSystemFunctions.downloadFile(
      ReactSystemFunctions.toString(
        this,
        stateVars.ImzaSirkuleriById?.length > 0
          ? stateVars.ImzaSirkuleriById[0].imzaSirkuleri
          : this.state.ImzaSirkuleriById?.length > 0
          ? this.state.ImzaSirkuleriById[0].imzaSirkuleri
          : null
      ),
      ReactSystemFunctions.toString(
        this,
        stateVars.ImzaSirkuleriById?.length > 0
          ? stateVars.ImzaSirkuleriById[0].imzaSirkuleriDosyaAd
          : this.state.ImzaSirkuleriById?.length > 0
          ? this.state.ImzaSirkuleriById[0].imzaSirkuleriDosyaAd
          : null
      )
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TedarikciTicariBilgileriComponent_724555_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tedarikciticaribilgileri_936868_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "tedarikciticaribilgileri_936868_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tedarikciticaribilgileri_546287_value", "value", "SelectCityAll", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "tedarikciticaribilgileri_546287_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "tedarikciticaribilgileri_100145_value",
            "value",
            "SelectDistrictAll",
            "id",
            ""
          )
        ),
        null
      ),
      message: "*",
      formName: "tedarikciticaribilgileri_100145_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tedarikciticaribilgileri_909820_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "tedarikciticaribilgileri_909820_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tedarikciticaribilgileri_158191_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "tedarikciticaribilgileri_158191_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tedarikciticaribilgileri_541657_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "tedarikciticaribilgileri_541657_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.getGuid?.length > 0
            ? stateVars.getGuid[0].getGuid
            : this.state.getGuid?.length > 0
            ? this.state.getGuid[0].getGuid
            : null
        ),
        "Guid"
      ),
      SirketAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tedarikciticaribilgileri_936868_value", "value", "", "", "")
        ),
        "string"
      ),
      CityID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "tedarikciticaribilgileri_546287_value",
            "value",
            "SelectCityAll",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      DistrictID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "tedarikciticaribilgileri_100145_value",
            "value",
            "SelectDistrictAll",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      Adres_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tedarikciticaribilgileri_909820_value", "value", "", "", "")
        ),
        "string"
      ),
      VergiDairesi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tedarikciticaribilgileri_158191_value", "value", "", "", "")
        ),
        "string"
      ),
      VergiKimlikNo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tedarikciticaribilgileri_541657_value", "value", "", "", "")
        ),
        "string"
      ),
      KUserId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kUserId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kUserId
            : null
        ),
        "Guid"
      ),
      BasvuruTarihi_0: ReactSystemFunctions.convertToTypeByName(moment().format("YYYY-MM-DDTHH:mm:ss"), "Date"),
      PowerUserOnay_0: ReactSystemFunctions.convertToTypeByName(false, "boolean")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "TedarikciTicariBilgileri/TedarikciTicariBilgileriComponent_724555_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_3;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TedarikciTicariBilgileriComponent_724555_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TedarikciTicariBilgileriComponent_724555_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.UploadFile = ((await ReactSystemFunctions.uploadFileWithInfo()) as any).data;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TedarikciTicariBilgileriComponent_724555_onClick2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  TedarikciTicariBilgileriComponent_724555_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      TicariSicilGazetesiData_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.data),
        "string"
      ),
      TicariSicilGazetesiDosyaAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.name),
        "string"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.getGuid?.length > 0
            ? stateVars.getGuid[0].getGuid
            : this.state.getGuid?.length > 0
            ? this.state.getGuid[0].getGuid
            : null
        ),
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "TedarikciTicariBilgileri/TedarikciTicariBilgileriComponent_724555_onClick2_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.TicariSicilGazetesiUpdate = result?.data.ticariSicilGazetesiUpdate;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TedarikciTicariBilgileriComponent_724555_onClick3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TedarikciTicariBilgileriComponent_724555_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "tedarikciticaribilgileri_576795_value",
      ReactSystemFunctions.toString(this, this.state.UploadFile?.name),
      null
    );
    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "14378aa8_10e2_53e2_60f0_3e00a8858ac9_notify",
        this.defaultML,
        "Belge Yüklendi!"
      ),
      "success",
      "bottom-right",
      2,
      null,
      null,
      null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TedarikciTicariBilgileriComponent_457110_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      prmID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmID ?? this.props.screenInputs.prmid,
        "Guid"
      ),
      getGuid_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.getGuid?.length > 0
            ? stateVars.getGuid[0].getGuid
            : this.state.getGuid?.length > 0
            ? this.state.getGuid[0].getGuid
            : null
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "TedarikciTicariBilgileri/TedarikciTicariBilgileriComponent_457110_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getDownloadGuid = result?.data.getDownloadGuid;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TedarikciTicariBilgileriComponent_457110_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TedarikciTicariBilgileriComponent_457110_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "PDFViewerFormsTicariSicilGazete",
      "prmGuid",
      ReactSystemFunctions.value(
        this,
        stateVars.getDownloadGuid?.length > 0
          ? stateVars.getDownloadGuid[0].getDownloadGuid
          : this.state.getDownloadGuid?.length > 0
          ? this.state.getDownloadGuid[0].getDownloadGuid
          : null
      )
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "TedarikciTicariBilgileri",
      "PDFViewerFormsTicariSicilGazete",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "675723",
      null,
      "left",
      null,
      "45%",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TedarikciTicariBilgileriComponent_751438_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      prmID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmID ?? this.props.screenInputs.prmid,
        "Guid"
      ),
      getGuid_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.getGuid?.length > 0
            ? stateVars.getGuid[0].getGuid
            : this.state.getGuid?.length > 0
            ? this.state.getGuid[0].getGuid
            : null
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "TedarikciTicariBilgileri/TedarikciTicariBilgileriComponent_751438_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getDownloadGuid = result?.data.getDownloadGuid;
    stateVars.TicariSicilGazetesiById = result?.data.ticariSicilGazetesiById;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TedarikciTicariBilgileriComponent_751438_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TedarikciTicariBilgileriComponent_751438_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.DownloadFile = await ReactSystemFunctions.downloadFile(
      ReactSystemFunctions.toString(
        this,
        stateVars.TicariSicilGazetesiById?.length > 0
          ? stateVars.TicariSicilGazetesiById[0].ticariSicilGazetesi
          : this.state.TicariSicilGazetesiById?.length > 0
          ? this.state.TicariSicilGazetesiById[0].ticariSicilGazetesi
          : null
      ),
      ReactSystemFunctions.toString(
        this,
        stateVars.TicariSicilGazetesiById?.length > 0
          ? stateVars.TicariSicilGazetesiById[0].ticariSicilGazetesiDosyaAd
          : this.state.TicariSicilGazetesiById?.length > 0
          ? this.state.TicariSicilGazetesiById[0].ticariSicilGazetesiDosyaAd
          : null
      )
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TedarikciTicariBilgileriComponent_997918_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tedarikciticaribilgileri_936868_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "tedarikciticaribilgileri_936868_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tedarikciticaribilgileri_546287_value", "value", "SelectCityAll", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "tedarikciticaribilgileri_546287_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "tedarikciticaribilgileri_100145_value",
            "value",
            "SelectDistrictAll",
            "id",
            ""
          )
        ),
        null
      ),
      message: "*",
      formName: "tedarikciticaribilgileri_100145_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tedarikciticaribilgileri_909820_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "tedarikciticaribilgileri_909820_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tedarikciticaribilgileri_158191_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "tedarikciticaribilgileri_158191_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tedarikciticaribilgileri_541657_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "tedarikciticaribilgileri_541657_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.getGuid?.length > 0
            ? stateVars.getGuid[0].getGuid
            : this.state.getGuid?.length > 0
            ? this.state.getGuid[0].getGuid
            : null
        ),
        "Guid"
      ),
      SirketAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tedarikciticaribilgileri_936868_value", "value", "", "", "")
        ),
        "string"
      ),
      CityID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "tedarikciticaribilgileri_546287_value",
            "value",
            "SelectCityAll",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      DistrictID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "tedarikciticaribilgileri_100145_value",
            "value",
            "SelectDistrictAll",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      Adres_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tedarikciticaribilgileri_909820_value", "value", "", "", "")
        ),
        "string"
      ),
      VergiDairesi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tedarikciticaribilgileri_158191_value", "value", "", "", "")
        ),
        "string"
      ),
      VergiKimlikNo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tedarikciticaribilgileri_541657_value", "value", "", "", "")
        ),
        "string"
      ),
      KUserId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kUserId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kUserId
            : null
        ),
        "Guid"
      ),
      BasvuruTarihi_0: ReactSystemFunctions.convertToTypeByName(moment().format("YYYY-MM-DDTHH:mm:ss"), "Date"),
      PowerUserOnay_0: ReactSystemFunctions.convertToTypeByName(false, "boolean")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "TedarikciTicariBilgileri/TedarikciTicariBilgileriComponent_997918_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_4;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TedarikciTicariBilgileriComponent_997918_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TedarikciTicariBilgileriComponent_997918_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.UploadFile = ((await ReactSystemFunctions.uploadFileWithInfo()) as any).data;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TedarikciTicariBilgileriComponent_997918_onClick2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  TedarikciTicariBilgileriComponent_997918_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      VergiLevhasiData_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.data),
        "string"
      ),
      VergiLevhasiDosyaAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.name),
        "string"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.getGuid?.length > 0
            ? stateVars.getGuid[0].getGuid
            : this.state.getGuid?.length > 0
            ? this.state.getGuid[0].getGuid
            : null
        ),
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "TedarikciTicariBilgileri/TedarikciTicariBilgileriComponent_997918_onClick2_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.VergiLevhasiUpdate = result?.data.vergiLevhasiUpdate;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TedarikciTicariBilgileriComponent_997918_onClick3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TedarikciTicariBilgileriComponent_997918_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "tedarikciticaribilgileri_27422_value",
      ReactSystemFunctions.toString(this, this.state.UploadFile?.name),
      null
    );
    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "efc772bd_7ec4_78eb_4f6b_4b7c2ead257f_notify",
        this.defaultML,
        "Belge Yüklendi!"
      ),
      "success",
      "bottom-right",
      2,
      null,
      null,
      null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TedarikciTicariBilgileriComponent_166808_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      prmID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmID ?? this.props.screenInputs.prmid,
        "Guid"
      ),
      getGuid_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.getGuid?.length > 0
            ? stateVars.getGuid[0].getGuid
            : this.state.getGuid?.length > 0
            ? this.state.getGuid[0].getGuid
            : null
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "TedarikciTicariBilgileri/TedarikciTicariBilgileriComponent_166808_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getDownloadGuid = result?.data.getDownloadGuid;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TedarikciTicariBilgileriComponent_166808_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TedarikciTicariBilgileriComponent_166808_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "PDFViewerFormsVergiLevhasi",
      "prmGuid",
      ReactSystemFunctions.value(
        this,
        stateVars.getDownloadGuid?.length > 0
          ? stateVars.getDownloadGuid[0].getDownloadGuid
          : this.state.getDownloadGuid?.length > 0
          ? this.state.getDownloadGuid[0].getDownloadGuid
          : null
      )
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "TedarikciTicariBilgileri",
      "PDFViewerFormsVergiLevhasi",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "4573",
      null,
      "left",
      null,
      "45%",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TedarikciTicariBilgileriComponent_795456_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      prmID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmID ?? this.props.screenInputs.prmid,
        "Guid"
      ),
      getGuid_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.getGuid?.length > 0
            ? stateVars.getGuid[0].getGuid
            : this.state.getGuid?.length > 0
            ? this.state.getGuid[0].getGuid
            : null
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "TedarikciTicariBilgileri/TedarikciTicariBilgileriComponent_795456_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getDownloadGuid = result?.data.getDownloadGuid;
    stateVars.VergiLevhasiById = result?.data.vergiLevhasiById;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TedarikciTicariBilgileriComponent_795456_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TedarikciTicariBilgileriComponent_795456_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.DownloadFile = await ReactSystemFunctions.downloadFile(
      ReactSystemFunctions.toString(
        this,
        stateVars.VergiLevhasiById?.length > 0
          ? stateVars.VergiLevhasiById[0].vergiLevhasi
          : this.state.VergiLevhasiById?.length > 0
          ? this.state.VergiLevhasiById[0].vergiLevhasi
          : null
      ),
      ReactSystemFunctions.toString(
        this,
        stateVars.VergiLevhasiById?.length > 0
          ? stateVars.VergiLevhasiById[0].vergiLevhasiDosyaAd
          : this.state.VergiLevhasiById?.length > 0
          ? this.state.VergiLevhasiById[0].vergiLevhasiDosyaAd
          : null
      )
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TedarikciTicariBilgileriComponent_948040_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tedarikciticaribilgileri_899075_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "tedarikciticaribilgileri_899075_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(Guid.create().toString(), "Guid"),
      NotKisiID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kUserId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kUserId
            : null
        ),
        "Guid"
      ),
      NotTarihi_0: ReactSystemFunctions.convertToTypeByName(moment().format("YYYY-MM-DDTHH:mm:ss"), "Date"),
      UyeID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.getGuid?.length > 0
            ? stateVars.getGuid[0].getGuid
            : this.state.getGuid?.length > 0
            ? this.state.getGuid[0].getGuid
            : null
        ),
        "Guid"
      ),
      DuzeltmeNotu_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tedarikciticaribilgileri_899075_value", "value", "", "", "")
        ),
        "string"
      ),
      UyeID_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.getGuid?.length > 0
            ? stateVars.getGuid[0].getGuid
            : this.state.getGuid?.length > 0
            ? this.state.getGuid[0].getGuid
            : null
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "TedarikciTicariBilgileri/TedarikciTicariBilgileriComponent_948040_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_5;
    stateVars.UyelikFormuDuzeltmeNotuByUyeID = result?.data.uyelikFormuDuzeltmeNotuByUyeID;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TedarikciTicariBilgileriComponent_948040_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TedarikciTicariBilgileriComponent_948040_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "tedarikciticaribilgileri_899075_value",
      "",
      null
    );

    stateVars.dataSource_54204 = this.state.UyelikFormuDuzeltmeNotuByUyeID;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TedarikciTicariBilgileriComponent_29276_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tedarikciticaribilgileri_983752_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "tedarikciticaribilgileri_983752_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tedarikciticaribilgileri_590460_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "tedarikciticaribilgileri_590460_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tedarikciticaribilgileri_576795_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "tedarikciticaribilgileri_576795_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tedarikciticaribilgileri_27422_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "tedarikciticaribilgileri_27422_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tedarikciticaribilgileri_936868_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "tedarikciticaribilgileri_936868_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tedarikciticaribilgileri_546287_value", "value", "SelectCityAll", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "tedarikciticaribilgileri_546287_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "tedarikciticaribilgileri_100145_value",
            "value",
            "SelectDistrictAll",
            "id",
            ""
          )
        ),
        null
      ),
      message: "*",
      formName: "tedarikciticaribilgileri_100145_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tedarikciticaribilgileri_909820_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "tedarikciticaribilgileri_909820_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tedarikciticaribilgileri_158191_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "tedarikciticaribilgileri_158191_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tedarikciticaribilgileri_541657_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "tedarikciticaribilgileri_541657_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Prm1_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      Prm2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tedarikciticaribilgileri_983752_value", "value", "", "", "")
        ),
        "string"
      ),
      Prm3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tedarikciticaribilgileri_590460_value", "value", "", "", "")
        ),
        "string"
      ),
      Prm4_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tedarikciticaribilgileri_576795_value", "value", "", "", "")
        ),
        "string"
      ),
      Prm5_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tedarikciticaribilgileri_27422_value", "value", "", "", "")
        ),
        "string"
      ),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.getGuid?.length > 0
            ? stateVars.getGuid[0].getGuid
            : this.state.getGuid?.length > 0
            ? this.state.getGuid[0].getGuid
            : null
        ),
        "Guid"
      ),
      SirketAd_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tedarikciticaribilgileri_936868_value", "value", "", "", "")
        ),
        "string"
      ),
      CityID_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "tedarikciticaribilgileri_546287_value",
            "value",
            "SelectCityAll",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      DistrictID_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "tedarikciticaribilgileri_100145_value",
            "value",
            "SelectDistrictAll",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      Adres_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tedarikciticaribilgileri_909820_value", "value", "", "", "")
        ),
        "string"
      ),
      VergiDairesi_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tedarikciticaribilgileri_158191_value", "value", "", "", "")
        ),
        "string"
      ),
      VergiKimlikNo_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tedarikciticaribilgileri_541657_value", "value", "", "", "")
        ),
        "string"
      ),
      KUserId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kUserId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kUserId
            : null
        ),
        "Guid"
      ),
      BasvuruTarihi_1: ReactSystemFunctions.convertToTypeByName(moment().format("YYYY-MM-DDTHH:mm:ss"), "Date"),
      PowerUserOnay_1: ReactSystemFunctions.convertToTypeByName(false, "boolean"),
      UyelikDurumKey_1: ReactSystemFunctions.convertToTypeByName(null, "number"),
      UserName_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      UyeId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.getGuid?.length > 0
            ? stateVars.getGuid[0].getGuid
            : this.state.getGuid?.length > 0
            ? this.state.getGuid[0].getGuid
            : null
        ),
        "Guid"
      ),
      KUserId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kUserId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kUserId
            : null
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "TedarikciTicariBilgileri/TedarikciTicariBilgileriComponent_29276_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.ValidasyonKontrol = result?.data.validasyonKontrol;
    stateVars.SaveRecord = result?.data.saveRecord_6;
    stateVars.KullaniciDetayUserIdUyeIdUpdate = result?.data.kullaniciDetayUserIdUyeIdUpdate;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TedarikciTicariBilgileriComponent_29276_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TedarikciTicariBilgileriComponent_29276_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "2c828e6d_b74b_b393_b716_b684db96e4da_notify",
        this.defaultML,
        "Form Taslak Olarak Kayıt Edildi!"
      ),
      "success",
      "bottom-right",
      2,
      null,
      null,
      null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TedarikciTicariBilgileriComponent_887191_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tedarikciticaribilgileri_983752_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "tedarikciticaribilgileri_983752_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tedarikciticaribilgileri_590460_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "tedarikciticaribilgileri_590460_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tedarikciticaribilgileri_576795_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "tedarikciticaribilgileri_576795_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tedarikciticaribilgileri_27422_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "tedarikciticaribilgileri_27422_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tedarikciticaribilgileri_936868_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "tedarikciticaribilgileri_936868_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tedarikciticaribilgileri_546287_value", "value", "SelectCityAll", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "tedarikciticaribilgileri_546287_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "tedarikciticaribilgileri_100145_value",
            "value",
            "SelectDistrictAll",
            "id",
            ""
          )
        ),
        null
      ),
      message: "*",
      formName: "tedarikciticaribilgileri_100145_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tedarikciticaribilgileri_909820_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "tedarikciticaribilgileri_909820_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tedarikciticaribilgileri_158191_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "tedarikciticaribilgileri_158191_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tedarikciticaribilgileri_541657_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "tedarikciticaribilgileri_541657_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Prm1_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      Prm2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tedarikciticaribilgileri_983752_value", "value", "", "", "")
        ),
        "string"
      ),
      Prm3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tedarikciticaribilgileri_590460_value", "value", "", "", "")
        ),
        "string"
      ),
      Prm4_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tedarikciticaribilgileri_576795_value", "value", "", "", "")
        ),
        "string"
      ),
      Prm5_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tedarikciticaribilgileri_27422_value", "value", "", "", "")
        ),
        "string"
      ),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.getGuid?.length > 0
            ? stateVars.getGuid[0].getGuid
            : this.state.getGuid?.length > 0
            ? this.state.getGuid[0].getGuid
            : null
        ),
        "Guid"
      ),
      SirketAd_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tedarikciticaribilgileri_936868_value", "value", "", "", "")
        ),
        "string"
      ),
      CityID_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "tedarikciticaribilgileri_546287_value",
            "value",
            "SelectCityAll",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      DistrictID_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "tedarikciticaribilgileri_100145_value",
            "value",
            "SelectDistrictAll",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      Adres_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tedarikciticaribilgileri_909820_value", "value", "", "", "")
        ),
        "string"
      ),
      VergiDairesi_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tedarikciticaribilgileri_158191_value", "value", "", "", "")
        ),
        "string"
      ),
      VergiKimlikNo_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tedarikciticaribilgileri_541657_value", "value", "", "", "")
        ),
        "string"
      ),
      KUserId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kUserId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kUserId
            : null
        ),
        "Guid"
      ),
      BasvuruTarihi_1: ReactSystemFunctions.convertToTypeByName(moment().format("YYYY-MM-DDTHH:mm:ss"), "Date"),
      PowerUserOnay_1: ReactSystemFunctions.convertToTypeByName(false, "boolean"),
      UyelikDurumKey_1: ReactSystemFunctions.convertToTypeByName(-1, "number")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "TedarikciTicariBilgileri/TedarikciTicariBilgileriComponent_887191_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.ValidasyonKontrol = result?.data.validasyonKontrol;
    stateVars.SaveRecord = result?.data.saveRecord_7;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TedarikciTicariBilgileriComponent_887191_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TedarikciTicariBilgileriComponent_887191_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "efe70861_27ec_4ff7_ac0b_7cb3512eac71_notify",
        this.defaultML,
        "Form Kaydı Alındı!"
      ),
      "success",
      "bottom-right",
      2,
      null,
      null,
      null
    );

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TedarikciTicariBilgileriComponent_887191_onClick2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  TedarikciTicariBilgileriComponent_887191_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      actionkey_0: ReactSystemFunctions.convertToTypeByName("poweruseronayinagonder", "string"),
      uyeliktipi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.UyelikFormuById?.length > 0
            ? stateVars.UyelikFormuById[0].uyelikTipi
            : this.state.UyelikFormuById?.length > 0
            ? this.state.UyelikFormuById[0].uyelikTipi
            : null
        ),
        "string"
      ),
      numara_0: ReactSystemFunctions.convertToTypeByName(null, "string"),
      templateId_1: ReactSystemFunctions.convertToTypeByName("9859035e-d0f0-48b5-997e-892a19c42cc1", "string"),
      receivers_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.GenelParametrelerPDF?.length > 0
            ? stateVars.GenelParametrelerPDF[0].powerUserMail
            : this.state.GenelParametrelerPDF?.length > 0
            ? this.state.GenelParametrelerPDF[0].powerUserMail
            : null
        ),
        "string"
      ),
      sender_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      cc_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      bcc_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentFileName_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentBase64_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      PublinkDestekTelNo_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.GenelParametrelerPDF?.length > 0
            ? stateVars.GenelParametrelerPDF[0].destekTelNo
            : this.state.GenelParametrelerPDF?.length > 0
            ? this.state.GenelParametrelerPDF[0].destekTelNo
            : null
        ),
        "string"
      ),
      uyekategorisi_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyelikTipi
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyelikTipi
            : null
        ),
        "string"
      ),
      UyeIsmi_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].firstName
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].firstName
            : null
        ),
        "string"
      ),
      UyeSoyad_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[stateVars.MyContext.length - 1].lastName
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[this.state.MyContext.length - 1].lastName
            : null
        ),
        "string"
      ),
      language_1: ReactSystemFunctions.convertToTypeByName("", "string")
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "TedarikciTicariBilgileri/TedarikciTicariBilgileriComponent_887191_onClick2_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SendMailSettings = result?.data.sendMailSettings;
    stateVars.SendMail = result?.data.sendMail;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TedarikciTicariBilgileriComponent_887191_onClick3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TedarikciTicariBilgileriComponent_887191_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.UpdateUserStartingScreen = await ReactSystemFunctions.updateUserStartingScreen(
      ReactSystemFunctions.getMyUsername(),
      "317b40ed-f5d4-4cdd-9771-1b80f2ebabc6"
    );

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TedarikciTicariBilgileriComponent_887191_onClick4_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  TedarikciTicariBilgileriComponent_887191_onClick4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      UyeId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.getGuid?.length > 0
            ? stateVars.getGuid[0].getGuid
            : this.state.getGuid?.length > 0
            ? this.state.getGuid[0].getGuid
            : null
        ),
        "Guid"
      ),
      KUserId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kUserId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kUserId
            : null
        ),
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "TedarikciTicariBilgileri/TedarikciTicariBilgileriComponent_887191_onClick4_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.KullaniciDetayUserIdUyeIdUpdate = result?.data.kullaniciDetayUserIdUyeIdUpdate;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TedarikciTicariBilgileriComponent_887191_onClick5_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TedarikciTicariBilgileriComponent_887191_onClick5_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "TedarikciTicariBilgileri",
      "TedarikciBilgileriKontrol",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TedarikciTicariBilgileriComponent_797679_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UyelikOnay_0: ReactSystemFunctions.convertToTypeByName(null, "boolean"),
      UyelikOnayTarihi_0: ReactSystemFunctions.convertToTypeByName(moment().format("YYYY-MM-DDTHH:mm:ss"), "Date"),
      UyelikOnayKisi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kUserId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kUserId
            : null
        ),
        "Guid"
      ),
      UyelikFormuId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmID ?? this.props.screenInputs.prmid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    stateVars.Confirm = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "f0f9192f_fd93_5f61_2001_5e106d0ceece_confirmation",
        this.defaultML,
        "Üyelik Düzeltme İçin Geri Gönderilecek, Açıklma Girildi ise İşleme Devam Edilsin mi?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel",
      customClass: {
        title: "swal2-font-poppins"
      }
    });

    if (stateVars.Confirm.isConfirmed) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "TedarikciTicariBilgileri/TedarikciTicariBilgileriComponent_797679_onClick",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.PowerUserOnay = result?.data.powerUserOnay;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TedarikciTicariBilgileriComponent_797679_onClick1_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TedarikciTicariBilgileriComponent_797679_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.UpdateUserStartingScreen = await ReactSystemFunctions.updateUserStartingScreen(
      ReactSystemFunctions.toString(
        this,
        stateVars.UyelikFormuById?.length > 0
          ? stateVars.UyelikFormuById[0].userName
          : this.state.UyelikFormuById?.length > 0
          ? this.state.UyelikFormuById[0].userName
          : null
      ),
      "db775535-6f5f-4209-adfb-ec684cd0ffea"
    );
    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "4bcc3b9b_1a3a_1a42_716b_5d395eef5529_notify",
        this.defaultML,
        "Üyelik Bilgileri Düzeltme İçin Geri Gönderildi!"
      ),
      "success",
      "bottom-right",
      2,
      null,
      null,
      null
    );

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TedarikciTicariBilgileriComponent_797679_onClick2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  TedarikciTicariBilgileriComponent_797679_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      actionkey_0: ReactSystemFunctions.convertToTypeByName("powerusergerigonder", "string"),
      uyeliktipi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.UyelikFormuById?.length > 0
            ? stateVars.UyelikFormuById[0].uyelikTipi
            : this.state.UyelikFormuById?.length > 0
            ? this.state.UyelikFormuById[0].uyelikTipi
            : null
        ),
        "string"
      ),
      numara_0: ReactSystemFunctions.convertToTypeByName(null, "string"),
      templateId_1: ReactSystemFunctions.convertToTypeByName("2f71eae3-f529-4bc4-b700-59e0a52004b6", "string"),
      receivers_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.UyelikFormuById?.length > 0
            ? stateVars.UyelikFormuById[0].userName
            : this.state.UyelikFormuById?.length > 0
            ? this.state.UyelikFormuById[0].userName
            : null
        ),
        "string"
      ),
      sender_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      cc_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      bcc_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentFileName_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentBase64_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      PublinkDestekTelNo_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.GenelParametrelerPDF?.length > 0
            ? stateVars.GenelParametrelerPDF[0].destekTelNo
            : this.state.GenelParametrelerPDF?.length > 0
            ? this.state.GenelParametrelerPDF[0].destekTelNo
            : null
        ),
        "string"
      ),
      UyeIsmi_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.UyelikFormuById?.length > 0
            ? stateVars.UyelikFormuById[0].firstName
            : this.state.UyelikFormuById?.length > 0
            ? this.state.UyelikFormuById[0].firstName
            : null
        ),
        "string"
      ),
      UyeKategorisi_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.UyelikFormuById?.length > 0
            ? stateVars.UyelikFormuById[0].uyelikTipi
            : this.state.UyelikFormuById?.length > 0
            ? this.state.UyelikFormuById[0].uyelikTipi
            : null
        ),
        "string"
      ),
      UyeSoyad_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.UyelikFormuById?.length > 0
            ? stateVars.UyelikFormuById[0].lastName
            : this.state.UyelikFormuById?.length > 0
            ? this.state.UyelikFormuById[0].lastName
            : null
        ),
        "string"
      ),
      language_1: ReactSystemFunctions.convertToTypeByName("", "string")
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "TedarikciTicariBilgileri/TedarikciTicariBilgileriComponent_797679_onClick2_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SendMailSettings = result?.data.sendMailSettings;
    stateVars.SendMail = result?.data.sendMail;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TedarikciTicariBilgileriComponent_797679_onClick3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TedarikciTicariBilgileriComponent_797679_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TedarikciTicariBilgileriComponent_717263_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UyelikOnay_0: ReactSystemFunctions.convertToTypeByName(false, "boolean"),
      UyelikOnayTarihi_0: ReactSystemFunctions.convertToTypeByName(moment().format("YYYY-MM-DDTHH:mm:ss"), "Date"),
      UyelikOnayKisi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kUserId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kUserId
            : null
        ),
        "Guid"
      ),
      UyelikFormuId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmID ?? this.props.screenInputs.prmid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    stateVars.Confirm = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "f3c3ae9a_b3d2_f0b5_749f_24d8fdeda127_confirmation",
        this.defaultML,
        "Üyelik Red Edilecektir; İşleme Devam Edilsin mi?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel",
      customClass: {
        title: "swal2-font-poppins"
      }
    });

    if (stateVars.Confirm.isConfirmed) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "TedarikciTicariBilgileri/TedarikciTicariBilgileriComponent_717263_onClick",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.PowerUserOnay = result?.data.powerUserOnay;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TedarikciTicariBilgileriComponent_717263_onClick1_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TedarikciTicariBilgileriComponent_717263_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "a7ae66d3_b8ca_0573_ecb0_6c67ba5c662e_notify",
        this.defaultML,
        "Form Red Edildi!"
      ),
      "success",
      "bottom-right",
      2,
      null,
      null,
      null
    );

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TedarikciTicariBilgileriComponent_717263_onClick2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  TedarikciTicariBilgileriComponent_717263_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      actionkey_0: ReactSystemFunctions.convertToTypeByName("poweruserred", "string"),
      uyeliktipi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.UyelikFormuById?.length > 0
            ? stateVars.UyelikFormuById[0].uyelikTipi
            : this.state.UyelikFormuById?.length > 0
            ? this.state.UyelikFormuById[0].uyelikTipi
            : null
        ),
        "string"
      ),
      numara_0: ReactSystemFunctions.convertToTypeByName(null, "string"),
      templateId_1: ReactSystemFunctions.convertToTypeByName("4239c471-f052-4cd0-ae85-0e4ad91e9471", "string"),
      receivers_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.UyelikFormuById?.length > 0
            ? stateVars.UyelikFormuById[0].userName
            : this.state.UyelikFormuById?.length > 0
            ? this.state.UyelikFormuById[0].userName
            : null
        ),
        "string"
      ),
      sender_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      cc_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      bcc_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentFileName_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentBase64_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      PublinkDestekTelNo_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.GenelParametrelerPDF?.length > 0
            ? stateVars.GenelParametrelerPDF[0].destekTelNo
            : this.state.GenelParametrelerPDF?.length > 0
            ? this.state.GenelParametrelerPDF[0].destekTelNo
            : null
        ),
        "string"
      ),
      uyekategorisi_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.UyelikFormuById?.length > 0
            ? stateVars.UyelikFormuById[0].uyelikTipi
            : this.state.UyelikFormuById?.length > 0
            ? this.state.UyelikFormuById[0].uyelikTipi
            : null
        ),
        "string"
      ),
      UyeIsmi_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.UyelikFormuById?.length > 0
            ? stateVars.UyelikFormuById[0].firstName
            : this.state.UyelikFormuById?.length > 0
            ? this.state.UyelikFormuById[0].firstName
            : null
        ),
        "string"
      ),
      UyeSoyad_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.UyelikFormuById?.length > 0
            ? stateVars.UyelikFormuById[0].lastName
            : this.state.UyelikFormuById?.length > 0
            ? this.state.UyelikFormuById[0].lastName
            : null
        ),
        "string"
      ),
      language_1: ReactSystemFunctions.convertToTypeByName("", "string")
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "TedarikciTicariBilgileri/TedarikciTicariBilgileriComponent_717263_onClick2_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SendMailSettings = result?.data.sendMailSettings;
    stateVars.SendMail = result?.data.sendMail;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TedarikciTicariBilgileriComponent_717263_onClick3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TedarikciTicariBilgileriComponent_717263_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TedarikciTicariBilgileriComponent_232553_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UyelikOnay_0: ReactSystemFunctions.convertToTypeByName(true, "boolean"),
      UyelikOnayTarihi_0: ReactSystemFunctions.convertToTypeByName(moment().format("YYYY-MM-DDTHH:mm:ss"), "Date"),
      UyelikOnayKisi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kUserId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kUserId
            : null
        ),
        "Guid"
      ),
      UyelikFormuId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmID ?? this.props.screenInputs.prmid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    stateVars.Confirm = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "37f2ae8e_6d20_4e53_91bf_d966b7a23001_confirmation",
        this.defaultML,
        "Üyelik Onaylanacaktır; İşleme Devam Edilsin mi?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel",
      customClass: {
        title: "swal2-font-poppins"
      }
    });

    if (stateVars.Confirm.isConfirmed) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "TedarikciTicariBilgileri/TedarikciTicariBilgileriComponent_232553_onClick",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.PowerUserOnay = result?.data.powerUserOnay;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TedarikciTicariBilgileriComponent_232553_onClick1_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TedarikciTicariBilgileriComponent_232553_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.UpdateUserStartingScreen = await ReactSystemFunctions.updateUserStartingScreen(
      ReactSystemFunctions.toString(
        this,
        stateVars.UyelikFormuById?.length > 0
          ? stateVars.UyelikFormuById[0].userName
          : this.state.UyelikFormuById?.length > 0
          ? this.state.UyelikFormuById[0].userName
          : null
      ),
      "d7f40e29-7fa4-40c4-ae18-230255e8fb36"
    );
    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "fc81c1ae_e694_f9a3_3fde_2a8f206e4e51_notify",
        this.defaultML,
        "Form Onaylandı!"
      ),
      "success",
      "bottom-right",
      2,
      null,
      null,
      null
    );

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TedarikciTicariBilgileriComponent_232553_onClick2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  TedarikciTicariBilgileriComponent_232553_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      actionkey_0: ReactSystemFunctions.convertToTypeByName("poweruseronay", "string"),
      uyeliktipi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.UyelikFormuById?.length > 0
            ? stateVars.UyelikFormuById[0].uyelikTipi
            : this.state.UyelikFormuById?.length > 0
            ? this.state.UyelikFormuById[0].uyelikTipi
            : null
        ),
        "string"
      ),
      numara_0: ReactSystemFunctions.convertToTypeByName(null, "string"),
      templateId_1: ReactSystemFunctions.convertToTypeByName("ee24e6c3-69ce-47a9-9dad-dff5e55542d0", "string"),
      receivers_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.UyelikFormuById?.length > 0
            ? stateVars.UyelikFormuById[0].userName
            : this.state.UyelikFormuById?.length > 0
            ? this.state.UyelikFormuById[0].userName
            : null
        ),
        "string"
      ),
      sender_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      cc_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      bcc_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentFileName_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentBase64_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      PublinkDestekTelNo_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.GenelParametrelerPDF?.length > 0
            ? stateVars.GenelParametrelerPDF[0].destekTelNo
            : this.state.GenelParametrelerPDF?.length > 0
            ? this.state.GenelParametrelerPDF[0].destekTelNo
            : null
        ),
        "string"
      ),
      UyeIsmi_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.UyelikFormuById?.length > 0
            ? stateVars.UyelikFormuById[0].firstName
            : this.state.UyelikFormuById?.length > 0
            ? this.state.UyelikFormuById[0].firstName
            : null
        ),
        "string"
      ),
      UyeKategorisi_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.UyelikFormuById?.length > 0
            ? stateVars.UyelikFormuById[0].uyelikTipi
            : this.state.UyelikFormuById?.length > 0
            ? this.state.UyelikFormuById[0].uyelikTipi
            : null
        ),
        "string"
      ),
      UyeSoyad_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.UyelikFormuById?.length > 0
            ? stateVars.UyelikFormuById[0].lastName
            : this.state.UyelikFormuById?.length > 0
            ? this.state.UyelikFormuById[0].lastName
            : null
        ),
        "string"
      ),
      language_1: ReactSystemFunctions.convertToTypeByName("", "string")
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "TedarikciTicariBilgileri/TedarikciTicariBilgileriComponent_232553_onClick2_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SendMailSettings = result?.data.sendMailSettings;
    stateVars.SendMail = result?.data.sendMail;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TedarikciTicariBilgileriComponent_232553_onClick3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TedarikciTicariBilgileriComponent_232553_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [412866, , 112560, 429152, 675723, 4573] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.TedarikciTicariBilgileriPageInit();
    }
  }
}
